import { put, select } from 'redux-saga/effects';
import {
  selectCards,
  selectPinnedCards, setCards, setPinnedCards,
} from '../../../state/cards/cardsSlice';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';

export default function* togglePinned({ payload: { cardId, pinned } }) {
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);
  const pinnedCards = yield select(selectPinnedCards);
  const otherCards = yield select(selectCards);

  let card;

  if (pinned) {
    const otherOrder = [];

    // get card and remove from list
    for (let i = 0; i < otherCards.length; i += 1) {
      if (otherCards[i].id !== cardId) {
        otherOrder.push(otherCards[i]);
      } else {
        card = otherCards[i];
      }
    }

    localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify([card, ...pinnedCards]));
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(otherOrder));

    yield put(setPinnedCards([card, ...pinnedCards]));
    yield put(setCards(otherOrder));
  } else {
    const pinnedOrder = [];

    // get card and remove from list
    for (let i = 0; i < pinnedCards.length; i += 1) {
      if (pinnedCards[i].id !== cardId) {
        pinnedOrder.push(pinnedCards[i]);
      } else {
        card = pinnedCards[i];
      }
    }

    localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify(pinnedOrder));
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify([card, ...otherCards]));

    yield put(setPinnedCards(pinnedOrder));
    yield put(setCards([card, ...otherCards]));
  }
}
