export default (removedCodecsList) => (description) => {
  const removedCodecs = removedCodecsList.split(' ');

  // logic partially borrowed from SIP.js
  const mediaDescs = [];
  const lines = description.sdp.split(/\r\n/);
  let currentMediaDesc;
  for (let i = 0; i < lines.length;) {
    const line = lines[i];
    if (/^m=(?:audio|video)/.test(line)) {
      currentMediaDesc = {
        index: i,
        stripped: [],
      };
      mediaDescs.push(currentMediaDesc);
      i += 1;
    } else if (currentMediaDesc) {
      const rtpmap = /^a=rtpmap:(\d+) ([^/]+)\//.exec(line);
      const fmtp = /^a=fmtp:(\d+) .+?$/.exec(line);
      const rtcpfb = /^a=rtcp-fb:(\d+) .+?$/.exec(line);

      if (rtpmap && removedCodecs.includes(rtpmap[1])) {
        lines.splice(i, 1);
        currentMediaDesc?.stripped?.push(rtpmap[1]);
      } else if (fmtp && removedCodecs.includes(fmtp[1])) {
        if (!currentMediaDesc?.stripped?.includes(fmtp[1])) {
          currentMediaDesc?.stripped?.push(fmtp[1]);
        }
        lines.splice(i, 1);
      } else if (rtcpfb && removedCodecs.includes(rtcpfb[1])) {
        if (!currentMediaDesc?.stripped?.includes(rtcpfb[1])) {
          currentMediaDesc?.stripped?.push(rtcpfb[1]);
        }
        lines.splice(i, 1);
      } else {
        i += 1;
      }
    } else {
      i += 1;
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const mediaDesc of mediaDescs) {
    const mline = lines[mediaDesc.index].split(' ');

    // Ignore the first 3 parameters of the mline. The codec information is after that
    for (let j = 3; j < mline.length;) {
      if (mediaDesc.stripped.indexOf(mline[j]) !== -1) {
        mline.splice(j, 1);
      } else {
        j += 1;
      }
    }

    lines[mediaDesc.index] = mline.join(' ');
  }

  // eslint-disable-next-line no-param-reassign
  description.sdp = lines.join('\r\n');

  return Promise.resolve(description);
};
