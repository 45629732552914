import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import {
  ERROR, INFO, SUCCESS, WARN,
} from '../../../constants';

function SnackbarIcon({ type }) {
  const sharedStyles = {
    infoCtn: {
      backgroundColor: 'rgba(104, 142, 255, 0.16)',
    },
    successCtn: {
      backgroundColor: 'rgba(84, 214, 44, 0.16)',
    },
    warnCtn: {
      backgroundColor: 'rgba(255, 193, 7, 0.16)',
    },
    errorCtn: {
      backgroundColor: 'rgba(255, 72, 66, 0.16)',
    },
  };

  let icon = '';
  if (type === INFO) {
    icon = (
      <InfoIcon sx={{
        m: '6px',
        color: '#1890ff',
      }}
      />
    );
  } else if (type === SUCCESS) {
    icon = (
      <CheckCircleIcon
        sx={{
          m: '6px',
          color: '#54d62c',
        }}
      />
    );
  } else if (type === WARN) {
    icon = (
      <WarningIcon
        sx={{
          m: '6px',
          color: '#ffc107',
        }}
      />
    );
  } else if (type === ERROR) {
    icon = (
      <ErrorIcon
        sx={{
          m: '6px',
          color: '#ff4842',
        }}
      />
    );
  }

  return (
    <Box sx={{
      position: 'relative',
      width: 40,
      height: 40,
      borderRadius: 1.5,
      ...sharedStyles[`${type}Ctn`],
    }}
    >
      {icon}
    </Box>
  );
}

SnackbarIcon.defaultProps = {
  type: null,
};

SnackbarIcon.propTypes = {
  type: PropTypes.oneOf([INFO, SUCCESS, WARN, ERROR]),
};

export default SnackbarIcon;
