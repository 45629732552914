import { call, put, select } from 'redux-saga/effects';
import { selectCallSessions, setActiveCount } from '../../../state/callSessions/callSessionsSlice';
import getActiveCount from '../../../utils/getActiveCount';
import getCardId from '../../../services/getCardId/getCardId';
import removeCard from '../../cardManagment/removeCard/removeCard';

export default function* updateActiveCount() {
  const sessions = yield select(selectCallSessions);
  const count = getActiveCount(sessions);
  yield put(setActiveCount(count));

  if (!count) {
    const cardId = getCardId({ type: 'call' });
    if (cardId) {
      yield call(removeCard, { payload: cardId });
    }
  }
}
