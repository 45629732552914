import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectAppName } from '../../state/configs/configsSlice';
import { setCallFromDevice } from '../../state/webphoneDevice/webphoneDeviceSlice';

export default function* setCallFrom(webphoneDevice) {
  // console.debug('in setcallfrom webphoneDevice: ', webphoneDevice);
  // console.debug('in setcallfrom webphoneDevice.payload: ', webphoneDevice.payload);

  if (webphoneDevice.payload !== undefined) {
    // eslint-disable-next-line no-param-reassign
    webphoneDevice = webphoneDevice.payload;
  }

  const callFromDevice = { ...webphoneDevice };
  callFromDevice.display = callFromDevice.aor.replace('sip:', '');
  callFromDevice.display = callFromDevice.display.replace(`@${callFromDevice.subscriber_domain}`, '');

  const appName = yield select(selectAppName);
  const decodedToken = yield call(nsToken.getDecoded);

  const storageDevice = localStorage.getItem(`${appName}-${decodedToken.user}_callfrom`);

  if (storageDevice) {
    const storedDevice = JSON.parse(storageDevice || '{}');

    if (storedDevice.aor === webphoneDevice.aor) {
      yield put(setCallFromDevice(callFromDevice));
    } else {
      const res = yield nsApi.get({
        object: 'device',
        action: 'read',
        user: decodedToken.user,
        domain: decodedToken.domain,
        noNDP: 'true',
        mode: 'registered_endpoint',
        format: 'json',
      });

      // console.debug('in setcallfrom res: ', res);

      let devices = _.compact(res);
      devices = _.filter(devices, (device) => device
        && device.aor
        && device.aor.indexOf('conference-bridge') === -1
        && device.aor.indexOf('vb') === -1);

      // console.debug('in setcallfrom devices: ', devices);

      if (devices && devices.length) {
        let match;
        for (let i = 0; i < devices.length; i += 1) {
          if (storedDevice.aor === devices[i].aor
            || storedDevice.aor === devices[i].aor
              .replace('sip:', '')
              .replace(`@${devices[i].subscriber_domain}`, '')
          ) {
            devices[i].display = devices[i].aor.replace('sip:', '');
            devices[i].display = devices[i].display.replace(`@${devices[i].subscriber_domain}`, '');
            match = devices[i];
            break;
          }
        }

        if (match) {
          yield put(setCallFromDevice(match));
        } else {
          yield put(setCallFromDevice(callFromDevice));
        }
      } else {
        yield put(setCallFromDevice(callFromDevice));
      }
    }
  } else {
    localStorage.setItem(
      `${appName}-${decodedToken.user}_callfrom`,
      JSON.stringify(webphoneDevice),
    );
    yield put(setCallFromDevice(callFromDevice));
  }
}
