import {
  call, put, select, delay,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import { UserAgent, Inviter } from 'sip.js';
import i18n from 'i18next';
import _ from 'lodash';
import { selectHasPermission } from '../../../state/userMedia/userMediaSlice';
import {
  selectAppName,
  selectConfig,
  selectDevicePostFix,
  selectMaxActiveCalls,
  selectThreeWayCallDisconnectOthersOnEnd,
} from '../../../state/configs/configsSlice';
import {
  addAnsweredCall,
  addCall,
  getDefaultAudioInput,
  getMediaDevices,
} from '../../../utils/devices';
import { selectUA } from '../../../state/ua/uaSlice';
import getActiveCount from '../../../utils/getActiveCount';
import { selectActiveCount, selectCallSessions, setActiveCallId } from '../../../state/callSessions/callSessionsSlice';
import matchContact from '../../../utils/matchContact';
import CallSession from '../../../models/CallSession';
import {
  playBusy, playFastBusy, playRingback, stopRingback,
} from '../../../utils/audio';
import stripCodecs from '../../../utils/stripCodecs';
import removeCodecs from '../../../utils/removeCodecs';
import addCallSession from '../../callSessions/addCallSession/addCallSession';
import getCardId from '../../../services/getCardId/getCardId';
import { setExpandedId } from '../../../state/cards/cardsSlice';
import addCard from '../../cardManagment/addCard/addCard';
import putSessionsOnHold from '../../callSessions/putSessionsOnHold/putSessionsOnHold';
import store from '../../../state/store';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';
import { ERROR, INFO } from '../../../constants';
import { setDialog } from '../../../state/dialog/dialogSlice';
import { fetchCallHistoryAction } from '../../callHistory/fetchCallHistory/action';
import { selectCallFromDevice } from '../../../state/webphoneDevice/webphoneDeviceSlice';
import { initiateCallFromAction } from '../../initiateCallFrom/action';
import replaceConferenceSession from '../../callSessions/replaceConferenceSession/replaceConferenceSession';
import { processDtmfStringAction } from '../processDtmfString/action';

/**
 * This will initiate call via User Agent (sip)
 * This is used to call from the webphone
 * See initiateCallFrom to make a call from a different device
 * @returns {Generator<*, void, *>}
 */
export default function* initiateUACall({ payload }) {
  const phoneNumber = _.toString(payload.phoneNumber);
  const maxActiveCalls = yield select(selectMaxActiveCalls);
  const activeCallSessionCount = yield select(selectActiveCount);
  const dtmfString = _.toString(payload.dtmfString);

  const callFromDevice = yield select(selectCallFromDevice);
  if (callFromDevice && !callFromDevice.aor.includes('wp')) {
    yield put(setSnackbar({
      type: INFO,
      open: true,
      message: `${i18n.t('CALLING_FROM')} ${callFromDevice.display}`,
    }));
    yield put(initiateCallFromAction(phoneNumber));
    return;
  }

  if (activeCallSessionCount >= maxActiveCalls && !payload.isConference) {
    console.debug('dont allow call');

    yield put(setSnackbar({
      open: false,
    }));

    yield put(setDialog({
      open: true,
      title: i18n.t('CALL_LIMIT_REACHED'),
      text: i18n.t('CANNOT_START_ANOTHER_CALL'),
      cancelText: i18n.t('DISMISS'),
    }));

    return;
  }

  let directUri = null;
  if (phoneNumber.includes('sip:')) {
    directUri = phoneNumber;
  }

  // clean number
  const cleanNumber = phoneNumber.replace(/[^0-9*#+]/g, '');

  if (cleanNumber.length < 1) {
    return;
  }

  const decodedToken = nsToken.getDecoded();

  // check if the browser has audio permission
  const hasPermission = yield select(selectHasPermission);
  if (!hasPermission) {
    return;
  }

  const devices = yield getMediaDevices();
  const appName = yield select(selectAppName);
  const inputDeviceId = getDefaultAudioInput(appName, decodedToken.user, devices);
  // const outputDeviceId = userMedia.getDefaultAudioOutput(appName, decodedToken.user, devices);

  const uri = directUri || `sip:${cleanNumber}@${decodedToken.domain}`;

  const ua = yield select(selectUA);
  const sessions = yield select(selectCallSessions);

  if (getActiveCount(sessions) < 1) {
    // set active id to null
    yield put(setActiveCallId(null));
  }

  // put any previous call sessions on hold by checking if any are active
  yield call(putSessionsOnHold);

  // match contact
  const contact = matchContact(cleanNumber);

  // Send an outgoing INVITE request
  const target = UserAgent.makeURI(uri.replace('#', '%23'));
  if (!target) {
    throw new Error('Failed to create target URI.');
  }

  const handlerOptions = {
    iceGatheringTimeout: ua.options.iceGatheringTimeout,
    constraints: {
      audio: { deviceId: inputDeviceId?.deviceId },
      video: false,
    },
  };

  const allowedCodecs = yield select((state) => selectConfig(state, 'PORTAL_WEBRTC_CODEC_LIST_AUDIO'));
  // [WP-1261][WP-1265] remove G726 & G729a
  const removedCodecs = yield select((state) => selectConfig(state, 'PORTAL_WEBRTC_CODEC_REMOVED_AUDIO') || '2 18');

  const inviterOptions = {
    earlyMedia: true,
    sessionDescriptionHandlerOptions: handlerOptions,
    sessionDescriptionHandlerOptionsReInvite: handlerOptions,
    sessionDescriptionHandlerModifiers: [
      allowedCodecs && allowedCodecs.length > 0 ? stripCodecs(allowedCodecs) : null,
      removedCodecs && removedCodecs.length > 0 ? removeCodecs(removedCodecs) : null,
    ],
  };

  // Create new Session instance in "initial" state
  const outgoingSession = new Inviter(ua, target, inviterOptions);

  // new session props
  const props = {
    contact,
    direction: 'outbound',
    id: outgoingSession.id,
    isMuted: false,
    number: cleanNumber,
    session: outgoingSession,
    status: 'outboundProgressing',
    // WP-781, WP-1088, WP-1435
    from_tag: outgoingSession.fromTag || outgoingSession.request.fromTag,
  };

  if (contact) {
    props.name = contact.name || null;
  }

  if ((directUri && directUri.includes('adhoc')) || payload.isConference) {
    props.name = directUri;
    props.isConference = true;
  }

  // initial state - {isMuted: false, status: 'trying'},
  const devicePostFix = yield select(selectDevicePostFix);
  const callSession = new CallSession(props);
  const threeWayCallDisconnectOthersOnEnd = yield select(selectThreeWayCallDisconnectOthersOnEnd);

  // Options including delegate to capture response messages
  // Replaces attachOutboundHandlers
  const inviteOptions = {
    iceGatheringTimeout: 500,
    requestDelegate: {
      onAccept: (response) => {
        console.debug('Positive response = ', response);

        // trigger the auto_dsc for assisted 3 way call
        if (directUri && directUri.includes('adhoc') && payload.isConference) {
          callSession.threeWayCallDisconnectOthersOnEndFn = () => {
            nsApi.get({ object: 'participant', action: 'read', conference_match: directUri })
              .then((participants) => {
                participants.forEach((participant) => {
                  if (participant.participant_match !== `sip:${decodedToken.user}${devicePostFix}@${decodedToken.domain}`) {
                    nsApi.post({
                      object: 'participant',
                      action: 'update',
                      user: decodedToken.user,
                      conference_match: directUri,
                      conference: directUri,
                      participant_match: participant.participant_match,
                      participant: participant.participant_match,
                      auto_dsc: threeWayCallDisconnectOthersOnEnd ? '2' : '1',
                    });
                  }
                });
              });
          };
        }
        if (dtmfString && dtmfString.length > 0) {
          store.dispatch(processDtmfStringAction({ dtmfString, activeCall: callSession }));
        }

        // const pc = outgoingSession.sessionDescriptionHandler.peerConnection;
        // const remoteStream = new MediaStream();
        // pc.getReceivers().forEach((receiver) => {
        //   const { track } = receiver;
        //   if (track) {
        //     remoteStream.addTrack(track);
        //   }
        // });
        //
        // const audioElement = getAudio('remote');
        // audioElement.srcObject = remoteStream;
        //
        // if (DetectRTC.isSetSinkIdSupported) {
        //   audioElement.setSinkId(outputDeviceId);
        // }
        // audioElement.play();
      },
      onProgress: (response) => {
        console.debug('onProgress', response);
        if (response.message.statusCode) {
          const code = response.message.statusCode;
          if (code === 180 && !callSession.hasEarlyMedia) {
            playRingback();
          } else if (code === 183) {
            // early media set up remotemedia stream
            callSession.hasEarlyMedia = true;
            callSession.setupRemoteMedia();
            stopRingback(); // NMS-820 early-media 183 dont play ringer
          }
        }

        console.debug('Positive response = ', response);
      },
      onReject: (response) => {
        console.debug('onReject', response);
        if (response.message.statusCode) {
          console.debug(`Negative response (code) = ${response.message.statusCode}`);

          if (response.message.statusCode === 407) {
            // faster call history grab
            store.dispatch(fetchCallHistoryAction());
            return;
          }
          if (response.message.statusCode === 487) {
            // faster call history grab
            store.dispatch(fetchCallHistoryAction());
            return;
          }

          const errorMessage = response.message.reasonPhrase || response.message.statusCode;
          console.debug(errorMessage);

          if (response.message.statusCode === 486 || response.message.statusCode === 603) {
            stopRingback();
            playBusy();
            store.dispatch(setSnackbar({
              type: INFO,
              open: true,
              message: i18n.t('CALL_BUSY'),
              duration: 6000,
            }));
          } else if (response.message.statusCode >= 400) {
            stopRingback();
            playFastBusy();
            store.dispatch(setSnackbar({
              type: ERROR,
              open: true,
              message: i18n.t('CALL_ERROR'),
              duration: 6000,
            }));
          }

          store.dispatch(fetchCallHistoryAction());
        }

        console.debug('Negative response (onReject) = ', response);
      },
      onTerminated: (response) => {
        console.debug('Negative response (onTerminated)= ', response);
      },
    },
  };

  try {
    yield outgoingSession.invite(inviteOptions);

    if (payload.isConference) {
      // replace temp call session with real session
      yield call(replaceConferenceSession, { payload: callSession });
    } else {
      // add the new call session
      yield call(addCallSession, { payload: callSession });
    }

    // set the active id as the new audio session
    yield put(setActiveCallId(callSession.id));

    // notify HID device
    addAnsweredCall(callSession.id);
    yield call(addCall);

    if (payload.genCard) {
      yield put(setExpandedId(null));
      yield delay(500);

      // when there's a call error or immediate hang up
      // the delay causes a bug where the call session is ended before the card is displayed
      // check if the session still exists before creating the card
      const stillActive = yield select(selectCallSessions);
      if (!stillActive.length) return;

      const cardId = getCardId({ type: 'call' });

      if (!cardId) {
        yield call(addCard, { payload: { type: 'call' } });
      }
    }

    if (_.isFunction(payload.onSuccess)) {
      payload.onSuccess();
    }
  } catch (e) {
    console.error(e);
  }
}
