// import moment from 'moment';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
// import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading,
} from '../../../state/chat/chatSlice';
import { selectHostname } from '../../../state/configs/configsSlice';
import sendMessage from '../sendMessage/sendMessage';

export default function* startVideo({ payload }) {
  const debug = false;
  if (debug) console.debug('in startVideo params type: ', payload);
  const decodedToken = yield call(nsToken.getDecoded);

  const {
    type,
    screenshare,
    session,
    createMessage,
  } = payload;

  const server = yield select(selectHostname);

  // if start send a message
  if (type === 'start') {
    // create a meeting with getId=yes
    const res = yield nsApi.post({
      object: 'meeting',
      action: 'create',
      user: decodedToken.user,
      domain: decodedToken.domain,
      getId: 'yes',
    }, {
      object: 'meeting',
      action: 'create',
      user: decodedToken.user,
      domain: decodedToken.domain,
      getId: 'yes',
    });

    if (debug) console.debug('this is res meeting: ', res);

    if (typeof res.id === 'undefined') {
      if (debug) console.debug('ERROR CREATING MEETING');
      return;
    }

    // send video message
    // only send message if parameter is set
    if (createMessage) {
      const args = {
        object: 'message',
        action: 'create',
        type: 'chat-video-invite',
        domain: decodedToken.domain,
        user: decodedToken.user,
        session_id: session.id,
        message: res.id.toString(), // doesnt actually appear anywhere, just placeholder
        destination: session.contactArr.map((obj) => obj.user).join(','),
      };
      if (debug) console.debug('this is args for sending out video meeting: ', args);

      yield call(sendMessage, { payload: args });
    }

    // open a new window for the video
    let url = `https://${server}/video/${type}?id=${res.id}`;

    const ext = session.contactArr.map((obj) => obj.user).join(',');
    if (ext) {
      url += `&users=${ext}`;
      if (screenshare) url += '&ss=1';
    }

    const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
    if (debug) console.debug('this is url: ', url);
    if (debug) console.debug('this is options: ', options);

    window.open(url, '_blank', options);
  } else if (type === 'schedule') {
    // schedule logic, just open a window for scheduling
    // separate because there is no message made
    let url = `https://${server}/video/schedule`;
    const ext = session.contactArr.map((obj) => obj.user).join(',');
    if (ext) {
      url += `?users=${ext}`;
    }
    const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
    if (debug) console.debug('this is url: ', url);
    if (debug) console.debug('this is options: ', options);

    window.open(url, '_blank', options);
  }

  yield put(setLoading(false));
  if (debug) console.debug('at the end of send message');
}
