import { call, put, select } from 'redux-saga/effects';
import { RegistererState } from 'sip.js';
import store from '../../../state/store';
import { incReconnectionAttempt, selectRegisterer } from '../../../state/ua/uaSlice';
import addRegistrar from '../addRegistrar/addRegistrar';
import { attemptReconnectAction } from '../attemptReconnect/action';
import bugsnagClient from '../../../services/bugsnag/bugsnag';

export default function* onConnect() {
  const registerer = yield select(selectRegisterer);

  const validState = [RegistererState.Initial, RegistererState.Unregistered];

  if (registerer && validState.includes(registerer.state) && !registerer.disposed) {
    try {
      yield registerer?.register({
        requestDelegate: {
          onReject(response) {
            console.debug('register onReject:', response);
            if (response.message.statusCode === 503 || response.message.statusCode === 500) {
              store.dispatch(incReconnectionAttempt());
            }
          },
        },
      });
    } catch (e) {
      if (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        bugsnagClient.notify(e);
        yield put(attemptReconnectAction());
      }
    }
  } else {
    yield call(addRegistrar);
  }
}
