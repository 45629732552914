import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectDevicePostFix } from '../../../state/configs/configsSlice';

export default function* transferQueued({ payload }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const devicePostFix = yield select(selectDevicePostFix);
  const { key, contact } = payload;
  const dest = contact || `${decodedToken.user}${devicePostFix}`;

  yield call(nsApi.post, {
    object: 'call',
    action: 'xfer',
    uid: decodedToken.sub,
    callid: key,
    destination: dest,
  });
}
