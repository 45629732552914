/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const routeStackSlice = createSlice({
  name: 'routeStack',
  initialState: {
    callStack: [],
    currentState: {},
    lastState: {},
    init: false,
    navStack: [],
  },
  reducers: {
    setCallStack: (state, action) => {
      state.callStack = action.payload;
    },
    setCurrentState: (state, action) => {
      state.lastState = state.currentState;
      state.currentState = action.payload;
    },
    setLastState: (state, action) => {
      state.lastState = action.payload;
    },
    setInit: (state, action) => {
      state.init = action.payload;
    },
    setNavStack: (state, action) => {
      state.navStack = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCallStack, setCurrentState, setLastState, setInit, setNavStack,
} = routeStackSlice.actions;

// Selectors
export const selectCallStack = (state) => state.routeStack.callStack;
export const selectCurrentState = (state) => state.routeStack.currentState;
export const selectLastState = (state) => state.routeStack.lastState;
export const selectInit = (state) => state.routeStack.init;
export const selectNavStack = (state) => state.routeStack.navStack;

export default routeStackSlice.reducer;
