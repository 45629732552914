import { BroadcastChannel } from 'broadcast-channel';
import store from '../state/store';
import { selectUniqID } from '../state/webphoneDevice/webphoneDeviceSlice';

const sendBroadcast = () => new Promise((resolve, reject) => {
  const uniqID = selectUniqID(store.getState());

  const channel = new BroadcastChannel('refresh');
  const pingMessage = {
    msg: 'reload check',
    uniqID,
  };

  const timeout = setTimeout(() => {
    channel.close();
    reject(new Error('No response from another instance.'));
  }, 1000);

  channel.onmessage = (msgRec) => {
    clearTimeout(timeout);
    channel.close();
    if (msgRec.msg === 'quiet, they are listening') {
      resolve();
    } else {
      reject(new Error('Invalid response from another instance.'));
    }
  };

  channel.postMessage(pingMessage);
});

export default sendBroadcast;
