import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Fade,
  Typography,
  Grid,
  Box,
} from '@mui/material';

import { Link } from 'react-router-dom';

import PageCenter from '../../templates/PageCenter/PageCenter';
import { getLoginRoute } from '../../../routePaths';

function ForgotPasswordExpired() {
  const { t } = useTranslation();

  return (
    <PageCenter>
      <Fade in>
        <Card sx={{
          maxWidth: 480,
          width: '100%',
          px: 2,
          pb: 0,
        }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {t('PASSWORD_RESET_EXPIRED')}
            </Typography>
            <Grid container direction="row" sx={{ mt: 2 }}>
              <Typography>
                {t('PASSWORD_RESET_EXPIRED_EXPLANATION')}
              </Typography>
            </Grid>
            <Box
              sx={{
                pt: 1,
              }}
            >
              <Link to={getLoginRoute()} style={{ textDecoration: 'none' }}>
                <Typography color="primary" variant="body2">{t('BACK_TO_LOGIN')}</Typography>
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </PageCenter>
  );
}

export default ForgotPasswordExpired;
