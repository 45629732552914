import moment from 'moment';
import _ from 'lodash';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  selectMessages, selectSessionsEntities, setLoading, setMessages,
} from '../../../state/chat/chatSlice';
import matchContact from '../../../utils/matchContact';
import fetchMessageSessions from '../fetchMessageSessions/fetchMessageSessions';
import { selectUser } from '../../../state/user/userSlice';
import deleteConversation from '../deleteConversation/deleteConversation';
import addCard from '../../cardManagment/addCard/addCard';
import fetchMessages from '../fetchMessages/fetchMessages';
import fetchMessageSession from '../fetchMessageSession/fetchMessageSession';
import showMessageNotification from '../showMessageNotification/showMessageNotification';

/* eslint-disable no-param-reassign */
export default function* addMessage(msg) {
  const debug = localStorage.getItem('debugFlag') === 'true';
  if (debug) console.debug('in addMessage params: ', msg);

  const user = yield select(selectUser);

  if (msg.type === 'chat-participant-remove'
    || msg.type === 'chat-participant-leave'
    || msg.type === 'chat-participant-add'
    || msg.type === 'chat-name-change'
    || msg.type === 'chat-leave'
    || msg.type === 'chat-video-invite'
  ) {
    msg.status_change = true;

    let uid;
    if (msg.text.split('@').length === 2) { // check if it's a uid
      uid = msg.text;
    } else if (msg.text.includes('<a href=')) {
      // work around for anchor element coming from somewhere
      const div = document.createElement('div');
      div.innerHTML = msg.text;
      uid = div.firstChild?.text;
    }

    if (debug) console.debug(msg.text);

    msg.term_contact = yield call(matchContact, uid);
    msg.from_contact = yield call(matchContact, msg.from_uid);
  }

  let videoInvite = false;
  let videoId = null;
  let videoInviteFrom = null;

  // if video invite, from someone else, and within the last 5 seconds, show video invite
  if (msg.type === 'chat-video-invite'
    && msg.direction !== 'term'
    && msg.from_contact?.uid !== user.uid
  ) {
    videoInvite = true;
    console.debug('adding video invite to card: ', msg.text);
    videoId = msg.text;
    videoInviteFrom = msg.from_contact;
  }

  // need to add in logic for chat-participant-add
  if (msg.type === 'chat-participant-add' && msg.text === user.uid) {
    // fetch the messagesessions, you have been added to the conversation
    yield call(fetchMessageSessions);
    yield call(fetchMessages, { payload: msg.session_id });
  }

  // will yield all the messages from the session_id,
  // later will check foundMessage on whether or not to add the message in or not
  yield call(fetchMessages, { payload: msg.session_id });

  if (msg.type === 'chat-participant-remove-notification' && msg.text === user.uid) {
    // need to delete the conversation
    const sessions = yield select(selectSessionsEntities);
    const session = sessions[msg.session_id];
    yield call(deleteConversation, {
      payload: {
        session,
      },
    });
    return;
  }

  const messages = yield select(selectMessages);
  if (debug) console.debug('this is messages: ', messages);

  let sessionMessages = messages.find((m) => m.id === msg.session_id);

  if (debug) console.debug('this is sessionMessages BEFORE: ', sessionMessages);

  // if the message already exists, do not add it again
  const foundMessage = _.find(sessionMessages?.messages, { id: msg.id });
  if (foundMessage !== undefined) {
    // open the card if it is a participant add
    if (msg.type === 'chat-participant-add') {
      const sessionsToOpen = yield select(selectSessionsEntities);
      const sessionToOpen = sessionsToOpen[msg.session_id];

      // open card
      const sendMessageParams = {
        chat: sessionToOpen,
      };
      const newCard = {
        type: 'conversation',
        sessionId: msg.session_id,
        sendMessageParams,
      };

      yield call(addCard, { payload: newCard });
    }
  }

  // if it is an chat-media message or mms, find if the message was added already (sent by you)
  if (msg.type === 'chat-media' || msg.type === 'mms') {
    const foundMediaMessage = _.find(sessionMessages.messages, {
      text: 'media',
      timeStampSent: msg.timeStampSent,
    });
    if (foundMediaMessage !== undefined) {
      return;
    }
  }

  let newMessages;

  if (sessionMessages && sessionMessages.messages) {
    const todayTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const timestampGroup = {
      type: 'timestampGroup',
      timestamp: moment(todayTime).locale(user.language).fromNow(),
      dateTime: moment.utc(),
    };

    msg.timestamp = moment(todayTime).locale(user.language).fromNow();
    msg.dateTime = moment.utc();
    msg.status = '';

    newMessages = foundMessage ? [...sessionMessages.messages] : [...sessionMessages.messages, msg];

    if (!_.findLast(sessionMessages.messages, { type: 'timestampGroup' })) {
      newMessages = [timestampGroup, ...newMessages];
    }

    sessionMessages = { ...sessionMessages, messages: newMessages };
  } else {
    const todayTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const timestampGroup = {
      type: 'timestampGroup',
      timestamp: moment(todayTime).locale(user.language).fromNow(),
      dateTime: moment.utc(),
    };

    newMessages = foundMessage ? [timestampGroup] : [timestampGroup, msg];

    sessionMessages = {
      id: msg.session_id,
      init: true,
      loading: false,
      messages: newMessages,
    };
  }

  if (debug) console.debug('this is sessionMessages AFTER: ', sessionMessages);
  yield put(setMessages(sessionMessages));

  // fetch the messagesession again to update it
  yield call(fetchMessageSessions);

  yield call(fetchMessageSession, { sessionId: msg.session_id });

  const sessionsToOpen = yield select(selectSessionsEntities);
  const sessionToOpen = sessionsToOpen[msg.session_id];

  // open card
  const sendMessageParams = {
    chat: sessionToOpen,
  };

  const newCard = {
    type: 'conversation',
    sessionId: msg.session_id,
    sendMessageParams,
    addMessage: true,
    videoInvite,
    videoId,
    videoInviteFrom,
  };

  yield call(addCard, { payload: newCard });

  yield call(showMessageNotification, msg);

  yield put(setLoading(false));
}
