import {
  put, select,
} from 'redux-saga/effects';
import {
  selectInCallContactsResultsSet, setInCallSearchedResultsSet,
} from '../../../state/contacts/contactsSlice';
import searchContacts from '../../../utils/contact/searchContacts';

export default function* processInCallSearchedResultSet({ payload: searchStr }) {
  const inCallContactsResultsSet = yield select(selectInCallContactsResultsSet);
  const searchResults = searchContacts(searchStr, inCallContactsResultsSet);

  yield put(setInCallSearchedResultsSet(searchResults));
}
