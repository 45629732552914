/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    isConnected: false,
    socket: null,
  },
  reducers: {
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsConnected, setSocket,
} = socketSlice.actions;

// Selectors
export const selectIsConnected = (state) => state.socket.isConnected;
export const selectSocket = (state) => state.socket.socket;

export default socketSlice.reducer;
