import _ from 'lodash';

/**
 * @param {[]} contactList
 * @param {string} sortBy 'first_name', 'last_name', 'online', 'extension'
 * @returns {object|Array}
 *
 * Return format for 'first_name', 'last_name'
 *      {A: [{contact}, {contact}], B: [{contact}], etc. }
 *
 * Return format for 'online'
 *      {online: [{contact}, {contact}], offline: [{contact}, {contact}]}
 *
 * Return format for 'extension'
 *      [{contact}, {contact}, etc.]
 */
export default function groupContactList(contactList, sortBy) {
  switch (sortBy) {
    case 'last_name':
      return _.groupBy(
        contactList,
        (contact) => (contact.last_name[0] ? contact.last_name[0].toUpperCase() : '-'),
      );
    case 'first_name':
      return _.groupBy(
        contactList,
        (contact) => (contact.first_name[0] ? contact.first_name[0].toUpperCase() : '-'),
      );
    case 'online':
      return _.groupBy(
        contactList,
        (contact) => {
          if (contact.extension && contact.presence) {
            return contact.presence === 'inactive' ? 'Offline' : 'Online';
          }
          return 'Offline';
        },
      );
    default:
      return contactList;
  }
}
