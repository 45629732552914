import { select } from 'redux-saga/effects';
import { selectConfig } from '../../state/configs/configsSlice';

export default function* setPageTitle() {
  const title = yield select((state) => selectConfig(state, 'PORTAL_WEBPHONE_NAME'));
  const searchElem = document.getElementsByTagName('title')[0];
  const titleElem = (searchElem) || document.createElement('title');
  titleElem.innerText = title || 'SNAPmobile Web';
  if (!searchElem) {
    document.getElementsByTagName('head')[0].appendChild(titleElem);
  }
}
