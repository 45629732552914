import { call, put } from 'redux-saga/effects';
import { setCurrentState, setCallStack } from '../../../state/routeStack/routeStackSlice';
import { getNavigate } from '../../../services/router/router';

export default function* callStackRebase({ payload }) {
  const navigate = yield call(getNavigate);
  yield put(setCurrentState(payload));
  yield put(setCallStack([payload]));
  navigate(payload.route, { state: payload.state });
}
