import _ from 'lodash';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import i18n from 'i18next';
import {
  selectGreetings,
  setGreetings,
} from '../../../state/greetings/greetingsSlice';

import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';
import { selectUser } from '../../../state/user/userSlice';
import { updateGreetingAction } from '../updateGreeting/action';

export default function* deleteGreeting({ payload }) {
  const token = yield call(nsToken.getDecoded);
  const user = yield select(selectUser);
  const setNewDefault = payload.index === +user.vmailGreeting;

  try {
    const params = {
      type: 'greeting',
      owner_domain: token.domain,
      owner: token.user,
      object: 'audio',
      action: 'delete',
      format: 'json',
      index: payload.index,
      intArray: 'true',

    };
    yield nsApi.post(params, params);

    const list = yield select(selectGreetings);

    const updatedList = _.filter(list, (g) => g.index !== payload.index);
    if (setNewDefault && updatedList.length > 0) {
      yield put(updateGreetingAction({
        ...user,
        vmailGreeting: String(updatedList[0].index),
      }));
    }
    yield put(setGreetings(updatedList));

    yield put(setSnackbar({
      open: true,
      message: i18n.t('GREETING_DELETED'),
    }));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setSnackbar({
      open: true,
      message: i18n.t('GREETING_DELETE_FAILED'),
    }));
  }
}
