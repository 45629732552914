import {
  all,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { sagaMiddleware } from '../state/store';

import addCallSessionSaga from './callSessions/addCallSession/addCallSession';
import { ADD_CALL_SESSION_ACTION_TYPE } from './callSessions/addCallSession/action';
import addCardSaga from './cardManagment/addCard/addCard';
import { ADD_CARD_ACTION_TYPE } from './cardManagment/addCard/action';
import addStreamSaga from './streams/addStream/addStream';
import { ADD_STREAM_ACTION_TYPE } from './streams/addStream/action';
import attemptReconnectSaga from './ua/attemptReconnect/attemptReconnect';
import { ATTEMPT_RECONNECT_ACTION_TYPE } from './ua/attemptReconnect/action';
import callStackBackSaga from './routeStacks/callStackBack/callStackBack';
import { CALL_STACK_BACK_ACTION_TYPE } from './routeStacks/callStackBack/action';
import callStackPushSaga from './routeStacks/callStackPush/callStackPush';
import { CALL_STACK_PUSH_ACTION_TYPE } from './routeStacks/callStackPush/action';
import callStackRebaseSaga from './routeStacks/callStackRebase/callStackRebase';
import { CALL_STACK_REBASE_ACTION_TYPE } from './routeStacks/callStackRebase/action';
import clearStackSaga from './routeStacks/clearStack/clearStack';
import { CLEAR_STACK_ACTION_TYPE } from './routeStacks/clearStack/action';
import connectSocketSaga from './socket/connectSocket/connectSocket';
import { CONNECT_SOCKET_TYPE } from './socket/connectSocket/action';
import contactSortedBySaga from './contacts/contactsSortedBy/contactsSortedBy';
import { CONTACTS_SORTED_BY_ACTION_TYPE } from './contacts/contactsSortedBy/action';
import contactFilteredBySaga from './contacts/contactsFilteredBy/contactsFilteredBy';
import { CONTACTS_FILTERED_BY_ACTION_TYPE } from './contacts/contactsFilteredBy/action';
import contactSearchBySaga from './contacts/contactsSearchBy/contactsSearchBy';
import { CONTACTS_SEARCH_BY_ACTION_TYPE } from './contacts/contactsSearchBy/action';
import inCallContactsFilteredBySaga from './contacts/inCallContactsFilteredBy/inCallContactsFilteredBy';
import { IN_CALL_CONTACTS_FILTERED_BY_ACTION_TYPE } from './contacts/inCallContactsFilteredBy/action';
import inCallContactSearchBySaga from './contacts/inCallContactsSearchBy/inCallContactsSearchBy';
import { IN_CALL_CONTACTS_SEARCH_BY_ACTION_TYPE } from './contacts/inCallContactsSearchBy/action';
import contactSortedDirectionSaga from './contacts/contactsSortedDirection/contactsSortedDirection';
import { CONTACTS_SORTED_DIRECTION_ACTION_TYPE } from './contacts/contactsSortedDirection/action';
import updateContactSaga from './contacts/updateContact/updateContact';
import { UPDATE_CONTACT_ACTION_TYPE } from './contacts/updateContact/action';
import deleteContactSaga from './contacts/deleteContact/deleteContact';
import { DELETE_CONTACT_ACTION_TYPE } from './contacts/deleteContact/action';
import createAgentLogSaga from './createAgentLog/createAgentLog';
import { CREATE_AGENT_LOG_ACTION_TYPE } from './createAgentLog/action';
import fetchInDispositions from './fetchInDispositions/fetchInDispositions';
import { DELETE_VOICEMAIL_ACTION_TYPE } from './voicemail/deleteVoicemail/action';
import deleteVoicemailSaga from './voicemail/deleteVoicemail/deleteVoicemail';
import { FETCH_CALL_CDR_ACTION_TYPE } from './callHistory/fetchCallCdr/action';
import fetchCallCdrSaga from './callHistory/fetchCallCdr/fetchCallCdr';
import { FETCH_IN_DISPOSITIONS_ACTION_TYPE } from './fetchInDispositions/action';
import fetchOutDispositions from './fetchOutDispositions/fetchOutDispositions';
import { FETCH_OUT_DISPOSITIONS_ACTION_TYPE } from './fetchOutDispositions/action';
import fetchMessagesSaga from './messages/fetchMessages/fetchMessages';
import { FETCH_MESSAGES_ACTION_TYPE } from './messages/fetchMessages/action';
import fetchAgentKPISaga from './fetchAgentCDR/fetchAgentKPI';
import fetchSumByDaySaga from './fetchAgentCDR/fetchSumByDay';
import fetchSumByHourSaga from './fetchAgentCDR/fetchSumByHour';
import fetchSumByUserSaga from './fetchAgentCDR/fetchSumByUser';
import {
  FETCH_AGENT_KPI_ACTION_TYPE, FETCH_AGENT_SUM_DAY_ACTION_TYPE, FETCH_AGENT_SUM_HOUR_ACTION_TYPE, FETCH_AGENT_SUM_USER_ACTION_TYPE,
} from './fetchAgentCDR/action';
import forwardVoicemailSaga from './voicemail/forwardVoicemail/forwardVoicemail';
import { FORWARD_VOICEMAIL_ACTION_TYPE } from './voicemail/forwardVoicemail/action';
import initSaga from './init/init';
import { INIT_ACTION_TYPE } from './init/action';
import initiateCallFromSaga from './initiateCallFrom/initiateCallFrom';
import { INIT_CALL_FROM_ACTION_TYPE } from './initiateCallFrom/action';
import initiateUACallSaga from './ua/initiateUACall/initiateUACall';
import { INIT_UA_CALL_ACTION_TYPE } from './ua/initiateUACall/action';
import loggedInSaga from './loggedIn/loggedIn';
import { LOGGED_IN_ACTION_TYPE } from './loggedIn/action';
import logoutSaga from './logout/logout';
import { LOGOUT_ACTION_TYPE } from './logout/action';

import checkPushSaga from './push/checkPush';
import { CHECKPUSH_ACTION_TYPE } from './push/action';

import navStackBackSaga from './routeStacks/navStackBack/navStackBack';
import { NAV_STACK_BACK_ACTION_TYPE } from './routeStacks/navStackBack/action';
import navStackPushSaga from './routeStacks/navStackPush/navStackPush';
import { NAV_STACK_PUSH_ACTION_TYPE } from './routeStacks/navStackPush/action';
import navStackRebaseSaga from './routeStacks/navStackRebase/navStackRebase';
import { NAV_STACK_REBASE_ACTION_TYPE } from './routeStacks/navStackRebase/action';
import onConnectSaga from './ua/onConnect/onConnect';
import { ON_CONNECT_ACTION_TYPE } from './ua/onConnect/action';
import onDisconnectSaga from './ua/onDisconnect/onDisconnect';
import { ON_DISCONNECT_ACTION_TYPE } from './ua/onDisconnect/action';
import onInviteSaga from './ua/onInvite/onInvite';
import { ON_INVITE_ACTION_TYPE } from './ua/onInvite/action';
import parkCallSaga from './parkCall/parkCall';
import { PARK_CALL_ACTION_TYPE } from './parkCall/action';
import filterCallHistorySaga from './callHistory/filterCallHistory/filterCallHistory';
import { FILTER_CALL_HISTORY_ACTION_TYPE } from './callHistory/filterCallHistory/action';
import callHistoryContactsLinkSaga from './callHistory/callHistoryContactsLink/callHistoryContactsLink';
import { CALL_HISTORY_CONTACTS_ACTION_TYPE } from './callHistory/callHistoryContactsLink/action';
import prioritizeQueuedSaga from './AgentCenter/prioritizeQueued/prioritizeQueued';
import { PRIORITIZE_QUEUED_ACTION_TYPE } from './AgentCenter/prioritizeQueued/action';
import processAgentStatusSaga from './AgentCenter/processAgentStatus/processAgentStatus';
import { PROCESS_AGENT_STATUS_ACTION_TYPE } from './AgentCenter/processAgentStatus/action';
import processCallParkSaga from './processCallPark/processCallPark';
import { PROCESS_CALL_PARK_ACTION_TYPE } from './processCallPark/action';
import processSearchedResultBySaga from './contacts/processSearchedResultSet/processSearchedResultSet';
import { PROCESS_SEARCHED_RESULT_SET_TYPE } from './contacts/processSearchedResultSet/action';
import processInCallSearchedResultSetSaga from './contacts/processInCallSearchedResultSet/processInCallSearchedResultSet';
import { PROCESS_IN_CALL_SEARCHED_RESULT_SET_TYPE } from './contacts/processInCallSearchedResultSet/action';
import postWrapupSaga from './postWrapup/postWrapup';
import { POST_WRAPUP_ACTION_TYPE } from './postWrapup/action';
import removeCardSaga from './cardManagment/removeCard/removeCard';
import { REMOVED_CARD_ACTION_TYPE } from './cardManagment/removeCard/action';
import updateMessageCardVideoSaga from './cardManagment/updateMessageCardVideo/updateMessageCardVideo';
import { UPDATE_MESSAGE_CARD_VIDEO_ACTION_TYPE } from './cardManagment/updateMessageCardVideo/action';
import retrieveParkedSaga from './retrieveParked/retrieveParked';
import { RETRIEVE_CALL_ACTION_TYPE } from './retrieveParked/action';
import saveVoicemailSaga from './voicemail/saveVoicemail/saveVoicemail';
import { SAVE_VOICEMAIL_ACTION_TYPE } from './voicemail/saveVoicemail/action';
import deleteAnsweringRule from './AnsweringRules/deleteAnsweringRule/deleteAnsweringRule';
import { DELETE_ANSWERING_RULE_ACTION_TYPE } from './AnsweringRules/deleteAnsweringRule/action';
import updateAnswerRulePriority from './AnsweringRules/updateAnswerRulePriority/updateAnswerRulePriority';
import { UPDATE_ANSWER_RULE_PRIORITY_ACTION_TYPE } from './AnsweringRules/updateAnswerRulePriority/action';
import enableAnsweringRuleSaga from './AnsweringRules/enableAnsweringRule/enableAnsweringRule';
import { ENABLE_ANSWERING_RULE_ACTION_TYPE } from './AnsweringRules/enableAnsweringRule/action';
import socketListenersSaga from './socket/socketListeners/socketListeners';
import { SOCKET_LISTENERS_TYPE } from './socket/socketListeners/action';
import socketSubscribeSaga from './socket/socketSubscribe/socketSubscribe';
import { SOCKET_SUBSCRIBE_TYPE } from './socket/socketSubscribe/action';
import ssoLoginSaga from './ssoLogin/ssoLogin';
import { SSO_LOGIN_ACTION_TYPE } from './ssoLogin/action';
import stopStreamsSaga from './streams/stopStreams/stopStreams';
import { STOP_STREAMS_ACTION_TYPE } from './streams/stopStreams/action';
import showDispositionSaga from './showDisposition/showDisposition';
import { SHOW_DISPOSITION_ACTION_TYPE } from './showDisposition/action';
import transferQueuedSaga from './AgentCenter/transferQueued/transferQueued';
import { TRANSFER_QUEUED_ACTION_TYPE } from './AgentCenter/transferQueued/action';
import updateAgentStatusSaga from './AgentCenter/updateAgentStatus/updateAgentStatus';
import { UPDATE_AGENT_STATUS_ACTION_TYPE } from './AgentCenter/updateAgentStatus/action';
import updateGreetingSaga from './greetings/updateGreeting/updateGreeting';
import { UPDATE_GREETING_ACTION_TYPE } from './greetings/updateGreeting/action';
import createGreetingSaga from './greetings/createGreeting/createGreeting';
import { CREATE_GREETING_ACTION_TYPE } from './greetings/createGreeting/action';
import deleteGreetingSaga from './greetings/deleteGreeting/deleteGreeting';
import { DELETE_GREETING_ACTION_TYPE } from './greetings/deleteGreeting/action';
import fetchGreetingsSaga from './fetchGreetings/fetchGreetings';
import { FETCH_GREETINGS_ACTION_TYPE } from './fetchGreetings/action';
import updateOrderSaga from './cardManagment/updateOrder/updateOrder';
import { UPDATE_ORDER_ACTION_TYPE } from './cardManagment/updateOrder/action';
import updateQueueEntrySaga from './updateQueueEntry/updateQueueEntry';
import { UPDATE_QUEUE_ENTRY_ACTION_TYPE } from './updateQueueEntry/action';
import updateUserSaga from './updateUser/updateUser';
import { UPDATE_USER_ACTION_TYPE } from './updateUser/action';
import setCallFromSaga from './setCallFrom/setCallFrom';
import { SET_CALL_FROM_ACTION_TYPE } from './setCallFrom/action';
import sendMessageSaga from './messages/sendMessage/sendMessage';
import { SEND_MESSAGE_ACTION_TYPE } from './messages/sendMessage/action';
import updateMessageStatusSaga from './messages/updateMessageStatus/updateMessageStatus';
import { UPDATE_MESSAGE_STATUS_ACTION_TYPE } from './messages/updateMessageStatus/action';
import startVideoSaga from './messages/startVideo/startVideo';
import { START_VIDEO_ACTION_TYPE } from './messages/startVideo/action';
import addMessageSaga from './messages/addMessage/addMessage';
import { ADD_MESSAGE_ACTION_TYPE } from './messages/addMessage/action';
import updateUserStatusSaga from './updateUserStatus/updateUserStatus';
import { UPDATE_USER_STATUS_ACTION_TYPE } from './updateUserStatus/action';
import acceptCallSaga from './ua/acceptCall/acceptCall';
import { ACCEPT_CALL_ACTION_TYPE } from './ua/acceptCall/action';
import ignoreCallSaga from './ua/ignoreCall/ignoreCall';
import { IGNORE_CALL_ACTION_TYPE } from './ua/ignoreCall/action';
import rejectCallSaga from './ua/rejectCall/rejectCall';
import { REJECT_CALL_ACTION_TYPE } from './ua/rejectCall/action';
import removeIncomingCallsSaga from './callSessions/removeIncomingCall/removeIncomingCall';
import { REMOVE_INCOMING_CALL_ACTION_TYPE } from './callSessions/removeIncomingCall/action';
import removeSessionSaga from './callSessions/removeSession/removeSession';
import { REMOVE_SESSION_ACTION_TYPE } from './callSessions/removeSession/action';
import sessionsUpdatedSaga from './callSessions/sessionsUpdated/sessionsUpdated';
import { SESSIONS_UPDATED_ACTION_TYPE } from './callSessions/sessionsUpdated/action';
import togglePinnedSaga from './cardManagment/togglePinned/togglePinned';
import { TOGGLE_PINNED_CARD_ACTION_TYPE } from './cardManagment/togglePinned/action';
import toggleMuteSaga from './messages/toggleMute/toggleMute';
import { TOGGLE_MUTE_ACTION_TYPE } from './messages/toggleMute/action';
import markReadSaga from './messages/markRead/markRead';
import { MARK_READ_ACTION_TYPE } from './messages/markRead/action';
import changeConversationNameSaga from './messages/changeConversationName/changeConversationName';
import { CHANGE_CONVERSATION_NAME_ACTION_TYPE } from './messages/changeConversationName/action';
import leaveConversationSaga from './messages/leaveConversation/leaveConversation';
import { LEAVE_CONVERSATION_ACTION_TYPE } from './messages/leaveConversation/action';
import deleteConversationSaga from './messages/deleteConversation/deleteConversation';
import { DELETE_CONVERSATION_ACTION_TYPE } from './messages/deleteConversation/action';
import fetchMessageSessionSaga from './messages/fetchMessageSession/fetchMessageSession';
import { FETCH_MESSAGESESSION_ACTION_TYPE } from './messages/fetchMessageSession/action';
import fetchMessageSessionsSaga from './messages/fetchMessageSessions/fetchMessageSessions';
import { FETCH_MESSAGESESSIONS_ACTION_TYPE } from './messages/fetchMessageSessions/action';
import fetchCallHistorySaga from './callHistory/fetchCallHistory/fetchCallHistory';
import { FETCH_CALL_HISTORY_ACTION_TYPE } from './callHistory/fetchCallHistory/action';
import changeParticipantsSaga from './messages/changeParticipants/changeParticipants';
import { CHANGE_PARTICIPANTS_ACTION_TYPE } from './messages/changeParticipants/action';
import processDtmfStringSaga from './ua/processDtmfString/processDtmfString';
import { PROCESS_DTMF_STRING_ACTION_TYPE } from './ua/processDtmfString/action';
import sendDtmfSaga from './ua/sendDtmf/sendDtmf';
import { SEND_DTMF_ACTION_TYPE } from './ua/sendDtmf/action';
import showMessageNotificationSaga from './messages/showMessageNotification/showMessageNotification';
import { SHOW_MESSAGE_NOTIFICATION_ACTION_TYPE } from './messages/showMessageNotification/action';
import {
  setRegisterer,
} from '../state/ua/uaSlice';
import launchCall from './launchCall/launchCall';
import { setLoading } from '../state/cards/cardsSlice';

const UA_ACTION_TYPE = setRegisterer().type;
const CARD_LOADING_ACTION_TYPE = setLoading().type;

export default function* rootSaga() {
  yield all([
    takeEvery(ADD_CALL_SESSION_ACTION_TYPE, addCallSessionSaga),
    takeEvery(ADD_CARD_ACTION_TYPE, addCardSaga),
    takeEvery(ADD_STREAM_ACTION_TYPE, addStreamSaga),
    takeEvery(ACCEPT_CALL_ACTION_TYPE, acceptCallSaga),
    takeEvery(IGNORE_CALL_ACTION_TYPE, ignoreCallSaga),
    takeEvery(REJECT_CALL_ACTION_TYPE, rejectCallSaga),
    takeEvery(ATTEMPT_RECONNECT_ACTION_TYPE, attemptReconnectSaga),
    takeEvery(CALL_STACK_BACK_ACTION_TYPE, callStackBackSaga),
    takeEvery(CALL_STACK_PUSH_ACTION_TYPE, callStackPushSaga),
    takeEvery(CALL_STACK_REBASE_ACTION_TYPE, callStackRebaseSaga),
    takeLatest(CLEAR_STACK_ACTION_TYPE, clearStackSaga),
    takeLatest(CONNECT_SOCKET_TYPE, connectSocketSaga),
    takeEvery(CONTACTS_FILTERED_BY_ACTION_TYPE, contactFilteredBySaga),
    takeEvery(CONTACTS_SEARCH_BY_ACTION_TYPE, contactSearchBySaga),
    takeEvery(IN_CALL_CONTACTS_FILTERED_BY_ACTION_TYPE, inCallContactsFilteredBySaga),
    takeEvery(IN_CALL_CONTACTS_SEARCH_BY_ACTION_TYPE, inCallContactSearchBySaga),
    takeEvery(CONTACTS_SORTED_BY_ACTION_TYPE, contactSortedBySaga),
    takeEvery(CONTACTS_SORTED_DIRECTION_ACTION_TYPE, contactSortedDirectionSaga),
    takeEvery(FILTER_CALL_HISTORY_ACTION_TYPE, filterCallHistorySaga),
    takeEvery(CALL_HISTORY_CONTACTS_ACTION_TYPE, callHistoryContactsLinkSaga),
    takeLatest(CREATE_AGENT_LOG_ACTION_TYPE, createAgentLogSaga),
    takeLatest(DELETE_VOICEMAIL_ACTION_TYPE, deleteVoicemailSaga),
    takeLatest(DELETE_ANSWERING_RULE_ACTION_TYPE, deleteAnsweringRule),
    takeEvery(DELETE_CONTACT_ACTION_TYPE, deleteContactSaga),
    takeEvery(ENABLE_ANSWERING_RULE_ACTION_TYPE, enableAnsweringRuleSaga),
    takeLatest(FETCH_CALL_CDR_ACTION_TYPE, fetchCallCdrSaga),
    takeLatest(FETCH_IN_DISPOSITIONS_ACTION_TYPE, fetchInDispositions),
    takeLatest(FETCH_OUT_DISPOSITIONS_ACTION_TYPE, fetchOutDispositions),
    takeEvery(FETCH_MESSAGES_ACTION_TYPE, fetchMessagesSaga),
    takeLatest(FETCH_AGENT_KPI_ACTION_TYPE, fetchAgentKPISaga),
    takeLatest(FETCH_AGENT_SUM_DAY_ACTION_TYPE, fetchSumByDaySaga),
    takeLatest(FETCH_AGENT_SUM_HOUR_ACTION_TYPE, fetchSumByHourSaga),
    takeLatest(FETCH_AGENT_SUM_USER_ACTION_TYPE, fetchSumByUserSaga),
    takeLatest(FORWARD_VOICEMAIL_ACTION_TYPE, forwardVoicemailSaga),
    takeLatest(INIT_ACTION_TYPE, initSaga),
    takeLatest(INIT_CALL_FROM_ACTION_TYPE, initiateCallFromSaga),
    takeLatest(INIT_UA_CALL_ACTION_TYPE, initiateUACallSaga),
    takeLatest(LOGGED_IN_ACTION_TYPE, loggedInSaga),
    takeLatest(LOGOUT_ACTION_TYPE, logoutSaga),
    takeLatest(CHECKPUSH_ACTION_TYPE, checkPushSaga),
    takeEvery(NAV_STACK_BACK_ACTION_TYPE, navStackBackSaga),
    takeEvery(NAV_STACK_PUSH_ACTION_TYPE, navStackPushSaga),
    takeEvery(NAV_STACK_REBASE_ACTION_TYPE, navStackRebaseSaga),
    takeLatest(ON_CONNECT_ACTION_TYPE, onConnectSaga),
    takeLatest(ON_DISCONNECT_ACTION_TYPE, onDisconnectSaga),
    takeLatest(ON_INVITE_ACTION_TYPE, onInviteSaga),
    takeLatest(PARK_CALL_ACTION_TYPE, parkCallSaga),
    takeLatest(PROCESS_AGENT_STATUS_ACTION_TYPE, processAgentStatusSaga),
    takeLatest(PROCESS_CALL_PARK_ACTION_TYPE, processCallParkSaga),
    takeEvery(PROCESS_SEARCHED_RESULT_SET_TYPE, processSearchedResultBySaga),
    takeEvery(PROCESS_IN_CALL_SEARCHED_RESULT_SET_TYPE, processInCallSearchedResultSetSaga),
    takeEvery(PRIORITIZE_QUEUED_ACTION_TYPE, prioritizeQueuedSaga),
    takeEvery(POST_WRAPUP_ACTION_TYPE, postWrapupSaga),
    takeLatest(REMOVED_CARD_ACTION_TYPE, removeCardSaga),
    takeEvery(UPDATE_MESSAGE_CARD_VIDEO_ACTION_TYPE, updateMessageCardVideoSaga),
    takeLatest(RETRIEVE_CALL_ACTION_TYPE, retrieveParkedSaga),
    takeLatest(SAVE_VOICEMAIL_ACTION_TYPE, saveVoicemailSaga),
    takeLatest(SOCKET_LISTENERS_TYPE, socketListenersSaga),
    takeLatest(SESSIONS_UPDATED_ACTION_TYPE, sessionsUpdatedSaga),
    takeLatest(SOCKET_SUBSCRIBE_TYPE, socketSubscribeSaga),
    takeLatest(STOP_STREAMS_ACTION_TYPE, stopStreamsSaga),
    takeLatest(SSO_LOGIN_ACTION_TYPE, ssoLoginSaga),
    takeEvery(SHOW_DISPOSITION_ACTION_TYPE, showDispositionSaga),
    takeEvery(TRANSFER_QUEUED_ACTION_TYPE, transferQueuedSaga),
    takeLatest(UPDATE_AGENT_STATUS_ACTION_TYPE, updateAgentStatusSaga),
    takeEvery(UPDATE_CONTACT_ACTION_TYPE, updateContactSaga),
    takeLatest(UPDATE_GREETING_ACTION_TYPE, updateGreetingSaga),
    takeLatest(CREATE_GREETING_ACTION_TYPE, createGreetingSaga),
    takeEvery(DELETE_GREETING_ACTION_TYPE, deleteGreetingSaga),
    takeLatest(FETCH_GREETINGS_ACTION_TYPE, fetchGreetingsSaga),
    takeEvery(UPDATE_CONTACT_ACTION_TYPE, updateContactSaga),
    takeLatest(UPDATE_ORDER_ACTION_TYPE, updateOrderSaga),
    takeLatest(UPDATE_ANSWER_RULE_PRIORITY_ACTION_TYPE, updateAnswerRulePriority),
    takeLatest(UPDATE_QUEUE_ENTRY_ACTION_TYPE, updateQueueEntrySaga),
    takeLatest(UPDATE_USER_ACTION_TYPE, updateUserSaga),
    takeLatest(SET_CALL_FROM_ACTION_TYPE, setCallFromSaga),
    takeEvery(SEND_MESSAGE_ACTION_TYPE, sendMessageSaga),
    takeEvery(UPDATE_MESSAGE_STATUS_ACTION_TYPE, updateMessageStatusSaga),
    takeEvery(START_VIDEO_ACTION_TYPE, startVideoSaga),
    takeEvery(ADD_MESSAGE_ACTION_TYPE, addMessageSaga),
    takeLatest(UPDATE_USER_STATUS_ACTION_TYPE, updateUserStatusSaga),
    takeLatest(REMOVE_SESSION_ACTION_TYPE, removeSessionSaga),
    takeLatest(REMOVE_INCOMING_CALL_ACTION_TYPE, removeIncomingCallsSaga),
    takeLatest(TOGGLE_PINNED_CARD_ACTION_TYPE, togglePinnedSaga),
    takeEvery(TOGGLE_MUTE_ACTION_TYPE, toggleMuteSaga),
    takeEvery(MARK_READ_ACTION_TYPE, markReadSaga),
    takeEvery(CHANGE_CONVERSATION_NAME_ACTION_TYPE, changeConversationNameSaga),
    takeEvery(LEAVE_CONVERSATION_ACTION_TYPE, leaveConversationSaga),
    takeEvery(DELETE_CONVERSATION_ACTION_TYPE, deleteConversationSaga),
    takeEvery(FETCH_MESSAGESESSION_ACTION_TYPE, fetchMessageSessionSaga),
    takeEvery(FETCH_MESSAGESESSIONS_ACTION_TYPE, fetchMessageSessionsSaga),
    takeEvery(FETCH_CALL_HISTORY_ACTION_TYPE, fetchCallHistorySaga),
    takeEvery(CHANGE_PARTICIPANTS_ACTION_TYPE, changeParticipantsSaga),
    takeEvery(PROCESS_DTMF_STRING_ACTION_TYPE, processDtmfStringSaga),
    takeEvery(SEND_DTMF_ACTION_TYPE, sendDtmfSaga),
    takeEvery(SHOW_MESSAGE_NOTIFICATION_ACTION_TYPE, showMessageNotificationSaga),
    takeEvery(CARD_LOADING_ACTION_TYPE, launchCall),
    takeEvery(UA_ACTION_TYPE, launchCall),
  ]);
}

sagaMiddleware.run(rootSaga);
