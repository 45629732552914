import {
  call,
  select,
} from 'redux-saga/effects';
import initPush, { unsubPush } from '../../utils/push';
import { selectConfig } from '../../state/configs/configsSlice';
import { selectPWAPrompt } from '../../state/userMedia/userMediaSlice';
import { selectWebphoneDevice } from '../../state/webphoneDevice/webphoneDeviceSlice';

/**
  will check whether pwa is enabled, wp device is set,
  and the window is openned as a standalone application
  if it is, then will initialize push

  initpush will check whether there is already a subscription,
  if there is there wont be a resubscribe
*/
export default function* checkPush() {
  const debug = localStorage.getItem('debugFlag') === 'true';
  const pwaEnabled = yield select(selectConfig, 'PORTAL_WEBPHONE_ENABLE_PWA');
  const pwaInstalled = yield select(selectPWAPrompt);
  const standalone = window.matchMedia('(display-mode: standalone)').matches;

  const fetchAudioTaskRes = yield select(selectWebphoneDevice);

  if (debug) console.debug('in checkpush pwaEnabled: ', pwaEnabled);
  if (debug) console.debug('in checkpush fetchAudioTaskRes: ', fetchAudioTaskRes);
  if (debug) console.debug('in checkpush pwaInstalled: ', pwaInstalled);
  if (debug) console.debug('in checkpush standalone: ', standalone);

  const pushEnabled = JSON.parse(localStorage.getItem('pushEnabled'));

  if (pwaEnabled === 'yes'
    && fetchAudioTaskRes !== null
    // && pwaInstalled === null
    && standalone
  ) {
    if (!pushEnabled) {
      if (debug) console.debug('in checkpush about to initPush');
      // set up push notifications if needed
      yield call(initPush, fetchAudioTaskRes);
    } else if (debug) console.debug('in checkpush already subbed');
  } else if (pushEnabled) {
    if (debug) console.debug('in checkpush about to unsubPush');
    yield call(unsubPush, fetchAudioTaskRes);
  } else {
    // do nothing
    // eslint-disable-next-line no-lonely-if
    if (debug) console.debug('in checkpush catch all');
  }

  return false;
}
