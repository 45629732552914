import {
  call, put, select,
} from 'redux-saga/effects';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import { selectContacts, setSortedBy } from '../../../state/contacts/contactsSlice';
import processResultSet from '../processResultSet/processResultSet';
import processSearchResultSet from '../processSearchResultSet/processSearchResultSet';

export default function* contactsSortedBy({ payload: sortBy }) {
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  localStorage.setItem(
    `${appName}-${userId}_contacts-sort-by`,
    sortBy,
  );

  yield put(setSortedBy(sortBy));

  const contacts = yield select(selectContacts);
  if (contacts) {
    // process result set
    yield call(processResultSet);
    yield call(processSearchResultSet);
  }
}
