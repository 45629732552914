// import crypto from 'crypto-browserify';
// import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import filterContactList from './filterContactList';
import sortContactList from './sortContactList';
import groupContactList from './groupContactList';
import addContactListDividers from './addContactListDividers';

export default function getContactResultSet(
  contactsArr,
  filterBy,
  filteredUser,
  sortedBy,
  sortedDirection,
) {
  // filter array
  let contactsList = filterContactList(contactsArr, filterBy, filteredUser);

  // sort array
  contactsList = sortContactList(contactsList, sortedBy);

  // reverse array if sortDirection is 'desc'
  if (sortedDirection === 'desc') {
    contactsList.reverse();
  }

  // group the array
  contactsList = groupContactList(contactsList, sortedBy);

  return addContactListDividers(contactsList, sortedBy, sortedDirection);
}
