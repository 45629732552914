import { put, select } from 'redux-saga/effects';
import { selectStreams, setStreams } from '../../../state/userMedia/userMediaSlice';
import { stopStream } from '../../../utils/devices';

export default function* stopStreams() {
  const streams = yield select(selectStreams);

  streams.forEach((s) => {
    if (s.active) {
      stopStream(s);
    }
  });

  yield put(setStreams([]));
}
