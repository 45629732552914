/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  title: null,
  titleParams: null,
  text: '',
  textParams: null,
  confirmText: null,
  cancelText: null,
  onResult: null,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.open = action.payload.open || initialState.open;
      state.title = action.payload.title || initialState.title;
      state.titleParams = action.payload.titleParams || initialState.titleParams;
      state.text = action.payload.text || initialState.text;
      state.textParams = action.payload.textParams || initialState.textParams;
      state.confirmText = action.payload.confirmText || initialState.confirmText;
      state.cancelText = action.payload.cancelText || initialState.cancelText;
      state.onResult = action.payload.onResult || initialState.onResult;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDialog,
} = dialogSlice.actions;

// Selectors
export const selectDialog = (state) => state.dialog;

export default dialogSlice.reducer;
