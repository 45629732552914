import nsApi from '@netsapiens/netsapiens-js/dist/api';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { selectApiHash, selectHostname, selectSSO } from '../../../state/configs/configsSlice';
import PageCenter from '../../templates/PageCenter/PageCenter';
import { loggedInAction } from '../../../sagas/loggedIn/action';
import {
  getForgotPasswordRoute,
  getForgotUsernameRoute,
  getMFARoute,
} from '../../../routePaths';
import GoogleSso from '../../atoms/GoogleSso/GoogleSso';
import AppleSso from '../../atoms/AppleSso/AppleSso';
import OfficeSso from '../../atoms/OfficeSso/OfficeSso';
import { LOGIN_CARD_STYLES, LOGIN_FAILED_ERROR } from '../../../constants';
import { getNavigate } from '../../../services/router/router';
import { setMFAType, setMFAUserName, setMFAVendor } from '../../../state/mfa/mfaSlice';

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const apiHash = useSelector(selectApiHash);
  const hostname = useSelector(selectHostname);
  const ssoConfigs = useSelector(selectSSO);

  const [imgLoaded, setImgLoaded] = useState(false);
  const [isUsernameDirty, setIsUsernameDirty] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const imgUrl = `https://${hostname}/ns-api/?object=image&action=read&server=${hostname}&filename=portal_landing.png`;
  const error = loginError || location?.state?.err === LOGIN_FAILED_ERROR;

  const appleSsoEnabled = ssoConfigs.PORTAL_LOGIN_APPLE_SSO_ENABLED === 'yes' && ssoConfigs.PORTAL_LOGIN_APPLE_SSO_CLIENT_ID;
  const googleSsoEnabled = ssoConfigs.PORTAL_LOGIN_GOOGLE_SSO_ENABLED === 'yes' && ssoConfigs.PORTAL_LOGIN_GOOGLE_SSO_CLIENT_ID;
  const officeSsoEnabled = ssoConfigs.PORTAL_LOGIN_OFFICE_SSO_ENABLED === 'yes' && ssoConfigs.PORTAL_LOGIN_OFFICE_SSO_CLIENT_ID;

  const ssoEnabled = appleSsoEnabled || googleSsoEnabled || officeSsoEnabled;

  // clear app badge if loaded to login page
  useEffect(() => {
    try {
      navigator.clearAppBadge();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleUsername = (e) => {
    setIsUsernameDirty(true);
    setLoginError(false);
    setUsername(e.target.value.trim());
  };

  const handlePassword = (e) => {
    setIsPasswordDirty(true);
    setLoginError(false);
    setPassword(e.target.value.trim());
  };

  const handleSubmit = async () => {
    if (username && password) {
      setSubmitting(true);
      // unsubscribePush(); todo

      const navigate = getNavigate();
      try {
        await nsApi.authenticate({
          username,
          password,
          scope: 'webphone',
          apiHash,
        });

        dispatch(loggedInAction());
      } catch (e) {
        if (e?.reason === 'MFA_REQUIRED') {
          // set variables for MFA page
          dispatch(setMFAType(e.mfa_type));
          dispatch(setMFAVendor(e.mfa_vendor));
          dispatch(setMFAUserName(username));

          // go to MFA page
          navigate(getMFARoute());
        }
        setSubmitting(false);
        setLoginError(true);
      }
    } else {
      setIsUsernameDirty(true);
      setIsPasswordDirty(true);
    }
  };

  const handleEnterSubmit = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <PageCenter>
      <Fade in={imgLoaded}>
        <Card sx={{ ...LOGIN_CARD_STYLES, textAlign: 'center', display: !imgLoaded && 'none' }}>
          <CardContent>
            <Fade in>
              <img
                src={imgUrl}
                alt="logo"
                aria-label="logo"
                style={{ maxWidth: '100%' }}
                data-testid="img-login"
                onLoad={() => setImgLoaded(true)}
              />
            </Fade>
            <Box sx={{ mt: 4 }}>
              <TextField
                aria-label={t('LOGIN_NAME')}
                label={t('LOGIN_NAME')}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                value={username}
                onChange={handleUsername}
                onKeyDown={handleEnterSubmit}
                error={isUsernameDirty && !username}
                disabled={submitting}
                fullWidth
                required
                data-testid="TextField-username"
              />
              <TextField
                type="password"
                aria-label={t('PASSWORD')}
                label={t('PASSWORD')}
                autoComplete="current-password"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                value={password}
                onChange={handlePassword}
                onKeyDown={handleEnterSubmit}
                error={isPasswordDirty && !password}
                disabled={submitting}
                sx={{ mt: 2 }}
                fullWidth
                required
                data-testid="TextField-password"
              />
              {error && (
                <Fade in>
                  <Typography variant="body2" sx={{ mt: 2, color: '#dd2c00' }}>
                    {location?.state?.err === LOGIN_FAILED_ERROR && !loginError
                      ? t('LOGIN_FAILED')
                      : t('LOGIN_INVALID')}
                    <br />
                    {t('LOGIN_TRY_AGAIN')}
                  </Typography>
                </Fade>
              )}
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: error ? 2 : 4 }}
                disabled={submitting}
                fullWidth
              >
                {submitting ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : t('LOGIN')}
              </Button>
              <Grid container sx={{ pt: 2 }}>
                <Grid item xs>
                  <Link to={getForgotUsernameRoute()} style={{ textDecoration: 'none' }} disabled={submitting}>
                    <Typography color="primary" variant="body2">{t('FORGOT_LOGIN_NAME')}</Typography>
                  </Link>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs>
                  <Link to={getForgotPasswordRoute()} style={{ textDecoration: 'none' }} disabled={submitting}>
                    <Typography color="primary" variant="body2">{t('FORGOT_PASSWORD')}</Typography>
                  </Link>
                </Grid>
              </Grid>
              {ssoEnabled && (
                <Divider orientation="horizontal" sx={{ mt: 1.5 }}>
                  <Typography sx={{ color: '#767676' }} variant="overline">{t('OR')}</Typography>
                </Divider>
              )}
              {googleSsoEnabled && (
                <GoogleSso
                  googleSsoClientId={ssoConfigs.PORTAL_LOGIN_GOOGLE_SSO_CLIENT_ID}
                />
              )}
              {appleSsoEnabled && (
                <AppleSso
                  appleSsoClientId={ssoConfigs.PORTAL_LOGIN_APPLE_SSO_CLIENT_ID}
                />
              )}
              {officeSsoEnabled && (
                <OfficeSso
                  officeSsoClientId={ssoConfigs.PORTAL_LOGIN_OFFICE_SSO_CLIENT_ID}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </PageCenter>
  );
}

export default Login;
