import React from 'react';
import { Box, Snackbar as MuiSnackbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectSnackbar, setSnackbar } from '../../../state/snackbar/snackbarSlice';
import SnackbarIcon from '../../atoms/SnackbarIcon/SnackbarIcon';

function Snackbar() {
  const dispatch = useDispatch();
  const {
    open, duration, message, type,
  } = useSelector(selectSnackbar);

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbar({}));
  };

  if (!message) {
    return null;
  }

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      message={(
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {type && <SnackbarIcon type={type} />}
          <Typography>{message}</Typography>
        </Box>
      )}
    />
  );
}

export default Snackbar;
