import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
// import { RegistererState } from 'sip.js';
import { RegistererState } from 'sip.js';
import { selectApiHash } from '../../state/configs/configsSlice';
import { selectRegisterer } from '../../state/ua/uaSlice';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import { unsubPush } from '../../utils/push';
import { selectWebphoneDevice } from '../../state/webphoneDevice/webphoneDeviceSlice';

/**
 * This function adds listeners for the browser extension
 * Handles messages for calls and sms
 * @returns {Generator<*, void, *>}
 */
export default function* logout() {
  const apiHash = yield select(selectApiHash);

  // unregister ua
  const registerer = yield select(selectRegisterer);
  if (registerer && registerer.state === RegistererState.Registered) {
    try {
      registerer.unregister();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      bugsnagClient.notify(e);
    }
  }

  // unregister the push notifications
  const fetchAudioTaskRes = yield select(selectWebphoneDevice);
  yield call(unsubPush, fetchAudioTaskRes);

  // logout
  try {
    yield call(nsApi.logout, apiHash);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    bugsnagClient.notify(e);
  }

  global.location.reload();
}
