import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import { selectCallFromDevice } from '../../state/webphoneDevice/webphoneDeviceSlice';

/**
 * This will initiate call from a different device than webphone
 * See initiateUACall to make a call from the webphone
 * @returns {Generator<*, void, *>}
 */
export default function* initiateCallFrom({ payload: number }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const device = yield select(selectCallFromDevice);

  yield call(nsApi.post, {
    object: 'call',
    action: 'call',
    callid: Math.floor(Math.random() * (100000000000 - 1 + 1)) + 1,
    origination: device.aor,
    destination: number,
    uid: decodedToken.sub,
    format: 'json',
  });
}
