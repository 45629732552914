import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { BROWSER_CHECK_FAIL } from '../constants';
import { supportedBrowsers } from '../config';

export default function browserSupport() {
  if (nsBrowser.isSupported(supportedBrowsers)) {
    return Promise.resolve(true);
  }
  return Promise.reject(BROWSER_CHECK_FAIL);
}
