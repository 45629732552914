/* eslint-disable no-console */
import { put, select } from 'redux-saga/effects';
import { attemptReconnectAction } from '../attemptReconnect/action';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { selectRegisterer, selectUAState } from '../../../state/ua/uaSlice';

export default function* onDisconnect({ payload: error }) {
  const registerer = yield select(selectRegisterer);
  const uaState = yield select(selectUAState);
  console.log('onDisconnect unRegister error:', error);
  console.log('onDisconnect uaState:', uaState);

  if (registerer && uaState === 'registered') {
    try {
      yield registerer.unregister();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      bugsnagClient.notify(e);
    }

    if (error) {
      yield put(attemptReconnectAction());
    }
  }
}
