import { put, select } from 'redux-saga/effects';
import { selectCallSessions, setCallSessions } from '../../../state/callSessions/callSessionsSlice';
import matchContact from '../../../utils/matchContact';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';

export default function* popConferenceList(session, data) {
  const sessions = yield select(selectCallSessions);
  const contact = matchContact(data.orig_from_user);

  let index = session.conferenceList.indexOf(formatPhoneNumber(data.orig_from_user));
  if (index !== -1) {
    session.conferenceList.splice(index, 1);
  } else if (contact) {
    index = session.conferenceList.indexOf(contact.name);
    if (index !== -1) {
      session.conferenceList.splice(index, 1);
    }
  }

  // eslint-disable-next-line no-param-reassign
  session.callerId = session.conferenceList.join(' + ');
  // eslint-disable-next-line no-param-reassign
  session.display = true;

  yield put(setCallSessions([...sessions]));
}
