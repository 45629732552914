import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectDevicePostFix } from '../../state/configs/configsSlice';

export default function* retrieveParked({ payload: parkId }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const devicePostFix = yield select(selectDevicePostFix);
  return nsApi.get({
    object: 'call',
    action: 'xfer',
    callid: parkId,
    destination: `sip:${decodedToken.user}${devicePostFix}@${decodedToken.domain}`,
    uid: decodedToken.sub,
    domain: decodedToken.domain,
  }).then((res) => { console.debug('retrieveParked res:', res); });
}
