import { call, put, select } from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectConfig } from '../../state/configs/configsSlice';
import { setShowAgentCenter } from '../../state/agent/agentSlice';

export default function* showAgentCenter() {
  const decodedToken = yield call(nsToken.getDecoded);
  const portalMenuShowCallCenterAgent = yield select((state) => selectConfig(
    state,
    'PORTAL_MENU_SHOW_CALL_CENTER_AGENT',
  ));
  const portalMenuShowCallCenterAgentSupervisor = yield select((state) => selectConfig(
    state,
    'PORTAL_MENU_SHOW_CALL_CENTER_AGENT_SUPERVISOR',
  ));

  const show = (decodedToken.user_scope === 'Call Center Agent'
    || decodedToken.user_scope === 'Call Center Supervisor'
    || portalMenuShowCallCenterAgent === 'yes'
    || (portalMenuShowCallCenterAgentSupervisor === 'yes'
      && decodedToken.user_scope !== 'Site Manager'
      && decodedToken.user_scope !== 'Basic User'
      && decodedToken.user_scope !== 'Simple User'
      && decodedToken.user_scope !== 'Advanced User'
    )
  );

  yield put(setShowAgentCenter(show));

  return show;
}
