/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const presenceSlice = createSlice({
  name: 'presence',
  initialState: {
    presence: {},
  },
  reducers: {
    setUserPresence: (state, action) => {
      const userPresence = state.presence[action.payload.user] || {};
      if (action.payload.presence) {
        userPresence.presence = action.payload.presence;
      }
      if (action.payload.chatPresence !== undefined) {
        userPresence.chatPresence = action.payload.chatPresence;
      }
      state.presence = {
        ...state.presence,
        [action.payload.user]: userPresence,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserPresence,
} = presenceSlice.actions;

// Selectors
export const selectUserPresence = (state, user) => state.presence.presence[user] || null;

export const selectAllPresence = (state) => state.presence.presence;

export default presenceSlice.reducer;
