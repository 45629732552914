import pack from '../package.json';

export const { version, name } = pack;
export const supportedBrowsers = [
  {
    name: 'Chrome',
    lowestVersion: 52,
    excludedVersions: [],
    lowestRenderVersion: null,
  },
  {
    name: 'Firefox',
    lowestVersion: 63,
    excludedVersions: [],
    lowestRenderVersion: null,
  },
];
export const portalURL = '*/portal/';
export const apiURL = process.env.NODE_ENV === 'development' ? '*:3000/ns-api/' : '*/ns-api/';
export const nsSocketURLs = ['*:8001'];
export const uaSocketURLs = ['*:9002'];
export const devicePostFix = 'wp';
