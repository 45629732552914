import { call, put, select } from 'redux-saga/effects';
import getCardId from '../../services/getCardId/getCardId';
import { setExpandedId } from '../../state/cards/cardsSlice';
import {
  selectDispositionsIn, selectDispositionsOut, setTempDisposition,
} from '../../state/agent/agentSlice';
import { selectConfig } from '../../state/configs/configsSlice';
import addCard from '../cardManagment/addCard/addCard';

export default function* showDisposition({ payload }) {
  // export const showWrapUpDialog = (details) => (dispatch, getState) => {
  const dispositionsIn = yield select(selectDispositionsIn);
  const dispositionsOut = yield select(selectDispositionsOut);

  const showNotes = yield select((state) => selectConfig(state, 'portalCallPopupShowNotes'));
  const showDispo = yield select((state) => selectConfig(state, 'portalCallPopupAllowComplete'));
  if (
    (showNotes === 'yes' || showDispo === 'yes')
    && (payload.direction === 'inbound' ? dispositionsIn?.length > 0 : dispositionsOut?.length > 0)
  ) {
    const cardMeta = {
      type: 'wrapup',
      details: payload,
    };
      // create card
    // let cardId = cardActions.getCardId(cardMeta);
    let cardId = getCardId(cardMeta);

    if (!cardId) {
      yield call(addCard, { payload: cardMeta });
      cardId = getCardId(cardMeta);
    }

    yield put(setTempDisposition({}));
    yield put(setExpandedId(cardId));
  }
}
