import {
  call, put,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading, setCallStats,
} from '../../state/agent/agentSlice';

export default function* fetchSumByUser() {
  const decodedToken = yield call(nsToken.getDecoded);

  const callStats = [];

  try {
    const res = yield call(nsApi.get, {
      object: 'cdr',
      action: 'read',
      option: 'summaryByUser',
      range_interval: '12 HOUR',
      end_date: moment().utcOffset(0).format('YYYY-MM-DD'),
      user: decodedToken.user,
      domain: decodedToken.domain,
    });

    if (res) {
      res.forEach((item) => {
        if (item.by_sub !== decodedToken.user) {
          callStats.push([item.by_sub, Number(item.volume)]);
        }
      });
    }

    yield put(setCallStats(callStats));
  } catch (e) {
    yield put(setLoading(false));
  }
}
