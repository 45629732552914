import {
  call, fork, put,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import bugsnagClient from '../../services/bugsnag/bugsnag';
import userSessionCheck from '../../services/userSessionCheck/userSessionCheck';
import { setConfig } from '../../state/configs/configsSlice';
import uiConfigsToState from '../uiConfigsToState/uiConfigsToState';
import favicon from '../favicon/favicon';
import setPageTitle from '../pageTitle/pageTitle';
import { getNavigate } from '../../services/router/router';
import { getLoginRoute } from '../../routePaths';
import postLogin from '../postLogin/postLogin';

export default function* loggedIn() {
  try {
    const decodedToken = yield call(nsToken.getDecoded);

    bugsnagClient.user = {
      domain: decodedToken.domain,
      id: decodedToken.sub,
      user: decodedToken.user,
      scope: decodedToken.user_scope,
    };

    // check to see if the user was logged out by another application
    // watch local storage ns_t token
    // if its removed the site will be reloaded
    yield call(userSessionCheck);

    yield put(setConfig('userId', decodedToken.user));

    // get server ui configs and save to state now that the user has logged
    yield call(uiConfigsToState, decodedToken);

    // fork fetching and setting the favicon
    // fetches from the api for custom branding
    yield fork(favicon);

    // fork setting the application title
    // ui configurable
    yield fork(setPageTitle);

    // check if there is an issue with the user scope
    if (decodedToken.user_scope === '') {
      const navigate = getNavigate();
      if (decodedToken.recover) {
        navigate(getLoginRoute(), { state: { wpPassResetMsg: true } });
        yield Promise.reject(Error('auth recover'));
      } else {
        navigate(getLoginRoute(), { state: { wpPassRecoverFail: true } });
        yield Promise.reject(Error('auth recover fail'));
      }
    }

    yield call(postLogin);

    // todo combine with postLogin
  } catch (e) {
    bugsnagClient.notify(e);
  }
}
