import {
  all, call, put, select,
} from 'redux-saga/effects';
import nsUiConfig from '@netsapiens/netsapiens-js/dist/ui-config';

import { selectConfig, setConfigs } from '../../state/configs/configsSlice';
import bugsnagClient from '../../services/bugsnag/bugsnag';

const allowedConfigsFromResponse = [
  'uaSocketURLs',
  'nsSocketURLs',

  'PORTAL_ALLOW_WEB_PHONE',

  'PORTAL_WEBPHONE_USE_HEADSET_RINGER',

  'PORTAL_COUNTRY_CODE',
  'PORTAL_LOCALIZATION_NUMBER_FORMAT',
  'PORTAL_SHOW_ADDRESS_BOOK',

  // login
  'PORTAL_LOGIN_GOOGLE_SSO_ENABLED',
  'PORTAL_LOGIN_GOOGLE_SSO_CLIENT_ID',
  'PORTAL_LOGIN_APPLE_SSO_ENABLED',
  'PORTAL_LOGIN_APPLE_SSO_CLIENT_ID',
  'PORTAL_LOGIN_OFFICE_SSO_ENABLED',
  'PORTAL_LOGIN_OFFICE_SSO_CLIENT_ID',

  'PORTAL_UC_LICENSE_EXPIRATION_NOTICE',

  'PORTAL_THEME_PRIMARY',
  'PORTAL_THEME_ACCENT',

  'PORTAL_CALL_RECORDING',
  'PORTAL_MAX_ACTIVE_CALLS',
  'PORTAL_WEB_PHONE_NAME',
  'PORTAL_WEBPHONE_NAME',
  'PORTAL_WEBPHONE_NEW_TAB_VIEW',
  'PORTAL_USERS_VMAIL_TRANSCRIPTION_SHOW',
  'PORTAL_LOCALIZATION_DATE_FORMAT',
  'PORTAL_LOCALIZATION_TIME_12_HOUR_FORMAT',
  'PORTAL_USERS_READ_ONLY_EMAIL',

  // nav controls
  'PORTAL_CONTACT_CENTER_FEATURE_NAME',
  'PORTAL_MENU_SHOW_CALL_CENTER_AGENT',
  'PORTAL_MENU_SHOW_CALL_CENTER_AGENT_SUPERVISOR',
  'PORTAL_WEB_PHONE_SHOW_CONTACTS',
  'PORTAL_WEB_PHONE_SHOW_CALLHISTORY',
  'PORTAL_WEB_PHONE_SHOW_VOICEMAIL',
  'PORTAL_CHAT_SMS',
  'PORTAL_WEB_PHONE_SHOW_CALLCENTER',
  'PORTAL_WEB_PHONE_SHOW_PARKS',
  'PORTAL_WEB_PHONE_SHOW_ANSWERRULES',
  'PORTAL_WEB_PHONE_SHOW_GREETINGS',
  'PORTAL_WEB_PHONE_SHOW_SETTINGS',

  'PORTAL_LOCALIZATION_LANGUAGE_MASTER',
  'PORTAL_WEBPHONE_LOCALIZATION_LANGUAGE_LAYOUTS',
  'PORTAL_WEBPHONE_USERS_VMAIL_HIDE_FORWARD',

  // UA
  'PORTAL_WEB_PHONE_STUN_SERVERS',
  'PORTAL_WEBRTC_CODEC_LIST_AUDIO',
  'PORTAL_WEBRTC_CODEC_REMOVED_AUDIO',
  'PORTAL_VIDEO_ICECHECKING_TIMEOUT',

  'portalCallPopupShowNotes',
  'portalCallPopupAllowComplete',

  'PORTAL_AGENT_SCREEN_POP_URL',

  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_1',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_2',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_3',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_4',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_5',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_6',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_7',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_8',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_9',
  'PORTAL_CALL_QUEUE_STATUS_CUSTOM_10',

  'PORTAL_WEBPHONE_ENABLE_PWA',
  'PORTAL_WEBPHONE_PWA_NAME',
  'PORTAL_WEBPHONE_PWA_SHORT_NAME',
  'PORTAL_WEBPHONE_PWA_DESCRIPTION',
  'PORTAL_WEBPHONE_PWA_ALLOW_MOBILE',
  'PORTAL_WEBPHONE_PWA_THEME_COLOR',
  'PORTAL_WEBPHONE_PWA_BACKGROUND_COLOR',

  'PORTAL_WEB_PHONE_AUTO_ANSWER_MIC_MUTE',
  'PORTAL_WEB_PHONE_RING_ANSWER_DELAY',
  'PORTAL_WEB_PHONE_RING_ANSWER_MIC_MUTE',

  'PORTAL_WEB_PHONE_ALLOW_QUEUED_PICKUP',

  'PORTAL_WEB_PHONE_IFRAME_ICON',
  'PORTAL_WEB_PHONE_IFRAME_NAME',
  'PORTAL_WEB_PHONE_IFRAME_URL',

  'PORTAL_TRANSFER_PREFIX_PARK',
  'portalTransferPrefixPark',

  // sms
  'PORTAL_MMS_SUPPORTED_CARRIERS',
  'PORTAL_MMS_GROUP_MESSAGING_SUPPORTED_CARRIERS',

  // password
  'PORTAL_USERS_SECURE_PASSWORD_STRONG',
  'PORTAL_USERS_SECURE_PASSWORD_FORCE_NUMERIC',
  'PORTAL_USERS_SECURE_PASSWORD_MIN_CAPITAL_LETTER_COUNT',
  'PORTAL_USERS_SECURE_PASSWORD_MIN_LENGTH',
  'PORTAL_USERS_SECURE_PASSWORD_MIN_NUMBER_COUNT',
  'PORTAL_USERS_SECURE_PASSWORD_MIN_SPECIAL_CHAR_COUNT',
  'PORTAL_USER_SECURE_PASSWORD_ALLOW_EXT_IN_PASSWORD',
  'PORTAL_USER_SECURE_PASSWORD_EXTENSION_FORBID_LIST',

  'PORTAL_THREE_WAY_CALL_DISCONNECT_OTHERS_ON_END',
];

/**
 * fetches UI configs and filters what is set in state configs
 * @param decodedToken
 * @returns {Generator<Promise<Awaited<unknown>[]>|*, void, *>}
 */
export default function* uiConfigsToState(decodedToken) {
  const updatedConfigs = [];

  const hostname = yield select((state) => selectConfig(state, 'hostname'));

  try {
    const res = yield all([
      call(nsUiConfig.get, {
        configName: 'WS_SERVERS',
        domain: decodedToken?.domain || '*',
        user: decodedToken?.user || '*',
        hostname,
        role: decodedToken?.user_scope || '*',
        reseller: decodedToken?.territory || '*',
        returnFirst: true,
      }),

      call(nsUiConfig.get, {
        configName: 'PORTAL_*',
        domain: decodedToken?.domain || '*',
        user: decodedToken?.user || '*',
        hostname,
        role: decodedToken?.user_scope || '*',
        reseller: decodedToken?.territory || '*',
      }),
    ]);

    // process socket response
    if (res[0]
      && res[0].config_value
      && res[0].config_value.trim()
    ) {
      const tempWs = res[0].config_value.split(',');
      if (tempWs) {
        const uaSockets = [];
        let weight = 100;
        for (let i = 0; i < tempWs.length; i += 1) {
          if (tempWs[i] && tempWs[i].trim()) {
            tempWs[i] = tempWs[i].trim();
            /* Allow defining wsServers parameter as:
             *  String: "host"
             *  Array of Strings: ["host1", "host2"]
             *  Array of Objects: [{ws_uri:"host1", weight:1}, {ws_uri:"host2", weight:0}]
             *  Array of Objects and Strings: [{ws_uri:"host1"}, "host2"]
            */
            // #NOTE: This will be different in newer SIP.js versions
            if (tempWs[i].indexOf(':') === -1) {
              uaSockets.push({ ws_uri: `wss://${tempWs[i]}:9002`, weight });
            } else {
              uaSockets.push({ ws_uri: `wss://${tempWs[i]}`, weight });
            }
            weight /= 10;
            if (weight < 1) weight = 0;
          }
        }
        if (uaSockets.length > 0) {
          updatedConfigs.push({
            config_name: 'uaSocketURLs',
            config_value: uaSockets,
          });
        }
      }
    }

    // process configs
    if (
      res[1]
      && res[1].length
    ) {
      for (let i = 0; i < res[1].length; i += 1) {
        // process ns sockets
        if (res[1][i].config_name === 'nsSocketHostName'
          && res[1][i].config_value
          && res[1][i].config_value.trim()
        ) {
          const tempWs = res[1][i].config_value.split(',');
          if (tempWs) {
            const nsSockets = [];
            for (let index = 0; index < tempWs.length; index += 1) {
              if (tempWs[index] && tempWs[index].trim()) {
                tempWs[index] = tempWs[index].trim();
                if (tempWs[index].indexOf(':') === -1) {
                  nsSockets.push(`wss://${tempWs[index]}:8001`);
                } else {
                  nsSockets.push(`wss://${tempWs[index]}`);
                }
              }
            }
            if (nsSockets) {
              updatedConfigs.push({
                config_name: 'nsSocketURLs',
                config_value: nsSockets,
              });
            }
          }
        } else {
          // process configs
          if (res[1][i].config_name === 'PORTAL_LOCALIZATION_NUMBER_FORMAT' && !res[1][i].config_value) {
            const browserLanguage = navigator.language;
            res[1][i].config_value = browserLanguage.indexOf('-') !== -1 ? browserLanguage.split('-')[1] : 'US';
          }
          updatedConfigs.push(res[1][i]);
        }
      }
    }

    yield put(setConfigs(
      updatedConfigs.filter((config) => allowedConfigsFromResponse.includes(config.config_name)),
    ));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
  }
}
