import { put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  selectAllCallParks, selectFilteredBy, selectParked, setCallParkResultSet,
} from '../../state/callPark/callParkSlice';

import matchContact from '../../utils/matchContact';
import { selectUserId } from '../../state/configs/configsSlice';

export default function* processCallPark() {
  const callParkList = yield select(selectAllCallParks);
  const parkedList = yield select(selectParked);
  const filteredBy = yield select(selectFilteredBy);
  const userId = yield select(selectUserId);

  let resultSet = [];

  switch (filteredBy) {
    case 'All':
      resultSet = callParkList;
      break;
    case 'Site':
      resultSet = _.filter(callParkList, (park) => park.same_site_as_requesting_user === 'yes');
      break;
    case 'Department':
      resultSet = _.filter(callParkList, (park) => park.same_dept_as_requesting_user === 'yes');
      break;
    default:
      resultSet = callParkList;
  }

  const expandedList = [];
  resultSet.forEach((res) => {
    const parked = _.filter(parkedList, (park) => park.term_user === res.queue_name);
    if (parked.length > 0) {
      parked.forEach((park) => {
        const contact = matchContact(park.by_sub !== park.term_user ? park.by_sub : park.orig_user);
        expandedList.push({
          ...res,
          ...park,
          queue_name: park.term_user,
          contact_name: contact?.name || '',
          park: true,
          park_id: userId === park.orig_user ? park.term_callid : park.orig_callid,
        });
      });
    } else {
      expandedList.push(res);
    }
  });

  yield put(setCallParkResultSet(expandedList));
}
