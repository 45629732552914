import {
  put, call, select,
} from 'redux-saga/effects';
import * as contactState from '../../../state/contacts/contactsSlice';
import getContactResultSet from '../../../utils/contact/getContactResultSet';
import getContactListDepartments from '../../../utils/contact/getContactListDepartments';
import callHistoryContactsLink from '../../callHistory/callHistoryContactsLink/callHistoryContactsLink';

export default function* processResultSet(updateLoading = false) {
  const contactsArr = yield select(contactState.selectContacts);
  const filteredBy = yield select(contactState.selectFilteredBy);
  const filteredUser = yield select(contactState.selectFilteredUser);
  const sortedBy = yield select(contactState.selectSortedBy);
  const sortedDirection = yield select(contactState.selectSortedDirection);

  // process contacts for contact result set
  const contactsList = yield call(
    getContactResultSet,
    contactsArr,
    filteredBy,
    filteredUser,
    sortedBy,
    sortedDirection,
  );

  // get list of departments
  const departments = yield call(getContactListDepartments, contactsArr);

  // dispatch department list
  yield put(contactState.setDepartments(departments));

  // dispatch result set
  yield put(contactState.setContactsResultSet(contactsList));

  if (updateLoading) {
    // dispatch(loading(false));
    yield put(contactState.setLoading(false));
  }

  // unfiltered, unordered list of contacts for selecting contacts
  // so that searching on contact list sidebar does not effect conversation cards
  const contactsListAll = yield call(
    getContactResultSet,
    contactsArr,
    'all',
    true,
    'last_name',
    'asc',
  );
  yield put(contactState.setContactsResultSetUnfiltered(contactsListAll));

  yield call(callHistoryContactsLink);
}
