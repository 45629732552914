import { put, select } from 'redux-saga/effects';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import { setCards, setPinnedCards } from '../../../state/cards/cardsSlice';
import { CARD_GROUP_TYPE_PINNED } from '../../../constants';

export default function* updateOrder({ payload: { groupType, cards } }) {
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  if (groupType === CARD_GROUP_TYPE_PINNED) {
    localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify(cards));
    yield put(setPinnedCards(cards));
  } else {
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(cards));
    yield put(setCards(cards));
  }
}
