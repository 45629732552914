import { call, put, select } from 'redux-saga/effects';
import updateActiveCount from '../updateActiveCount/updateActiveCount';
import updateIsConference from '../updateIsConference/updateIsConference';
import { selectCallSessions, setCallSessions } from '../../../state/callSessions/callSessionsSlice';

export default function* addCallSession({ payload: callSession }) {
  const sessions = yield select(selectCallSessions);
  yield put(setCallSessions([...sessions, callSession]));
  yield call(updateActiveCount);
  yield call(updateIsConference);
}
