import {
  call, put, select,
} from 'redux-saga/effects';
import moment from 'moment-timezone';

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading, setKpiStats,
} from '../../state/agent/agentSlice';
import { selectUserTimeZone } from '../../state/user/userSlice';

export default function* fetchAgentKPI() {
  const decodedToken = yield call(nsToken.getDecoded);
  const timeZone = yield select(selectUserTimeZone);

  try {
    const res = yield call(nsApi.get, {
      object: 'statistics',
      action: 'read',
      user: decodedToken.user,
      domain: decodedToken.domain,
      type: 'agent',
      fields: 'CH,ATT,IA,IAVG,IM,OANS,OAvg,OM,TT',
      op_term_sub: decodedToken.user,
      range_interval: `${Math.abs(moment.tz(timeZone).utcOffset() / 60)} HOUR`,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: `${moment().format('YYYY-MM-DD')} 23:59:59`,
    });

    const kpistats = res[decodedToken.user];
    if (kpistats) {
      if (kpistats.IAVG) {
        kpistats.IAVG = Math.trunc(kpistats.IAVG);
      }
      if (kpistats.OAvg) {
        kpistats.OAvg = Math.trunc(kpistats.OAvg);
      }

      yield put(setKpiStats(kpistats));
    }
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    yield put(setLoading(false));
  }
}
