/* eslint-disable camelcase */
import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import { selectQueueById, selectQueued, setQueued } from '../../state/agent/agentSlice';
import queuedSort from '../../utils/agentcenter/queuedSort';

export default function* updateQueueWaiting({ payload }) {
  if (yield select((state) => selectQueueById(state, payload.queue_name))) {
    const queued = yield select((state) => selectQueued(state, payload.queue_name));
    if (_.isEmpty(queued.queued)) {
      const queudArray = [];
      queudArray.push(payload);
      yield put(setQueued({ queued: queuedSort(queudArray), queueId: payload.queue_name }));
    } else {
      const filterQ = _.cloneDeep(queued);
      const waiting = filterQ.queued.find(({ session_key }) => session_key === payload.session_key);
      if (!waiting) {
        filterQ.queued.push(payload);
      } else {
        filterQ.queued = queued.queued.map((q) => (q.session_key !== payload.session_key ? q : { ...payload, pickup: q.pickup }));
      }

      yield put(setQueued({ queued: queuedSort(filterQ.queued), queueId: payload.queue_name }));
    }
  }
}
