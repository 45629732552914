import _ from 'lodash';
import {
  call, put, select,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectQueues, setQueues, setResponders } from '../../state/agent/agentSlice';
import { selectUserId } from '../../state/configs/configsSlice';

export function* handleResponder(responder) {
  const userId = yield select(selectUserId);
  if (responder.sub_user.toString() === userId) {
    let queues = yield select(selectQueues);
    queues = _.cloneDeep(queues);
    const queue = _.find(queues, { queue_name: responder.huntgroup_name.toString() });
    if (queue !== undefined) {
      queue.agent = responder;
      const upQueues = queues.map((q) => (q.queue_name === responder.huntgroup_name.toString() ? queue : q));
      yield put(setQueues(upQueues));
    }
  }
}

export default function* fetchResponders(queueId) {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    const res = yield call(nsApi.get, {
      object: 'agent',
      action: 'read',
      user: decodedToken.user,
      domain: decodedToken.domain,
      queue_name: queueId,
      format: 'json',
    });

    yield put(setResponders({ queueId, responders: res }));
    // yield all(res.map((responder) => call(handleResponder, responder))); // [WP-1684] use delayed queue fetcher rather than re-map agent
  } catch (e) {
    // todo bugsnag
    // eslint-disable-next-line no-console
    console.error(`Fetching Responders ${queueId} failed: ${e}`);
  }
}
