export const BROWSER_CHECK_FAIL = 'BROWSER_CHECK_FAIL';
export const REPEAT_INSTANCE = 'REPEAT_INSTANCE';
export const LOGIN_CARD_STYLES = { maxWidth: 380, width: '100%', px: 2 };
export const ONBOARDING_CARD_STYLES = {
  maxWidth: 380, width: '100%', height: '580px', px: 2,
};
export const ONBOARD_COMPLETE = 'onboard-complete';
export const ONBOARD_SETUP = 'onboard-setup';
export const LOGIN_FAILED_ERROR = 'LOGIN_FAILED_ERROR';
export const STOP_INIT = 'STOP_INIT';

// agent status
export const STATUS_ONLINE = 'online';
export const STATUS_OFFLINE = 'offline';
export const STATUS_SINGLE_CALL = 'singlecall';
export const STATUS_END_SHIFT = 'endshift';
export const STATUS_LUNCH = 'lunch';
export const STATUS_BREAK = 'break';
export const STATUS_MEETING = 'meeting';
export const STATUS_OTHER = 'other';
export const STATUS_CUSTOM_1 = 'cust1';
export const STATUS_CUSTOM_2 = 'cust2';
export const STATUS_CUSTOM_3 = 'cust3';
export const STATUS_CUSTOM_4 = 'cust4';
export const STATUS_CUSTOM_5 = 'cust5';
export const STATUS_CUSTOM_6 = 'cust6';
export const STATUS_CUSTOM_7 = 'cust7';
export const STATUS_CUSTOM_8 = 'cust8';
export const STATUS_CUSTOM_9 = 'cust9';
export const STATUS_CUSTOM_10 = 'cust10';

// cards
export const CARD_GROUP_TYPE_PINNED = 'CARD_GROUP_TYPE_PINNED';
export const CARD_GROUP_TYPE_OTHER = 'CARD_GROUP_TYPE_OTHER';
export const CARD_VARIANT_STANDARD = 'CARD_VARIANT_STANDARD';
export const CARD_VARIANT_MODAL = 'CARD_VARIANT_MODAL';
export const CARD_VARIANT_SUBMODAL = 'CARD_VARIANT_SUBMODAL';

export const KEYPAD_CONTEXT_DIALER = 'KEYPAD_CONTEXT_DIALER';
export const KEYPAD_CONTEXT_PHONE = 'KEYPAD_CONTEXT_PHONE';

export const CARD_CALL_MAIN = 'CARD_CALL_MAIN';
export const CARD_CALL_CONTACTS = 'CARD_CALL_CONTACTS';
export const CARD_CALL_CONTACT_DETAILS = 'CARD_CALL_CONTACT_DETAILS';
export const CARD_CALL_DIAL_PAD = 'CARD_CALL_DIAL_PAD';
export const CARD_CALL_DIALER = 'CARD_CALL_DIALER';
export const CARD_CALL_CALL_PARKS = 'CARD_CALL_CALL_PARKS';

// snackbar severity types
export const ERROR = 'ERROR';
export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const WARN = 'WARN';

// socket
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR';

// workflows
export const WORKFLOW_ADD_CALL = 'WORKFLOW_ADD_CALL';
export const WORKFLOW_TRANSFER = 'WORKFLOW_TRANSFER';
export const WORKFLOW_VOICEMAIL_FORWARD = 'WORKFLOW_VOICEMAIL_FORWARD';
export const WORKFLOW_SELECT_CONTACT = 'WORKFLOW_SELECT_CONTACT';
export const WORKFLOW_CHIP_TEXT_FIELD = 'WORKFLOW_CHIP_TEXT_FIELD';
