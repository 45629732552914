import { call, put, select } from 'redux-saga/effects';
import {
  selectCallStack,
  selectNavStack,
  setCallStack,
  setCurrentState,
  setLastState,
  setNavStack,
} from '../../../state/routeStack/routeStackSlice';
import { getNavigate } from '../../../services/router/router';

export default function* clearStack({ payload }) {
  const navigate = yield call(getNavigate);

  if (payload.stackType === 'callStack') {
    if (payload.toBase) {
      const stack = yield select(selectCallStack);
      yield put(setCallStack([stack[0]]));
      yield put(setCurrentState(stack[0]));

      if (payload.navigate) {
        navigate(stack[0].route);
      }
    } else {
      yield put(setCallStack([]));
      yield put(setCurrentState({}));
    }
    yield put(setLastState({}));
  } else if (payload.stackType === 'navStack') {
    if (payload.toBase) {
      const stack = yield select(selectNavStack);
      yield put(setNavStack([stack[0]]));
      yield put(setCurrentState(stack[0]));

      if (payload.navigate) {
        navigate(stack[0].route);
      }
    } else {
      yield put(setNavStack([]));
      yield put(setCurrentState({}));
    }
  }
}
