import { put } from 'redux-saga/effects';
import { setCurrentState, setNavStack } from '../../../state/routeStack/routeStackSlice';
import { getNavigate } from '../../../services/router/router';

export default function* navStackRebase({ payload }) {
  const navigate = getNavigate();
  yield put(setCurrentState(payload));
  yield put(setNavStack([payload]));
  navigate(payload.route, { state: payload.state });
}
