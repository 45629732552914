/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { CARD_CALL_MAIN } from '../../constants';

const cardsList = nsUtils.normalizeList('id', []);

export const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    loading: true,
    pinnedCards: [],
    pinnedCardsIds: cardsList.ids,
    pinnedCardsEntities: cardsList.entities,
    cards: [],
    cardsIds: cardsList.ids,
    cardsEntities: cardsList.entities,
    expandedId: null,
    addParticipantsId: null,
    viewParticipantsId: null,
    changeConversationNameId: null,
    dialedNumber: undefined,
    callCardState: CARD_CALL_MAIN,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPinnedCards: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('id', action.payload);
      state.pinnedCards = action.payload;
      state.pinnedCardsIds = ids;
      state.pinnedCardsEntities = entities;
    },
    setCards: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('id', action.payload);
      state.cards = action.payload;
      state.cardsIds = ids;
      state.cardsEntities = entities;
    },
    setExpandedId: (state, action) => {
      state.expandedId = action.payload;
    },
    setAddParticipantsId: (state, action) => {
      state.addParticipantsId = action.payload;
    },
    setViewParticipantsId: (state, action) => {
      state.viewParticipantsId = action.payload;
    },
    setChangeConversationNameId: (state, action) => {
      state.changeConversationNameId = action.payload;
    },
    setDialedNumber: (state, action) => {
      state.dialedNumber = action.payload;
    },
    setCallCardState: (state, action) => {
      state.callCardState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setPinnedCards,
  setCards,
  setExpandedId,
  setAddParticipantsId,
  setViewParticipantsId,
  setChangeConversationNameId,
  setDialedNumber,
  setCallCardState,
} = cardsSlice.actions;

// Selectors
export const selectLoading = (state) => state.cards.loading;
export const selectPinnedCards = (state) => state.cards.pinnedCards;
export const selectPinnedCard = (state, id) => state.cards.pinnedCardsEntities[id];
export const selectPinnedCardsIds = (state) => state.cards.pinnedCardsIds;
export const selectPinnedCardsEntities = (state) => state.cards.pinnedCardsEntities;
export const selectCards = (state) => state.cards.cards;
export const selectCard = (state, id) => state.cards.cardsEntities[id];
export const selectCardsIds = (state) => state.cards.cardsIds;
export const selectCardsEntities = (state) => state.cards.cardsEntities;
export const selectExpandedId = (state) => state.cards.expandedId;
export const selectAddParticipantsId = (state) => state.cards.addParticipantsId;
export const selectViewParticipantsId = (state) => state.cards.viewParticipantsId;
export const selectChangeConversationNameId = (state) => state.cards.changeConversationNameId;
export const selectDialedNumber = (state) => state.cards.dialedNumber;
export const selectCallCardState = (state) => state.cards.callCardState;

export default cardsSlice.reducer;
