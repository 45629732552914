import Touchtone from 'touchtone';
import baudio from 'webaudio';
import i18next from 'i18next';
import { call } from 'redux-saga/effects';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';
import { WARN } from '../../../constants';
import store from '../../../state/store';
import sessionsUpdated from '../../callSessions/sessionsUpdated/sessionsUpdated';

export default function* sendDtmf({ payload }) {
  const { activeCall, tone } = payload;
  const touchtone = new Touchtone({ duration: 0.1, pause: 0.01, volume: 0.1 });

  let dtmfSender = false;
  if (activeCall?.session?.sessionDescriptionHandler) {
    const selfPC = activeCall.session.sessionDescriptionHandler.peerConnection;
    if (selfPC?.getSenders) {
      dtmfSender = selfPC.getSenders()[0].dtmf;
    } else if (selfPC?.createDTMFSender) {
      const activeStream = activeCall.session.getLocalStreams()[0];
      dtmfSender = selfPC.createDTMFSender(activeStream.getAudioTracks()[0]);
    }
  }

  if (dtmfSender?.canInsertDTMF) {
    dtmfSender.insertDTMF(tone, 100, 70);
    touchtone.press(tone);
    baudio(touchtone.play()).play();
  } else if (!activeCall) {
    // storybook testing
    touchtone.press(tone);
    baudio(touchtone.play()).play();
  } else if (activeCall?.session?.sessionDescriptionHandler) {
    // Send DTMF via RTP (RFC 4733)
    const success = activeCall.session.sessionDescriptionHandler.sendDtmf(tone, { duration: 100, interToneGap: 70 });
    if (!success) {
      store.dispatch(setSnackbar({
        type: WARN,
        open: true,
        message: i18next.t('DTMF_NOT_ENABLED'),
        duration: 1000,
      }));
    }
  } else {
    store.dispatch(setSnackbar({
      type: WARN,
      open: true,
      message: i18next.t('DTMF_NOT_ENABLED'),
      duration: 1000,
    }));
  }

  activeCall.dtmfString += tone;
  yield call(sessionsUpdated);
}
