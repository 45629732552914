import { call } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

export default function* prioritizeQueued({ payload }) {
  const { queue_name, session_key } = payload; // eslint-disable-line camelcase
  const decodedToken = yield call(nsToken.getDecoded);

  yield call(nsApi.post, {
    object: 'queued',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    queue_name, // eslint-disable-line camelcase
    session_key, // eslint-disable-line camelcase
    prioritized: 'yes',
  });
}
