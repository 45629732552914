import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import nsConfig from '@netsapiens/netsapiens-js/dist/config';

import axios from 'axios';
import localMessages from '../../translations/en.json';
import store from '../../state/store';
import { selectPortalURL } from '../../state/configs/configsSlice';
import normalizeLocal from '../../utils/normalizeLocal';
import pack from '../../../package.json';

export const { version } = pack;

export default async function i18nInit(locale = 'en_us') {
  localStorage.setItem('webphoneRenderedLang', normalizeLocal(locale));

  let messages = localMessages;

  // use the local string during development
  if (process.env.NODE_ENV !== 'development'
    && process.env.NODE_ENV !== 'test'
    && !window?.location?.href?.includes('local.netsapiens.com')
  ) {
    try {
      console.debug('sdk config', nsConfig.getConfiguration());
      const portalURL = selectPortalURL(store.getState());
      ({ data: messages } = await axios.get(`${portalURL}webroot/locale/wp/${normalizeLocal(locale)}.json?version=${version}`));
    } catch (e) {
      console.error('Failed to fetch translations');
      // todo bugsnag failed to get translations
    }
  }

  await i18n
    .use(initReactI18next)
    .init({
      resources: {
        [locale]: { translation: messages },
      },
      lng: locale,
      interpolation: {
        escapeValue: false,
      },
    });
}
