import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ssoLoginAction } from '../../../sagas/ssoLogin/action';
import googleLogo from '../../../assets/images/google-logo.svg';

function GoogleSso({
  googleSsoClientId,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const buttonRef = createRef();

  useEffect(() => {
    const aScript = document.createElement('script');
    aScript.type = 'text/javascript';
    aScript.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(aScript);

    aScript.onload = () => {
      window?.google?.accounts?.id?.initialize({
        client_id: googleSsoClientId,
        callback: (res) => {
          if (res?.credential) {
            dispatch(ssoLoginAction({ token: res.credential, vendor: 'google' }));
          }
        },
      });

      window?.google?.accounts?.id?.renderButton(
        document.getElementById('google-sso'),
        { width: 'large' },
      );
    };
  }, []);

  const doUniversalClick = (element) => {
    for (let i = 0; i < element.children.length; i += 1) {
      const child = element.children[i];
      doUniversalClick(child);
      // if element could be the button, click it (avoid iframes, vectors, etc)
      if (['DIV', 'BUTTON', 'A'].includes(child.nodeName)) child?.click();
    }
  };

  const ssoLoginSubmit = (e) => {
    e.preventDefault();
    doUniversalClick(buttonRef.current);
  };

  return (
    <div>
      <Button
        id="GoogleSsoLogin"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={ssoLoginSubmit}
        sx={{
          mt: 2,
          color: '#3c4043',
          fontWeight: '400',
          backgroundColor: '#fff',
          boxShadow: '0',
          borderRadius: 1,
          border: '1px solid #cccccc',
          textTransform: 'none',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#fff',
          },
          height: '40px',
        }}
      >
        <img
          src={googleLogo}
          alt="google logo"
          style={{
            height: 18,
            marginRight: 12,
          }}
        />
        {t('SIGN_IN_WITH_GOOGLE')}
      </Button>
      <Box
        id="google-sso"
        sx={{ display: 'none' }}
        data-testid="google-sso-container"
        ref={buttonRef}
      />
    </div>
  );
}

GoogleSso.propTypes = {
  googleSsoClientId: PropTypes.string.isRequired,
};

export default GoogleSso;
