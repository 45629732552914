import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import microsoftLogo from '../../../assets/images/microsoft-logo.svg';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { ssoLoginAction } from '../../../sagas/ssoLogin/action';

let msalInstance;

function initOffice(officeSsoClientId) {
  const msalConfig = {
    auth: {
      clientId: officeSsoClientId,
      // eslint-disable-next-line no-restricted-globals
      redirectUri: `${location.origin}/webphone`,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  // eslint-disable-next-line
  msalInstance = new Msal.UserAgentApplication(msalConfig); // Msal object will come from msal.js
}

function OfficeSso({
  officeSsoClientId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const aScript = document.createElement('script');
    aScript.type = 'text/javascript';
    aScript.src = 'https://alcdn.msauth.net/lib/1.4.18/js/msal.js';
    document.head.appendChild(aScript);

    aScript.onload = () => {
      initOffice(officeSsoClientId);
    };
  }, []);

  const ssoLoginSubmit = (e) => {
    e.preventDefault();

    window.history.pushState('portal', 'Login', '/webphone');

    const authenticationParameters = {
      scopes: ['user.read'], // optional Array<string>
      prompt: 'select_account',
    };

    msalInstance.loginPopup(authenticationParameters)
      .then(() => {
        msalInstance.acquireTokenSilent(authenticationParameters)
          .then((response) => {
            const token = response.accessToken;
            dispatch(ssoLoginAction({ token, vendor: 'office' }));
          })
          .catch((err) => {
            if (err.name === 'InteractionRequiredAuthError') {
              msalInstance.acquireTokenPopup(authenticationParameters)
                .then((response2) => {
                  const token = response2.accessToken;
                  dispatch(ssoLoginAction({ token, vendor: 'office' }));
                })
                .catch((err2) => {
                  console.error('acquireTokenSilent fresh err', err);
                  bugsnagClient.notify(err);
                  console.error('acquireTokenPopup fresh err', err2);
                  bugsnagClient.notify(err2);
                });
            } else {
              console.error('acquireTokenSilent fresh err', err);
              bugsnagClient.notify(err);
            }
          });
      })
      .catch((err) => {
        console.error('loginPopup err', err);
        bugsnagClient.notify(err);
      });
  };

  return (
    <Button
      id="OfficeSsoLogin"
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      onClick={ssoLoginSubmit}
      sx={{
        mt: 2,
        color: '#3c4043',
        fontWeight: '400',
        backgroundColor: '#fff',
        boxShadow: '0',
        borderRadius: 1,
        border: '1px solid #cccccc',
        textTransform: 'none',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: '#fff',
        },
        height: '40px',
      }}
    >
      <img
        src={microsoftLogo}
        alt="microsoft logo"
        style={{
          height: 22,
          marginRight: 12,
        }}
      />
      {t('SIGN_IN_WITH_MICROSOFT')}
    </Button>
  );
}

OfficeSso.propTypes = {
  officeSsoClientId: PropTypes.string.isRequired,
};

export default OfficeSso;
