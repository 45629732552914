import {
  call, delay, put, select,
} from 'redux-saga/effects';
import { selectUaSocketURLs } from '../../../state/configs/configsSlice';
import {
  selectRegisterer,
  selectUA,
  setUA,
  setUAState,
  incReconnectionAttempt,
  selectAttemptingReconnect,
  selectOneSameServerReconnect,
  selectReconnectionAttempt,
  setAttemptReconnect,
  setOneSameServerReconnect,
} from '../../../state/ua/uaSlice';
import addRegistrar from '../addRegistrar/addRegistrar';
import initUA from '../initUA/initUA';

// Number of seconds to wait between reconnection attempts
const reconnectionDelay = 4;

// eslint-disable-next-line require-yield
export default function* attemptReconnect() {
  const attemptingReconnection = yield select(selectAttemptingReconnect);
  console.debug('attemptReconnect', attemptingReconnection);

  // Reconnection attempt already in progress
  if (attemptingReconnection) {
    return;
  }

  // We're attempting a reconnection
  yield put(setAttemptReconnect(true));

  let connected = false;
  while (!connected) {
    // delay retries
    yield delay(reconnectionDelay * 1000);
    yield put(setUAState('init'));
    const oneSameServerReconnectAvailable = yield select(selectOneSameServerReconnect);

    // should not attempt if it is duplicate instance
    let currentRoute = window.location.pathname;
    if (currentRoute === '/webphone/duplicate-instance') {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!oneSameServerReconnectAvailable) {
      yield put(incReconnectionAttempt());
      yield put(setOneSameServerReconnect(true));
    }
    const connectionAttempt = yield select(selectReconnectionAttempt);
    console.debug('Attempt Reconnect: connectionAttempt', connectionAttempt);
    const ua = yield select(selectUA);
    if (ua) {
      const uaSocketURLs = yield select(selectUaSocketURLs);
      const newIndex = connectionAttempt % uaSocketURLs.length;
      // eslint-disable-next-line no-param-reassign
      ua.transport.configuration.server = uaSocketURLs[newIndex].ws_uri || uaSocketURLs[newIndex];
      console.debug('Attempt Reconnect: new server', ua.transport.configuration.server);

      if (ua.state === 'Stopped') {
        try {
          // check if it is a duplicate instance, if it is, do not start
          currentRoute = window.location.pathname;
          if (currentRoute === '/webphone/duplicate-instance') {
            // eslint-disable-next-line no-continue
            continue;
          }
          console.debug('Attempt Reconnect: stopped is starting...');
          yield ua.start();
          const hasRegisterer = yield select(selectRegisterer);
          if (!hasRegisterer) {
            console.debug('UA stoppped re-add Registrar');
            yield call(addRegistrar);
          }
          connected = true;
          yield put(setAttemptReconnect(false));
          yield put(setOneSameServerReconnect(true));
        } catch (e) {
          yield put(setOneSameServerReconnect(false));
          console.debug('Attempt Reconnect: failed to start', e);
          // remove ua
          yield put(setUA(null));
        }
      } else {
        try {
          yield ua.reconnect();
          console.debug('Attempt Reconnect: reconnect success!');

          const hasRegisterer = yield select(selectRegisterer);
          if (!hasRegisterer) {
            console.debug('UA reconnecting re-add Registrar');
            yield call(addRegistrar);
          }

          connected = true;
          yield put(setAttemptReconnect(false));
          yield put(setOneSameServerReconnect(true));
        } catch (e) {
          console.debug('Attempt Reconnect: reconnect failed');
        }
      }
    } else {
      console.debug('Attempt Reconnect: connectionAttempt ua = null calling initUA() to reset UA ');
      yield call(initUA);
    }
  }
}
