import { call, put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectUser, setUser } from '../../state/user/userSlice';
import bugsnagClient from '../../services/bugsnag/bugsnag';

export default function* updateUserStatus({ payload: status }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const user = yield select(selectUser);

  /* eslint-disable-next-line */
  const params = {
    uid: decodedToken.sub,
    user: decodedToken.user,
    domain: decodedToken.domain,
    object: 'subscriber',
    action: 'update',
    format: 'json',
    current_password: user.pwd || '',
    message: status,
  };

  try {
    yield put(setUser({
      ...user,
      portal_status: status,
    }));
    yield call(nsApi.post, params);
  } catch (e) {
    bugsnagClient.notify(e);
  }
}
