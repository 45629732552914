import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import i18n from 'i18next';
import {
  selectNewVoicemail,
  selectSavedVoicemail,
  setNewVoicemail,
  setSavedVoicemail,
} from '../../../state/voicemail/voicemailSlice';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';

export default function* saveVoicemail({ payload }) {
  const token = yield call(nsToken.getDecoded);

  try {
    yield call(nsApi.post, {
      object: 'audio',
      action: 'update',
      format: 'json',
      owner_domain: token.domain,
      owner: token.user,
      index: `${payload.index}.wav`,
      type: payload.vmail_type,
      intArray: 'true',
    });

    let list;

    if (payload.vmail_type === 'vmail/new') {
      list = yield select(selectNewVoicemail);
    } else {
      list = yield select(selectSavedVoicemail);
    }

    const updatedList = _.filter(list, (v) => v.index !== payload.index);
    yield put(setNewVoicemail(updatedList));

    const savedVoicemail = yield select(selectSavedVoicemail);
    yield put(setSavedVoicemail([
      payload,
      ...savedVoicemail,
    ]));

    yield put(setSnackbar({
      open: true,
      message: i18n.t('VOICEMAIL_SAVED'),
    }));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setSnackbar({
      open: true,
      message: i18n.t('VOICEMAIL_SAVE_FAILED'),
    }));
  }
}
