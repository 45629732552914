/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const networkSlice = createSlice({
  name: 'network',
  initialState: {
    networkConnected: null,
  },
  reducers: {
    setNetworkConnected: (state, action) => {
      state.networkConnected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNetworkConnected } = networkSlice.actions;

// Selectors
export const selectNetworkConnected = (state) => state.network.networkConnected;

export default networkSlice.reducer;
