import {
  call, fork, put,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsConfig from '@netsapiens/netsapiens-js/dist/config';
import nsPortal from '@netsapiens/netsapiens-js/dist/portal';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import { REPEAT_INSTANCE, BROWSER_CHECK_FAIL } from '../../constants';
import browserSupport from '../../utils/browserSupport';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import checkAuthCode from '../checkAuthCode/checkAuthCode';
import configureSDK from '../configureSDK/configureSDK';
import networkConnection from '../networkConnection/networkConnection';
import uiConfigsToState from '../uiConfigsToState/uiConfigsToState';
import favicon from '../favicon/favicon';
import setPageTitle from '../pageTitle/pageTitle';
import { setConfig } from '../../state/configs/configsSlice';
import { getNavigate } from '../../services/router/router';
import * as routes from '../../routePaths';
import loggedIn from '../loggedIn/loggedIn';
import 'moment/min/locales';

export default function* initSaga() {
  const authCode = yield call(nsBrowser.getQuery, 'auth_code');
  const username = yield call(nsBrowser.getQuery, 'username');
  const navigate = yield call(getNavigate);
  navigate(routes.getInitRoute());

  try {
    // start watching for network connection
    // displays snackbar if network is not connected or network is re-connected
    yield fork(networkConnection);

    // test if the browser is supported
    // if the _browser is not supported
    // the catch will change the state to display the browser-support-msg component
    yield call(browserSupport);

    // configure the sdk with the app name and portal/api url
    // this configures the initial configuration before user authentication is checked
    yield call(configureSDK);

    // get server ui configs and save to state
    yield call(uiConfigsToState);

    // fork fetching and setting the favicon
    // fetches from the api for custom branding
    yield fork(favicon);

    // fork setting the application title
    // ui configurable
    yield fork(setPageTitle);

    // verify the uc licensing is valid
    // if uc license has expired the catch will display the uc-license-msg component
    const ucCheck = yield nsPortal.ucCheck();
    yield put(setConfig({
      config_name: 'apiHash',
      config_value: ucCheck.api_hash,
    }));

    // check auth_code
    // if password reset display the password-reset component
    let resetPassword = false;
    try {
      resetPassword = yield call(checkAuthCode, authCode, username);
    } catch (e) {
      const statusCode = e?.response?.status;

      // 401 for normal expire
      // 400 for Invalid auth code owner
      if (statusCode === 401 || statusCode === 400) {
        navigate(routes.getForgotPasswordExpiredRoute());
        return;
      }
    }
    if (resetPassword) {
      const decodedToken = yield call(nsToken.getDecoded);
      yield call(uiConfigsToState, decodedToken);
      // todo check portal language

      navigate(routes.getPasswordResetRoute(), {
        state: {
          user: nsToken.getDecoded().user,
          prAuthCode: authCode,
        },
      });
      return;
    }

    // call sdk proxy authentication
    // if proxy authentication fails the catch will display the login-form component
    yield call(nsApi.proxyAuthentication, ucCheck.api_hash);

    yield call(loggedIn);
  } catch (e) {
    // get error constants from sdk
    const {
      API_TOKEN_REFRESH_FAIL,
      INVALID_TOKEN,
      NO_PORTAL_SESSION,
      NO_PORTAL_TOKEN,
      NO_STORED_TOKEN,
    } = nsApi;

    // error handler
    switch (e) {
      case REPEAT_INSTANCE:
        navigate(routes.getDuplicateInstanceRoute());
        break;

      case BROWSER_CHECK_FAIL:
        navigate(routes.getBrowserSupportRoute());
        break;
      case API_TOKEN_REFRESH_FAIL:
      case INVALID_TOKEN:
      case NO_PORTAL_SESSION:
      case NO_PORTAL_TOKEN:
      case NO_STORED_TOKEN:
        // if no portal token, then unsubscribe push
        // unsubscribePush(); todo
        navigate(routes.getLoginRoute());
        break;
      case nsConfig.NO_SDK_CONFIG:
        break;
      default:
        bugsnagClient.notify(e);
        navigate(routes.getGenericErrorRoute(), { state: { body: e } });
    }
  }
}
