import {
  all, call, fork, put,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setCallPark, setLoading } from '../../state/callPark/callParkSlice';
import processCallPark from '../processCallPark/processCallPark';
import socketSubscribe from '../socket/socketSubscribe/socketSubscribe';
import bugsnagClient from '../../services/bugsnag/bugsnag';

export default function* fetchCallParks() {
  try {
    const decodedToken = yield call(nsToken.getDecoded);
    yield put(setLoading(true));

    const res = yield call(nsApi.get, {
      object: 'callqueue',
      action: 'read',
      type: 'park',
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
      addDeptSite: 'inclMatchesCurrentUser',
    });

    yield put(setCallPark(res));
    yield fork(processCallPark);
    yield all(res.map((park) => call(socketSubscribe, {
      payload: {
        type: 'call',
        subtype: 'queue',
        filter: park.queue_name,
      },
    })));

    yield put(setLoading(false));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setLoading(false));
  }
}
