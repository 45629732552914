import {
  call, put, select,
} from 'redux-saga/effects';
import { selectContacts, setSearchBy } from '../../../state/contacts/contactsSlice';
import processResultSet from '../processResultSet/processResultSet';
import processSearchResultSet from '../processSearchResultSet/processSearchResultSet';

export default function* contactsSearchBy({ payload: searchBy }) {
  yield put(setSearchBy(searchBy));

  const contacts = yield select(selectContacts);
  if (contacts) {
    yield call(processResultSet);
    yield call(processSearchResultSet);
  }
}
