// call statuses that are considered active
export const activeTypes = [
  'trying',
  'inboundProgressing',
  'outboundProgressing',
  'accepted',
];

export default (sessions) => {
  let count = 0;
  for (let i = 0; i < sessions.length; i += 1) {
    if (activeTypes.indexOf(sessions[i].status) !== -1) {
      count += 1;
    }
  }
  return count;
};
