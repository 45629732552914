import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import debounce from 'debounce';

import agentReducer from './agent/agentSlice';
import answeringRulesReducer from './answeringRules/answeringRulesSlice';
import callHistoryReducer from './callHistory/callHistorySlice';
import callParkReducer from './callPark/callParkSlice';
import callSessionsReducer from './callSessions/callSessionsSlice';
import cardsReducer from './cards/cardsSlice';
import chatReducer from './chat/chatSlice';
import configsReducer from './configs/configsSlice';
import contactsReducer from './contacts/contactsSlice';
import dialogReducer from './dialog/dialogSlice';
import greetingsReducer from './greetings/greetingsSlice';
import devicesReducer from './devices/devicesSlice';
import hidConsentReducer from './hidConsent/hidConsentSlice';
import networkReducer from './network/networkSlice';
import presenceReducer from './presence/presenceSlice';
import ringtonesReducer from './ringtones/ringtonesSlice';
import routeStackReducer from './routeStack/routeStackSlice';
import screenSizeReducer, { getSize, setResize } from './screenSize/screenSize';
import snackbarReducer from './snackbar/snackbarSlice';
import socketReducer from './socket/socketSlice';
import uaReducer from './ua/uaSlice';
import userReducer from './user/userSlice';
import mfaReducer from './mfa/mfaSlice';
import userMediaReducer from './userMedia/userMediaSlice';
import voicemailReducer from './voicemail/voicemailSlice';
import webphoneDeviceReducer from './webphoneDevice/webphoneDeviceSlice';

export const sagaMiddleware = createSagaMiddleware();

const reducers = {
  agent: agentReducer,
  answeringRules: answeringRulesReducer,
  callHistory: callHistoryReducer,
  callPark: callParkReducer,
  callSessions: callSessionsReducer,
  cards: cardsReducer,
  chat: chatReducer,
  configs: configsReducer,
  contacts: contactsReducer,
  dialog: dialogReducer,
  greetings: greetingsReducer,
  devices: devicesReducer,
  hidConsent: hidConsentReducer,
  network: networkReducer,
  presence: presenceReducer,
  ringtones: ringtonesReducer,
  routeStack: routeStackReducer,
  screenSize: screenSizeReducer,
  snackbar: snackbarReducer,
  socket: socketReducer,
  ua: uaReducer,
  user: userReducer,
  mfa: mfaReducer,
  userMedia: userMediaReducer,
  voicemail: voicemailReducer,
  webphoneDevice: webphoneDeviceReducer,
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  })
    .prepend(sagaMiddleware),
});

// TESTING SETUP
export const setupStore = (preloadedState) => configureStore({
  reducer: combineReducers(reducers),
  preloadedState,
});

export default store;

/**
 * Handler for window resize events
 * @private
 */
function handler() {
  const width = getSize('Width');

  let display;
  let size;

  if (width > 1200) {
    display = 'fullscreen';
    size = 'lg';
  } else if (width > 716) {
    display = 'fullscreen';
    size = 'md';
  } else {
    display = 'compact';
    size = 'sm';
  }

  store.dispatch(setResize({
    display,
    height: getSize('Height'),
    size,
    width,
  }));
}
window.addEventListener('resize', debounce(handler, 50));

/**
 * Dispatch initial size on load
 */
document.addEventListener('load', handler);
