import { call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setLoading } from '../../../state/answeringRules/answeringRulesSlice';
import fetchAnsweringRules from '../fetchAnsweringRules';

export default function* updateAnswerRulePriority({ payload: list }) {
  const decodedToken = yield call(nsToken.getDecoded);

  const newAnswerRulePriorityList = list.reduce((l, rule) => [...l, rule.priority], []);
  try {
    const params = {
      object: 'answerrule',
      action: 'reorder',
      user: decodedToken.user,
      domain: decodedToken.domain,
      uid: decodedToken.sub,
      priority: newAnswerRulePriorityList,
      application: 'webphone',
    };

    yield call(nsApi.post, params);
    yield call(fetchAnsweringRules);
  } catch (e) {
    console.error('Updating Answering Rules Priority User failed:', e);
    bugsnagClient.notify(e);
    yield put(setLoading(false));
  }
}
