/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const contactsList = nsUtils.normalizeList('id', [], [
  { name: 'names', path: 'name', toLower: true },
  { name: 'users', path: 'user' },
  { name: 'phones', path: 'user' },
  { name: 'phones', path: 'cell_phone' },
  { name: 'phones', path: 'home_phone' },
  { name: 'phones', path: 'work_phone' },
]);

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    loading: true,

    badgeList: [],
    departments: [],

    contacts: [],
    contactsIds: contactsList.ids,
    contactsEntities: contactsList.entities,
    contactsNames: contactsList.names,
    contactsUsers: contactsList.users,
    contactsPhones: contactsList.phones,

    filteredBy: 'all',
    filteredUser: true,
    sortedBy: 'last_name',
    sortedDirection: 'asc',
    contactsResultSet: [], // sorted, filtered list used for the contact-list component
    searchResultsSet: [], // optimized contact list used for searching
    contactsResultSetUnfiltered: [],
    searchedResultsSet: null, // processed search result set flag
    searchBy: '',

    inCallFilteredBy: 'all',
    inCallSortedBy: 'last_name',
    inCallSortedDirection: 'asc',
    inCallContactsResultSet: [], // sorted, filtered list used for the call contact-list component
    inCallSearchResultsSet: [], // optimized contact list used for searching
    inCallSearchedResultsSet: null, // processed search result set flag
    inCallSearchBy: '',

    domainUserList: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBadgeList: (state, action) => {
      state.badgeList = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setContacts: (state, action) => {
      const {
        entities, ids, names, users, phones,
      } = nsUtils.normalizeList('id', action.payload, [
        { name: 'names', path: 'name', toLower: true },
        { name: 'users', path: 'user' },
        { name: 'phones', path: 'user' },
        { name: 'phones', path: 'cell_phone' },
        { name: 'phones', path: 'home_phone' },
        { name: 'phones', path: 'work_phone' },
      ]);
      state.contacts = action.payload;
      state.contactsIds = ids;
      state.contactsEntities = entities;
      state.contactsNames = names;
      state.contactsUsers = users;
      state.contactsPhones = phones;
    },
    setFilteredBy: (state, action) => {
      state.filteredBy = action.payload;
    },
    setFilteredUser: (state, action) => {
      state.filteredUser = action.payload;
    },
    setSortedBy: (state, action) => {
      state.sortedBy = action.payload;
    },
    setSortedDirection: (state, action) => {
      state.sortedDirection = action.payload;
    },
    setContactsResultSet: (state, action) => {
      state.contactsResultSet = action.payload;
    },
    setSearchResultsSet: (state, action) => {
      state.searchResultsSet = action.payload;
    },
    setSearchedResultsSet: (state, action) => {
      state.searchedResultsSet = action.payload;
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },

    setInCallFilteredBy: (state, action) => {
      state.inCallFilteredBy = action.payload;
    },
    setInCallSortedBy: (state, action) => {
      state.inCallSortedBy = action.payload;
    },
    setInCallSortedDirection: (state, action) => {
      state.inCallSortedDirection = action.payload;
    },
    setInCallContactsResultSet: (state, action) => {
      state.inCallContactsResultSet = action.payload;
    },
    setInCallSearchResultsSet: (state, action) => {
      state.inCallSearchResultsSet = action.payload;
    },
    setInCallSearchedResultsSet: (state, action) => {
      state.inCallSearchedResultsSet = action.payload;
    },
    setInCallSearchBy: (state, action) => {
      state.inCallSearchBy = action.payload;
    },
    setContactsResultSetUnfiltered: (state, action) => {
      state.contactsResultSetUnfiltered = action.payload;
    },

    setDomainUserList: (state, action) => {
      state.domainUserList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setBadgeList,
  setDepartments,
  setContacts,
  setFilteredBy,
  setFilteredUser,
  setSortedBy,
  setSortedDirection,
  setContactsResultSet,
  setSearchResultsSet,
  setSearchedResultsSet,
  setSearchBy,
  setInCallContactsResultSet,
  setInCallFilteredBy,
  setInCallSortedBy,
  setInCallSortedDirection,
  setInCallSearchResultsSet,
  setInCallSearchedResultsSet,
  setInCallSearchBy,
  setDomainUserList,
  setContactsResultSetUnfiltered,
} = contactsSlice.actions;

// Selectors
export const selectLoading = (state) => state.contacts.loading;
export const selectBadgeList = (state) => state.contacts.badgeList;
export const selectDepartments = (state) => state.contacts.departments;
export const selectContacts = (state) => state.contacts.contacts;
export const selectContactsIds = (state) => state.contacts.contactsIds;
export const selectContactsEntities = (state) => state.contacts.contactsEntities;
export const selectContactsNames = (state) => state.contacts.contactsNames;
export const selectContactsUsers = (state) => state.contacts.contactsUsers;
export const selectContactsPhones = (state) => state.contacts.contactsPhones;
export const selectFilteredBy = (state) => state.contacts.filteredBy;
export const selectFilteredUser = (state) => state.contacts.filteredUser;
export const selectSortedBy = (state) => state.contacts.sortedBy;
export const selectSortedDirection = (state) => state.contacts.sortedDirection;
export const selectContactsResultSet = (state) => state.contacts.contactsResultSet;
export const selectSearchResultsSet = (state) => state.contacts.searchResultsSet;
export const selectSearchedResultsSet = (state) => state.contacts.searchedResultsSet;
export const selectSearchBy = (state) => state.contacts.searchBy;
export const selectContactsResultSetUnfiltered = (state) => state.contacts.contactsResultSetUnfiltered;

export const selectInCallFilteredBy = (state) => state.contacts.inCallFilteredBy;
export const selectInCallContactsResultsSet = (state) => state.contacts.inCallContactsResultSet;
export const selectInCallSearchResultsSet = (state) => state.contacts.inCallSearchResultsSet;
export const selectInCallSearchedResultsSet = (state) => state.contacts.inCallSearchedResultsSet;
export const selectInCallSearchBy = (state) => state.contacts.inCallSearchBy;
export const selectInCallSortedBy = (state) => state.contacts.inCallSortedBy;
export const selectInCallSortedDirection = (state) => state.contacts.inCallSortedDirection;

export const selectDomainUserList = (state) => state.contacts.domainUserList;

export default contactsSlice.reducer;
