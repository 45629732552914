import { call, fork, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setContacts, setDomainUserList, setLoading } from '../../../state/contacts/contactsSlice';
import formatContact from '../../../utils/contact/formatContact';
import processSearchResultSet from '../processSearchResultSet/processSearchResultSet';
import { setUserPresence } from '../../../state/presence/presenceSlice';
import processResultSet from '../processResultSet/processResultSet';
import processInCallResultSet from '../processInCallResultSet/processInCallResultSet';
import processInCallSearchResultSet from '../processInCallSearchResultSet/processInCallSearchResultSet';

export default function* fetchContacts() {
  try {
    const decodedToken = yield call(nsToken.getDecoded);
    yield put(setLoading(true));

    const res = yield call(nsApi.get, {
      object: 'contact',
      action: 'read',
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
      includeDomain: 'yes',
    });

    // format contacts formatContacts
    const formattedArr = [];
    const l = res.length;
    for (let i = 0; i < l; i += 1) {
      const formattedContact = formatContact(res[i]);
      yield put(setUserPresence({ user: res[i].user, presence: res[i].presence }));
      formattedArr.push(formattedContact);
    }

    // set contacts (each contact must have an ID)
    yield put(setContacts(formattedArr));

    // set the setContactsResultSet, filtered and sorted
    yield call(processResultSet);
    // set the optimized setSearchResultsSet for searching
    yield fork(processSearchResultSet);

    // set the setInCallResultSet, filtered and sorted
    yield fork(processInCallResultSet);
    // set the optimized setInCallSearchResultsSet for searching
    yield fork(processInCallSearchResultSet);

    const formattedDomainUsers = [];

    const queuesList = yield call(nsApi.get, {
      object: 'callqueue',
      action: 'list',
      domain: decodedToken.domain,
      format: 'json',
    });

    const attendantsList = yield call(nsApi.get, {
      object: 'attendant',
      action: 'list',
      domain: decodedToken.domain,
      format: 'json',
    });

    const conferencesList = yield call(nsApi.get, {
      object: 'conference',
      action: 'list',
      domain: decodedToken.domain,
      format: 'json',
    });

    for (let i = 0; i < queuesList.length; i += 1) {
      formattedDomainUsers.push({
        user: `${queuesList[i].queue_name}@${queuesList[i].domain}`,
        name: queuesList[i].description,
      });
    }

    for (let i = 0; i < attendantsList.length; i += 1) {
      formattedDomainUsers.push({
        user: attendantsList[i].callee_match,
        name: attendantsList[i].mainname,
      });
    }

    for (let i = 0; i < conferencesList.length; i += 1) {
      formattedDomainUsers.push({
        user: conferencesList[i].aor,
        name: conferencesList[i].name,
      });
    }

    yield put(setDomainUserList(formattedDomainUsers));

    yield put(setLoading(false));
  } catch (e) {
    // todo bugsnag
    yield put(setLoading(false));
  }
}
