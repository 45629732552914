import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import moment from 'moment-timezone';
import { call, fork, put } from 'redux-saga/effects';
import { setCallHistory, setLoading, setMissedCallCount } from '../../../state/callHistory/callHistorySlice';
import callHistoryContactsLink from '../callHistoryContactsLink/callHistoryContactsLink';

export default function* fetchCallHistory() {
  try {
    const decodedToken = yield call(nsToken.getDecoded);
    yield put(setLoading(true));

    const res = yield call(nsApi.get, {
      object: 'cdr2',
      action: 'read',
      uid: decodedToken.sub,
      format: 'json',
      limit: 200,
    });

    const formattedArr = [];
    const l = res.length;
    for (let i = 0; i < l; i += 1) {
      res[i].hide = parseInt(res[i].hide, 10);
      if (!res[i].hide) {
        // delete unused props
        delete res[i].hide;
        delete res[i].tag;
        delete res[i].time_answer;
        delete res[i].time_release;

        try {
          res[i].time_start = res[i].time_start
            ? moment.unix(res[i].time_start)
            : res[i].time_start;
        } catch (e) {
          console.error(`Error processing moment, time_start = ${res[i].time_start}`);
          console.error(e);
        }

        if (!res[i].number) {
          console.error('Error call history, number is null  ');
          console.error(res[i]);
          res[i].number = '';
        } else if (res[i].number.indexOf('sip') !== -1) {
          res[i].number = res[i].number.slice(0, res[i].number.indexOf('@'));
          res[i].number = res[i].number.replace(/[^0-9+*]+/g, ''); // Remove non-digit characters except '+'
        } else if (res[i].number === 'VMail' || res[i].number === 'Auto-Attendant') {
          res[i].name = 'VMail';
          res[i].number = res[i].orig_to_uri.slice(0, res[i].orig_to_uri.indexOf('@'));
          res[i].number = res[i].orig_to_uri.replace(/\D+/g, ''); // Remove all non-digits
        } else {
          res[i].number = res[i].number.replace(/[^0-9+]+/g, ''); // Remove non-digit characters except '+'
        }

        // WP-740
        if (res[i].orig_to_uri != null && res[i].orig_to_uri.indexOf('sip') !== -1) {
          res[i].orig_to_uri = res[i].orig_to_uri.slice(0, res[i].orig_to_uri.indexOf('@'));
          res[i].orig_to_uri = res[i].orig_to_uri.replace(/[^0-9+*]+/g, ''); // Remove non-digit characters except '+'
        }

        formattedArr.push(res[i]);
      }
    }
    yield put(setCallHistory(formattedArr));
    yield put(setMissedCallCount(formattedArr.filter((record) => record.type === '2').length));
    yield fork(callHistoryContactsLink);
    yield put(setLoading(false));
  } catch (e) {
    // todo bugsnag
    yield put(setLoading(false));
  }
}
