import React, {
  useEffect, useMemo, useState, Suspense,
} from 'react';
import {
  Routes, Route, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import getTheme from './theme';
import bugsnagClient from './services/bugsnag/bugsnag';
import * as routes from './routePaths';
import { initAction } from './sagas/init/action';
import { setNavigate } from './services/router/router';
import { selectThemePrimary, selectThemeSecondary } from './state/configs/configsSlice';

import Dialog from './components/organisms/Dialog/Dialog';
import Loading from './components/pages/Loading/Loading';
import Login from './components/pages/Login/Login';
import i18nInit from './services/i18n/i18nInit';
import { selectUserLanguage } from './state/user/userSlice';
import Snackbar from './components/molecules/Snackbar/Snackbar';
import DuplicateInstance from './components/pages/DuplicateInstance.jsx/DuplicateInstance';
import { setPWAPrompt } from './state/userMedia/userMediaSlice';
import MFAForm from './components/pages/MFAForm/MFAForm';
import ForgotPasswordExpired from './components/pages/ForgotPasswordExpired/ForgotPasswordExpired';
import HidConsentDialog from './components/organisms/HidConsentDialog/HidConsentDialog';

const AgentCenter = React.lazy(() => import('./components/pages/AgentCenter/AgentCenter'));
const AnsweringRules = React.lazy(() => import('./components/pages/AnsweringRules/AnsweringRules'));
const AnswerRuleDetails = React.lazy(() => import('./components/pages/AnsweringRulesDetails/AnsweringRulesDetails'));
const BrowserSupport = React.lazy(() => import('./components/pages/BrowserSupport/BrowserSupport'));
const CallHistory = React.lazy(() => import('./components/pages/CallHistory/CallHistory'));
const CallDetails = React.lazy(() => import('./components/pages/CallDetails/CallDetails'));
const CallPark = React.lazy(() => import('./components/pages/CallPark/CallPark'));
const ChatSMS = React.lazy(() => import('./components/pages/ChatSMS/ChatSMS'));
const Contacts = React.lazy(() => import('./components/pages/Contacts/Contacts'));
const ContactForm = React.lazy(() => import('./components/pages/ContactForm/ContactForm'));
const ContactDetails = React.lazy(() => import('./components/pages/ContactDetails/ContactDetails'));
const DashboardLayout = React.lazy(() => import('./components/templates/Dashboard/Dashboard'));
const ForgotPassword = React.lazy(() => import('./components/pages/ForgotPassword/ForgotPassword'));
const ForgotUsername = React.lazy(() => import('./components/pages/ForgotUsername/ForgotUsername'));
const Greetings = React.lazy(() => import('./components/pages/Greetings/Greetings'));
const GreetingsNew = React.lazy(() => import('./components/pages/GreetingsNew/GreetingsNew'));
const GenericError = React.lazy(() => import('./components/pages/GenericError/GenericError'));
const IFrameHolder = React.lazy(() => import('./components/pages/IFrameHolder/IFrameHolder'));
const Onboarding = React.lazy(() => import('./components/pages/Onboarding/Onboarding'));
const PasswordReset = React.lazy(() => import('./components/pages/PasswordReset/PasswordReset'));
const Settings = React.lazy(() => import('./components/pages/Settings/Settings'));
const SelectSubscriber = React.lazy(() => import('./components/pages/SelectSubscriber/SelectSubscriber'));
const SSOEnroll = React.lazy(() => import('./components/pages/SSOEnroll/SSOEnroll'));
const Voicemail = React.lazy(() => import('./components/pages/Voicemail/Voicemail'));
const Profile = React.lazy(() => import('./components/pages/Profile/Profile'));
const AccessDenied = React.lazy(() => import('./components/pages/AccessDenied/AccessDenied'));

const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const themePrimary = useSelector(selectThemePrimary);
  const themeSecondary = useSelector(selectThemeSecondary);
  const userLanguage = useSelector(selectUserLanguage);

  const [loading, setLoading] = useState(true);

  const theme = useMemo(
    () => getTheme(themePrimary, themeSecondary),
    [themePrimary, themeSecondary],
  );

  // grab the pwa install prompt
  // for later use to prompt it on click and also to check if pwa is installed
  // will be null if pwa is installed
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      dispatch(setPWAPrompt(e));
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      dispatch(setPWAPrompt(null));
    };
  }, []);

  useEffect(() => {
    setNavigate(navigate);
    dispatch(initAction());
    setLoading(false);
  }, []);

  // Listens for changes in 'ns_t' key in storage and reloads if 'sub' changes.
  useEffect(() => {
    const parseJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
          .join(''),
      );

      return JSON.parse(jsonPayload);
    };

    const storageEventListener = (e) => {
      if (
        e.key !== undefined
        && e.key === 'ns_t'
        && e.oldValue !== undefined
        && e.newValue !== undefined
        && e.newValue !== null
      ) {
        const oldJwt = parseJwt(e.oldValue);
        const newJwt = parseJwt(e.newValue);

        if (
          oldJwt.sub !== undefined
          && newJwt.sub !== undefined
          && oldJwt.sub !== newJwt.sub
        ) {
          window.location.reload();
        }
      }
    };

    window.addEventListener('storage', storageEventListener);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', storageEventListener);
    };
  }, []); // Empty dependency array to run this effect only once on mount

  // handle user language switching
  useEffect(() => {
    if (userLanguage) {
      (async () => {
        await i18nInit(userLanguage);
      })();
    }
  }, [userLanguage]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                path={routes.getDashboardRoute()}
                element={loading ? <Loading /> : <DashboardLayout />}
              >
                <Route
                  path={routes.getAgentCenterRoute()}
                  element={<Suspense fallback={<Loading />}><AgentCenter /></Suspense>}
                />
                <Route
                  path={routes.getAnsweringRulesListRoute()}
                  element={<Suspense fallback={<Loading />}><AnsweringRules /></Suspense>}
                />
                <Route
                  path="answer-rule-details/:id"
                  element={<Suspense fallback={<Loading />}><AnswerRuleDetails /></Suspense>}
                />
                <Route
                  path={routes.getCallHistoryRoute()}
                  element={<Suspense fallback={<Loading />}><CallHistory /></Suspense>}
                />
                <Route
                  path="/call-details/:id"
                  element={<Suspense fallback={<Loading />}><CallDetails /></Suspense>}
                />
                <Route
                  path={routes.getCallParkListRoute()}
                  element={<Suspense fallback={<Loading />}><CallPark /></Suspense>}
                />
                <Route
                  path={routes.getChatListRoute()}
                  element={<Suspense fallback={<Loading />}><ChatSMS /></Suspense>}
                />
                <Route
                  path={routes.getProfileRoute()}
                  element={<Suspense fallback={<Loading />}><Profile /></Suspense>}
                />
                <Route
                  path={routes.getContactsRoute()}
                  element={<Suspense fallback={<Loading />}><Contacts /></Suspense>}
                />
                <Route
                  path={routes.getContactFormRoute()}
                  element={<Suspense fallback={<Loading />}><ContactForm /></Suspense>}
                />
                <Route
                  path="/contact-details/:id"
                  element={<Suspense fallback={<Loading />}><ContactDetails /></Suspense>}
                />
                <Route
                  path={routes.getGreetingsRoute()}
                  element={<Suspense fallback={<Loading />}><Greetings /></Suspense>}
                />
                <Route
                  path={routes.getGreetingsNewRoute()}
                  element={<Suspense fallback={<Loading />}><GreetingsNew /></Suspense>}
                />
                <Route
                  path={routes.getIFrameRoute()}
                  element={<Suspense fallback={<Loading />}><IFrameHolder /></Suspense>}
                />
                <Route
                  path={routes.getSettingsRoute()}
                  element={<Suspense fallback={<Loading />}><Settings /></Suspense>}
                />
                <Route
                  path={routes.getVoicemailRoute()}
                  element={<Suspense fallback={<Loading />}><Voicemail /></Suspense>}
                />
              </Route>
              <Route path={routes.getInitRoute()} element={<Loading />} />
              <Route path={routes.getBrowserSupportRoute()} element={<BrowserSupport />} />
              <Route path={routes.getDuplicateInstanceRoute()} element={<DuplicateInstance />} />
              <Route path={routes.getMFARoute()} element={<MFAForm />} />
              <Route path={routes.getForgotPasswordRoute()} element={<ForgotPassword />} />
              <Route path={routes.getForgotUsernameRoute()} element={<ForgotUsername />} />
              <Route path={routes.getGenericErrorRoute()} element={<GenericError />} />
              <Route path={routes.getLoginRoute()} element={<Login />} />
              <Route path={routes.getOnboardingRoute()} element={<Onboarding />} />
              <Route path={routes.getPasswordResetRoute()} element={<PasswordReset />} />
              <Route path={routes.getSelectSubscriberRoute()} element={<SelectSubscriber />} />
              <Route path={routes.getSSOEnrollRoute()} element={<SSOEnroll />} />
              <Route
                path={routes.getForgotPasswordExpiredRoute()}
                element={<ForgotPasswordExpired />}
              />
              <Route
                path={routes.getAccessDeniedRoute()}
                element={<AccessDenied />}
              />
            </Routes>
          </Suspense>
          <Dialog />
          <Snackbar />
          <HidConsentDialog />
        </Box>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
