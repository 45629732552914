import { call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setLoading } from '../../../state/answeringRules/answeringRulesSlice';
import fetchAnsweringRules from '../fetchAnsweringRules';

export default function* deleteAnsweringRule({ payload: ruleId }) {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    const params = {
      object: 'answerrule',
      action: 'delete',
      uid: decodedToken.sub,
      user: decodedToken.user,
      domain: decodedToken.domain,
      time_frame: ruleId,
    };

    yield call(nsApi.post, params);
    yield call(fetchAnsweringRules);
  } catch (e) {
    console.error('Fetching Summary by User failed:', e);
    bugsnagClient.notify(e);
    yield put(setLoading(false));
  }
}
