import _ from 'lodash';

/**
 *
 * @param {[]} contactList
 * @param {string} sortBy 'first_name', 'last_name', 'online', 'extension'
 * @returns {[]}
 */
export default function sortContactList(contactList, sortBy) {
  switch (sortBy) {
    case 'last_name':
    case 'online':
      return _.sortBy(contactList, (contact) => (
        contact.last_name ? contact.last_name.toLowerCase() : contact.last_name));
    case 'first_name':
      return _.sortBy(contactList, (contact) => (
        contact.first_name ? contact.first_name.toLowerCase() : contact.first_name));
    case 'extension':
      return _.sortBy(contactList, 'user');
    default:
      return contactList;
  }
}
