import {
  put, select,
} from 'redux-saga/effects';
import { selectContactsResultSet, setSearchedResultsSet } from '../../../state/contacts/contactsSlice';
import searchContacts from '../../../utils/contact/searchContacts';

export default function* processSearchedResultSet({ payload: searchStr }) {
  const contactsResultSet = yield select(selectContactsResultSet);
  const searchResults = searchContacts(searchStr, contactsResultSet);

  yield put(setSearchedResultsSet(searchResults));
}
