import _ from 'lodash';
import {
  put, select,
} from 'redux-saga/effects';
import {
  selectPortalStatus,
  selectQueuesEntities,
  setAgentStatus,
} from '../../../state/agent/agentSlice';

export default function* processAgentStatus() {
  const queueEntities = yield select(selectQueuesEntities);
  const portalAgentStatus = yield select(selectPortalStatus);
  let newStatus = portalAgentStatus !== '' ? portalAgentStatus : 'Offline';

  const filterQueues = _.filter(
    queueEntities,
    (q) => q.agent.entry_status === 'available' || q.agent.entry_option === 'immediate',
  );

  if (filterQueues.length > 0) {
    newStatus = 'Online';

    if (_.some(filterQueues, (q) => q.agent.entry_option === 'manual')) {
      newStatus = 'singlecall';
    }
  }

  yield put(setAgentStatus(newStatus));
  return newStatus;
}
