import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import {
  selectCards, selectPinnedCards, setCards, setExpandedId, setPinnedCards,
} from '../../../state/cards/cardsSlice';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import { removeCardAction } from '../removeCard/action';
import updateMessageCardVideo from '../updateMessageCardVideo/updateMessageCardVideo';

export default function* addCard({ payload }) {
  const pinnedCards = yield select(selectPinnedCards);
  const otherCards = yield select(selectCards);

  const allCards = [...pinnedCards, ...otherCards];

  const callCard = _.find(allCards, { type: 'call' });
  const dialerCard = _.find(allCards, { type: 'dialer' });

  // new-chat is for cards that might not have a session_id or session on reload,
  // brand-new sessions that might need to be skipped
  const conversationCard = _.find(
    allCards,
    (card) => (card.type === 'conversation' || card.type === 'new-chat') && card.meta?.sessionId === payload?.sessionId,
  );

  // new conversation card is openning the card for dialing a new sms/chat
  const newConversationCard = _.find(allCards, { type: 'new-conversation' });

  const queueCard = _.find(allCards, (card) => (card.type === 'queue') && card.meta?.queueId === payload?.queueId);

  const statCard = _.find(allCards, (card) => (card.type === 'stat') && (card.meta.stat === payload?.stat));

  const parkCard = _.find(allCards, { type: 'park' });

  if ((payload.type === 'dialer' && !dialerCard)
    || (payload.type === 'call' && !callCard)
    || (payload.type === 'dialer' && !dialerCard)
    || (payload.type === 'conversation' && !conversationCard)
    || (payload.type === 'new-chat' && !conversationCard)
    || (payload.type === 'new-conversation' && !newConversationCard)
    || payload.type === 'wrapup'
    || (payload.type === 'queue' && !queueCard)
    || (payload.type === 'stat' && !statCard)
    || (payload.type === 'park' && !parkCard)
  ) {
    const newCard = new nsModels.card.Card({
      id: nsUtils.randomId(),
      meta: {
        contactId: payload.contactId || null,
        sessionId: payload.sessionId || null,
        queueId: payload.queueId || null,
        sendMessageParams: payload.sendMessageParams || null,
        videoInvite: payload.videoInvite || false,
        videoId: payload.videoId || null,
        videoInviteFrom: payload.videoInviteFrom || null,
        details: payload.details || {},
        stat: payload.stat || null,
      },
      type: payload.type,
    });

    const appName = yield select(selectAppName);
    const userId = yield select(selectUserId);

    // if this a new call prepend to the "pinned" card group
    // otherwise add to the "other" card group
    if (newCard.type === 'call') {
      const newOrder = [];
      if (pinnedCards.length) {
        for (let i = 0; i < pinnedCards.length; i += 1) {
          newOrder.push(pinnedCards[i]);
        }
        newOrder.unshift(newCard);
      } else {
        newOrder.push(newCard);
      }

      localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify(newOrder));
      yield put(setPinnedCards(newOrder));
    } else {
      const newOrder = [];
      if (otherCards.length) {
        for (let i = 0; i < otherCards.length; i += 1) {
          newOrder.push(otherCards[i]);
        }
        newOrder.unshift(newCard);
      } else {
        newOrder.push(newCard);
      }

      localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(newOrder));
      yield put(setCards(newOrder));
    }

    // expand the type of card is call or dialer
    if (newCard.type === 'call'
      || newCard.type === 'dialer'
      || newCard.type === 'new-conversation'
      || (newCard.type === 'conversation' && !payload.addMessage)
      || (newCard.type === 'new-chat' && !payload.addMessage)
      || newCard.type === 'wrapup'
      || newCard.type === 'queue'
      || newCard.type === 'stat'
      || newCard.type === 'park') {
      yield put(setExpandedId(newCard.id));
    }
  } else if (payload.type === 'call' && callCard) {
    yield put(setExpandedId(callCard.id));
  } else if (payload.type === 'dialer' && dialerCard) {
    yield put(setExpandedId(dialerCard.id));
  } else if (payload.type === 'conversation' && conversationCard && !payload.addMessage) {
    yield put(setExpandedId(conversationCard.id));
  } else if (payload.type === 'new-conversation' && newConversationCard) {
    yield put(setExpandedId(newConversationCard.id));
  } else if (payload.type === 'new-chat' && conversationCard) {
    yield put(setExpandedId(conversationCard.id));
  } else if (payload.type === 'queue' && queueCard) {
    yield put(setExpandedId(queueCard.id));
  } else if (payload.type === 'stat' && statCard) {
    yield put(setExpandedId(statCard.id));
  } else if (payload.type === 'park' && parkCard) {
    yield put(setExpandedId(parkCard.id));
  }

  // if card exists and is a conversation, add video invite update the card
  if (conversationCard && payload.videoInvite) {
    const updateMessageCardVideoPayload = {
      sessionId: payload.sessionId,
      videoInvite: payload.videoInvite,
      videoId: payload.videoId,
      videoInviteFrom: payload.videoInviteFrom,
    };
    yield call(updateMessageCardVideo, updateMessageCardVideoPayload);
  }

  // remove new conversation card if any other cards are added
  if (payload.type !== 'new-conversation' && newConversationCard?.id) {
    yield put(removeCardAction(newConversationCard.id));
  }

  // TODO set expanded conversation card as expanded

  // ignore the dialer and call card if the card already exists
}
