import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'lodash';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import i18nInit from './services/i18n/i18nInit';
import normalizeLocal from './utils/normalizeLocal';
import reportWebVitals from './reportWebVitals';
import store from './state/store';
import './sagas/rootSaga';

(async () => {
  let language = localStorage.getItem('webphoneRenderedLang');

  // convert old local storage key/value to new
  const oldLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
  if (oldLang && oldLang !== 'null' && oldLang !== 'undefined') {
    language = normalizeLocal(oldLang);
    localStorage.setItem('webphoneRenderedLang', language || 'en_us');
    localStorage.removeItem('NG_TRANSLATE_LANG_KEY');
  }

  // use the browser language if it hasn't been set yet
  if (_.isNil(language) || language === 'null' || language === 'undefined') {
    language = normalizeLocal(navigator.language || 'en_us');
    localStorage.setItem('webphoneRenderedLang', language);
  }

  // set initial language
  await i18nInit(language);
  // todo update date-fns local

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter basename="/webphone">
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
