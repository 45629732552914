import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagClient = Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  appVersion: process.env.VERSION,
  releaseStage: process.env.NODE_ENV,
  plugins: [new BugsnagPluginReact(React)],
});

export default bugsnagClient;
