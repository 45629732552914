import { put, select } from 'redux-saga/effects';
import { selectCallSessions, setCallSessions } from '../../../state/callSessions/callSessionsSlice';

export default function* putSessionsOnHold() {
  const sessions = yield select(selectCallSessions);
  if (sessions && sessions.length) {
    for (let i = 0; i < sessions.length; i += 1) {
      sessions[i].hold();
    }
    yield put(setCallSessions([...sessions]));
  }
}
