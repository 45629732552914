/* eslint-disable camelcase */
import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import { selectQueued, setQueued } from '../../state/agent/agentSlice';
import queuedSort from '../../utils/agentcenter/queuedSort';

export default function* updateWaitingPickup({ payload }) {
  const queued = yield select((state) => selectQueued(state, payload.queue_name));
  if (!_.isEmpty(queued.queued)) {
    const uniqueQ = _.cloneDeep(queued);
    const waiting = uniqueQ.queued.find(({ session_key }) => session_key === payload.session_key);
    if (waiting) {
      waiting.pickup = payload.pickup;
      uniqueQ.queued = queued.queued.map((q) => (q.session_key !== payload.session_key ? q : waiting));
      yield put(setQueued({ queued: queuedSort(uniqueQ.queued), queueId: payload.queue_name }));
    }
  }
}
