import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import { selectAgentType } from '../../state/agent/agentSlice';

export default function* updateQueueEntry({ payload }) {
  const decodedToken = yield call(nsToken.getDecoded);
  let agentType = yield select(selectAgentType);
  if (!agentType) { agentType = decodedToken.sub; }

  const params = {
    object: 'agent',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    queue: payload.queueName,
    device: agentType,
    entry_action: payload.action,
    toAsQueue: payload.toAsQueue || 'no',
    format: 'json',
  };

  if (payload.option) {
    params.entry_option = payload.option;
  }

  try {
    yield call(nsApi.post, params);
  } catch (e) {
    bugsnagClient.notify(e);
  }
}
