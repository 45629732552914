export default function decodeNewTimeframe(answeringRules) {
  // Create a new array to store modified rules without directly mutating the function parameter
  const modifiedRules = answeringRules.map((rule) => {
    const modifiedRule = { ...rule }; // Clone the current rule to avoid direct mutations

    if (modifiedRule.new_time_frame_data && modifiedRule.new_time_frame_data !== false) {
      // Process 'dow'
      if (modifiedRule.new_time_frame_data.dow && modifiedRule.new_time_frame_data.dow !== 'null') {
        // modifiedRule.new_time_frame_data.dow = (modifiedRule.new_time_frame_data.dow);
      } else {
        modifiedRule.new_time_frame_data.dow = null;
      }

      // Process 'holidays'
      if (modifiedRule.new_time_frame_data.holidays && modifiedRule.new_time_frame_data.holidays !== 'null') {
        // modifiedRule.new_time_frame_data.holidays = (modifiedRule.new_time_frame_data.holidays);
      } else {
        modifiedRule.new_time_frame_data.holidays = null;
      }

      // Process 'specific_dates'
      if (modifiedRule.new_time_frame_data.specific_dates && modifiedRule.new_time_frame_data.specific_dates !== 'null') {
        // modifiedRule.new_time_frame_data.specific_dates = (modifiedRule.new_time_frame_data.specific_dates);
      } else {
        modifiedRule.new_time_frame_data.specific_dates = null;
      }
    }

    return modifiedRule; // Return the modified rule for each iteration
  });

  return modifiedRules; // Return the new array with modified rules
}
