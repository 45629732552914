/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const greetingsSlice = createSlice({
  name: 'greetings',
  initialState: {
    loading: true,
    greetings: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGreetings: (state, action) => {
      state.greetings = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading, setGreetings,
} = greetingsSlice.actions;

// Selectors
export const selectLoading = (state) => state.greetings.loading;
export const selectGreetings = (state) => state.greetings.greetings;

export default greetingsSlice.reducer;
