// import moment from 'moment';
import _ from 'lodash';
import {
  // call,
  put,
  select,
} from 'redux-saga/effects';

import {
  selectMessages,
  setLoading, setMessages,
} from '../../../state/chat/chatSlice';

/* eslint-disable no-param-reassign */
export default function* updateMessageStatus(msg, status) {
  const debug = false;
  if (debug) console.debug('in updateMessageStatus params: ', msg);
  if (debug) console.debug('in updateMessageStatus params status: ', status);

  const messages = yield select(selectMessages);
  if (debug) console.debug('this is messages: ', messages);

  let sessionMessages = messages.find((m) => m.id === msg.session_id);

  if (debug) console.debug('this is sessionMessages BEFORE: ', sessionMessages);

  // if the message already exists, do not add it again
  const foundMessage = _.find(sessionMessages?.messages, { id: msg.id });
  if (foundMessage !== undefined) {
    if (debug) console.debug('this is foundMessage FOUND: ', foundMessage);
    // update the message
    // return;
    const updatedMessage = { ...foundMessage, msgStatus: status };

    // Create a copy of sessionMessages and update the message within it
    const updatedSessionMessages = {
      ...sessionMessages,
      messages: sessionMessages.messages.map(
        (message) => (message.id === foundMessage.id ? updatedMessage : message),
      ),
    };
    if (debug) console.debug('this is sessionMessages AFTER: ', updatedSessionMessages);

    // Update the sessionMessages reference to the updated version
    sessionMessages = updatedSessionMessages;
  }

  if (debug) console.debug('updateMessageStatus this is sessionMessages AFTER: ', sessionMessages);
  yield put(setMessages(sessionMessages));

  yield put(setLoading(false));
}
