/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const webphoneDeviceSlice = createSlice({
  name: 'webphoneDevice',
  initialState: {
    device: null,
    callFromDevice: null,
    uniqID: null,
  },
  reducers: {
    setWebphoneDevice: (state, action) => {
      state.device = action.payload;
    },
    setCallFromDevice: (state, action) => {
      state.callFromDevice = action.payload;
    },
    setUniqueId: (state, action) => {
      state.uniqID = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setWebphoneDevice,
  setUniqueId,

  setCallFromDevice,
} = webphoneDeviceSlice.actions;

// Selectors
export const selectWebphoneDevice = (state) => state.webphoneDevice.device;
export const selectCallFromDevice = (state) => state.webphoneDevice.callFromDevice;
export const selectUniqID = (state) => state.webphoneDevice.uniqID;

export default webphoneDeviceSlice.reducer;
