import {
  call, put,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading, setDayStats,
} from '../../state/agent/agentSlice';

export default function* fetchSumByDay() {
  const decodedToken = yield call(nsToken.getDecoded);

  const dayTable = [
    ['Day', 'Calls'],
  ];

  try {
    const res = yield call(nsApi.get, {
      object: 'cdr',
      action: 'read',
      option: 'summaryByDay',
      duration: '7 DAY',
      user: decodedToken.user,
      domain: decodedToken.domain,
    });

    for (let i = 7; i >= 0; i -= 1) {
      if (res[0] && moment(res[0].dayAndHour).format('ddd') === moment().subtract(i, 'days').format('ddd')) {
        dayTable.push([moment(res[0].dayAndHour).local().format('ddd'), Number(res[0].volume)]);
        res.shift();
      } else {
        dayTable.push([moment().subtract(i, 'days').format('ddd'), 0]);
      }
    }

    yield put(setDayStats(dayTable));
  } catch (e) {
    yield put(setLoading(false));
  }
}
