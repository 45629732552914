import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setUser } from '../../state/user/userSlice';
import bugsnagClient from '../../services/bugsnag/bugsnag';

export default function* updateUser({ payload: user }) {
  const decodedToken = yield call(nsToken.getDecoded);

  const params = {
    uid: decodedToken.sub,
    user: decodedToken.user,
    domain: decodedToken.domain,
    object: 'subscriber',
    action: 'update',
    format: 'json',
    current_password: user.pwd || '',
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    language: user.language,
    vmail_greeting: user.vmailGreeting,
  };

  if (params.email) params.email = _.chain(params.email).compact().join(';').value();

  try {
    yield put(setUser(user));
    yield call(nsApi.post, params);
  } catch (e) {
    bugsnagClient.notify(e);
  }
}
