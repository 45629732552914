/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userMediaSlice = createSlice({
  name: 'userMedia',
  initialState: {
    stream: false,
    streams: [],
    hidDevice: null,
    hidDevices: [],
    inputDevice: null,
    inputDevices: [],
    outputDevice: null,
    outputDeviceRinger: null,
    outputDevices: [],
    hasPermission: false,
    pwaPrompt: null,
  },
  reducers: {
    setStream: (state, action) => {
      state.hasStream = action.payload;
    },
    setStreams: (state, action) => {
      state.streams = action.payload;
    },
    setHidDevice: (state, action) => {
      state.hidDevice = action.payload;
    },
    setHidDevices: (state, action) => {
      state.hidDevices = action.payload;
    },
    setInputDevice: (state, action) => {
      state.inputDevice = action.payload;
    },
    setInputDevices: (state, action) => {
      state.inputDevices = action.payload;
    },
    setOutputDevice: (state, action) => {
      state.outputDevice = action.payload;
    },
    setOutputDeviceRinger: (state, action) => {
      state.outputDeviceRinger = action.payload;
    },
    setOutputDevices: (state, action) => {
      state.outputDevices = action.payload;
    },
    setHasPermission: (state, action) => {
      state.hasPermission = action.payload;
    },
    setPWAPrompt: (state, action) => {
      state.pwaPrompt = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setStream,
  setStreams,
  setHidDevice,
  setHidDevices,
  setInputDevice,
  setInputDevices,
  setOutputDevice,
  setOutputDeviceRinger,
  setOutputDevices,
  setHasPermission,
  setPWAPrompt,
} = userMediaSlice.actions;

// Selectors
export const selectStream = (state) => state.userMedia.stream;
export const selectStreams = (state) => state.userMedia.streams;
export const selectHidDevice = (state) => state.userMedia.hidDevice;
export const selectHidDevices = (state) => state.userMedia.hidDevices;
export const selectInputDevice = (state) => state.userMedia.inputDevice;
export const selectInputDevices = (state) => state.userMedia.inputDevices;
export const selectOutputDevice = (state) => state.userMedia.outputDevice;
export const selectOutputDeviceRinger = (state) => state.userMedia.outputDeviceRinger;
export const selectOutputDevices = (state) => state.userMedia.outputDevices;
export const selectHasPermission = (state) => state.userMedia.hasPermission;
export const selectPWAPrompt = (state) => state.userMedia.pwaPrompt;

export default userMediaSlice.reducer;
