import _ from 'lodash';
import { delay, put } from 'redux-saga/effects';
import { setIsAutodialling } from '../../../state/callSessions/callSessionsSlice';
import { setCallCardState } from '../../../state/cards/cardsSlice';
import { CARD_CALL_DIAL_PAD } from '../../../constants';
import { sendDtmfAction } from '../sendDtmf/action';

export default function* processDtmfString({ payload }) {
  const dtmfString = _.toString(payload.dtmfString);
  const { activeCall } = payload;

  yield put(setIsAutodialling(true));
  yield put(setCallCardState(CARD_CALL_DIAL_PAD));

  // dispatch each char waiting 2s for each comma and 0.4s for all others
  // eslint-disable-next-line no-restricted-syntax
  for (const char of dtmfString.split('')) {
    if (char === ',') {
      yield delay(2000);
    } else {
      yield put(sendDtmfAction({
        tone: char,
        activeCall,
      }));
      yield delay(400);
    }
  }
  yield put(setIsAutodialling(false));
}
