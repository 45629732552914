/* eslint-disable max-len */

import { put, select } from 'redux-saga/effects';
import i18n from 'i18next';

import { selectConfig } from '../../state/configs/configsSlice';
import { setDialog } from '../../state/dialog/dialogSlice';

export default function* checkEmergencyAddress(deviceInformation) {
  const showAddressBook = yield select((state) => selectConfig(state, 'PORTAL_SHOW_ADDRESS_BOOK'));
  if (
    deviceInformation // device exists
    && showAddressBook === 'yes' // has config
    && !localStorage.getItem(`doNotShowEmergencyAddressNotification_${deviceInformation.aor}`) // is not already shown
    && deviceInformation.is_endpoint_callid
  ) {
    if (
      deviceInformation.address_id != null
      && deviceInformation.address_id_source === undefined
      && deviceInformation.received_from.includes(deviceInformation.address_id)
    ) {
      // if address_id is set and not from any level (with correct IP) then address is already set
    } else {
      // if user was taking from a default, notify user of the source of the emergency address
      let notifyMessage = '';
      if (deviceInformation.address_id_source != null && deviceInformation.address_id !== 'add_address_id') {
        if (deviceInformation.address_id_source === 'domain_level') {
          notifyMessage = 'EMERGENCY_ADDRESS_FROM_DOMAIN_LEVEL';
        } else if (deviceInformation.address_id_source === 'site_level') {
          notifyMessage = 'EMERGENCY_ADDRESS_FROM_SITE_LEVEL';
        } else if (deviceInformation.address_id_source === 'user_level') {
          notifyMessage = 'EMERGENCY_ADDRESS_FROM_USER_LEVEL';
        }
      }
      // if address_id is not set at all, notify user that the device has no emergency address
      if (deviceInformation.address_id === null
        || deviceInformation.address_id === ''
        || deviceInformation.address_id === 'add_address_id'
      ) {
        notifyMessage = 'NO_EMERGENCY_ADDRESS_SET';
      }

      // if the pretty print string is set, ask the user if this is the current address
      let currentAddressStringQuestion = '';
      let currentAddressString = '';
      if (deviceInformation.address_id !== 'add_address_id'
        && deviceInformation.address_pretty_print != null
        && deviceInformation.address_pretty_print !== ''
      ) {
        currentAddressStringQuestion = 'IS_THIS_CURRENT_ADDRESS';
        currentAddressString = deviceInformation.address_pretty_print;
      }

      // if ip address on file and where logged in are different, then show message
      let checkIPAddress = '';
      if ((deviceInformation.address_ip === false
            || deviceInformation.address_ip === ''
            || !deviceInformation.received_from.includes(deviceInformation.address_ip))
          && deviceInformation.address_ip !== undefined
          && deviceInformation.address_id !== 'add_address_id') {
        checkIPAddress = 'EMERGENCY_ADDRESS_IP_SET_DIFFERENT';
      }
      yield put(setDialog({
        open: true,
        title: i18n.t('EMERGENCY_ADDRESS_CONFIGURATION'),
        text: `<p>${i18n.t(notifyMessage)}</p>
              <p>${i18n.t(currentAddressStringQuestion)} <span style="font-size: 18px;font-weight: 500;"> ${currentAddressString} </span></p>
              <p>${i18n.t(checkIPAddress)}</p>
              <p>${i18n.t('CHANGE_EMERGENCY_ADDRESS')}</p>`,
        confirmText: i18n.t('DO_NOT_SHOW_AGAIN'),
        cancelText: i18n.t('DISMISS'),
        onResult: (doNotShowAgain) => {
          if (doNotShowAgain) {
            localStorage.setItem(`doNotShowEmergencyAddressNotification_${deviceInformation.aor}`, true);
          }
        },
      }));
    }
  }
}
