// import moment from 'moment';
import {
  call,
  put,
  // select,
} from 'redux-saga/effects';
// import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading,
} from '../../../state/chat/chatSlice';
import fetchMessageSession from '../fetchMessageSession/fetchMessageSession';

export default function* changeConversationName({ payload }) {
  const debug = false;

  const { session, conversationName } = payload;

  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_name: conversationName,
    session_id: session.id,
    send_update_message: 'yes',
  };

  if (debug) {
    console.debug('this is changeConversationName to post: ', params);
  }

  const res = yield nsApi.post(params, params);

  if (debug) console.debug('this is changeConversationName res: ', res);

  // fetch the new updated message session after the update
  yield call(fetchMessageSession, { sessionId: session.id });

  yield put(setLoading(false));
  if (debug) console.debug('at the end of changeConversationName');
}
