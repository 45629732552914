import { call, put, select } from 'redux-saga/effects';
import {
  selectCallHistory, setCallHistory,
} from '../../../state/callHistory/callHistorySlice';
import matchContact from '../../../utils/matchContact';
import filterCallHistory from '../filterCallHistory/filterCallHistory';

export default function* callHistoryContactsLink() {
  const callHistoryList = yield select(selectCallHistory);

  const resultSet = callHistoryList.map((item) => {
    let contact = matchContact(item.name);
    if (!contact) {
      contact = matchContact(item.number);
    }
    if (contact) {
      return {
        ...item,
        contact,
      };
    }
    return {
      ...item,
      contact: null,
    };
  });

  yield put(setCallHistory(resultSet));
  yield call(filterCallHistory);
}
