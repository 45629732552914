import { call, put, select } from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectApiURL, selectHostname, setConfig } from '../../state/configs/configsSlice';

export default function* favicon() {
  const apiURL = yield select(selectApiURL);
  const server = yield select(selectHostname);
  let faviconURL = `${apiURL}?object=image&action=read&server=${server}&filename=favicon.gif`;

  const decodedToken = yield call(nsToken.getDecoded);

  if (decodedToken?.domain) {
    faviconURL += `&domain=${decodedToken.domain}`;
  }
  if (decodedToken?.territory) {
    faviconURL += `&territory=${decodedToken.territory}`;
  }

  yield put(setConfig({
    config_name: 'faviconURL',
    config_value: faviconURL,
  }));

  const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconURL;
  document.getElementsByTagName('head')[0].appendChild(link);
}
