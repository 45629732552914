/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const mfaSlice = createSlice({
  name: 'mfa',
  initialState: {
    mfa_type: '',
    mfa_vendor: '',
    mfa_username: '',
  },
  reducers: {
    setMFAType: (state, action) => {
      state.mfa_type = action.payload;
    },
    setMFAVendor: (state, action) => {
      state.mfa_vendor = action.payload;
    },
    setMFAUserName: (state, action) => {
      state.mfa_username = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  setMFAType,
  setMFAVendor,
  setMFAUserName,
} = mfaSlice.actions;

// Selectors
export const selectMFAType = (state) => state.mfa.mfa_type;
export const selectMFAVendor = (state) => state.mfa.mfa_vendor;
export const selectMFAUserName = (state) => state.mfa.mfa_username;

export default mfaSlice.reducer;
