import {
  call,
  put,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import {
  setGreetings,
  setLoading,
} from '../../state/greetings/greetingsSlice';

import bugsnagClient from '../../services/bugsnag/bugsnag';

export default function* fetchGreetings() {
  try {
    const decodedToken = yield call(nsToken.getDecoded);
    yield put(setLoading(true));

    const res = yield call(nsApi.get, {
      object: 'audio',
      action: 'read',
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
      intArray: 'true',
      type: 'greeting',
    });

    const greetings = [];
    for (let i = 0; i < res.length; i += 1) {
      res[i].remotepath = res[i].remotepath.replace(/&amp;/g, '&');
      res[i].remotepath = res[i].remotepath.replace(/%40/g, '@');
      greetings.push(res[i]);
    }
    yield put(setGreetings(greetings));
    yield put(setLoading(false));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setLoading(false));
  }
}
