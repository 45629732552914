import { put, select } from 'redux-saga/effects';
import { selectCallSessions, setCallSessions } from '../../../state/callSessions/callSessionsSlice';
import { selectDevicePostFix } from '../../../state/configs/configsSlice';
import matchContact from '../../../utils/matchContact';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';

export default function* pushConferenceList(session, data) {
  const sessions = yield select(selectCallSessions);
  const devicePostfix = yield select(selectDevicePostFix);

  const contact = matchContact(data.orig_from_user);

  if (data.orig_from_user === devicePostfix) return;
  if (contact) {
    if (!session.conferenceList.includes(contact.name)) session.conferenceList.push(contact.name);
  } else if (data.orig_from_user) {
    if (!session.conferenceList.includes(formatPhoneNumber(data.orig_from_user))) {
      session.conferenceList.push(formatPhoneNumber(data.orig_from_user));
    }
  }

  // eslint-disable-next-line no-param-reassign
  session.callerId = session.conferenceList.join(' + ');
  // eslint-disable-next-line no-param-reassign
  session.display = true;
  // eslint-disable-next-line no-param-reassign
  session.isConference = true; // this is for incoming calls that are moved to a conference

  yield put(setCallSessions([...sessions]));
}
