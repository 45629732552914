import {
  call, put, select,
} from 'redux-saga/effects';
import { selectContacts, setInCallSearchBy } from '../../../state/contacts/contactsSlice';
import processInCallResultSet from '../processInCallResultSet/processInCallResultSet';
import processInCallSearchResultSet from '../processInCallSearchResultSet/processInCallSearchResultSet';

export default function* inCallContactsSearchBy({ payload: searchBy }) {
  yield put(setInCallSearchBy(searchBy));

  const contacts = yield select(selectContacts);
  if (contacts) {
    yield call(processInCallResultSet);
    yield call(processInCallSearchResultSet);
  }
}
