import { put, select } from 'redux-saga/effects';
import {
  selectAppName,
  selectUserId,
} from '../../state/configs/configsSlice';
import {
  setCallWaitTone,
} from '../../state/ringtones/ringtonesSlice';

export default function* callWaiting() {
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  const callWaitSetting = localStorage.getItem(`${appName}-${userId}_callwaiting`) || 'enabled';

  yield put(setCallWaitTone(callWaitSetting));
}
