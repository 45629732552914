const queuedSort = (queued) => {
  const sorted = queued.sort((qA, qB) => {
    if (qA.prioritized === qB.prioritized) {
      const timeqA = new Date(`${qA.time_queued} UTC`).getTime();
      const timeqB = new Date(`${qB.time_queued} UTC`).getTime();
      return timeqA - timeqB;
    } if (qA.prioritized === 'yes') {
      return -1;
    } if (qA.prioritized === 'no') {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export default queuedSort;
