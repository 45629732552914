import { call } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

export default function* createAgentLog({ payload: mode }) {
  const decodedToken = yield call(nsToken.getDecoded);

  yield call(nsApi.post, {
    object: 'agentlog',
    action: 'create',
    user: decodedToken.user,
    domain: decodedToken.domain,
    mode,
    format: 'json',
  });
}
