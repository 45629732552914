import { put, select } from 'redux-saga/effects';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import {
  selectCards, selectExpandedId, selectPinnedCards, setCards, setExpandedId, setPinnedCards,
} from '../../../state/cards/cardsSlice';

export default function* removeCard({ payload: cardId }) {
  const pinnedCards = yield select(selectPinnedCards);
  const otherCards = yield select(selectCards);

  const pinnedOrder = [];
  for (let i = 0; i < pinnedCards.length; i += 1) {
    if (pinnedCards[i].id !== cardId) {
      pinnedOrder.push(pinnedCards[i]);
    }
  }

  const otherOrder = [];
  for (let i = 0; i < otherCards.length; i += 1) {
    if (otherCards[i].id !== cardId) {
      otherOrder.push(otherCards[i]);
    }
  }

  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify(pinnedOrder));
  localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(otherOrder));

  const expandedId = yield select(selectExpandedId);

  if (expandedId === cardId) {
    yield put(setExpandedId(null));
  }

  const allCards = [...pinnedOrder, ...otherOrder];
  if (allCards.length === 1 && allCards[0].type === 'user') {
    yield put(setExpandedId(allCards[0].id));
  }

  yield put(setPinnedCards(pinnedOrder));
  yield put(setCards(otherOrder));
}
