import { call, put, select } from 'redux-saga/effects';
import { selectCallStack, setCurrentState, setCallStack } from '../../../state/routeStack/routeStackSlice';
import { getNavigate } from '../../../services/router/router';

export default function* callStackBack() {
  const navigate = yield call(getNavigate);
  let stack = yield select(selectCallStack);
  stack = stack.slice(0, -1);
  const lastOnStack = stack[stack.length - 1];

  yield put(setCurrentState(lastOnStack));
  yield put(setCallStack(stack));
  navigate(lastOnStack.route, { state: lastOnStack.state });
}
