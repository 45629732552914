import {
  call, put,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading, setHourStats,
} from '../../state/agent/agentSlice';

export default function* fetchSumByHour() {
  const decodedToken = yield call(nsToken.getDecoded);

  const hourTable = [
    ['Hour', 'Calls'],
  ];

  try {
    const res = yield call(nsApi.get, {
      object: 'cdr',
      action: 'read',
      option: 'summaryByHour',
      duration: '12 HOUR',
      user: decodedToken.user,
      domain: decodedToken.domain,
    });

    while (res[0] && moment.utc(res[0].dayAndHour).local() < moment().subtract(13, 'hours')) { res.shift(); }

    for (let i = 12; i >= 0; i -= 1) {
      if (res[0] && moment.utc(res[0].dayAndHour).local().format('ha') === moment().subtract(i, 'hours').format('ha')) {
        hourTable.push([moment.utc(res[0].dayAndHour).local().format('ha'), Number(res[0].volume)]);
        res.shift();
      } else {
        hourTable.push([moment().subtract(i, 'hours').format('ha'), 0]);
      }
    }

    yield put(setHourStats(hourTable));
  } catch (e) {
    yield put(setLoading(false));
  }
}
