import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsMedia from '@netsapiens/netsapiens-js/dist/media';

export default function* initUserMedia() {
  try {
    const res = yield nsMedia.getUserMedia({
      audio: true,
      video: false,
    });

    localStorage.setItem('WPmicrophone', 'granted');
    return res;
  } catch (e) {
    // check if firefox
    if (nsBrowser.name === 'Firefox') {
      localStorage.setItem('WPmicrophone', 'denied');
      return false;
    }
    const permissions = yield navigator.permissions.query({ name: 'microphone' });

    localStorage.setItem('WPmicrophone', permissions.state);
    return false;
  }
}
