import {
  call, delay, put, select,
} from 'redux-saga/effects';
import { selectCallSessions, setCallEnded, setCallSessions } from '../../../state/callSessions/callSessionsSlice';
import updateActiveCount from '../updateActiveCount/updateActiveCount';
import updateIsConference from '../updateIsConference/updateIsConference';
import { stopCallWaiting, stopRingback, stopRinger } from '../../../utils/audio';
import setLastActive from '../setLastActive/setLastActive';

export default function* removeSession({ payload }) {
  const { sessionId } = payload;
  const existingSessions = yield select(selectCallSessions);

  let removedSession;
  const sessions = [];
  for (let i = 0; i < existingSessions.length; i += 1) {
    if (existingSessions[i].id !== sessionId) {
      sessions.push(existingSessions[i]);
    } else {
      removedSession = existingSessions[i];
    }
  }

  yield put(setCallSessions(sessions));
  yield call(updateActiveCount);
  yield call(updateIsConference);
  yield call(setLastActive);

  // Check if removedSession is set, some cases try to remove a session that no longer exists.
  // One case is when ignoring a call it is removed from sessions,
  // but the session still exists until the call is terminated.
  // When it is terminated it tries to delete itself from the list removedSession is not set.
  // todo move this comment to the top after hasSession function has been implemented
  if (removedSession) {
    // [WP-620, WP-1701] Ignoring or rejecting a call doesn't stop the ringtone
    if (['trying', 'inboundProgressing', 'outboundProgressing', 'ended'].includes(removedSession.status)) {
      if (removedSession.direction === 'inbound') {
        stopRinger();
        stopCallWaiting();
      } else {
        stopRingback();
      }
    }

    // signal to the ui only if the status is not trying or progressing
    if (!['trying', 'inboundProgressing', 'outboundProgressing'].includes(removedSession.status)) {
      yield put(setCallEnded(removedSession.name || removedSession.number));
      yield delay(3000);
      yield put(setCallEnded(null));
    }
  }
}
