import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardContent,
  Fade,
  Typography,
  Grid,
} from '@mui/material';
import {
  useSelector,
} from 'react-redux';
import { useEffect, useState } from 'react';
import sendBroadcast from '../../../utils/sendBroadcast';
import PageCenter from '../../templates/PageCenter/PageCenter';
import { LOGIN_CARD_STYLES } from '../../../constants';
import {
  // selectAppName,
  selectConfig,
} from '../../../state/configs/configsSlice';

function DuplicateInstance() {
  const debug = localStorage.getItem('localDebug') === 'true' || false;
  const { t } = useTranslation();

  // get whether user is on an active call or not
  const [activeCallRes, setActiveCallRes] = useState(null);
  useEffect(() => {
    const fetchActiveCallData = async () => {
      try {
        const decodedToken = nsToken.getDecoded();
        const response = await nsApi.get({
          object: 'call',
          action: 'read',
          domain: decodedToken.domain,
          user: decodedToken.user,
          format: 'json',
        });
        if (debug) console.debug('this is fetchActiveCallData response: ', response);
        setActiveCallRes(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchActiveCallData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Call the function to send the ping and handle the response
      sendBroadcast()
        .then(() => {
          // console.debug('Ping successful. Another instance is active.');
        })
        .catch(() => {
          // console.debug('Ping failed. No response from another instance.');
          window.location.reload();
        });
    }, 3000); // 3 seconds interval (3000 milliseconds)

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // build the notice string
  const wpName = useSelector((state) => selectConfig(state, 'PORTAL_WEBPHONE_NAME'))
    || 'SNAPmobile Web';

  // check if activeCallRes is empty array
  const isOpenedOnCall = (
    Array.isArray(activeCallRes) && activeCallRes.length > 0
  ) && (activeCallRes !== undefined && activeCallRes !== '' && activeCallRes !== null);

  const titleSentence = isOpenedOnCall ? t('OPENED_ON_CALL') : t('WAS_OPENED_ELSEWHERE');
  const actionSentence = isOpenedOnCall ? t('TO_START') : t('TO_CONTINUE');
  const body = `${actionSentence} ${wpName} 
    ${isOpenedOnCall ? t('CLOSE_EXISTING') : t('CLOSE_NEWLY')}`;

  return (
    <PageCenter>
      <Fade in>
        <Card sx={{ ...LOGIN_CARD_STYLES, pb: 0 }}>
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {wpName}
              {' '}
              {titleSentence}
            </Typography>
            <Grid container direction="row" sx={{ mt: 2 }}>
              <Typography>
                {body}
              </Typography>
            </Grid>
            <Button variant="contained" onClick={() => window.location.reload()} sx={{ mt: 2 }}>
              {t('REFRESH')}
            </Button>
          </CardContent>
        </Card>
      </Fade>
    </PageCenter>
  );
}

export default DuplicateInstance;
