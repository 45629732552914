import {
  call, delay, fork, put, select,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import setContactSorting from '../contacts/setContactSorting/setContactSorting';
import fetchUser from '../fetchUser/fetchUser';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import initUserMedia from '../initUserMedia/initUserMedia';
import fetchLogo from '../fetchLogo/fetchLogo';
import { setLogo } from '../../services/logo/logo';
import showAgentCenter from '../showAgentCenter/showAgentCenter';
import fetchAgentQueues from '../fetchAgentQueues/fetchAgentQueues';
import fetchSumByHour from '../fetchAgentCDR/fetchSumByHour';
import fetchSumByDay from '../fetchAgentCDR/fetchSumByDay';
import fetchSumByUser from '../fetchAgentCDR/fetchSumByUser';
import fetchAgentKPI from '../fetchAgentCDR/fetchAgentKPI';
import fetchCallParks from '../fetchCallParks/fetchCallParks';
import fetchContacts from '../contacts/fetchContacts/fetchContacts';
import fetchMessageSessions from '../messages/fetchMessageSessions/fetchMessageSessions';
import fetchCallHistory from '../callHistory/fetchCallHistory/fetchCallHistory';
import initUA from '../ua/initUA/initUA';
import { navStackPushAction } from '../routeStacks/navStackPush/action';
import {
  getDuplicateInstanceRoute,
  getAgentCenterRoute,
  getContactsRoute,
  getAccessDeniedRoute,
  getCallHistoryRoute,
  getVoicemailRoute,
  getChatListRoute,
  getCallParkListRoute,
  getAnsweringRulesListRoute,
  getGreetingsRoute,
  getSettingsRoute,
  getOnboardingRoute,
} from '../../routePaths';
import {
  selectAppName,
  selectConfig,
  selectDevicePostFix,
  selectUserId,
  selectWebphoneName,
  selectNavEnabledAnsweringRules,
  selectNavEnabledCallHistory,
  selectNavEnabledCallPark,
  selectNavEnabledCallCenter,
  selectChatAllowed,
  selectNavEnabledContacts,
  selectNavEnabledGreetings,
  selectNavEnabledSettings,
  selectNavEnabledVoicemail,
} from '../../state/configs/configsSlice';
import { setWebphoneDevice } from '../../state/webphoneDevice/webphoneDeviceSlice';
import fetchAudioDevice from '../fetchAudioDevice.js/fetchAudioDevice';
import ringtones from '../ringtones/ringtones';
import callWaiting from '../calllWaiting/callWaiting';
import fetchInDispositions from '../fetchInDispositions/fetchInDispositions';
import fetchOutDispositions from '../fetchOutDispositions/fetchOutDispositions';
import fetchEndpoints from '../devices/fetchEndpoints';
import fetchAnsweringRules from '../AnsweringRules/fetchAnsweringRules';
import setCallFrom from '../setCallFrom/setCallFrom';
import { selectPWAPrompt, setHasPermission, setStream } from '../../state/userMedia/userMediaSlice';
import { initDevices, stopStream } from '../../utils/devices';
import { changeAudioOutputVolume, changeNotificationsVolume } from '../../utils/audio';
import connectSocket from '../socket/connectSocket/connectSocket';
import fetchVoicemail from '../voicemail/fetchVoicemail/fetchVoicemail';
import fetchCards from '../cardManagment/fetchCards/fetchCards';
import fetchGreetings from '../fetchGreetings/fetchGreetings';
import { getNavigate } from '../../services/router/router';
import initPush, { unsubPush } from '../../utils/push';
import checkEmergencyAddress from '../checkEmergencyAddress/checkEmergencyAddress';
import broadcastChannel from '../broadcastChannel/broadcastChannel';
import { ONBOARD_COMPLETE, ONBOARD_SETUP, REPEAT_INSTANCE } from '../../constants';
import normalizeYes from '../../utils/normalizeYes';

export default function* postLogin() {
  try {
    const navigate = yield call(getNavigate);
    const decodedToken = yield call(nsToken.getDecoded);
    const appName = yield select(selectAppName);

    const allowWebPhone = yield select((state) => selectConfig(state, 'PORTAL_ALLOW_WEB_PHONE'));
    if (normalizeYes(allowWebPhone) !== 'yes') {
      navigate(getAccessDeniedRoute());
      console.error('User does not have webphone access.');
    } else {
      yield fork(setContactSorting);

      const user = yield call(fetchUser);

      if (user.onboarding !== ONBOARD_COMPLETE) {
        yield initDevices(appName, decodedToken.user);
        localStorage.setItem(`${appName}-${decodedToken.user}_onboarding`, ONBOARD_SETUP);
        navigate(getOnboardingRoute());
        return;
      }

      yield call(broadcastChannel);
      // eslint-disable-next-line no-unused-vars
      const mediaTask = yield fork(initUserMedia);

      const logo = yield call(fetchLogo);
      setLogo(logo);

      const navEnabledContacts = yield select(selectNavEnabledContacts);
      const navEnabledCallHistory = yield select(selectNavEnabledCallHistory);
      const navEnabledVoicemail = yield select(selectNavEnabledVoicemail);
      const navEnabledChatSMS = yield select(selectChatAllowed);
      const navEnabledCallPark = yield select(selectNavEnabledCallPark);
      const navEnabledCallCenter = yield select(selectNavEnabledCallCenter);
      const navEnabledAnsweringRules = yield select(selectNavEnabledAnsweringRules);
      const navEnabledGreetings = yield select(selectNavEnabledGreetings);
      const navEnabledSettings = yield select(selectNavEnabledSettings);

      let route = navEnabledContacts ? getContactsRoute() : null;
      if (!route && navEnabledCallHistory) route = getCallHistoryRoute();
      if (!route && navEnabledVoicemail) route = getVoicemailRoute();
      if (!route && navEnabledChatSMS) route = getChatListRoute();
      if (!route && navEnabledCallPark) route = getCallParkListRoute();
      if (!route && navEnabledAnsweringRules) route = getAnsweringRulesListRoute();
      if (!route && navEnabledGreetings) route = getGreetingsRoute();
      if (!route && navEnabledSettings) route = getSettingsRoute();

      const initNav = {
        route: route || '/',
        state: null,
        fromName: null,
        fromState: null,
      };

      const showAc = yield call(showAgentCenter);
      if (showAc && navEnabledCallCenter) {
        initNav.route = getAgentCenterRoute();
        yield fork(fetchAgentQueues);
        yield fork(fetchSumByDay);
        yield fork(fetchSumByHour);
        yield fork(fetchSumByUser);
        yield fork(fetchAgentKPI);
      }

      yield fork(fetchCards);
      yield fork(fetchContacts); // [WP-1540] first contacts fetch
      yield fork(fetchCallHistory);
      yield fork(fetchVoicemail);
      yield fork(fetchGreetings);
      yield fork(fetchMessageSessions);
      yield fork(fetchAnsweringRules);

      // push the current state to the nav stack
      yield put(navStackPushAction(initNav));

      // todo start tracking current state
      // todo fetch audio device
      const devicePostFix = yield select(selectDevicePostFix);
      const fetchAudioTask = yield fork(fetchAudioDevice, devicePostFix);

      // setup ringtones
      yield fork(ringtones);

      // setup call waiting
      yield fork(callWaiting);

      // fetch wrap up
      yield fork(fetchInDispositions);
      yield fork(fetchOutDispositions);

      // pause until the user device task is done
      const fetchAudioTaskRes = yield fetchAudioTask.toPromise();
      yield fork(setCallFrom, fetchAudioTaskRes);

      // test if the user media stream request is still running
      if (mediaTask.isRunning()) {
        // todo pop up dialog
      }

      yield fork(checkEmergencyAddress, fetchAudioTaskRes);

      // pause until the user media task is done
      const mediaTaskRes = yield mediaTask.toPromise();

      if (fetchAudioTaskRes && mediaTaskRes) {
        yield put(setHasPermission(true));

        // stop stream
        stopStream(mediaTaskRes);

        // set the stream with no tracks
        yield put(setStream(mediaTaskRes));

        // get default/selected media devices
        yield call(initDevices, appName, decodedToken.user);

        // will be used for offline tab header
        const wpNameConfig = yield select(selectWebphoneName);
        localStorage.setItem('wpNameConfig', wpNameConfig);

        // set the user's audio device
        yield put(setWebphoneDevice(fetchAudioTaskRes));

        // put toggle on init push
        // only initialize push if: pwa is enabled, pwa is installed,
        // and webphone is opened as standalone
        const pwaEnabled = yield select((state) => selectConfig(state, 'PORTAL_WEBPHONE_ENABLE_PWA'));
        const pwaInstalled = yield select(selectPWAPrompt);
        const standalone = window.matchMedia('(display-mode: standalone)').matches;

        if (pwaEnabled === 'yes' && fetchAudioTaskRes !== null && pwaInstalled === null && standalone) {
          // set up push notifications
          yield call(initPush, fetchAudioTaskRes);
        } else {
          yield call(unsubPush, fetchAudioTaskRes);
        }
        // delete push
        // prepare sip.js user agent for in/outbound calls
        yield call(initUA);

        // init tab-comms for launching calls from the portal
        // yield call(tabCommInit, $state); todo

        // init the audio volume setting
        const userId = yield select(selectUserId);
        const initialVolume = parseInt(localStorage.getItem(`${appName}-${userId}_outputVolume`), 10) || 75;
        changeAudioOutputVolume(initialVolume);

        // init ringer volume
        const initialNotificationVolume = parseInt(localStorage.getItem(`${appName}-${userId}_notificationsVolume`), 10) || 75;
        changeNotificationsVolume(initialNotificationVolume);
      } else {
        yield put(setHasPermission(false));
        // todo pop up dialog about not being able to make calls
      }

      yield fork(connectSocket);

      yield fork(fetchEndpoints);

      yield delay(1000); // [WP-1540] perform fetch again to make sure logged-in user presence is correct
      yield fork(fetchContacts);
      if (showAc && navEnabledCallCenter) {
        yield fork(fetchAgentQueues, false);
      }
      yield fork(fetchCallParks);
    }
    // todo init tab-coms for launching calls from the portal
  } catch (e) {
    if (e === REPEAT_INSTANCE) {
      const navigate = yield call(getNavigate);
      console.debug('postLogin e: ', e);
      navigate(getDuplicateInstanceRoute());
      return;
    }

    // eslint-disable-next-line no-console
    console.error(e);
    bugsnagClient.notify(e);
  }
}
