import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import bugsnagClient from '../../../services/bugsnag/bugsnag';

import mapDevicesToRules from './mapDevicesToRules';
import mapUserToRules from './mapUserToRules';
import decodeNewTimeframe from './decodeNewTimeframe';
import { selectUser } from '../../../state/user/userSlice';

export default function* prepList(answeringRules, endpoints) {
  try {
    answeringRules = _.compact(answeringRules); // eslint-disable-line no-param-reassign
    endpoints = _.compact(endpoints); // eslint-disable-line no-param-reassign

    answeringRules = decodeNewTimeframe(answeringRules); // eslint-disable-line no-param-reassign

    if (!_.size(answeringRules)) {
      return [];
    }

    const user = yield select(selectUser);

    answeringRules = yield call(mapDevicesToRules, answeringRules, endpoints); // eslint-disable-line no-param-reassign
    answeringRules = yield call(mapUserToRules, answeringRules, user); // eslint-disable-line no-param-reassign

    return answeringRules;
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    return [];
  }
}
