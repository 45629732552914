export const getAgentCenterRoute = () => '/agent-center';
export const getAnsweringRulesListRoute = () => '/answering-rule-list';
export const getAnswerRuleDetailsRoute = (id) => `/answer-rule-details/${id}`;
export const getBrowserSupportRoute = () => '/browser-support-msg';
export const getDuplicateInstanceRoute = () => '/duplicate-instance';
export const getMFARoute = () => '/mfa-form';
export const getCallHistoryRoute = () => '/call-history';
export const getCallDetailsRoute = (id) => `/call-details/${id}`;
export const getCallParkListRoute = () => '/call-park-list';
export const getChatListRoute = () => '/chat-list';
export const getContactsRoute = () => '/contacts';
export const getContactFormRoute = () => '/contact-add';
export const getContactDetailsRoute = (id) => `/contact-details/${id}`;
export const getDashboardRoute = () => '/';
export const getForgotPasswordRoute = () => '/forgot-password';
export const getForgotUsernameRoute = () => '/forgot-username';
export const getGenericErrorRoute = () => '/generic-error';
export const getGreetingsRoute = () => '/greetings';
export const getGreetingsNewRoute = () => '/greetings-new';
export const getIFrameRoute = () => '/iframe';
export const getInitRoute = () => '/loading';
export const getLoginRoute = () => '/login';
export const getOnboardingRoute = () => '/onboarding';
export const getPasswordResetRoute = () => '/password-reset';
export const getSSOEnrollRoute = () => '/sso-enroll';
export const getSelectSubscriberRoute = () => '/select-subscriber';
export const getSettingsRoute = () => '/settings';
export const getSettingsCallFromRoute = () => '/settings-callfrom';
export const getVoicemailRoute = () => '/voicemail';
export const getProfileRoute = () => '/profile';
export const getAccessDeniedRoute = () => '/access-denied';
export const getForgotPasswordExpiredRoute = () => '/forgot-password-expired';
