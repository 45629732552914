import _ from 'lodash';

/**
 *
 * @param {[]} contactsArr
 * @private
 */
export default function getContactListDepartments(contactsArr) {
  return _.chain(contactsArr)
    .reject((contact) => !contact.group)
    .map('group')
    .sortBy((dept) => dept.toLowerCase())
    .uniq(true, (dept) => dept)
    .value();
}
