import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import matchContact from '../../../utils/matchContact';
import {
  setCards, setExpandedId, setLoading, setPinnedCards,
} from '../../../state/cards/cardsSlice';

export default function* fetchCards() {
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  const storagePinnedCards = JSON.parse(
    localStorage.getItem(`${appName}-${userId}_pinned_cards`) || '[]',
  );

  const storageCards = JSON.parse(
    localStorage.getItem(`${appName}-${userId}_cards`) || '[]',
  );

  const cards = [];
  const chatSessionRequests = [];
  const pinnedCards = [];
  const pinnedChatSessionRequests = [];
  const decodedToken = yield call(nsToken.getDecoded);

  if (storagePinnedCards.length) {
    // convert json to Card classes
    for (let i = 0; i < storagePinnedCards.length; i += 1) {
      // if the browser was refreshed or closed omit call and new-chat cards
      if (storagePinnedCards[i].type !== 'call' && storagePinnedCards[i].type !== 'new-chat') {
        const card = new nsModels.card.Card(storagePinnedCards[i]);

        // update contact for chat cards
        if (card.type === 'chat') {
          const contact = matchContact(card.meta.sendMessageParams.destination);
          if (contact) {
            card.meta.contactId = contact.id;
          } else {
            card.meta.contactId = null;
          }

          pinnedChatSessionRequests.push(Promise.all([
            Promise.resolve(card),
            nsApi.get({
              object: 'messagesession',
              action: 'read',
              user: decodedToken.user,
              domain: decodedToken.domain,
              session_id: card.meta.sessionId,
            }).then((res) => {
              if (!res[0]) {
                return null;
              }
              return res[0];
            }),
          ]));
        } else pinnedCards.push(card);
      }
    }

    const res = yield Promise.all(pinnedChatSessionRequests);

    res.forEach((item) => {
      if (item[1]) {
        const startTs = (new Date(item[1].start_timestamp)).getTime();
        const lastTs = (new Date(item[1].last_timestamp)).getTime();
        if (startTs < lastTs) {
          pinnedCards.unshift(item[0]);
        } // else do nothing, invalid session
      }
    });

    // update local storage after possible removal of call and new-chat cards
    localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify(pinnedCards));

    yield put(setPinnedCards(pinnedCards));
  }

  if (storageCards.length) {
    // convert json to Card classes
    for (let i = 0; i < storageCards.length; i += 1) {
      // if the browser was refreshed or closed omit call and new-chat cards
      if (storageCards[i].type !== 'call' && storageCards[i].type !== 'new-chat') {
        // do not have video parameters from the storage cards
        if (storageCards[i].type === 'conversation' && storageCards[i].meta.videoInvite) {
          storageCards[i].meta.videoInvite = false;
          storageCards[i].meta.videoId = null;
          storageCards[i].meta.videoFrom = null;
        }

        const card = new nsModels.card.Card(storageCards[i]);

        // update contact for chat cards
        if (card.type === 'chat') {
          const contact = matchContact(card.meta.sendMessageParams.destination);
          if (contact) {
            card.meta.contactId = contact.id;
          } else {
            card.meta.contactId = null;
          }

          chatSessionRequests.push(Promise.all([
            Promise.resolve(card),
            nsApi.get({
              object: 'messagesession',
              action: 'read',
              user: decodedToken.user,
              domain: decodedToken.domain,
              session_id: card.meta.sessionId,
            }).then((res) => {
              if (!res[0]) {
                return null;
              }
              return res[0];
            }),
          ]));
        } else cards.push(card);
      }
    }

    const res = yield Promise.all(chatSessionRequests);

    res.forEach((item) => {
      if (item[1]) {
        const startTs = (new Date(item[1].start_timestamp)).getTime();
        const lastTs = (new Date(item[1].last_timestamp)).getTime();
        if (startTs < lastTs) {
          cards.unshift(item[0]);
        } // else do nothing, invalid session
      }
    });

    // update local storage after possible removal of call and new-chat cards
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(cards));

    yield put(setCards(cards));
  }

  if (!storagePinnedCards.length && !storageCards.length) {
    // create user card
    const userCard = {
      id: nsUtils.randomId(),
      type: 'user',
    };

    const card = new nsModels.card.Card(userCard);
    cards.push(card);

    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(cards));

    yield put(setCards(cards));
  }

  if (!_.size(pinnedCards) && _.size(cards) === 1) {
    yield put(setExpandedId(cards[0].id));
  }

  yield put(setLoading(false));
}
