import store from '../../state/store';
import { selectCards, selectPinnedCards } from '../../state/cards/cardsSlice';

export default ({
  contactId, sessionId, queueId, type = null,
}) => {
  const pinnedCards = selectPinnedCards(store.getState());
  const cards = selectCards(store.getState());
  const allCards = [...pinnedCards, ...cards];

  let paramCnt = 0;
  if (contactId) { paramCnt += 1; }
  if (sessionId) { paramCnt += 1; }
  if (queueId) { paramCnt += 1; }
  if (type) { paramCnt += 1; }

  let matchCnt;
  for (let i = 0; i < allCards.length; i += 1) {
    matchCnt = 0;
    if (contactId
      && allCards[i].meta
      && allCards[i].meta.contactId === contactId
    ) {
      matchCnt += 1;
    }
    if (sessionId
      && allCards[i].meta
      && allCards[i].meta.sessionId === sessionId
    ) {
      matchCnt += 1;
    }
    if (queueId
      && allCards[i].meta
      && allCards[i].meta.queueId === queueId
    ) {
      matchCnt += 1;
    }
    if (type && allCards[i].type === type) {
      matchCnt += 1;
    }
    if (paramCnt === matchCnt) {
      return allCards[i].id;
    }
  }

  return null;
};
