import { put, select } from 'redux-saga/effects';
import {
  setAgentStatus,
  setPortalStatus,
} from '../../state/agent/agentSlice';
import {
  selectCallCustomStatus1,
  selectCallCustomStatus2,
  selectCallCustomStatus3,
  selectCallCustomStatus4,
  selectCallCustomStatus5,
  selectCallCustomStatus6,
  selectCallCustomStatus7,
  selectCallCustomStatus8,
  selectCallCustomStatus9,
  selectCallCustomStatus10,
} from '../../state/configs/configsSlice';

export default function* updateAgentPortalStatus({ payload }) {
  const allowed = [' ', '', 'Lunch', 'Break', 'Meeting', 'Other', 'Web'];
  allowed.push(yield select(selectCallCustomStatus1));
  allowed.push(yield select(selectCallCustomStatus2));
  allowed.push(yield select(selectCallCustomStatus3));
  allowed.push(yield select(selectCallCustomStatus4));
  allowed.push(yield select(selectCallCustomStatus5));
  allowed.push(yield select(selectCallCustomStatus6));
  allowed.push(yield select(selectCallCustomStatus7));
  allowed.push(yield select(selectCallCustomStatus8));
  allowed.push(yield select(selectCallCustomStatus9));
  allowed.push(yield select(selectCallCustomStatus10));

  if (payload.portal_status && allowed.includes(payload.portal_status)) {
    yield put(setPortalStatus(payload.portal_status));
    yield put(setAgentStatus(payload.portal_status));
  }
}
