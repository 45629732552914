import { call, delay, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import i18next from 'i18next';
import moment from 'moment-timezone';

import bugsnagClient from '../../services/bugsnag/bugsnag';
import { setSnackbar } from '../../state/snackbar/snackbarSlice';
import { SUCCESS } from '../../constants';

export default function* postWrapup({ payload }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const details = { ...payload };
  try {
    if (!details.cdrId) {
      yield delay(2000);
      const cdr = yield nsApi.get({
        object: 'cdr2',
        action: 'read',
        orig_callid: payload.orig_callid,
        uid: decodedToken.sub,
        start_date: moment().utcOffset(0).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'),
      });
      details.cdrId = cdr[0].cdr_id;
    }

    const params = {
      object: 'cdr2',
      action: 'update',
      uid: decodedToken.sub,
      cdr_id: details.cdrId,
      disposition: details.disposition,
      reason: details.reason,
    };
    if (details.notes) {
      params.notes = details.notes;
    }
    yield nsApi.post(params);

    yield put(setSnackbar({
      type: SUCCESS,
      open: true,
      message: `${i18next.t('WRAP_UP')} ${i18next.t('COMPLETE')}`,
      duration: 2000,
    }));
  } catch (e) {
    console.error('Post Wrapup Disposition failed:', e);
    bugsnagClient.notify(e);
  }
}
