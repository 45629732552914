import crypto from 'crypto-browserify';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

export default function formatContact(contact) {
  const newContact = contact;
  newContact.name = `${contact.first_name} ${contact.last_name}`;

  newContact.initials = '';
  if (contact.first_name) {
    newContact.initials += contact.first_name[0].toUpperCase();
  }
  if (contact.last_name) {
    newContact.initials += contact.last_name[0].toUpperCase();
  }

  // create a unique id
  newContact.id = contact.contact_id || nsUtils.generateContactId({
    firstName: contact.first_name,
    lastName: contact.last_name,
    user: contact.user,
  });

  // phone numbers
  if (contact.cell_phone) {
    newContact.cell_phone = contact.cell_phone.replace(/[^0-9+*]/g, '');
  }
  if (contact.home_phone) {
    newContact.home_phone = contact.home_phone.replace(/[^0-9+*]/g, '');
  }
  if (contact.work_phone) {
    newContact.work_phone = contact.work_phone.replace(/[^0-9+*]/g, '');
  }

  if (contact.user) {
    newContact.extension = contact.user;
    newContact.extension_name = contact.name;
  }

  if (contact.email && contact.email.trim()) {
    newContact.email = contact.email.trim();
    newContact.email = contact.email.toLowerCase();
    newContact.email = contact.email.split(';');

    newContact.gravatar = crypto.createHash('md5').update(contact.email[0]).digest('hex');
  }

  return newContact;
}
