import _ from 'lodash';

/**
 * @param {[]} contactList
 * @param {string} sortBy 'first_name', 'last_name', 'online', 'extension'
 * @returns {object|Array}
 *
 * Return format for 'first_name', 'last_name'
 *      {A: [{contact}, {contact}], B: [{contact}], etc. }
 *
 * Return format for 'online'
 *      {online: [{contact}, {contact}], offline: [{contact}, {contact}]}
 *
 * Return format for 'extension'
 *      [{contact}, {contact}, etc.]
 */
export default function searchContacts(str, contactsResultSet) {
  const searchStr = str.toLowerCase();
  return _.chain(contactsResultSet)
    .filter((contact) => {
      if (contact.index !== undefined) {
        return false;
      }
      if (contact.name && contact.name.toLowerCase().indexOf(searchStr) !== -1) {
        return true;
      }
      if (contact.user && contact.user.toLowerCase().indexOf(searchStr) !== -1) {
        return true;
      }
      if (contact.work_phone && contact.work_phone.indexOf(searchStr) !== -1) {
        return true;
      }
      if (contact.cell_phone && contact.cell_phone.indexOf(searchStr) !== -1) {
        return true;
      }
      return contact.home_phone && contact.home_phone.indexOf(searchStr) !== -1;
    })
    .value();
}
