import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
import { selectConfig } from '../state/configs/configsSlice';
import store from '../state/store';

momentDurationFormatSetup(moment);

export default function formatPhoneNumber(phoneNumber) {
  const countryCode = selectConfig(store.getState(), 'PORTAL_LOCALIZATION_NUMBER_FORMAT');
  return nsUtils.formatPhoneNumber(phoneNumber, countryCode);
}
