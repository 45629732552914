import _ from 'lodash';

import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import i18n from 'i18next';

import {
  selectContacts,
  setContacts,
} from '../../../state/contacts/contactsSlice';
import processResultSet from '../processResultSet/processResultSet';
import processSearchResultSet from '../processSearchResultSet/processSearchResultSet';

import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';

export default function* deleteContact({ payload: toDelete }) {
  const contact = { ...toDelete };

  // prep post params
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'contact',
    action: 'delete',
    user: decodedToken.user,
    domain: decodedToken.domain,
    contact_id: contact.contact_id ? contact.contact_id : contact.id,
    first_name: contact.first_name,
    last_name: contact.last_name,
  };

  // post to api
  try {
    nsApi.post(params);

    const contacts = yield select(selectContacts);
    const newContacts = [...contacts];

    _.remove(newContacts, {
      id: contact.id,
    });

    yield put(setSnackbar({
      open: true,
      message: i18n.t('CONTACT_DELETED'),
    }));

    yield put(setContacts(newContacts));

    yield call(processResultSet);
    yield call(processSearchResultSet);
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setSnackbar({
      open: true,
      message: i18n.t('CONTACT_DELETE_FAILED'),
    }));
  }
}
