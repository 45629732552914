import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  selectSessions,
  setLoading,
} from '../../../state/chat/chatSlice';
import fetchMessageSession from '../fetchMessageSession/fetchMessageSession';
import fetchMessages from '../fetchMessages/fetchMessages';

export default function* changeParticipants({ payload }) {
  const debug = false;

  if (debug) console.debug('this is changeParticipants payload: ', payload);
  const { sessionId, participantStr } = payload;

  const decodedToken = nsToken.getDecoded();

  const participants = `${participantStr}${decodedToken.user}@${decodedToken.domain}`;

  const res = yield nsApi.post({
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
    participants,
    send_update_message: 'yes',
  }, {
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
    participants,
    send_update_message: 'yes',
  });

  if (debug) console.debug('this is res: ', res);

  // fetch the new updated message session after the update
  yield call(fetchMessageSession, { sessionId });

  yield call(fetchMessages, { payload: sessionId, override: true });

  const sessionsAfter = yield select(selectSessions);
  if (debug) console.debug('this is sessionsAfter: ', sessionsAfter);

  yield put(setLoading(false));
  if (debug) console.debug('at the end of send message');
}
