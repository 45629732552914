import { call, delay, select } from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectIsConnected, selectSocket } from '../../../state/socket/socketSlice';
import { selectUser } from '../../../state/user/userSlice';

export default function* socketSubscribe({ payload }) {
  let isConnected = yield select(selectIsConnected);

  while (!isConnected) {
    yield delay(1000);
    isConnected = yield select(selectIsConnected);
  }

  const user = yield select(selectUser);
  const socket = yield select(selectSocket);
  const token = yield call(nsToken.get);
  const decodedToken = yield call(nsToken.getDecoded);

  socket.emit('subscribe', {
    bearer: token,
    domain: decodedToken.domain,
    user: user.id,
    ...payload,
  });
}
