import {
  call, put, select,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setFilteredBy, setSortedBy, setSortedDirection } from '../../../state/contacts/contactsSlice';
import { selectConfig } from '../../../state/configs/configsSlice';

export default function* setContactSorting() {
  const appName = yield select((state) => selectConfig(state, 'appName'));

  const decodedToken = yield call(nsToken.getDecoded);
  const fb = localStorage.getItem(`${appName}-${decodedToken.user}_contacts-filtered-by`) || 'all';
  const sd = localStorage.getItem(`${appName}-${decodedToken.user}_contacts-sort-direction`) || 'asc';
  const sb = localStorage.getItem(`${appName}-${decodedToken.user}_contacts-sorted-by`) || 'last_name';

  yield put(setFilteredBy(fb));
  yield put(setSortedBy(sb));
  yield put(setSortedDirection(sd));
}
