import socketIO from 'socket.io-client/dist/socket.io.min';
import { eventChannel } from 'redux-saga';
import {
  all, call, put, delay, select, take, fork,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import i18n from 'i18next';
import { selectChatAllowed, selectNsSocketURLs, selectUserId } from '../../../state/configs/configsSlice';
import {
  SOCKET_CONNECT,
  SOCKET_CONNECTION_ERROR,
  SOCKET_DISCONNECT,
  SOCKET_RECONNECT_ERROR,
} from '../../../constants';
import { setIsConnected, setSocket } from '../../../state/socket/socketSlice';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import socketSubscribe from '../socketSubscribe/socketSubscribe';
import socketListeners from '../socketListeners/socketListeners';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';

export default function* connectSocket() {
  const nsSocketURLs = yield select(selectNsSocketURLs);
  const chatAllowed = yield select(selectChatAllowed);

  try {
    const socket = socketIO.connect(
      nsSocketURLs[0],
      {
        secure: true,
        transports: ['websocket'],
        perMessageDeflate: false,
      },
    );

    yield fork(socketListeners);

    // create channel for callbacks
    const channel = eventChannel((emitter) => {
      socket.on('connect', () => {
        console.debug('connect');
        console.debug('subscriptions', socket.subscriptions);
        emitter(SOCKET_CONNECT);
        socket.subscriptions?.forEach((sub) => socket.emit('subscribe', sub));
      });

      socket.on('disconnect', () => {
        console.debug('disconnect');
        emitter(SOCKET_DISCONNECT);
      });

      socket.on('reconnect_attempt', () => {
        console.debug('reconnect_attempt');
        emitter(SOCKET_RECONNECT_ERROR);
      });

      socket.on('error', () => {
        console.debug('error');
        emitter(SOCKET_CONNECTION_ERROR);
      });

      // unsubscribe method
      return () => {
        socket.disconnect();
      };
    });

    while (true) {
      const res = yield take(channel);

      if (res === SOCKET_DISCONNECT) {
        console.error('Socket disconnect');
        yield put(setIsConnected(false));

        if (navigator.onLine) {
          yield put(setSnackbar({
            open: true,
            message: i18n.t('NETWORK_LOST_CONNECTION_TOAST'),
          }));
        } else {
          yield put(setSnackbar({
            open: true,
            message: i18n.t('NETWORK_NO_CONNECTION_TOAST'),
          }));
        }
      } else if (res === SOCKET_CONNECTION_ERROR) {
        console.error('Socket connection');
        yield put(setIsConnected(false));

        if (navigator.onLine) {
          yield put(setSnackbar({
            open: true,
            message: i18n.t('NETWORK_LOST_CONNECTION_TOAST'),
          }));
        } else {
          yield put(setSnackbar({
            open: true,
            message: i18n.t('NETWORK_NO_CONNECTION_TOAST'),
          }));
        }
      } else if (res === SOCKET_RECONNECT_ERROR) {
        console.error('Initial socket connection');
        yield put(setIsConnected(false));
      } else {
        yield put(setSocket(socket));
        yield put(setIsConnected(true));

        yield delay(1000);

        const decodedToken = yield call(nsToken.getDecoded);
        const userId = yield select(selectUserId);

        yield all([
          call(socketSubscribe, {
            payload: { type: 'call', filter: userId },
          }),
          // do not subscribe to chat if user has chat disabled
          chatAllowed ? call(socketSubscribe, {
            payload: { type: 'ucchat', filter: userId },
          }) : null,
          call(socketSubscribe, {
            payload: { type: 'voicemail', filter: userId },
          }),
          call(socketSubscribe, {
            payload: { type: 'contacts' },
          }),
          call(socketSubscribe, {
            payload: { type: 'agent', user: userId, unit: 'seconds' },
          }),
          call(socketSubscribe, {
            payload: { type: 'queue', domain: decodedToken.domain },
          }),
          call(socketSubscribe, {
            payload: { type: 'chat', user: userId },
          }),
        ]);
      }
    }
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: nsSocketConnect';
    });
    yield put(setIsConnected(false));
  }
}
