import _ from 'lodash';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
// import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading,
  selectMessages,
  selectSessions,
  setSessionsList,
  setMessagesList,
} from '../../../state/chat/chatSlice';
import { selectCards, selectPinnedCards } from '../../../state/cards/cardsSlice';

import removeCard from '../../cardManagment/removeCard/removeCard';

export default function* deleteConversation({ payload }) {
  const debug = false;

  const { session } = payload;
  if (debug) console.debug('this is session to delete: ', session);

  const decodedToken = nsToken.getDecoded();

  const res = yield nsApi.post({
    object: 'messagesession',
    action: 'delete',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: `${session.id}`,
  }, {
    object: 'messagesession',
    action: 'delete',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: `${session.id}`,
  });
  if (debug) console.debug('this is res: ', res);

  //
  // remove the session from sessions array.
  //
  const sessionsCurr = yield select(selectSessions);
  const updatedSessions = _.filter(sessionsCurr, (obj) => obj.id !== session.id);

  yield put(setSessionsList(updatedSessions));

  //
  // remove any messages that are from the messages array.
  //
  const messages = yield select(selectMessages);
  const updatedMessages = _.filter(messages, (obj) => obj.id !== session.id);

  yield put(setMessagesList(updatedMessages));

  //
  // remove the card from card list
  //
  const pinnedCards = yield select(selectPinnedCards);
  const otherCards = yield select(selectCards);

  const allCards = [...pinnedCards, ...otherCards];

  const cardToDelete = allCards.filter((obj) => (obj?.meta?.sessionId === session.id));
  yield call(removeCard, { payload: cardToDelete[0].id });

  yield put(setLoading(false));
  if (debug) console.debug('remove session');
}
