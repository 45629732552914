import { all, call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import { setAnsweringRules, setLoading } from '../../state/answeringRules/answeringRulesSlice';
import prepList from './utils/prepList';

export default function* fetchAnsweringRules() {
  yield put(setLoading(true));

  const decodedToken = yield call(nsToken.getDecoded);

  try {
    const [rules, endpoints] = yield all([
      call(nsApi.get, {
        object: 'answerrule',
        action: 'read',
        uid: decodedToken.sub,
        user: decodedToken.user,
        domain: decodedToken.domain,
      }),
      call(nsApi.get, {
        object: 'device',
        action: 'read',
        user: decodedToken.user,
        domain: decodedToken.domain,
        noNDP: 'true',
        // mode: 'registered_endpoint', [WP-1614] show all devices for answering rules, even if unregistered
        format: 'json',
      }),
    ]);

    const answeringRules = yield call(prepList, rules, endpoints);
    yield put(setAnsweringRules(answeringRules));
    yield put(setLoading(false));
  } catch (e) {
    console.error('Fetching Summary by User failed:', e);
    bugsnagClient.notify(e);
    yield put(setLoading(false));
  }
}
