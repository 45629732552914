import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { selectApiHash } from '../../state/configs/configsSlice';

export default function* checkAuthCode(authCode, username) {
  const isPasswordReset = !!authCode && !!username;

  // check for password reset
  if (isPasswordReset) {
    const apiHash = yield select(selectApiHash);

    yield call(nsApi.authenticate, {
      username: nsUtils.encoding.base64Decode(username),
      authCode,
      apiHash,
      scope: 'webphone',
    });

    return true;
  }

  return false;
}
