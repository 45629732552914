import _ from 'lodash';
import { call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import { setUserEndpoints } from '../../state/devices/devicesSlice';

export default function* fetchEndpoints() {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    const endpoints = yield nsApi.get({
      object: 'device',
      action: 'read',
      user: decodedToken.user,
      domain: decodedToken.domain,
      noNDP: 'true',
      format: 'json',
    });

    const filteredEndpoints = _.filter(endpoints, (endpoint) => endpoint
    && endpoint.aor
    && endpoint.aor.indexOf('conference-bridge') === -1
    && endpoint.aor.indexOf('vb') === -1);
    yield put(setUserEndpoints(filteredEndpoints));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
  }
}
