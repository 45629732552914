import { put, select } from 'redux-saga/effects';
import { selectNavStack, setCurrentState, setNavStack } from '../../../state/routeStack/routeStackSlice';
import { getNavigate } from '../../../services/router/router';

export default function* navStackPush({ payload }) {
  const navigate = getNavigate();
  const stack = yield select(selectNavStack);
  yield put(setCurrentState(payload));
  yield put(setNavStack([...stack, payload]));
  navigate(payload.route, { state: payload.state });
}
