import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import { selectRespondersEntities, setResponders } from '../../state/agent/agentSlice';

export default function* updateResponder({ payload }) {
  let responders = yield select(selectRespondersEntities);
  responders = _.cloneDeep(responders);

  if (responders[payload.huntgroup_name]) {
    const responder = responders[payload.huntgroup_name].responders.find(
      (tempResponder) => tempResponder.device_aor === payload.device_aor,
    );

    if (responder) {
      responder.entry_option = payload.entry_option;
      responder.entry_status = payload.entry_status;
      responder.session_count = payload.session_count;

      const newResponders = responders[payload.huntgroup_name].responders.map(
        (el) => (el.device_aor === responder.device_aor ? responder : el),
      );

      yield put(setResponders({ queueId: payload.huntgroup_name, responders: newResponders }));
    }
  }
}
