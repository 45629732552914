/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const hidConsentSlice = createSlice({
  name: 'hidConsent',
  initialState: {
    showDialog: false,
  },
  reducers: {
    setShowHidConsent: (state, action) => {
      state.showDialog = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShowHidConsent,
} = hidConsentSlice.actions;

// Selectors
export const selectShowHidConsent = (state) => state.hidConsent.showDialog;

export default hidConsentSlice.reducer;
