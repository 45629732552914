/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const messagesList = nsUtils.normalizeList('id', []);
const sessionsList = nsUtils.normalizeList('id', []);

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    loading: true,
    unreadCount: 0,
    mmsCapableNumbers: '',
    groupMmsCapableNumbers: '',
    messages: [],
    messagesIds: messagesList.ids,
    messagesEntities: messagesList.entities,
    sessions: [],
    sessionsIds: sessionsList.ids,
    sessionsEntities: sessionsList.entities,
    textFieldValues: {},
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = _.toNumber(action.payload);
    },
    setMmsCapableNumbers: (state, action) => {
      state.mmsCapableNumbers = action.payload;
    },
    setGroupMmsCapableNumbers: (state, action) => {
      state.groupMmsCapableNumbers = action.payload;
    },
    // set the entire list
    setMessagesList: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('id', action.payload);
      state.messages = action.payload;
      state.messagesIds = ids;
      state.messagesEntities = entities;
    },
    // upsert messages
    setMessages: (state, action) => {
      let updatedMessages;

      if (state.messagesEntities[action.payload.id]) {
        updatedMessages = state.messages.map((messages) => (messages.id === action.payload.id
          ? action.payload
          : messages));
      } else {
        updatedMessages = [
          ...state.messages,
          action.payload,
        ];
      }

      const { entities, ids } = nsUtils.normalizeList('id', updatedMessages);
      state.messages = updatedMessages;
      state.messagesIds = ids;
      state.messagesEntities = entities;
    },
    setSessionsList: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('id', action.payload);
      state.sessions = action.payload;
      state.sessionsIds = ids;
      state.sessionsEntities = entities;
    },
    setTextFieldValue: (state, action) => {
      const { textVal, id } = action.payload;
      state.textFieldValues[id] = textVal;
    },
    setSession: (state, action) => {
      let updatedSessions;

      if (state.sessionsEntities[action.payload.id]) {
        updatedSessions = state.sessions.map((session) => (session.id === action.payload.id
          ? action.payload
          : session));
      } else {
        updatedSessions = [
          ...state.messages,
          action.payload,
        ];
      }

      const { entities, ids } = nsUtils.normalizeList('id', updatedSessions);
      state.sessions = action.payload;
      state.sessionsIds = ids;
      state.sessionsEntities = entities;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setUnreadCount,
  setMmsCapableNumbers,
  setGroupMmsCapableNumbers,
  setMessagesList,
  setMessages,
  setSessionsList,
  setSession,
  setTextFieldValue,
} = chatSlice.actions;

// Selectors
export const selectLoading = (state) => state.chat.loading;
export const selectUnreadCount = (state) => state.chat.unreadCount;
export const selectMmsCapableNumbers = (state) => state.chat.mmsCapableNumbers;
export const selectGroupMmsCapableNumbers = (state) => state.chat.groupMmsCapableNumbers;
export const selectMessages = (state) => state.chat.messages;
export const selectMessagesIds = (state) => state.chat.messagesIds;
export const selectMessagesEntities = (state) => state.chat.messagesEntities;
export const selectSessions = (state) => state.chat.sessions;
export const selectSessionsIds = (state) => state.chat.sessionsIds;
export const selectSessionsEntities = (state) => state.chat.sessionsEntities;
export const selectTextFieldValues = (id) => (state) => state.chat.textFieldValues[id];

export default chatSlice.reducer;
