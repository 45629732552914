import { useDispatch, useSelector } from 'react-redux';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { useState } from 'react';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { webHidPairing } from '@gnaudio/jabra-js';
import { selectShowHidConsent, setShowHidConsent } from '../../../state/hidConsent/hidConsentSlice';
import { selectAppName } from '../../../state/configs/configsSlice';
import { selectUser } from '../../../state/user/userSlice';
import { ONBOARD_COMPLETE } from '../../../constants';

function HidConsentDialog() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appName = useSelector(selectAppName);
  const user = useSelector(selectUser);
  const showConsent = useSelector(selectShowHidConsent);

  const [dontAsk, setDontAsk] = useState(false);

  // keep closed while onboarding
  const firstLogin = localStorage.getItem(`${appName}-${user?.userId}_onboarding`);

  const handleCancel = () => {
    const decodedToken = nsToken.getDecoded();
    localStorage.setItem(`${appName}-${decodedToken.user}_hid_dont_ask_again`, dontAsk);
    dispatch(setShowHidConsent(false));
  };

  const handleConfirm = async () => {
    const decodedToken = nsToken.getDecoded();
    localStorage.setItem(`${appName}-${decodedToken.user}_hid_dont_ask_again`, dontAsk);
    dispatch(setShowHidConsent(false));
    await webHidPairing();
  };

  return (
    <Dialog open={showConsent && firstLogin === ONBOARD_COMPLETE}>
      <DialogContent sx={{ width: { xs: 340, sm: 460, md: 600 } }}>
        <Typography
          variant="h5"
          sx={{ m: (theme) => theme.spacing(0, 0, 2, 0) }}
        >
          {t('HID_CONSENT_TITLE')}
        </Typography>
        <Typography>{t('HID_CONSENT_BODY')}</Typography>
        <Typography marginTop={2} marginBottom={2}>{t('HID_CONSENT_BODY_ADDITIONAL')}</Typography>
        <FormControlLabel
          label={t('HID_CONSENT_DONT_ASK_AGAIN')}
          control={(
            <Checkbox
              checked={dontAsk}
              onChange={(e) => setDontAsk(e.target.checked)}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: 'secondary.main' }} onClick={handleCancel}>
          {t('HID_CONSENT_CANCEL')}
        </Button>
        <Button sx={{ color: 'primary.main' }} onClick={handleConfirm}>
          {t('HID_CONSENT_PAIR')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HidConsentDialog;
