import _ from 'lodash';
// import moment from 'moment';
import {
  // call,
  put,
  select,
} from 'redux-saga/effects';
// import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading,
  selectSessions,
  setSessionsList,
  // setSession,
} from '../../../state/chat/chatSlice';
// import { selectHostname } from '../../../state/configs/configsSlice';
// import sendMessage from '../sendMessage/sendMessage';
import matchContact from '../../../utils/matchContact';
import getParticipantNames from '../../../utils/contact/getParticipantNames';
import {
  selectAppName,
  selectUserId,
} from '../../../state/configs/configsSlice';

export default function* fetchMessageSession({ sessionId }) {
  const debug = false;
  const decodedToken = nsToken.getDecoded();

  const res = yield nsApi.get({
    object: 'messagesession',
    action: 'read',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
  });

  if (debug) console.debug('this in fetchMessageSession is res :', res);
  // get the correct formatted session result
  if (!res[0] || (res[0].session_id && res[0].session_id.includes('meeting'))) {
    return;
  }
  let searchStr = res[0].remote || '';
  const contactArr = [];
  const tempContactArr = [];
  let names;
  let numPart = 0;
  let contact;

  if (!searchStr.includes(',')) {
    // for single user/contact
    if (typeof res[0].remote !== 'undefined' && res[0].remote.indexOf('@') !== -1) {
      const uidArr = res[0].remote.split('@');
      searchStr = uidArr['0'];
    }

    contact = matchContact(searchStr);
    if (contact === undefined || contact === null) {
      contact = {};
      // [WP-975]
      // if search string is 11 digits,
      // then take off leading 1 for first_name to match portal
      if (searchStr.length > 10) {
        // remove leading 1 from phone number
        contact.first_name = searchStr.replace(/^1/, '');
      }
      contact.last_name = '';
      contact.name = searchStr;
      contact.cell_phone = searchStr;
    }

    contactArr.push(contact);
    tempContactArr.push(contact);
  } else {
    // for group conversation
    const contactUids = searchStr.split(',');
    for (let x = 0; x < contactUids.length; x += 1) {
      const uidArr = contactUids[x].split('@');
      searchStr = uidArr['0'];
      searchStr = searchStr.replace(/['"\][]+/g, '');
      contact = matchContact(searchStr);
      // if contact is not found, make temp one for this conversation
      if (contact === undefined || contact === null) {
        contact = {};
        // [WP-975]
        // if search string is 11 digits,
        // then take off leading 1 for first_name to match portal
        if (searchStr.length > 10) {
          // remove leading 1 from phone number
          contact.first_name = searchStr.replace(/^1/, '');
        }
        contact.last_name = '';
        contact.name = searchStr;
        contact.cell_phone = searchStr;
      }
      contactArr.push(contact);
      tempContactArr.push(contact);
    }
    names = getParticipantNames(contactArr);
    numPart = contactArr.length;
  }

  const formattedSession = {
    contact,
    contactArr,
    tempContactArr, // this will always be the original contactArr
    partNames: names,
    numPart,
    // session_name: res[0].session_name,
    domain: res[0].domain,
    id: res[0].session_id,
    lastMessage: res[0].last_mesg,
    lastMessageType: res[0].last_mesg_type,
    lastStatus: res[0].last_status,
    lastSender: res[0].last_sender,
    lastTimestamp: res[0].last_timestamp,
    muted: res[0].muted,
    remote: res[0].remote,
    smsani: res[0].smsani,
    startTimestamp: res[0].start_timestamp,
    session_name: res[0].session_name,
  };
  if (debug) console.debug('this in fetchMessageSession is formattedSession :', formattedSession);

  const sessionsCurr = yield select(selectSessions);

  const updatedSessions = _.map(sessionsCurr, (obj) => {
    if (obj.id === formattedSession.id) {
      return formattedSession;
    }
    return obj;
  });

  if (localStorage.getItem('debugFlag') === 'true') console.debug('this is updatedSessions3: ', updatedSessions);

  yield put(setSessionsList(updatedSessions));

  // need to change the localstorage to have the new fetched session
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  const storageCards = JSON.parse(
    localStorage.getItem(`${appName}-${userId}_cards`) || '[]',
  );

  // find and replace the item with the session id in localstorage
  const result = _.map(storageCards, (obj) => {
    if (_.has(obj, 'meta.sendMessageParams.chat.id') && obj.meta.sendMessageParams.chat.id === formattedSession.id) {
      return _.set(obj, 'meta.sendMessageParams.chat', formattedSession);
    }
    return obj;
  });

  localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(result));

  yield put(setLoading(false));
  if (debug) console.debug('at the end of send message');
}
