import {
  call,
  put,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import i18n from 'i18next';
import {
  setUser,
} from '../../../state/user/userSlice';

import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';

export default function* updateGreeting({ payload }) {
  const token = yield call(nsToken.getDecoded);

  try {
    const params = {
      vmail_greeting: payload.vmailGreeting,
      domain: token.domain,
      user: token.user,
      object: 'subscriber',
      action: 'update',
      format: 'json',
    };

    yield call(nsApi.post, params);

    yield put(setUser(payload));

    yield put(setSnackbar({
      open: true,
      message: i18n.t('GREETING_SAVED'),
    }));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setSnackbar({
      open: true,
      message: i18n.t('GREETING_SAVE_FAILED'),
    }));
  }
}
