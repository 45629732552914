import { call } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

export function* getPortalLogo(decodedToken) {
  try {
    const res = yield call(nsApi.getLogo, {
      object: 'image',
      action: 'read',
      server: global.location.hostname,
      territory: decodedToken?.territory || '*',
      domain: decodedToken?.domain || '*',
      user: decodedToken?.user || '*',
      filename: 'portal_main_top_left.png',
    });

    return res;
  } catch (e) {
    return false;
  }
}

export default function* fetchLogo() {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    let res = yield call(nsApi.getLogo, {
      object: 'image',
      action: 'read',
      server: global.location.hostname,
      territory: decodedToken?.territory || '*',
      domain: decodedToken?.domain || '*',
      user: decodedToken?.user || '*',
      filename: 'webphone_main_top_left.png',
    });

    if (res !== 'data:') {
      return res;
    }

    res = yield call(getPortalLogo, decodedToken);
    return res;
  } catch (e) {
    const res = yield call(getPortalLogo, decodedToken);
    return res;
  }
}
