import { put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  selectCallHistory, selectFilteredBy, setCallHistoryResultSet,
} from '../../../state/callHistory/callHistorySlice';

export default function* filterCallHistory() {
  const callHistoryList = yield select(selectCallHistory);
  const filteredBy = yield select(selectFilteredBy);

  let resultSet = [];

  switch (filteredBy) {
    case 'all':
      resultSet = callHistoryList;
      break;
    case 'missed':
      resultSet = _.filter(callHistoryList, (record) => record.type === '2');
      break;
    case 'inbound':
      resultSet = _.filter(callHistoryList, (record) => record.type === '1');
      break;
    case 'outbound':
      resultSet = _.filter(callHistoryList, (record) => record.type === '0');
      break;
    default:
      resultSet = callHistoryList;
  }

  yield put(setCallHistoryResultSet(resultSet));
}
