import { CircularProgress } from '@mui/material';
import PageCenter from '../../templates/PageCenter/PageCenter';

function Loading() {
  return (
    <PageCenter>
      <CircularProgress />
    </PageCenter>
  );
}

export default Loading;
