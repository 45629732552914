import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsWebRTC from '@netsapiens/netsapiens-js/dist/web-rtc';

import store from '../state/store';
import { selectRingtone } from '../state/ringtones/ringtonesSlice';
import { selectOutputDevice, selectOutputDeviceRinger } from '../state/userMedia/userMediaSlice';
import { selectAppName, selectUserId } from '../state/configs/configsSlice';

const audioElements = {
  local: document.createElement('audio'),
  remote: document.createElement('audio'),
  ringback: document.createElement('audio'),
  ringer: document.createElement('audio'),
  callWaiting: document.createElement('audio'),
  busy: document.createElement('audio'),
  fastbusy: document.createElement('audio'),
};

let repeatRinger = false;
let repeatRingback = false;
let repeatCallWaiting = false;

// setup local audio
audioElements.local.muted = true;
audioElements.ringback.src = 'https://upload.wikimedia.org/wikipedia/commons/c/cd/US_ringback_tone.ogg';
audioElements.callWaiting.src = '/webphone/assets/audio/ringtones/ringtone_ah_ha_moment.wav';
audioElements.busy.src = 'https://upload.wikimedia.org/wikipedia/commons/6/6c/NorthAmericanBusySignal.ogg';
audioElements.fastbusy.src = 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Reorder_tone.ogg';

/**
 * sets the sink id for the audio element
 * @param audioElement
 * @param isRinger
 */
const setSink = (audioElement, isRinger) => {
  if (nsWebRTC.isSetSinkIdSupported && nsBrowser.name === 'Chrome') {
    let sinkId = selectOutputDevice(store.getState());
    if (isRinger) {
      sinkId = selectOutputDeviceRinger(store.getState());
    }
    if (sinkId) {
      audioElement.setSinkId(sinkId);
    }
  } else if (nsBrowser.name === 'Firefox') {
    let sinkId = selectOutputDevice(store.getState());
    if (isRinger) {
      sinkId = selectOutputDeviceRinger(store.getState());
    }
    if (sinkId) {
      audioElement.setSinkId(sinkId);
    }
  }
};

export const updateElementSinkId = () => {
  setSink(audioElements.local);
  setSink(audioElements.remote);
  // set ringer flag to true
  setSink(audioElements.ringer, true);
  setSink(audioElements.callWaiting);
  setSink(audioElements.ringback);
  setSink(audioElements.busy);
  setSink(audioElements.fastbusy);
};

/**
 *
 * @param {string} type - Expects local, remote, ringback or ringer
 * @returns {*}
 */
export const getAudio = (type) => audioElements[type];

/**
 * Play Audio Element
 * @param {string} type - Expects local, remote, ringback or ringer
 */
const playElement = (type) => {
  getAudio(type).currentTime = 0;
  getAudio(type).muted = false;
  getAudio(type).play();
};

/**
 * Pause Audio Element
 * @param {string} type - Expects local, remote, ringback or ringer
 */
const pauseElement = (type) => {
  getAudio(type).pause();
  getAudio(type).muted = true;
  getAudio(type).currentTime = getAudio(type).duration;
};

/**
 * Repeats audio after the track is finished
 * The 'repeatRinger' flag is used to prevent the track from playing again
 */
audioElements.ringer.onended = () => {
  if (repeatRinger) {
    const selectedRingtone = selectRingtone(store.getState());
    setTimeout(() => {
      if (repeatRinger) {
        playElement('ringer');
      } else {
        pauseElement('ringer');
      }
    }, selectedRingtone.delayRepeat);
  } else if (audioElements.ringer.currentTime !== audioElements.ringer.duration) {
    pauseElement('ringer');
  }
};

/**
 * Repeats audio after the track is finished
 * The 'repeatRingback' flag is used to prevent the track from playing again
 */
audioElements.ringback.onended = () => {
  if (repeatRingback) {
    setTimeout(() => {
      if (repeatRingback) {
        playElement('ringback');
      } else {
        pauseElement('ringback');
      }
    }, 1000);
  } else if (audioElements.ringback.currentTime !== audioElements.ringback.duration) {
    pauseElement('ringback');
  }
};

/**
 * Repeats audio after the track is finished
 * The 'repeatCallWaiting' flag is used to prevent the track from playing again
 */
audioElements.callWaiting.onended = () => {
  if (repeatCallWaiting) {
    setTimeout(() => {
      if (repeatCallWaiting) {
        playElement('callWaiting');
      } else {
        pauseElement('callWaiting');
      }
    }, 4000);
  } else if (audioElements.callWaiting.currentTime !== audioElements.callWaiting.duration) {
    pauseElement('callWaiting');
  }
};

/**
 *
 */
export const playRinger = () => {
  try {
    repeatRinger = true;
    // set ringer flag to true
    setSink(audioElements.ringer, true);
    playElement('ringer');
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playCallWaiting = () => {
  try {
    const appName = selectAppName(store.getState());
    const userId = selectUserId(store.getState());
    const cValue = localStorage.getItem(`${appName}-${userId}_callwaiting`);
    setSink(audioElements.callWaiting);
    audioElements.callWaiting.volume = 0.5;
    if (cValue === null || cValue === true || cValue === 'true' || cValue === 'enabled') {
      repeatCallWaiting = true;
      playElement('callWaiting');
    } else if (cValue === 'one') {
      repeatCallWaiting = false;
      playElement('callWaiting');
    } else {
      // eslint-disable-next-line no-console
      console.debug('incoming call, skipping audio');
    }
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playRingback = () => {
  try {
    repeatRingback = true;
    setSink(audioElements.ringback);
    playElement('ringback');
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const stopRinger = () => {
  try {
    repeatRinger = false;
    pauseElement('ringer');
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const stopRingback = () => {
  try {
    repeatRingback = false;
    pauseElement('ringback');
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const stopCallWaiting = () => {
  try {
    repeatCallWaiting = false;
    pauseElement('callWaiting');
  } catch (err) {
    // todo
  }
};

/**
 *
 * @param {string} path - path to audio file
 */
export const ringerSrc = (path) => {
  try {
    setSink(audioElements.ringer, true);
    audioElements.ringer.src = path;
    pauseElement('ringer');
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playBusy = () => {
  try {
    setSink(audioElements.busy);
    audioElements.busy.volume = 0.3;
    playElement('busy');
    setTimeout(() => {
      pauseElement('busy');
    }, 3000);
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playFastBusy = () => {
  try {
    setSink(audioElements.fastbusy);
    audioElements.fastbusy.volume = 0.3;
    playElement('fastbusy');
    setTimeout(() => {
      pauseElement('fastbusy');
    }, 3000);
  } catch (err) {
    // todo
  }
};

/**
 * Change audio volume
 */
export const changeAudioOutputVolume = (volume) => {
  audioElements.local.volume = volume / 100;
  audioElements.remote.volume = volume / 100;
  const appName = selectAppName(store.getState());
  const userId = selectUserId(store.getState());

  localStorage.setItem(`${appName}-${userId}_outputVolume`, volume);
};

/**
 * Change notification/ringer volume
 */
export const changeNotificationsVolume = (volume) => {
  audioElements.ringer.volume = volume / 100;
  const appName = selectAppName(store.getState());
  const userId = selectUserId(store.getState());

  localStorage.setItem(`${appName}-${userId}_notificationsVolume`, volume);
};
