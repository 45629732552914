import {
  put, select,
} from 'redux-saga/effects';
import * as contactState from '../../../state/contacts/contactsSlice';
import { selectUser } from '../../../state/user/userSlice';

export default function* processSearchResultSet() {
  const contactsArr = yield select(contactState.selectContacts);
  const user = yield select(selectUser);

  if (contactsArr?.length) {
    const contactsSearchArr = [];
    for (let i = 0; i < contactsArr.length; i += 1) {
      const name = contactsArr[i].name.toLowerCase();

      if (user.userId !== contactsArr[i].user) {
        if (contactsArr[i].user && contactsArr[i].extension) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].extension,
            searchStr: `${name} ${contactsArr[i].extension}`,
            type: 'extension',
          });
        }

        if (contactsArr[i].cell_phone) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].cell_phone,
            searchStr: `${name} ${contactsArr[i].cell_phone}`,
            type: 'cell',
            sms: user.sms && user.sms.length,
          });
        }
        if (contactsArr[i].home_phone) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].home_phone,
            searchStr: `${name} ${contactsArr[i].home_phone}`,
            type: 'home',
            sms: user.sms && user.sms.length,
          });
        }
        if (contactsArr[i].work_phone) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].work_phone,
            searchStr: `${name} ${contactsArr[i].work_phone}`,
            type: 'work',
            sms: user.sms && user.sms.length,
          });
        }
      }
    }

    yield put(contactState.setSearchResultsSet(contactsSearchArr));
  }
}
