import _, { toInteger } from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import {
  selectAnsweringRule, selectAnsweringRules, setAnsweringRules, setLoading,
} from '../../../state/answeringRules/answeringRulesSlice';

export default function* enableAnsweringRule({ payload }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const rule = yield select((state) => selectAnsweringRule(state, payload.ruleId));

  try {
    const params = {
      object: 'answerrule',
      action: 'update',
      uid: decodedToken.sub,
      user: decodedToken.user,
      domain: decodedToken.domain,
      time_frame: rule.time_frame,
      enable: payload.enable,
    };

    const rules = yield select(selectAnsweringRules);
    const updatedRules = _.map(rules, (r) => {
      if (r.id === toInteger(payload.ruleId)) {
        const clonedRule = _.clone(r);
        clonedRule.enable = payload.enable;
        return clonedRule;
      }
      return r;
    });
    yield put(setAnsweringRules(updatedRules));
    yield call(nsApi.post, params);
  } catch (e) {
    console.error('Fetching Summary by User failed:', e);
    bugsnagClient.notify(e);
    yield put(setLoading(false));
  }
}
