import { call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import bugsnagClient from '../../../services/bugsnag/bugsnag';

import { setCdrSet } from '../../../state/callHistory/callHistorySlice';

export default function* fetchCallCdr({ payload: cdrId }) {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    const params = {
      object: 'cdr2',
      action: 'read',
      uid: decodedToken.sub,
      cdr_id: cdrId,
      format: 'json',
    };

    const res = yield call(nsApi.get, params);
    yield put(setCdrSet({ cdr_id: cdrId, cdr: res[0] }));
  } catch (e) {
    console.error('Fetching Summary by User failed:', e);
    bugsnagClient.notify(e);
  }
}
