import moment from 'moment';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';

import {
  setLoading,
  // selectSessions,
  // selectMessagesEntities,
  selectSessionsEntities,
  setSessionsList,
} from '../../../state/chat/chatSlice';
import addMessage from '../addMessage/addMessage';
import updateMessageStatus from '../updateMessageStatus/updateMessageStatus';

export default function* sendMessage({ payload }) {
  const debug = localStorage.getItem('debugFlag') === 'true';

  if (debug) console.debug('in sendmessage params: ', payload);

  const id = nsUtils.randomId().toString();

  const msgToAdd = {
    direction: 'term',
    id,
    type: payload.type,
    from_num: payload.from_num ? payload.from_num : null,
    from_uid: payload.from_uid ? payload.from_uid : null,
    text: (payload.type === 'chat-media' || payload.type === 'mms')
      ? 'media' : payload.message,
    msgStatus: 'sending',
    session_id: payload.session_id,
  };

  const dateTimeAPI = moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss');

  // if it is a media message
  if (payload.type === 'chat-media' || payload.type === 'mms') {
    msgToAdd.file = payload.file;
    // maybe add timestamp real
    msgToAdd.timeStampSent = dateTimeAPI;
  }

  // if creating a brand new card, will need to keep this else sessionentities will add a null
  const sessionsEntitiesOrig = yield select(selectSessionsEntities);
  const sessionOrig = sessionsEntitiesOrig[payload.session_id];

  if (debug) console.debug('this is msgtoAdd: ', msgToAdd);

  const param1 = {
    object: 'message',
    action: 'create',
    domain: payload.domain,
    user: payload.user,
    type: payload.type,
    message: payload.message,
    session_id: payload.session_id,
    destination: payload.destination,
    id,
  };

  const param2 = {
    object: 'message',
    action: 'create',
    domain: payload.domain,
    user: payload.user,
    message: payload.message,
    session_id: payload.session_id,
    destination: payload.destination,
    data: payload.data,
    mime_type: payload.mime_type,
    size: payload.size,
    timestamp: dateTimeAPI,
    id,
  };

  if (payload.from_num) {
    param1.from_num = payload.from_num;
    param2.from_num = payload.from_num;
  }

  let res;
  try {
    res = yield nsApi.post(param1, param2);
  } catch (e) {
    console.debug('SENDING MESSAGE ERROR: ', e);
    res = 400;
  }

  yield call(addMessage, msgToAdd); // cannot put above the post for new instances of sessions

  if (debug) console.debug('res sendmessage: ', res);

  // might need to change the status of this message
  // update the status of the message
  if (res !== 400) {
    yield call(updateMessageStatus, msgToAdd, 'sent');
  } else {
    yield call(updateMessageStatus, msgToAdd, 'failed');
  }

  const sessionsEntities = yield select(selectSessionsEntities);
  if (debug) console.debug('sessionsEntities: ', sessionsEntities);
  const session = sessionsEntities[payload.session_id];
  let updatedSession;
  if (session) {
    // update existing session
    updatedSession = {
      ...session,
      lastMessage: payload.message,
      lastTimestamp: res.timestamp,
      lastStatus: 'read',
    };
  } else {
    // TODO create a new session to add
    if (debug) console.debug('this is sessionOrig: ', sessionOrig);
    if (sessionOrig) {
      updatedSession = {
        ...sessionOrig,
        lastMessage: payload.message,
        lastTimestamp: res.timestamp,
        lastStatus: 'read',
      };
    }
  }
  const updatedSessionsEntities = Object.keys(sessionsEntities).map((key) => {
    if (key === payload.session_id) {
      // remove old session object from array
      return null;
    }
    return sessionsEntities[key];
  }).filter(Boolean); // filter out null values from array

  // add updatedSession to front of updatedSessionsEntities array
  // extra check just in case updatedsession is null
  if (updatedSession) {
    updatedSessionsEntities.unshift(updatedSession);
  }
  if (debug) console.debug('updatedSessionsEntities: ', updatedSessionsEntities);

  yield put(setSessionsList(updatedSessionsEntities));

  yield put(setLoading(false));
  if (debug) console.debug('at the end of send message');
}
