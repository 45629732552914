import { call, put, select } from 'redux-saga/effects';
import updateActiveCount from '../updateActiveCount/updateActiveCount';
import updateIsConference from '../updateIsConference/updateIsConference';
import { selectCallSessions, setCallSessions } from '../../../state/callSessions/callSessionsSlice';

export default function* replaceConferenceSession({ payload: callSession }) {
  let sessions = yield select(selectCallSessions);

  sessions = sessions.map((session) => {
    if (session.id === 'conference') {
      session.conferenceList.forEach((caller) => {
        callSession.conferenceList.push(caller);
      });

      return callSession;
    }

    return session;
  });

  yield put(setCallSessions(sessions));
  yield call(updateActiveCount);
  yield call(updateIsConference);
}
