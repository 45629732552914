import nsApi from '@netsapiens/netsapiens-js/dist/api';

import {
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageCenter from '../../templates/PageCenter/PageCenter';
import { LOGIN_CARD_STYLES } from '../../../constants';
import { selectApiHash, selectHostname } from '../../../state/configs/configsSlice';
import { getLoginRoute } from '../../../routePaths';
import { selectMFAType, selectMFAUserName, selectMFAVendor } from '../../../state/mfa/mfaSlice';
import { loggedInAction } from '../../../sagas/loggedIn/action';

function MFAForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hostname = useSelector(selectHostname);
  const imgUrl = `https://${hostname}/ns-api/?object=image&action=read&server=${hostname}&filename=portal_landing.png`;
  const [imgLoaded, setImgLoaded] = useState(false);

  const mfaVendor = useSelector(selectMFAVendor);
  const mfaType = useSelector(selectMFAType);
  const apiHash = useSelector(selectApiHash);
  const mfaUserName = useSelector(selectMFAUserName);

  const [securityCode, setSecurityCode] = useState('');

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSecurityCodeChange = (event) => {
    setError(false);
    setSecurityCode(event.target.value);
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    if (securityCode === '') {
      setError(true);
      setSubmitting(false);
      return;
    }

    try {
      await nsApi.mfaAuth({
        username: mfaUserName,
        mfa_type: mfaType,
        mfa_vendor: mfaVendor,
        passcode: securityCode,
        apiHash,
      });

      dispatch(loggedInAction());
    } catch (e) {
      console.error('MFAFORM ERROR: ', e);
      setError(true);
      setSubmitting(false);
    }
  };

  const handleEnterSubmit = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <PageCenter>
      <Fade in={imgLoaded}>
        <Card sx={{ ...LOGIN_CARD_STYLES, textAlign: 'center', display: !imgLoaded && 'none' }}>
          <CardContent>
            <Fade in>
              <img
                src={imgUrl}
                alt="logo"
                aria-label="logo"
                style={{ maxWidth: '100%' }}
                data-testid="img-login"
                onLoad={() => setImgLoaded(true)}
              />
            </Fade>
            <Box
              sx={{
                mt: 2,
                textAlign: 'left',
              }}
            >
              {t('MFA_INSTRUCTIONS')}
              <Box
                sx={{
                  pt: 2,
                }}
              >
                <TextField
                  aria-label={t('SECURITY_CODE')}
                  label={t('SECURITY_CODE')}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  value={securityCode}
                  onChange={handleSecurityCodeChange}
                  onKeyDown={handleEnterSubmit}
                  disabled={submitting}
                  error={error}
                  fullWidth
                  required
                  data-testid="TextField-security_code"
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: 2,
              }}
            >
              {error && (
                <Box
                  sx={{
                    pb: 1,
                    color: 'error.main',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    {t('INVALID_SECURITY_CODE')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    {t('LOGIN_TRY_AGAIN')}
                  </Typography>
                </Box>
              )}
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={submitting}
                fullWidth
              >
                {t('SUBMIT')}
              </Button>
              {/* return to login */}
              <Box
                sx={{
                  pt: 1,
                }}
              >
                <Link
                  to={getLoginRoute()}
                  style={{ textDecoration: 'none' }}
                  disabled={submitting}
                >
                  <Typography color="primary" variant="body2">{t('RETURN_TO_LOGIN')}</Typography>
                </Link>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </PageCenter>
  );
}

export default MFAForm;
