/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const voicemailSlice = createSlice({
  name: 'voicemail',
  initialState: {
    loading: true,
    listType: 'new',
    newVoicemail: [],
    savedVoicemail: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setListType: (state, action) => {
      state.listType = action.payload;
    },
    setNewVoicemail: (state, action) => {
      state.newVoicemail = action.payload;
    },
    setSavedVoicemail: (state, action) => {
      state.savedVoicemail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading, setListType, setNewVoicemail, setSavedVoicemail,
} = voicemailSlice.actions;

// Selectors
export const selectLoading = (state) => state.voicemail.loading;
export const selectListType = (state) => state.voicemail.listType;
export const selectNewVoicemail = (state) => state.voicemail.newVoicemail;
export const selectSavedVoicemail = (state) => state.voicemail.savedVoicemail;

export default voicemailSlice.reducer;
