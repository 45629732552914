import { createTheme } from '@mui/material';
import Color from 'color';

import NSIconEot from './assets/css/NSIcon-Material.eot';
import NSIconTtf from './assets/css/NSIcon-Material.ttf';
import NSIconWoff from './assets/css/NSIcon-Material.woff';
import NSIconSvg from './assets/css/NSIcon-Material.svg';
import useContrastText from './utils/useContrastText';

function generateColors(hex) {
  const color = Color(hex);

  return {
    light: color.lighten(0.06).hex(),
    highlight: color.fade(0.9).toString(),
    highlightContrastText: color.isDark() ? color.hex() : color.darken(0.35).desaturate(0.25).hex(),
    main: color.hex(),
    dark: color.darken(0.06).hex(),
    contrastText: useContrastText(hex) ? '#333' : '#fff',
    contrastLight: useContrastText(hex) ? Color('#333').fade(0.24).string() : Color('#fff').fade(0.54).string(),
  };
}

export default (primary = '#0072bc', secondary = '#F49F0A') => createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 876,
      md: 1168,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: generateColors(primary),
    secondary: generateColors(secondary),
    green: {
      light: '#3ed455',
      main: '#19ad1d',
    },
    red: {
      light: '#ef6a6e',
      main: '#ff270e',
    },
    grey: {
      50: '#cccccc',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'NSIcon-Material';
          src:  url(${NSIconEot});
          src:  url('${NSIconEot}#iefix') format('embedded-opentype'),
            url(${NSIconTtf}) format('truetype'),
            url(${NSIconWoff}) format('woff'),
            url('${NSIconSvg}#NSIcon-Material') format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }
        
        .nsicon-m {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'NSIcon-Material' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
        
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        
        .nsicon-m-rule-screen:before {
          content: "\\e900";
        }
        .nsicon-m-assisted-transfer:before {
          content: "\\e901";
        }
        .nsicon-m-bar-chart:before {
          content: "\\e902";
        }
        .nsicon-m-conversation-layout:before {
          content: "\\e903";
        }
        .nsicon-m-corner-grip:before {
          content: "\\e904";
        }
        .nsicon-m-gauge-chart:before {
          content: "\\e905";
        }
        .nsicon-m-grid-layout:before {
          content: "\\e906";
        }
        .nsicon-m-lights-off:before {
          content: "\\e907";
        }
        .nsicon-m-lights-on:before {
          content: "\\e908";
        }
        .nsicon-m-nav-answering-rules:before {
          content: "\\e909";
        }
        .nsicon-m-nav-attendants:before {
          content: "\\e90a";
        }
        .nsicon-m-nav-callcenter:before {
          content: "\\e90b";
        }
        .nsicon-m-nav-callhistory-orig:before {
          content: "\\e90c";
        }
        .nsicon-m-nav-callhistory:before {
          content: "\\e90d";
        }
        .nsicon-m-nav-conferences:before {
          content: "\\e90e";
        }
        .nsicon-m-nav-contacts:before {
          content: "\\e90f";
        }
        .nsicon-m-nav-domains:before {
          content: "\\e910";
        }
        .nsicon-m-nav-fax-alt:before {
          content: "\\e911";
        }
        .nsicon-m-nav-fax:before {
          content: "\\e912";
        }
        .nsicon-m-nav-home:before {
          content: "\\e913";
        }
        .nsicon-m-nav-inventory:before {
          content: "\\e914";
        }
        .nsicon-m-nav-message:before {
          content: "\\e915";
        }
        .nsicon-m-nav-moh:before {
          content: "\\e916";
        }
        .nsicon-m-nav-numbers:before {
          content: "\\e917";
        }
        .nsicon-m-nav-office:before {
          content: "\\e918";
        }
        .nsicon-m-nav-phones:before {
          content: "\\e919";
        }
        .nsicon-m-nav-queues:before {
          content: "\\e91a";
        }
        .nsicon-m-nav-reseller:before {
          content: "\\e91b";
        }
        .nsicon-m-nav-resellers:before {
          content: "\\e91c";
        }
        .nsicon-m-nav-routes:before {
          content: "\\e91d";
        }
        .nsicon-m-nav-settings:before {
          content: "\\e91e";
        }
        .nsicon-m-nav-siptrunks:before {
          content: "\\e91f";
        }
        .nsicon-m-nav-super:before {
          content: "\\e920";
        }
        .nsicon-m-nav-timeframes:before {
          content: "\\e921";
        }
        .nsicon-m-nav-users:before {
          content: "\\e922";
        }
        .nsicon-m-nav-voicemail:before {
          content: "\\e923";
        }
        .nsicon-m-park-call:before {
          content: "\\e924";
        }
        .nsicon-m-rule-active:before {
          content: "\\e925";
        }
        .nsicon-m-rule-busy:before {
          content: "\\e926";
        }
        .nsicon-m-rule-disabled:before {
          content: "\\e927";
        }
        .nsicon-m-rule-dnd:before {
          content: "\\e928";
        }
        .nsicon-m-rule-forward:before {
          content: "\\e929";
        }
        .nsicon-m-rule-offline:before {
          content: "\\e92a";
        }
        .nsicon-m-rule-on-active:before {
          content: "\\e92b";
        }
        .nsicon-m-rule-simring:before {
          content: "\\e92c";
        }
        .nsicon-m-rule-unanswered:before {
          content: "\\e92d";
        }
        .nsicon-m-spotlight-layout:before {
          content: "\\e92e";
        }
        .nsicon-m-status-message:before {
          content: "\\e92f";
        }
        .nsicon-m-table:before {
          content: "\\e930";
        }
        .nsicon-m-transfer:before {
          content: "\\e931";
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          color: useContrastText(primary) ? '#333' : '#fff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '64px !important',
          marginLeft: 16,
          marginRight: 16,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          lineHeight: 1.2,
        },
      },
    },
  },
});
