import { put, select } from 'redux-saga/effects';
import { selectCallSessions, setActiveCallId } from '../../../state/callSessions/callSessionsSlice';

export const activeTypes = [
  'trying',
  'inboundProgressing',
  'outboundProgressing',
  'accepted',
];

export default function* setLastActive() {
  const sessions = yield select(selectCallSessions);
  const reversedSessions = [...sessions].reverse();

  let id = null;
  for (let i = 0; i < reversedSessions.length; i += 1) {
    if (activeTypes.indexOf(reversedSessions[i].status) !== -1) {
      id = reversedSessions[i].id;
    }
  }

  yield put(setActiveCallId(id));
}
