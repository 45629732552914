import {
  call,
  // fork,
  put,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setSessionsList, setLoading, setUnreadCount } from '../../../state/chat/chatSlice';

import matchContact from '../../../utils/matchContact';
import getParticipantNames from '../../../utils/contact/getParticipantNames';

export default function* fetchMessageSessions() {
  const debug = localStorage.getItem('localDebug') === 'true' || false;
  try {
    const decodedToken = yield call(nsToken.getDecoded);
    yield put(setLoading(true));
    // console.debug('in fetchMessageSessions decodedToken: ', decodedToken);

    const res = yield call(nsApi.get, {
      object: 'messagesession',
      action: 'read',
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
    });

    const sessions = [];
    let unreadCount = 0;
    if (debug) console.debug('this is fetchMessageSessions res: ', res);
    if (res) {
      for (let i = 0; i < res.length; i += 1) {
        if (!res[i] || res[i] === undefined || res[i]?.remote === null) {
          continue; // eslint-disable-line no-continue
        }

        // const element = res[i];
        if (res[i].session_id && res[i].session_id.includes('meeting')) {
          continue; // eslint-disable-line no-continue
        }
        let searchStr = res[i].remote || '';
        const contactArr = [];
        const tempContactArr = [];
        let names;
        let numPart = 0;
        let contact;
        // console.debug('this is searchStr : ', searchStr);

        if (res[i].last_status !== 'read') {
          unreadCount += 1;
        }
        if (!searchStr.includes(',')) {
          // for single user/contact
          if (typeof res[i]?.remote !== 'undefined'
            && res[i]?.remote !== null
            && res[i]?.remote.indexOf('@') !== -1) {
            const uidArr = res[i].remote.split('@');
            searchStr = uidArr['0'];
          }

          contact = matchContact(searchStr);
          // console.debug('this is matched contact : ', contact);
          if (contact === undefined || contact === null) {
            contact = {};
            // [WP-975]
            // if search string is 11 digits,
            // then take off leading 1 for first_name to match portal
            if (searchStr.length > 10) {
              // remove leading 1 from phone number
              contact.first_name = searchStr.replace(/^1/, '');
            }
            contact.last_name = '';
            contact.name = searchStr;
            contact.cell_phone = searchStr;
            contact.customContact = true;
          }

          contactArr.push(contact);
          tempContactArr.push(contact);
        } else {
          // for group conversation
          const contactUids = searchStr.split(',');
          for (let x = 0; x < contactUids.length; x += 1) {
            const uidArr = contactUids[x].split('@');
            searchStr = uidArr['0'];
            searchStr = searchStr.replace(/['"\][]+/g, '');
            contact = matchContact(searchStr);
            // if contact is not found, make temp one for this conversation
            if (contact === undefined || contact === null) {
              contact = {};
              // [WP-975]
              // if search string is 11 digits,
              // then take off leading 1 for first_name to match portal
              if (searchStr.length > 10) {
                // remove leading 1 from phone number
                contact.first_name = searchStr.replace(/^1/, '');
              }
              contact.last_name = '';
              contact.name = searchStr;
              contact.cell_phone = searchStr;
              contact.customContact = true;
            }
            contactArr.push(contact);
            tempContactArr.push(contact);
          }
          names = getParticipantNames(contactArr);
          numPart = contactArr.length;
        }
        sessions.push({
          contact,
          contactArr,
          tempContactArr, // this will always be the original contactArr
          partNames: names,
          numPart,
          // session_name: res.session_name,
          domain: res[i].domain,
          id: res[i].session_id,
          lastMessage: res[i].last_mesg,
          lastMessageType: res[i].last_mesg_type,
          lastMessageMediaType: res[i].last_mesg_media_type,
          lastStatus: res[i].last_status,
          lastSender: res[i].last_sender,
          lastTimestamp: res[i].last_timestamp,
          muted: res[i].muted,
          remote: res[i].remote,
          smsani: res[i].smsani,
          startTimestamp: res[i].start_timestamp,
          session_name: res[i].session_name,
        });
      }
    }
    if (debug) console.debug('this is sessions: ', sessions);
    if (localStorage.getItem('debugFlag') === 'true') console.debug('this is updatedSessions4: ', sessions);

    yield put(setUnreadCount(unreadCount));
    yield put(setSessionsList(sessions));

    yield put(setLoading(false));
  } catch (e) {
    if (debug) console.debug('this is e: ', e);
    // todo bugsnag
    yield put(setLoading(false));
  }
}
