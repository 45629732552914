import { put, select } from 'redux-saga/effects';
import { selectIncomingCalls, setIncomingCalls } from '../../../state/ua/uaSlice';

export default function* removeIncomingCall({ payload: id }) {
  const incomingCalls = yield select(selectIncomingCalls);

  const filteredIc = [];
  for (let i = 0; i < incomingCalls.length; i += 1) {
    if (incomingCalls[i].id !== id) {
      filteredIc.push(incomingCalls[i]);
    }
  }

  yield put(setIncomingCalls(filteredIc));
}
