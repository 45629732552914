/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/**
 * Gets the window inner size
 * @param {string} name - expects Height or Width
 * @returns {*}
 * @private
 */
export function getSize(name) {
  return window[`inner${name}`];
}

export const screenSizeSlice = createSlice({
  name: 'screenSize',
  initialState: {
    display: null,
    height: getSize('Height'),
    size: null,
    width: getSize('Width'),
  },
  reducers: {
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setHeight: (state, action) => {
      state.height = action.payload;
    },
    setResize: (state, action) => {
      state.display = action.payload.display;
      state.height = action.payload.height;
      state.size = action.payload.size;
      state.width = action.payload.width;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDisplay,
  setHeight,
  setResize,
  setSize,
  setWidth,
} = screenSizeSlice.actions;

// Selectors
export const selectDisplay = (state) => state.screenSize.display;
export const selectHeight = (state) => state.screenSize.height;
export const selectSize = (state) => state.screenSize.size;
export const selectWidth = (state) => state.screenSize.width;

export default screenSizeSlice.reducer;
