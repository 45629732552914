import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectCallParkIds } from '../../state/callPark/callParkSlice';
import { selectConfig } from '../../state/configs/configsSlice';

export default function* parkCall({ payload: park }) {
  const decodedToken = yield call(nsToken.getDecoded);
  const prefix = yield select((state) => selectConfig(state, 'portalTransferPrefixPark'));

  // Add toast 'PARKED_AT #ext#'

  if (park.dynamic) {
    const parkLot = yield select(selectCallParkIds);

    return nsApi.get({
      object: 'call',
      action: 'park',
      callid: park.parkId,
      prefix,
      valid: parkLot.join(), // comma seperated list of all parkExt
      uid: decodedToken.sub,
      format: 'json',
    }).then((res) => { console.debug('parkCall dynamic res:', res); });
  }
  return nsApi.get({
    object: 'call',
    action: 'xfer',
    callid: park.parkId,
    destination: `sip:${!prefix ? '' : prefix}${park.parkExt}@${decodedToken.domain}`,
    uid: decodedToken.sub,
    format: 'json',
  }).then((res) => { console.debug('parkCall specific res:', res); });
}
