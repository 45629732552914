/* eslint-disable no-param-reassign, max-len */
import { createSlice } from '@reduxjs/toolkit';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import i18next from 'i18next';
import * as configs from '../../config';
import normalizeYes from '../../utils/normalizeYes';

localStorage.setItem('portalURL', configs.portalURL);

const nsSocketURLs = [];
const uaSocketURLs = [];

if (configs.nsSocketURLs.length) {
  for (let i = 0; i < configs.nsSocketURLs.length; i += 1) {
    nsSocketURLs.push(`wss://${configs.nsSocketURLs[i].replace('*', global.location.hostname)}`);
  }
}

if (configs.uaSocketURLs.length) {
  for (let i = 0; i < configs.uaSocketURLs.length; i += 1) {
    uaSocketURLs.push(`wss://${configs.uaSocketURLs[i].replace('*', global.location.hostname)}`);
  }
}

const initialState = {
  appName: configs.name,
  apiURL: `https://${configs.apiURL.replace('*', global.location.hostname)}`,
  devicePostFix: configs.devicePostFix,
  hostname: global.location.hostname,
  nsSocketURLs,
  pathname: global.location.pathname,
  portalURL: `https://${configs.portalURL.replace('*', global.location.hostname)}`,
  protocol: global.location.protocol,
  supportedBrowsers: configs.supportedBrowsers,
  uaSocketURLs,
  userId: nsBrowser.getQuery('user'),
  version: configs.version,
};

export const configsSlice = createSlice({
  name: 'configs',
  initialState: {
    configs: initialState,
  },
  reducers: {
    setConfig: (state, action) => {
      state.configs[action.payload.config_name] = action.payload.config_value;
    },
    setConfigs: (state, action) => {
      const stateConfigs = state.configs;
      for (let i = 0; i < action.payload.length; i += 1) {
        if (action.payload[i].config_value || (action.payload[i].config_name === 'PORTAL_WEB_PHONE_POSTFIX')) {
          if (action.payload[i].config_name === 'PORTAL_WEB_PHONE_POSTFIX') {
            stateConfigs.devicePostFix = action.payload[i].config_value;
          } else stateConfigs[action.payload[i].config_name] = action.payload[i].config_value;
        }
      }
      state.configs = stateConfigs;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfig, setConfigs } = configsSlice.actions;

// Selectors
export const selectApiHash = (state) => state.configs.configs.apiHash;
export const selectAppName = (state) => state.configs.configs.appName;
export const selectApiURL = (state) => state.configs.configs.apiURL;
export const selectAudioCodecs = (state) => state.configs.configs.PORTAL_WEBRTC_CODEC_LIST_AUDIO;
export const selectCallCenterName = (state) => state.configs.configs.PORTAL_CONTACT_CENTER_FEATURE_NAME || i18next.t('CALL_CENTER');
export const selectCallCustomStatus1 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_1 || '';
export const selectCallCustomStatus2 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_2 || '';
export const selectCallCustomStatus3 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_3 || '';
export const selectCallCustomStatus4 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_4 || '';
export const selectCallCustomStatus5 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_5 || '';
export const selectCallCustomStatus6 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_6 || '';
export const selectCallCustomStatus7 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_7 || '';
export const selectCallCustomStatus8 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_8 || '';
export const selectCallCustomStatus9 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_9 || '';
export const selectCallCustomStatus10 = (state) => state.configs.configs.PORTAL_CALL_QUEUE_STATUS_CUSTOM_10 || '';
export const selectChatAllowed = (state) => normalizeYes(state.configs.configs.PORTAL_CHAT_SMS || 'no') === 'yes';
export const selectConfig = (state, name) => state.configs.configs[name] || null;
export const selectDevicePostFix = (state) => state.configs.configs.devicePostFix;
export const selectHostname = (state) => state.configs.configs.hostname;
export const selectIceCheckingTimeout = (state) => state.configs.configs.PORTAL_VIDEO_ICECHECKING_TIMEOUT || 1000;
export const selectPasswordRequirements = (state) => ({
  securePass: normalizeYes(state.configs.configs.PORTAL_USERS_SECURE_PASSWORD_STRONG || 'no') === 'yes',
  forceNumeric: normalizeYes(state.configs.configs.PORTAL_USERS_SECURE_PASSWORD_FORCE_NUMERIC || 'no') === 'yes',
  minimumCapitals: +state.configs.configs.PORTAL_USERS_SECURE_PASSWORD_MIN_CAPITAL_LETTER_COUNT,
  minimumLength: +state.configs.configs.PORTAL_USERS_SECURE_PASSWORD_MIN_LENGTH,
  minimumNumbers: +state.configs.configs.PORTAL_USERS_SECURE_PASSWORD_MIN_NUMBER_COUNT,
  minimumSpecialCharacters: +state.configs.configs.PORTAL_USERS_SECURE_PASSWORD_MIN_SPECIAL_CHAR_COUNT,
  allowExtension: normalizeYes(state.configs.configs.PORTAL_USER_SECURE_PASSWORD_ALLOW_EXT_IN_PASSWORD || 'no') === 'yes',
  forbiddenPasswords: state.configs.configs.PORTAL_USER_SECURE_PASSWORD_EXTENSION_FORBID_LIST?.split(',') || [],
});
export const selectThreeWayCallDisconnectOthersOnEnd = (state) => normalizeYes(state.configs.configs.PORTAL_THREE_WAY_CALL_DISCONNECT_OTHERS_ON_END || 'no') === 'yes';
export const selectPathname = (state) => state.configs.configs.pathname;
export const selectPortalURL = (state) => state.configs.configs.portalURL;
export const selectLocalizationLayouts = (state) => normalizeYes(state.configs.configs.PORTAL_WEBPHONE_LOCALIZATION_LANGUAGE_LAYOUTS || 'yes') === 'yes';
export const selectLocalizationFormat = (state) => state.configs.configs.PORTAL_LOCALIZATION_DATE_FORMAT || 'MDY';
export const selectLocalizationDelimiter = (state) => state.configs.configs.PORTAL_LOCALIZATION_DATE_DELIMITER || ':';
export const selectLocalizationTimeFormat = (state) => state.configs.configs.PORTAL_LOCALIZATION_TIME_FORMAT || 'HMS';
export const selectLocalizationTimeDelimiter = (state) => state.configs.configs.PORTAL_LOCALIZATION_TIME_DELIMITER || ':';
export const selectLocalizationHourFormat = (state) => normalizeYes(state.configs.configs.PORTAL_LOCALIZATION_TIME_12_HOUR_FORMAT || 'yes') === 'yes';
export const selectLocalized = (state) => normalizeYes(state.configs.configs.PORTAL_LOCALIZATION_LANGUAGE_MASTER || 'yes') === 'yes';
export const selectMaxActiveCalls = (state) => state.configs.configs.PORTAL_MAX_ACTIVE_CALLS || 2;
export const selectNavEnabledContacts = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_CONTACTS || 'yes') === 'yes';
export const selectNavEnabledCallHistory = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_CALLHISTORY || 'yes') === 'yes';
export const selectNavEnabledVoicemail = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_VOICEMAIL || 'yes') === 'yes';
export const selectNavEnabledCallPark = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_PARKS || 'yes') === 'yes';
export const selectNavEnabledCallCenter = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_CALLCENTER || 'yes') === 'yes';
export const selectNavEnabledAnsweringRules = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_ANSWERRULES || 'yes') === 'yes';
export const selectNavEnabledGreetings = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_GREETINGS || 'yes') === 'yes';
export const selectNavEnabledSettings = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_SHOW_SETTINGS || 'yes') === 'yes';
export const selectAllowQueuedPickUp = (state) => normalizeYes(state.configs.configs.PORTAL_WEB_PHONE_ALLOW_QUEUED_PICKUP || 'yes') === 'yes';
export const selectNsSocketURLs = (state) => state.configs.configs.nsSocketURLs;
export const selectRecordingAllowed = (state) => normalizeYes(state.configs.configs.PORTAL_CALL_RECORDING || 'yes') === 'yes';
export const selectShowTranscription = (state) => normalizeYes(state.configs.configs.PORTAL_USERS_VMAIL_TRANSCRIPTION_SHOW || 'yes') === 'yes';
export const selectSSO = (state) => ({
  PORTAL_LOGIN_APPLE_SSO_ENABLED: state.configs.configs.PORTAL_LOGIN_APPLE_SSO_ENABLED,
  PORTAL_LOGIN_APPLE_SSO_CLIENT_ID: state.configs.configs.PORTAL_LOGIN_APPLE_SSO_CLIENT_ID,
  PORTAL_LOGIN_GOOGLE_SSO_ENABLED: state.configs.configs.PORTAL_LOGIN_GOOGLE_SSO_ENABLED,
  PORTAL_LOGIN_GOOGLE_SSO_CLIENT_ID: state.configs.configs.PORTAL_LOGIN_GOOGLE_SSO_CLIENT_ID,
  PORTAL_LOGIN_OFFICE_SSO_ENABLED: state.configs.configs.PORTAL_LOGIN_OFFICE_SSO_ENABLED,
  PORTAL_LOGIN_OFFICE_SSO_CLIENT_ID: state.configs.configs.PORTAL_LOGIN_OFFICE_SSO_CLIENT_ID,
});
export const selectStunServers = (state) => state.configs.configs.PORTAL_WEB_PHONE_STUN_SERVERS;
export const selectThemePrimary = (state) => state.configs.configs.PORTAL_THEME_PRIMARY;
export const selectThemeSecondary = (state) => state.configs.configs.PORTAL_THEME_ACCENT;
export const selectUaSocketURLs = (state) => state.configs.configs.uaSocketURLs;
export const selectUserId = (state) => state.configs.configs.userId || state.user?.user?.userId;
export const selectVersion = (state) => state.configs.configs.version;
export const selectWebphoneName = (state) => state.configs.configs.PORTAL_WEBPHONE_NAME || 'SNAPmobile Web';

export const selectUseHeadsetRinger = (state) => normalizeYes(state.configs.configs.PORTAL_WEBPHONE_USE_HEADSET_RINGER || 'yes') === 'yes';
export const selectUserReadOnlyEmail = (state) => normalizeYes(state.configs.configs.PORTAL_USERS_READ_ONLY_EMAIL || 'no') === 'yes';

export default configsSlice.reducer;
