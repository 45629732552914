import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import { v4 as uuidv4 } from 'uuid';
import {
  call,
  // fork,
  put,
} from 'redux-saga/effects';

import { BroadcastChannel } from 'broadcast-channel';
import { RegistererState } from 'sip.js';
import { getNavigate } from '../../services/router/router';
import { REPEAT_INSTANCE } from '../../constants';
import { selectActiveCount, setActiveCount } from '../../state/callSessions/callSessionsSlice';
import { setUniqueId } from '../../state/webphoneDevice/webphoneDeviceSlice';
import store from '../../state/store';
import { getDuplicateInstanceRoute } from '../../routePaths';
import { selectRegisterer, setRegisterer, setUA } from '../../state/ua/uaSlice';

export default function* broadcastChannel() {
  const debug = false;

  if (debug) console.debug('in broadcastChannel');

  const uniqueId = uuidv4();
  yield put(setUniqueId(uniqueId));

  const navigate = yield call(getNavigate);

  const decodedToken = yield call(nsToken.getDecoded);

  // check for active call
  let activeCallRes;
  if (decodedToken) {
    activeCallRes = yield nsApi.get({
      object: 'call',
      action: 'read',
      domain: decodedToken.domain,
      user: decodedToken.user,
      format: 'json',
    });
    if (debug) console.debug('this is res active calls: ', activeCallRes);
  }

  if (debug) console.debug('in broadcastChannel decodedToken: ', decodedToken);

  const channel = new BroadcastChannel('initialBroadcast');
  const messageObject = {
    msg: 'am i alone?',
  };
  channel.postMessage(messageObject);

  // Create a Promise that resolves when the response message is received
  const waitForResponse = new Promise((resolve) => {
    channel.onmessage = (msgRec) => {
      if (msgRec.msg === 'quiet, they are listening') {
        if (msgRec.activeCallRes !== null) {
          setActiveCount(msgRec.activeCallRes.length);
        } else {
          setActiveCount(0);
        }
        // do not load here, when there is an activecall res
        resolve('DO NOT LOAD');
      }
    };
  });

  // Create a timeout Promise to resolve after a certain time (e.g., 5 seconds)
  const timeoutPromise = new Promise((resolve) => {
    setTimeout(() => {
      console.debug('Timeout: Response not received within the specified time.');
      resolve('we are alone');
    }, 500); // 500ms timeout
  });

  // Use Promise.race to wait for either the response or the timeout
  // yield Promise.race([waitForResponse, timeoutPromise]);
  const result = yield Promise.race([waitForResponse, timeoutPromise]);

  channel.onmessage = async (msgRec) => {
    if (msgRec.msg === 'am i alone?') {
      if (debug) console.debug(msgRec);

      const state = store.getState();
      const activeCount = selectActiveCount(state);

      if (activeCount !== 0) {
        const messageSend = {
          msg: 'quiet, they are listening',
          activeCallRes,
        };
        channel.postMessage(messageSend);
      } else {
        const registerer = selectRegisterer(state);

        if (registerer && registerer.state === RegistererState.Registered) {
          try {
            store.dispatch(setRegisterer(null)); // Dispatch action to update the state

            // Wait for a microtask to ensure the state update is processed
            await Promise.resolve();

            const updatedState = store.getState(); // Get the updated state
            const updatedRegisterer = selectRegisterer(updatedState);

            if (debug) console.debug('after setting registerer to null: ', updatedRegisterer);
            if (debug) console.debug('unregistering old client broadcastchannel');
            registerer.unregister();
            store.dispatch(setUA(null)); // Dispatch action to update the state
          } catch (e) {
            // Handle errors
            console.error(e);
            // bugsnagClient.notify(e);
          }
        }

        // Navigate to the duplicate instance route
        navigate(getDuplicateInstanceRoute());
      }
    } else if (msgRec.msg === 'quiet, they are listening') {
      if (debug) console.debug('do not continue loading');
    }
  };

  if (result === 'DO NOT LOAD') {
    console.debug('in do not load, rejecting promise');
    return yield Promise.reject(REPEAT_INSTANCE);
  }
  console.debug('resolving as true');

  // refresh channel
  const refreshChannel = new BroadcastChannel('refresh');

  refreshChannel.onmessage = (msgRec) => {
    if (msgRec.msg === 'reload check') {
      if (msgRec.uniqID === uniqueId) {
        if (debug) console.debug('it is same application, do nothing');
      } else {
        if (debug) console.debug('should send out that they are not alone');
        const messageSend = {
          msg: 'quiet, they are listening',
          uniqueId,
        };
        refreshChannel.postMessage(messageSend);
      }
    }
  };

  return yield Promise.resolve(true);
}
