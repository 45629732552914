import {
  select,
} from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import i18n from 'i18next';
import axios from 'axios';
import {
  selectGreetings,
} from '../../../state/greetings/greetingsSlice';

import bugsnagClient from '../../../services/bugsnag/bugsnag';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';

import store from '../../../state/store';
import { fetchGreetingsAction } from '../../fetchGreetings/action';
import { navStackBackAction } from '../../routeStacks/navStackBack/action';

import {
  selectApiURL,
} from '../../../state/configs/configsSlice';
import {
  ERROR,
  SUCCESS,
} from '../../../constants';

export default function* createGreeting({ payload }) {
  try {
    const apiURL = yield select(selectApiURL);
    const decodedToken = nsToken.getDecoded();
    const greetings = yield select(selectGreetings);

    let index;
    if (greetings.length) {
      index = (parseInt(greetings[greetings.length - 1].index, 10) + 1);
    } else {
      index = 1;
    }

    let url = apiURL;
    url += '?object=audio';
    url += '&action=upload';
    url += '&format=json';
    url += `&owner_domain=${decodedToken.domain}`;
    url += `&owner=${decodedToken.user}`;
    url += '&type=greeting';
    url += `&script=${payload.name}`;
    url += '&convert=yes';
    url += `&index=${index}`;

    const formData = new FormData();
    formData.append('file', payload.greeting);

    axios({
      method: 'post',
      url,
      data: formData,
      headers: { Authorization: `Bearer ${nsToken.get()}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200 || response.status === 202) {
          store.dispatch(fetchGreetingsAction());
          store.dispatch(navStackBackAction());
          store.dispatch(setSnackbar({
            type: SUCCESS,
            open: true,
            message: i18n.t('GREETING_SAVED'),
          }));
        } else {
          console.error('Greeting create error: ', response);
          store.dispatch(setSnackbar({
            type: ERROR,
            open: true,
            message: i18n.t('GREETING_SAVE_FAILED'),
          }));
        }
      })
      .catch((response) => {
        // handle error
        console.error(response);
      });
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
  }
}
