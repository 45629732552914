import { call, select } from 'redux-saga/effects';
import getCardId from '../../../services/getCardId/getCardId';
import addCard from '../../cardManagment/addCard/addCard';
import removeIncomingCall from '../../callSessions/removeIncomingCall/removeIncomingCall';
import { selectConfig } from '../../../state/configs/configsSlice';

export default function* acceptCall({ payload: { call: callSession } }) {
  callSession.accept();

  const cardId = getCardId({ type: 'call' });

  if (!cardId) {
    yield call(addCard, { payload: { type: 'call' } });
  }

  yield call(removeIncomingCall, { payload: callSession.id });

  const popUrl = yield select((state) => selectConfig(state, 'PORTAL_AGENT_SCREEN_POP_URL'));
  if (popUrl && callSession.number.length >= 6) {
    const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=800,height=800';
    window.open(popUrl + callSession.number, '_blank', options);
  }
}
