/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: null,
  duration: 5000,
  type: null,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.open = action.payload?.open || initialState.open;
      state.message = action.payload?.message || initialState.message;
      state.duration = action.payload?.duration || initialState.duration;
      state.type = action.payload?.type || initialState.type;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSnackbar,
} = snackbarSlice.actions;

// Selectors
export const selectSnackbar = (state) => state.snackbar;

export default snackbarSlice.reducer;
