import { call } from 'redux-saga/effects';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsDevice from '@netsapiens/netsapiens-js/dist/device';
import bugsnagClient from '../../services/bugsnag/bugsnag';

export default function* fetchAudioDevice(devicePostFix) {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    return yield call(nsDevice.get, {
      device: `sip:${decodedToken.user}${devicePostFix}@${decodedToken.domain}`,
      domain: decodedToken.domain,
      userId: decodedToken.user,
      createDevice: true,
    });
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    return false;
  }
}
