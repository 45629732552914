import {
  all, call, put, select,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectDevicePostFix } from '../../state/configs/configsSlice';
import { setAgentType, setQueues } from '../../state/agent/agentSlice';
import fetchResponders from '../fetchResponders/fetchResponders';
import socketSubscribe from '../socket/socketSubscribe/socketSubscribe';
import processAgentStatus from '../AgentCenter/processAgentStatus/processAgentStatus';

export function* handleQueue(queue) {
  yield call(socketSubscribe, {
    payload: {
      type: 'queued',
      filter: queue.queue_name,
    },
  });

  yield call(socketSubscribe, {
    payload: {
      type: 'call',
      subtype: 'queue',
      filter: queue.queue_name,
    },
  });

  yield call(fetchResponders, queue.queue_name);
}

export default function* fetchAgentDeviceQueues() {
  const decodedToken = yield call(nsToken.getDecoded);
  const devicePostFix = yield select(selectDevicePostFix);
  const agentType = `sip:${decodedToken.user}${devicePostFix}@${decodedToken.domain}`;

  try {
    const res = yield call(nsApi.get, {
      object: 'callqueue',
      action: 'read',
      agent: agentType,
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
    });

    yield put(setAgentType(agentType));
    yield put(setQueues(res));
    yield call(socketSubscribe, { payload: { type: 'agent', unit: 'seconds', filter: agentType } });
    yield all(res.map((queue) => call(handleQueue, queue)));
    yield call(processAgentStatus);
  } catch (e) {
    // todo bugsnag
  }
}
