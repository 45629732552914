import {
  all, call, put, select,
} from 'redux-saga/effects';
import crypto from 'crypto-browserify';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setUser } from '../../state/user/userSlice';
import { selectConfig } from '../../state/configs/configsSlice';
import { setGroupMmsCapableNumbers, setMmsCapableNumbers } from '../../state/chat/chatSlice';

export default function* fetchUser() {
  const decodedToken = yield call(nsToken.getDecoded);

  const userFields = [
    'domain',
    'first_name',
    'email',
    'last_name',
    'language',
    'scope',
    'subscriber_login',
    'user',
    'vmail_greeting',
    'subscriber_pin',
    'address_id',
    'address_id_source',
    'time_zone',
    'message',
  ];

  const [subscriberRes, smsRes] = yield all([
    call(nsApi.get, {
      user: decodedToken.user,
      domain: decodedToken.domain,
      object: 'subscriber',
      action: 'read',
      fields: userFields.join(','),
      format: 'json',
      includeDomain: 'yes',
    }),
    call(nsApi.get, {
      object: 'smsnumber',
      action: 'read',
      domain: decodedToken.domain,
      dest: decodedToken.user,
    }),
  ]);

  const user = subscriberRes[0];
  const sms = smsRes;

  const userObj = {
    firstName: user.first_name,
    lastName: user.last_name,
    displayName: decodedToken.displayName,
    uid: `${user.user}@${user.domain}`,
    scope: user.scope,
    email: user.email,
    userId: user.user,
    domain: user.domain,
    language: user.language,
    subscriberLogin: user.subscriber_login,
    vmailGreeting: user.vmail_greeting,
    subscriberPin: user.subscriber_pin,
    address_id: user.address_id,
    address_id_source: user.address_id_source,
    timeZone: user.time_zone,
    portal_status: user.message,
  };

  // [WP-1468] password reset token case (no displayName from api)
  if (!userObj.displayName) userObj.displayName = `${user.first_name} ${user.last_name}`;

  // set user initials
  if (userObj.displayName && userObj.displayName.trim()) {
    userObj.displayName = userObj.displayName.trim();
    const nameArr = userObj.displayName.split(' ');
    userObj.initials = nameArr[0][0].toUpperCase();
    if (nameArr.length > 1) {
      // get the last name, this skips middle initials etc.
      userObj.initials += nameArr[nameArr.length - 1][0].toUpperCase();
    }
  }

  // check for first login
  const appName = yield select((state) => selectConfig(state, 'appName'));
  userObj.onboarding = localStorage.getItem(`${appName}-${decodedToken.user}_onboarding`) || 'start-onboard';

  // set user email and gravatar
  if (user.email && user.email.trim()) {
    userObj.email = user.email.trim().toLowerCase().split(';');
    userObj.gravatar = crypto.createHash('md5').update(user.email.split(';')[0]).digest('hex');
  }

  // set user sms
  if (sms && sms.length) {
    const defaultSMS = localStorage.getItem(`${appName}-${decodedToken.user}_sms-default`);
    if (defaultSMS) {
      let found = false;
      for (let i = 0; i < sms.length; i += 1) {
        if (defaultSMS === sms[i].number) {
          sms[i].default = true;
          found = true;
        }
      }
      // handle if the sms number has been deleted from the user
      if (!found) {
        sms[0].default = true;
        localStorage.setItem(`${appName}-${decodedToken.user}_sms-default`, sms[0].number);
      }
    } else {
      sms[0].default = true;
      localStorage.setItem(`${appName}-${decodedToken.user}_sms-default`, sms[0].number);
    }
    userObj.sms = sms;

    // set group mms capable numbers
    const groupSupportedConfig = yield select((state) => selectConfig(state, 'PORTAL_MMS_GROUP_MESSAGING_SUPPORTED_CARRIERS')
      || 'inteliquent,bandwidth');

    // set attachment capable numbers
    const mmsSupportedConfig = yield select((state) => selectConfig(state, 'PORTAL_MMS_SUPPORTED_CARRIERS')
      || 'inteliquent,thinq,bandwidth,brightlink');

    let groupSupport = '';
    let mmsSupport = '';
    sms.forEach((num) => {
      const trimmedCarrier = num.carrier.toLowerCase().trim();

      console.debug('this is trimmedcarrier: ', trimmedCarrier);
      if (groupSupportedConfig) {
        const supportedCarriers = groupSupportedConfig.split(',').map((c) => c.toLowerCase().trim());
        if (supportedCarriers.includes(trimmedCarrier)) {
          groupSupport += num.number;
        }
      }

      if (mmsSupportedConfig) {
        const supportedCarriersMMS = mmsSupportedConfig.split(',').map((c) => c.toLowerCase().trim());
        if (supportedCarriersMMS.includes(trimmedCarrier)) {
          mmsSupport += num.number;
        }
      }
    });

    yield put(setGroupMmsCapableNumbers(groupSupport));
    yield put(setMmsCapableNumbers(mmsSupport));
  }

  yield put(setUser(userObj));
  return userObj;
}
