export default (val) => {
  switch ((val || '').toLowerCase()) {
    case 'yes':
    case 'y':
    case 'true':
    case '1':
      return 'yes';
    default:
      return 'no';
  }
};
