import { call, put, select } from 'redux-saga/effects';
import { selectActiveCount } from '../../../state/callSessions/callSessionsSlice';
import { selectDevicePostFix, selectMaxActiveCalls } from '../../../state/configs/configsSlice';
import matchContact from '../../../utils/matchContact';
import acceptCall from '../acceptCall/acceptCall';
import addCallSession from '../../callSessions/addCallSession/addCallSession';
import {
  addCall,
  addIncomingCall,
  getHidState,
} from '../../../utils/devices';
import { selectIncomingCalls, setIncomingCalls } from '../../../state/ua/uaSlice';
import CallSession from '../../../models/CallSession';
import { selectHidDevice } from '../../../state/userMedia/userMediaSlice';

export default function* onInvite({ payload: invitation }) {
  const activeCount = yield select(selectActiveCount);
  const maxActiveCalls = yield select(selectMaxActiveCalls);

  // [WP-677] limit calls
  if (activeCount >= maxActiveCalls) {
    return;
  }

  // An Invitation is a Session
  const incomingSession = invitation;

  // get the user or phone number
  const devicePostFix = yield select(selectDevicePostFix);
  const userId = incomingSession.remoteIdentity.uri.user.replace(devicePostFix, '');

  const callSession = new CallSession({
    direction: 'inbound',
    id: incomingSession.id,
    isMuted: false,
    number: userId,
    name: incomingSession.remoteIdentity.displayName || null,
    session: incomingSession,
    status: 'inboundProgressing',
    userId,
    from_tag: incomingSession.fromTag || incomingSession.request.fromTag, // WP-781, WP-1088
  });

  const contact = yield call(matchContact, userId);
  const isConferenceInbound = incomingSession.remoteIdentity.uri.user.includes('adhoc');

  if (contact) {
    callSession.contact = contact;
    // [WP-1212] only replace with the contact name if it is not an attendant prefix situation
    // ie session.name!=contact.name && session.name.endsWith(contact.name)
    if (callSession.name === callSession.contact?.name
        || !callSession.name?.endsWith(callSession.contact?.name)) {
      callSession.name = callSession.contact.name;
    }
  } else if (isConferenceInbound) {
    callSession.name = 'Multiple Callers';
    callSession.auto_ans = true;
    callSession.isConference = isConferenceInbound;

    yield call(acceptCall, { payload: { call: callSession } });
    yield call(addCallSession, { payload: callSession });
    yield addCall(); // notify hid device
    return;
  }

  // add session to sessions array
  yield call(addCallSession, { payload: callSession });

  // dispatch incoming call to trigger modal
  const incomingCalls = yield select(selectIncomingCalls);
  yield put(setIncomingCalls([...incomingCalls, callSession]));

  // signal webhid device of incoming call
  try {
    // when call control is not configured update the hid state anyways
    addIncomingCall(callSession.id);
    if (!getHidState().answeredCallSessions.length) {
      const callControl = yield select(selectHidDevice);
      if (callControl) {
        yield callControl.signalIncomingCall();
      }
    }
  } catch (e) {
    console.error(e);
  }
}
