import { createAction } from '@reduxjs/toolkit';

export const FETCH_AGENT_KPI_ACTION_TYPE = 'saga/fetchAgentKPI';
export const fetchAgentKPIAction = createAction(FETCH_AGENT_KPI_ACTION_TYPE);

export const FETCH_AGENT_SUM_DAY_ACTION_TYPE = 'saga/fetchSumByDay';
export const fetchAgentSumByDayAction = createAction(FETCH_AGENT_SUM_DAY_ACTION_TYPE);

export const FETCH_AGENT_SUM_HOUR_ACTION_TYPE = 'saga/fetchSumByHour';
export const fetchAgentSumByHourAction = createAction(FETCH_AGENT_SUM_HOUR_ACTION_TYPE);

export const FETCH_AGENT_SUM_USER_ACTION_TYPE = 'saga/fetchSumByUser';
export const fetchAgentSumByUserAction = createAction(FETCH_AGENT_SUM_USER_ACTION_TYPE);
