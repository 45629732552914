/* eslint-disable no-console */
import {
  call, delay, fork, put, select, take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import crypto from 'crypto-browserify';

import { selectIsConnected, selectSocket } from '../../../state/socket/socketSlice';
import updateResponder from '../../updateResponder/updateResponder';
import {
  selectAgentType, setPortalStatus, updateQueueAgent,
} from '../../../state/agent/agentSlice';
import addMessage from '../../messages/addMessage/addMessage';
import updateAgentPortalStatus from '../../AgentCenter/updateAgentPortalStatus';
import {
  selectConfig, selectDevicePostFix, selectUserId, selectWebphoneName,
} from '../../../state/configs/configsSlice';
import { removeParked, selectCallParkIds, setParked } from '../../../state/callPark/callParkSlice';
import processAgentStatus from '../../AgentCenter/processAgentStatus/processAgentStatus';
import processCallPark from '../../processCallPark/processCallPark';
import fetchAgentQueues from '../../fetchAgentQueues/fetchAgentQueues';
import { selectCallSessions, setCallSessions } from '../../../state/callSessions/callSessionsSlice';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import matchContact from '../../../utils/matchContact';
import fetchCallHistory from '../../callHistory/fetchCallHistory/fetchCallHistory';
import fetchVoicemail from '../../voicemail/fetchVoicemail/fetchVoicemail';
import { selectUser, selectUserLanguage } from '../../../state/user/userSlice';
import en_us from '../../../translations/en.json'; // eslint-disable-line camelcase
import en_tt from '../../../translations/en_tt.json'; // eslint-disable-line camelcase
import es_mx from '../../../translations/es.json'; // eslint-disable-line camelcase
import fr_ca from '../../../translations/fr.json'; // eslint-disable-line camelcase
import removeQueueWaiting from '../../AgentCenter/removeQueueWaiting';
import updateQueueWaiting from '../../AgentCenter/updateQueueWaiting';
import formatPhoneNumber from '../../../utils/formatPhoneNumber'; // eslint-disable-line camelcase
import { setUserPresence } from '../../../state/presence/presenceSlice';
import updateQueueSessionCount from '../../AgentCenter/updateQueueSessionCount';
import pushConferenceList from '../conferenceList/pushConferenceList';
import popConferenceList from '../conferenceList/popConferenceList';
import { selectDomainUserList } from '../../../state/contacts/contactsSlice';
import updateQueueResponders from '../../AgentCenter/updateQueueResponders';
import updateWaitingPickup from '../../AgentCenter/updateWaitingPickup';
import updateContacDetails from '../../contacts/updateContactDetails/updateContactDetails';

const SOCKET_MESSAGE_AGENT = 'SOCKET_MESSAGE_AGENT';
const SOCKET_MESSAGE_AGENT_DEVICE = 'SOCKET_MESSAGE_AGENT_DEVICE';
const SOCKET_MESSAGE_CALL = 'SOCKET_MESSAGE_CALL';
const SOCKET_MESSAGE_CALL_QUEUE = 'SOCKET_MESSAGE_CALL_QUEUE';
const SOCKET_MESSAGE_CHAT_AVAILABLE = 'SOCKET_MESSAGE_CHAT_AVAILABLE';
const SOCKET_MESSAGE_CHAT_LEAVE = 'SOCKET_MESSAGE_CHAT_LEAVE';
const SOCKET_MESSAGE_CONTACTS_DOMAIN = 'SOCKET_MESSAGE_CONTACTS_DOMAIN';
const SOCKET_MESSAGE_UC_MESSAGE = 'SOCKET_MESSAGE_UC_MESSAGE';
const SOCKET_MESSAGE_QUEUE = 'SOCKET_MESSAGE_QUEUE';
const SOCKET_MESSAGE_QUEUED = 'SOCKET_MESSAGE_QUEUED';
const SOCKET_MESSAGE_VOICEMAIL = 'SOCKET_MESSAGE_VOICEMAIL';
const SOCKET_MESSAGE_CALL_UNMUTE = 'SOCKET_MESSAGE_CALL_UNMUTE';

export default function* socketListeners() {
  let isConnected = yield select(selectIsConnected);

  while (!isConnected) {
    yield delay(50);
    isConnected = yield select(selectIsConnected);
  }

  const appTitle = yield select(selectWebphoneName);
  const confBridgeBuffer = {};
  let callIds = [];
  let interval;
  let titleIndex = 0;
  let titlesArray;
  const aLanguage = [en_tt, en_us, es_mx, fr_ca]; // eslint-disable-line camelcase

  function changeTitle() {
    if (titlesArray.length === 0 || callIds.length === 0) {
      document.title = appTitle;
    } else {
      titleIndex = (titleIndex + 1) % titlesArray.length;
      document.title = titlesArray[titleIndex];
    }
  }

  function findTransferSession(sessions, data) {
    let i = 0;
    while (
      i < sessions.length
      && !(sessions[i].callId === data.by_callid
      || sessions[i].userId.includes(data.by_sub))) {
      i += 1;
    }
    if (i >= sessions.length) { i = 0; }
    return sessions[i];
  }

  function* delayResetPortalStatus() {
    yield delay(3000);
    yield put(setPortalStatus(''));
  }

  function* delayFetchCallHistory() {
    yield delay(2000);
    yield call(fetchCallHistory);
  }

  function* forkProcessParkResults() {
    yield call(processCallPark);
  }

  const socket = yield select(selectSocket);

  const channel = yield eventChannel((emitter) => {
    socket.on('agent', (data) => {
      emitter({ type: SOCKET_MESSAGE_AGENT, data });
    });

    socket.on('agent-device', (data) => {
      emitter({ type: SOCKET_MESSAGE_AGENT_DEVICE, data });
    });

    socket.on('call', (data) => {
      emitter({ type: SOCKET_MESSAGE_CALL, data });
    });

    socket.on('call-queue', (data) => {
      emitter({ type: SOCKET_MESSAGE_CALL_QUEUE, data });
    });

    socket.on('chatavailable', (data) => {
      emitter({ type: SOCKET_MESSAGE_CHAT_AVAILABLE, data });
    });

    socket.on('chat_leave', (data) => {
      emitter({ type: SOCKET_MESSAGE_CHAT_LEAVE, data });
    });

    socket.on('contacts-domain', (data) => {
      emitter({ type: SOCKET_MESSAGE_CONTACTS_DOMAIN, data });
    });

    const messageQueue = [];
    let isProcessing = false;

    const processMessageQueue = () => {
      const debug = localStorage.getItem('debugFlag') === 'true';
      if (messageQueue.length > 0) {
        isProcessing = true;
        const data = messageQueue.shift();
        if (debug) console.debug('emitting data: ', data);
        emitter({ type: SOCKET_MESSAGE_UC_MESSAGE, data });

        // After processing, continue with the next message
        processMessageQueue();
      } else {
        isProcessing = false;
      }
    };

    socket.on('uc-mesg', (data) => {
      const debug = localStorage.getItem('debugFlag') === 'true';

      if (debug) console.debug('uc-mesg data: ', data);
      messageQueue.push(data);

      if (!isProcessing) {
        processMessageQueue();
      }
    });

    socket.on('queue', (data) => {
      emitter({ type: SOCKET_MESSAGE_QUEUE, data });
    });

    socket.on('queued', (data) => {
      emitter({ type: SOCKET_MESSAGE_QUEUED, data });
    });

    socket.on('voicemail', (data) => {
      emitter({ type: SOCKET_MESSAGE_VOICEMAIL, data });
    });

    socket.on('call_unmute', (data) => {
      emitter({ type: SOCKET_MESSAGE_CALL_UNMUTE, data });
    });

    return () => { };
  });

  // handle channel event
  while (true) {
    const res = yield take(channel);

    try {
      switch (res.type) {
        case SOCKET_MESSAGE_AGENT: {
          yield call(updateResponder, { payload: res.data });
          const agentType = yield select(selectAgentType);
          if (res.data?.device_aor === agentType) {
            yield put(updateQueueAgent(res.data));
            const status = yield call(processAgentStatus);
            if (status === 'Online' || status === 'singlecall') {
              yield fork(delayResetPortalStatus);
            }
          }
          break;
        }
        case SOCKET_MESSAGE_AGENT_DEVICE: {
          if (res.data?.remove || res.data?.create) {
            console.debug('Remove or New Agent Device');
            yield fork(fetchAgentQueues);
          }
          const agentType = yield select(selectAgentType);
          if (res.data?.device_aor === agentType) {
            yield put(updateQueueAgent(res.data));
          }
          break;
        }
        case SOCKET_MESSAGE_CALL: {
          console.debug('handle call socket message -----------------------');
          let sessions = yield select(selectCallSessions);
          const user = yield select(selectUser);
          const userId = yield select(selectUserId);

          // [WP-1479] check to see if dialed number is call queue, autoattendant, or conference
          const domainUserList = yield select(selectDomainUserList);
          for (let i = 0; i < sessions.length; i += 1) {
            if (sessions[i].name === null && sessions[i].id.includes(res.data.orig_callid)) {
              sessions[i].name = domainUserList?.find((domainUser) => domainUser.user === res.data.term_to_uri
              || domainUser.user === `${res.data.term_sub}@${res.data.term_domain}`)?.name;
            }
          }

          // ignore, the socket updates twice for some reason when a call has ended
          // the call has been removed already the first time it was called
          if (res.data.dnis && res.data.dnis.includes('adhoc.monitor.')) {
            const cacheId = res.data.dnis.replace('X', '');
            if (!confBridgeBuffer[cacheId]) confBridgeBuffer[cacheId] = [];
            const contact = matchContact(res.data.orig_from_user);
            if (contact && contact.name) {
              confBridgeBuffer[cacheId].push(contact.name);
            } else if (res.data.orig_from_user) {
              confBridgeBuffer[cacheId].push(formatPhoneNumber(res.data.orig_from_user));
            }
          }

          const callParkIds = yield select(selectCallParkIds);
          const prefix = yield select((state) => selectConfig(state, 'portalTransferPrefixPark'));
          const stripPark = res.data.dnis.replace('X', '').replace(`${prefix}`, '');
          if (res.data.term_uri === 'Call-Queue'
          && (callParkIds.includes(stripPark) || callParkIds.includes(res.data.term_user))) {
            if (res.data.remove && res.data.remove === 'yes') {
              yield put(removeParked(res.data));
            } else {
              yield put(setParked(res.data));
            }
            yield fork(forkProcessParkResults);
            break;
          }

          // const queueEntities = yield select(selectQueuesEntities);
          // if (queueEntities[res.data.dnis] && res.data.remove === 'yes') {
          //   console.log('remove Queued', res.data.dnis, res.data.orig_callid);
          //   yield call(removeQueueWaiting, {
          //     payload: { queue_name: res.data.dnis, session_key: res.data.orig_callid },
          //   });
          //   break;
          // }

          if (!callIds.includes(res.data.orig_callid) && res.data.remove === 'yes') {
            break;
          }

          if (res.data.by_action === 'XferSup'
            && (res.data.dnis === `X${user.userId}` || res.data.ani.includes(user.userId))
          ) {
            const xferId = res.data.ani.includes(user.userId) ? res.data.dnis : res.data.ani;

            const contact = matchContact(xferId);
            const matchedSession = findTransferSession(sessions, res.data);
            if (matchedSession) {
              matchedSession.updateContactToTransfered(xferId, contact);
              yield put(setCallSessions([...sessions]));
            }
          }

          // check if the call has already been added
          if (callIds.includes(res.data.orig_callid)) {
            sessions = yield select(selectCallSessions);

            console.debug('user', res.data.orig_from_user);
            console.debug('remove', res.data.remove);

            // check if the call is being removed
            if (res.data.remove === 'yes') {
              if (sessions.length) {
                for (let i = 0; i < sessions.length; i += 1) {
                  console.debug('pop from list', sessions[i].isConference);
                  if (sessions[i].isConference) {
                    yield call(popConferenceList, sessions[i], res.data);
                  }
                }
              }

              // remove the id from the array
              const updatedArr = [];
              for (let i = 0; i < callIds.length; i += 1) {
                if (callIds[i] !== res.data.orig_id && callIds[i] !== res.data.orig_callid) {
                  updatedArr.push(callIds[i]);
                }
              }
              // reset the callIds array with the updated array
              callIds = updatedArr;

              // if there are no more calls, clear the interval and set the title
              if (callIds.length === 0) {
                clearInterval(interval);
                interval = null;
                document.title = appTitle;
                titlesArray = [appTitle];
              }

              yield fork(delayFetchCallHistory);
            } else {
              sessions = yield select(selectCallSessions);
              let updateSessions = false;
              if (sessions.length) {
                for (let i = 0; i < sessions.length; i += 1) {
                  console.debug('session', sessions[i]);
                  if (sessions[i].isConference) {
                    console.debug('push 1', sessions[i].isConference);
                    yield call(pushConferenceList, sessions[i], res.data);
                  } else if (sessions[i].name
                    && res.data.dnis
                    && sessions[i].name.includes(res.data.dnis.replace('X', ''))
                    && confBridgeBuffer[sessions[i].name]
                  ) {
                    console.debug('push 2', sessions[i].isConference);
                    for (let index = 0; index < confBridgeBuffer[sessions[i].name].length; index += 1) {
                      yield call(pushConferenceList, sessions[i], confBridgeBuffer[sessions[i].name][index]);
                    }
                  } else if ((res.data.orig_user !== userId && res.data.term_user !== userId && res.data.orig_to_user !== userId)) {
                    if (callParkIds.includes(res.data.orig_user)) {
                      sessions[i].parkId = res.data.term_callid;
                      updateSessions = true;
                      console.debug('Update parkId ->', sessions[i].parkId);
                    }
                  } else {
                    sessions[i].parkId = res.data.orig_user === userId ? res.data.term_callid : res.data.orig_callid;
                    updateSessions = true;
                    console.debug('Update parkId ->', sessions[i].parkId);
                  }
                }
                if (updateSessions) {
                  yield put(setCallSessions([...sessions]));
                }
              }
            }

            break;
          }

          // reset the title array, preps for the update language string
          titlesArray = [appTitle];

          // only start a new interval if one isn't running already
          // this happens when there are multiple inbound calls
          if (!interval) {
            interval = setInterval(changeTitle, 1300);
          }

          // update the sessions callId
          const devicePostfix = yield select(selectDevicePostFix);
          sessions = yield select(selectCallSessions);

          if (sessions.length) {
            let updateSessions = false;
            for (let i = 0; i < sessions.length; i += 1) {
              if (sessions[i].isConference) {
                console.debug('push 3', sessions[i].isConference);
                yield call(pushConferenceList, sessions[i], res.data);
              } else if (res.data.orig_call_info === 'progressing'
                && (res.data.ani === sessions[i].userId
                  || res.data.ani === `${sessions[i].userId}${devicePostfix}`
                  || res.data.ani === sessions[i].number || res.data.ani === 'Call-To-Talk'
                  || res.data.ani === 'Call-Record-Account')
              ) {
                sessions[i].callId = res.data.orig_callid;
                sessions[i].callerId = res.data.orig_name;
                sessions[i].display = true;
                updateSessions = true;
              } else if (
                sessions[i].name
                && res.data.dnis
                && sessions[i].name.includes(res.data.dnis.replace('X', ''))
              ) {
                if (confBridgeBuffer[sessions[i].name]) {
                  for (let index = 0; index < confBridgeBuffer[sessions[i].name].length; index += 1) {
                    console.debug('push 4', sessions[i].isConference);
                    const d = confBridgeBuffer[sessions[i].name][index];
                    yield call(pushConferenceList, sessions[i], d);
                  }
                  sessions[i].display = true;
                  updateSessions = true;
                }
              } else if ((res.data.orig_user !== userId && res.data.term_user !== userId && res.data.orig_to_user !== userId)) {
                if (callParkIds.includes(res.data.orig_user)) {
                  sessions[i].parkId = res.data.term_callid;
                  updateSessions = true;
                  console.debug('Update parkId ->', sessions[i].parkId);
                }
              } else {
                sessions[i].parkId = res.data.orig_user === userId ? res.data.term_callid : res.data.orig_callid;
                updateSessions = true;
                console.debug('Update parkId ->', sessions[i].parkId);
              }
            }

            if (updateSessions) {
              yield put(setCallSessions([...sessions]));
            }

            // keep track of the number, so it doesn't get added again
            callIds.push(res.data.orig_callid);

            // grab user language if not default to en_us
            // it may have changed since the last time this function has run
            const userLanguage = yield select(selectUserLanguage);
            for (let i = 0; i < aLanguage.length; i += 1) {
              if (aLanguage[i].name === userLanguage) {
                if (aLanguage[i].ON_A_CALL) {
                  titlesArray.push(aLanguage[i].ON_A_CALL);
                }
              }
            }
          }

          break;
        }
        case SOCKET_MESSAGE_CALL_QUEUE: {
          console.debug('handle call-queue socket message -----------------------');

          // WP-1707 handle queued for park queues
          const callParkIds = yield select(selectCallParkIds);
          if (res.data.orig_call_info === 'held'
            && (callParkIds.includes(res.data.by_sub) || callParkIds.includes(res.data.term_sub))
          ) {
            if (res.data.remove && res.data.remove === 'yes') {
              yield put(removeParked(res.data));
            } else {
              yield put(setParked(res.data));
            }
            yield fork(forkProcessParkResults);
          } else if (res.data.by_action === 'QueueSDispatch' || res.data.by_action === 'NoAnswer') {
            yield call(updateWaitingPickup, {
              payload: {
                session_key: res.data.orig_callid,
                pickup: res.data.by_action !== 'QueueSDispatch',
                queue_name: res.data.by_sub,
              },
            });
          }
          break;
        }
        case SOCKET_MESSAGE_CHAT_AVAILABLE: {
          if (res?.data.length) {
            for (let i = 0; i < res.data.length; i += 1) {
              yield put(setUserPresence({ user: res.data[i], chatPresence: true }));
            }
          }
          break;
        }
        case SOCKET_MESSAGE_CHAT_LEAVE: {
          yield put(setUserPresence({ user: res.data, chatPresence: false }));
          break;
        }
        case SOCKET_MESSAGE_CONTACTS_DOMAIN: {
          const userId = yield select(selectUserId);
          if (res.data?.user === userId) {
            yield call(updateAgentPortalStatus, { payload: res.data });
          }
          yield put(setUserPresence({ user: res.data.user, presence: res.data.presence }));
          yield call(updateContacDetails, {
            payload: {
              subscriber_login: res.data.subscriber_login,
              params: { presence: res.data.presence },
            },
          });
          break;
        }
        case SOCKET_MESSAGE_UC_MESSAGE: {
          try {
            const debug = localStorage.getItem('debugFlag') === 'true';

            if (debug) console.debug('this is res.data: ', res.data);
            // get remote path for media
            if (res?.data?.type === 'chat-media' || res?.data?.type === 'mms') {
              if (debug) console.debug('this is received media message: ', res.data);
              // need to get remotepath/hash for incoming chat-media messages
              const user = yield select(selectUser);

              let remotepath = `https://${res.data.rx_hostname}/ns-api/?object=message&action=read_media`;
              remotepath += `&domain=${user.domain}`;
              remotepath += `&user=${user.userId}`;
              remotepath += `&id=${res.data.id}`;

              // get date in YmdHis in UTC
              const date = new Date();
              date.setMonth(date.getMonth() + 1); // set to one month else might expire

              const year = date.getUTCFullYear();
              const month = (date.getUTCMonth() < 10 ? '0' : '') + date.getUTCMonth();
              const day = (date.getUTCDate() < 10 ? '0' : '') + date.getUTCDate();
              const hour = (date.getUTCHours() < 10 ? '0' : '') + date.getUTCHours();
              const minute = (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes();
              const second = (date.getUTCSeconds() < 10 ? '0' : '') + date.getUTCSeconds();
              const timestamp = `${year}${month}${day}${hour}${minute}${second}`;
              remotepath += `&time=${timestamp}`;

              const toHash = `${user.domain}${user.userId}${res.data.id}${timestamp}${user.subscriberPin}`;

              const auth = crypto.createHash('md5').update(toHash).digest('hex');
              remotepath += `&auth=${auth}`;

              res.data.remotepath = remotepath;
            }

            const msgToAdd = {
              direction: 'orig',
              id: res.data.id,
              type: res.data.type,
              from_num: res.data.from_num ? res.data.from_num : null,
              from_uid: res.data.from_uid ? res.data.from_uid : null,
              text: (res.data.type === 'chat-media' || res.data.type === 'mms')
                ? 'media' : res.data.text,
              status: 'sending',
              session_id: res.data.session_id,
              timeStampSent: res.data.timestamp,
            };

            if (res?.data?.direction) {
              msgToAdd.direction = res.data.direction;
            }

            if (res?.data?.remotepath) {
              msgToAdd.remotepath = res.data.remotepath;
            }
            if (debug) console.debug('adding message: ', msgToAdd);
            // TODO need to also check whether it is from an sms num the user owns ?
            yield addMessage(msgToAdd);
          } catch (e) {
            console.debug('socket message error: ', e);
          }
          break;
        }
        case SOCKET_MESSAGE_QUEUED: {
          console.debug('handle queued socket message -----------------------');
          if (res.data?.remove === 'yes') {
            yield call(removeQueueWaiting, { payload: res.data });
          } else {
            yield call(updateQueueWaiting, { payload: res.data });
          }
          break;
        }
        case SOCKET_MESSAGE_QUEUE: {
          if (res.data?.gau_queue_length !== undefined || res.data?.gau_agent_available !== undefined) {
            yield call(updateQueueSessionCount, { payload: res.data });
          }
          if (res.data?.gau_agent_logged_in !== undefined) {
            yield fork(updateQueueResponders, { payload: res.data });
          }
          break;
        }
        case SOCKET_MESSAGE_VOICEMAIL: {
          // [WP-1632] functional but not elegant, re-fetch voicemail on any voicemail event
          yield call(fetchVoicemail);
          break;
        }
        case SOCKET_MESSAGE_CALL_UNMUTE: {
          console.log('SOCKET_MESSAGE_CALL_UNMUTE');
          const sessions = yield select(selectCallSessions);
          if (callIds.includes(res.data.callid)) {
            if (sessions.length) {
              console.log('sessions.length');
              for (let i = 0; i < sessions.length; i += 1) {
                console.log('i', i);
                console.log('sessions[i].callId', sessions[i].callId);
                if (sessions[i].callId === res.data.callid || sessions[i].id.includes(res.data.callid)) {
                  console.log('sessions[i].callId === res.data.callid || sessions[i].id.includes(res.data.callid)');
                  try {
                    sessions[i].unmute();
                  } catch (e) {
                    console.error(e);
                  }
                  try {
                    sessions[i].unhold();
                  } catch (e) {
                    console.error(e);
                  }
                }
              }
            }
          }
          break;
        }

        default:
      }
    } catch (e) {
      console.error(e);
      bugsnagClient.notify(e);
    }
  }
}
