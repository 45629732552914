import {
  call, put, select,
} from 'redux-saga/effects';
import { selectContacts, setInCallFilteredBy } from '../../../state/contacts/contactsSlice';
import processInCallResultSet from '../processInCallResultSet/processInCallResultSet';
import processInCallSearchResultSet from '../processInCallSearchResultSet/processInCallSearchResultSet';

export default function* inCallContactsFilteredBy({ payload: filterBy }) {
  yield put(setInCallFilteredBy(filterBy));

  const contacts = yield select(selectContacts);

  if (contacts) {
    yield call(processInCallResultSet);
    yield call(processInCallSearchResultSet);
  }
}
