import _ from 'lodash';
import naturalSort from 'javascript-natural-sort';

export default function mapDevicesToRules(rules, endpoints) {
  rules = _.compact(rules); // eslint-disable-line no-param-reassign

  if (!_.size(rules)) {
    return [];
  }

  return rules.map((ar) => {
    const answeringRule = { ...ar };
    if (answeringRule.sim_control === 'e'
    && answeringRule.sim_parameters
    && answeringRule.sim_parameters.indexOf('<OwnDevices>') !== -1
    ) {
      answeringRule.devices = _.compact(endpoints);

      // remove conference bridge device
      answeringRule.devices = _.filter(
        answeringRule.devices,
        (device) => device.aor && device.aor.indexOf('conference-bridge') === -1 && device.aor.indexOf('vb') === -1,
      );

      // format number
      for (let i = 0; i < answeringRule.devices.length; i += 1) {
        answeringRule.devices[i].aor = answeringRule.devices[i].aor.replace('sip:', '');
        answeringRule.devices[i].aor = answeringRule.devices[i].aor.replace(`@${answeringRule.domain}`, '');
      }

      answeringRule.sim_delay = {};
      const simParams = answeringRule.sim_parameters.split(' ');

      for (let idx = 0; idx < simParams.length; idx += 1) {
        if (simParams[idx].indexOf(';delay=') !== -1) {
          const tmp = simParams[idx].split(';delay=');
          if (!answeringRule.sim_delay[tmp[1]]) {
            answeringRule.sim_delay[tmp[1]] = {
              confirm: tmp[0].indexOf('confirm_') !== -1,
              numbers: [tmp[0].replace('confirm_', '')],
            };
          } else {
            answeringRule.sim_delay[tmp[1]].numbers.push(tmp[0].replace('confirm_', ''));
          }
        } else {
          let push = false;
          for (let d = 0; d < answeringRule.devices.length; d += 1) {
            if (simParams[idx] === '<OwnDevices>'
                              || answeringRule.devices[d].aor === simParams[idx]
            ) {
              break;
            }
            push = true;
          }
          if (push) {
            answeringRule.devices.push({
              aor: simParams[idx].replace('confirm_', ''),
            });
          }
        }
      }

      if (_.isEmpty(answeringRule.sim_delay)) {
        answeringRule.sim_delay = null;
      }

      answeringRule.devices = answeringRule.devices.sort((a, b) => naturalSort(a.aor, b.aor));
    }
    return answeringRule;
  });
}
