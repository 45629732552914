/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const callParkList = nsUtils.normalizeList('queue_name', []);
const parkedList = nsUtils.normalizeList('orig_callid', []);

export const callParkSlice = createSlice({
  name: 'callPark',
  initialState: {
    loading: true,
    filteredBy: 'All',
    allCallParks: [],
    callParkIds: callParkList.ids,
    callParkEntities: callParkList.entities,
    callParkResultSet: [],
    parked: [],
    parkedIds: parkedList.ids,
    parkedEntities: parkedList.entities,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilteredBy: (state, action) => {
      state.filteredBy = action.payload;
    },
    setCallPark: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('queue_name', action.payload);
      state.allCallParks = action.payload;
      state.callParkIds = ids;
      state.callParkEntities = entities;
    },
    setCallParkResultSet: (state, action) => {
      state.callParkResultSet = action.payload;
    },
    setParked: (state, action) => {
      let updatedParked;

      if (state.parkedEntities[action.payload.orig_callid]) {
        updatedParked = state.parked.map((parked) => (parked.orig_callid === action.payload.orig_callid
          ? action.payload
          : parked));
      } else {
        updatedParked = [
          ...state.parked,
          action.payload,
        ];
      }

      const { entities, ids } = nsUtils.normalizeList('orig_callid', updatedParked);
      state.parked = updatedParked;
      state.parkedIds = ids;
      state.parkedEntities = entities;
    },
    removeParked: (state, action) => {
      const updatedParked = state.parked.filter((waiter) => waiter.orig_callid !== action.payload.orig_callid);

      const { entities, ids } = nsUtils.normalizeList('orig_callid', updatedParked);
      state.parked = updatedParked;
      state.parkedIds = ids;
      state.parkedEntities = entities;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading, setFilteredBy, setCallPark, setCallParkResultSet, setParked, removeParked,
} = callParkSlice.actions;

// Selectors
export const selectLoading = (state) => state.callPark.loading;
export const selectFilteredBy = (state) => state.callPark.filteredBy;
export const selectAllCallParks = (state) => state.callPark.allCallParks;
export const selectCallParkIds = (state) => state.callPark.callParkIds;
export const selectCallParkEntities = (state) => state.callPark.callParkEntities;
export const selectCallParkbById = (state, id) => state.callPark.callParkEntities[id] || null;
export const selectCallParkResultSet = (state) => state.callPark.callParkResultSet;
export const selectParked = (state) => state.callPark.parked;
export const selectParkedIds = (state) => state.callPark.parkedIds;
export const selectParkedEntities = (state) => state.callPark.parkedEntities;

export default callParkSlice.reducer;
