import { call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import i18n from 'i18next';
import bugsnagClient from '../../../services/bugsnag/bugsnag';
import fetchVoicemail from '../fetchVoicemail/fetchVoicemail';
import { setSnackbar } from '../../../state/snackbar/snackbarSlice';

export default function* forwardVoicemail({ payload }) {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    yield call(nsApi.post, {
      action: 'update',
      format: 'json',
      object: 'audio',
      intArray: true,
      owner_domain: decodedToken.domain,
      index: `${payload.voicemail.index}.wav`,
      type: payload.voicemail.vmail_type,
      forward: payload.contactId,
      owner: decodedToken.user,
    });

    yield call(fetchVoicemail);

    yield put(setSnackbar({
      open: true,
      message: i18n.t('VOICEMAIL_FORWARDED'),
    }));
  } catch (e) {
    console.error(e);
    bugsnagClient.notify(e);
    yield put(setSnackbar({
      open: true,
      message: i18n.t('VOICEMAIL_FORWARD_FAILED'),
    }));
  }
}
