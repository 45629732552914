/* eslint-disable camelcase */
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { selectQueues, setQueues } from '../../state/agent/agentSlice';
import fetchResponders from '../fetchResponders/fetchResponders';

export default function* updateQueueResponders({ payload }) {
  let queues = yield select(selectQueues);
  queues = _.cloneDeep(queues);
  const queue = _.find(queues, { queue_name: payload.user });
  if (queue !== undefined) {
    if (Number(payload.gau_agent_logged_in) !== queue.agent_count) {
      yield call(fetchResponders, queue.queue_name);

      queue.agent_count = payload.gau_agent_logged_in;
      const upQueues = queues.map((q) => (q.queue_name === payload.user ? queue : q));
      yield put(setQueues(upQueues));
    }
  }
}
