/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const uaSlice = createSlice({
  name: 'ua',
  initialState: {
    UA: null,
    registerer: null,
    state: 'init',
    incomingCalls: [],
    ts: new Date().getTime(),
    attemptingReconnect: false,
    reconnectionAttempt: 0,
    oneSameServerReconnect: true,
  },
  reducers: {
    setUA: (state, action) => {
      state.UA = action.payload;
    },
    setRegisterer: (state, action) => {
      state.registerer = action.payload;
    },
    setUAState: (state, action) => {
      state.state = action.payload;
    },
    setIncomingCalls: (state, action) => {
      state.incomingCalls = action.payload;
    },
    setTS: (state, action) => {
      state.ts = action.payload;
    },
    // Used to guard against overlapping reconnection attempts
    setAttemptReconnect: (state, action) => {
      state.attemptingReconnect = action.payload;
    },
    incReconnectionAttempt: (state) => {
      state.reconnectionAttempt += 1;
    },
    setOneSameServerReconnect: (state, action) => {
      state.oneSameServerReconnect = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUA, setRegisterer, setUAState, setIncomingCalls, setTS, setAttemptReconnect, incReconnectionAttempt, setOneSameServerReconnect,
} = uaSlice.actions;

// Selectors
export const selectUA = (state) => state.ua.UA;
export const selectRegisterer = (state) => state.ua.registerer;
export const selectUAState = (state) => state.ua.state;
export const selectIncomingCalls = (state) => state.ua.incomingCalls;
export const selectTS = (state) => state.ua.ts;
export const selectAttemptingReconnect = (state) => state.ua.attemptingReconnect;
export const selectReconnectionAttempt = (state) => state.ua.reconnectionAttempt;
export const selectOneSameServerReconnect = (state) => state.ua.oneSameServerReconnect;

export default uaSlice.reducer;
