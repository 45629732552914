import { call, select } from 'redux-saga/effects';
import nsConfig from '@netsapiens/netsapiens-js/dist/config';

import {
  selectApiURL, selectAppName, selectPortalURL, selectUserId,
} from '../../state/configs/configsSlice';

export default function* configureSDK() {
  const appName = yield select(selectAppName);
  const apiURL = yield select(selectApiURL);
  const portalURL = yield select(selectPortalURL);
  const userId = yield select(selectUserId);

  const sdkConfig = {
    appName,
    apiURL,
    portalURL,
    tokenStorageUserId: true,
  };

  if (userId) {
    sdkConfig.userId = userId;
  }

  yield call(nsConfig.configure, sdkConfig);
}
