import _ from 'lodash';
import store from '../state/store';

import {
  selectContactsEntities,
  selectContactsIds,
  selectContactsNames,
  selectContactsPhones,
  selectContactsUsers,
} from '../state/contacts/contactsSlice';
/**
 * Matches string by name, user, uid or phone number
 * @param str
 * @returns {null|*}
 */
export default function matchContact(str) {
  if (!str) {
    return null;
  }

  const contactsIds = selectContactsIds(store.getState());
  const contactsEntities = selectContactsEntities(store.getState());
  const contactsNames = selectContactsNames(store.getState());
  const contactsUsers = selectContactsUsers(store.getState());
  const contactsPhones = selectContactsPhones(store.getState());

  if (!contactsIds || !contactsIds.length) {
    return null;
  }

  let searchString = _.toString(str).toLowerCase();

  // search by name
  if (contactsNames[searchString]) {
    return contactsEntities[contactsNames[searchString]];
  }

  // match by user
  if (contactsUsers[searchString]) {
    const contactId = contactsUsers[searchString];
    const entity = contactsEntities[contactId];
    if (entity) {
      return entity;
    }
  }

  // match by uid
  const uid = searchString.split('@');
  if (uid && uid.length && contactsUsers[uid[0]]) {
    const contactId = contactsUsers[uid[0]];
    const entity = contactsEntities[contactId];
    if (entity) {
      return entity;
    }
  }

  // search by phone number
  searchString = _.toString(str).replace(/\D/g, '');
  // save with leading 1 check
  const searchStringOriginal = searchString;
  if (searchString.length > 6) {
    // remove leading 1 from phone number
    // without leading 1 check
    searchString = _.toString(str).replace(/^1+/, '');
  }
  if (contactsPhones[searchString]) {
    return contactsEntities[contactsPhones[searchString]];
  }

  // check with original, with leading 1
  if (contactsPhones[searchStringOriginal]) {
    return contactsEntities[contactsPhones[searchStringOriginal]];
  }

  return null;
}
