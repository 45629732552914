import { put, select } from 'redux-saga/effects';
import { Registerer, RegistererState } from 'sip.js';

import {
  setRegisterer, selectUA, setUA, setUAState,
  incReconnectionAttempt,
  selectRegisterer,
} from '../../../state/ua/uaSlice';
import { attemptReconnectAction } from '../attemptReconnect/action';
import store from '../../../state/store';
import bugsnagClient from '../../../services/bugsnag/bugsnag';

let registrationTimeoutTimer = null;

export default function* addRegistrar() {
  const ua = yield select(selectUA);

  try {
    let registerer = yield select(selectRegisterer);
    if (!registerer || registerer.state === RegistererState.Terminated) {
      registerer = new Registerer(ua);
      yield put(setRegisterer(registerer));

      registerer.stateChange.addListener((newState) => {
        switch (newState) {
          case RegistererState.Initial:
            store.dispatch(setUAState('init'));
            break;
          case RegistererState.Registered:
            if (registrationTimeoutTimer) {
              clearTimeout(registrationTimeoutTimer);
              registrationTimeoutTimer = null;
            }
            store.dispatch(setUAState('registered'));
            break;
          case RegistererState.Terminated:
            store.dispatch(setUAState('closed'));
            break;
          case RegistererState.Unregistered:
            if (!registrationTimeoutTimer) {
              registrationTimeoutTimer = setTimeout(() => {
                ua.stop().then(() => {
                  store.dispatch(attemptReconnectAction());
                });
                registrationTimeoutTimer = null;
              }, 3000);
            }
            store.dispatch(setUAState('unregistered'));
            break;
          default:
        }
      });
    }

    console.debug('pre-register registerer state:', registerer.state);
    try {
      yield registerer.register({
        requestDelegate: {
          onReject(response) {
            console.debug('register onReject:', response);
            if (response.message.statusCode === 503 || response.message.statusCode === 500) {
              store.dispatch(incReconnectionAttempt());
            }
          },
        },
      });
    } catch (e) {
      console.debug(e); // eslint-disable-line no-console
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to Register', e);
    bugsnagClient.notify(e);
    yield put(setUA(null));
    yield put(setRegisterer(null));
    yield put(attemptReconnectAction());
  }
}
