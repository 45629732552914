import {
  put, call, select,
} from 'redux-saga/effects';
import * as contactState from '../../../state/contacts/contactsSlice';
import getContactResultSet from '../../../utils/contact/getContactResultSet';

export default function* processInCallResultSet() {
  const contactsArr = yield select(contactState.selectContacts);
  const filteredBy = yield select(contactState.selectInCallFilteredBy);
  const filteredUser = yield select(contactState.selectFilteredUser);

  // process contacts for contact result set
  const contactsList = yield call(
    getContactResultSet,
    contactsArr,
    filteredBy,
    filteredUser,
    'last_name',
    'asc',
  );

  yield put(contactState.setInCallContactsResultSet(contactsList));
}
