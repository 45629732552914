import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { put, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { selectLoading } from '../../state/cards/cardsSlice';
import { selectCallFromDevice } from '../../state/webphoneDevice/webphoneDeviceSlice';
import { initiateUACallAction } from '../ua/initiateUACall/action';
import { initiateCallFromAction } from '../initiateCallFrom/action';
import { INFO } from '../../constants';
import { setSnackbar } from '../../state/snackbar/snackbarSlice';

let launchCallNumber = nsBrowser.getQuery('call');
let preReq = 2;

export default function* launchCall() {
  preReq -= 1;
  if (preReq > 0) return;
  const cardsLoading = yield select(selectLoading);

  // check call url param, when launching a call from the portal
  // also test if cards has been initialized
  if (launchCallNumber && !cardsLoading) {
    let dtmfString = '';
    if (launchCallNumber.includes(',')) {
      const index = launchCallNumber.indexOf(',');
      dtmfString = launchCallNumber.substring(index);
      launchCallNumber = launchCallNumber.substring(0, index);
    }
    const callFromDevice = yield select(selectCallFromDevice);

    if (!callFromDevice || callFromDevice.aor.includes('wp')) {
      yield put(initiateUACallAction({ phoneNumber: launchCallNumber, genCard: true, dtmfString }));
      launchCallNumber = undefined;
    } else {
      yield put(setSnackbar({
        type: INFO,
        open: true,
        message: `${i18n.t('CALLING_FROM')} ${callFromDevice.display}`,
      }));
      yield put(initiateCallFromAction(launchCallNumber));
      launchCallNumber = undefined;
    }

    // remove query
    nsBrowser.removeQuery('call');
  }
}
