import { put, select } from 'redux-saga/effects';
import meta from 'netsapiens-ringtones/audio/ringtones-meta.json';
import { selectAppName, selectUserId } from '../../state/configs/configsSlice';
import { setRingtonePath, setRingtones, setToneName } from '../../state/ringtones/ringtonesSlice';
import { ringerSrc } from '../../utils/audio';

export default function* ringtones() {
  const appName = yield select(selectAppName);
  const userId = yield select(selectUserId);

  let toneName = localStorage.getItem(`${appName}-${userId}_ringtone`) || 'default';

  // test if there is a value and if the fileName exists in the meta list
  if (toneName && meta[toneName]) {
    yield put(setRingtonePath(`assets/audio/ringtones/${meta[toneName].fileName}`));
    ringerSrc(`assets/audio/ringtones/${meta[toneName].fileName}`);
  } else {
    // use the default wav and store setting in local storage
    toneName = 'default';
    yield put(setRingtonePath('assets/audio/ringtones/default.wav'));
    ringerSrc('assets/audio/ringtones/default.wav');
    localStorage.setItem(`${appName}-${userId}_ringtone`, 'default');
  }

  yield put(setToneName(toneName));

  const orderedRingtones = [];
  Object.keys(meta).forEach((prop) => {
    meta[prop].selected = prop === toneName;
    // set the path
    meta[prop].path = `assets/audio/ringtones/${meta[prop].fileName}`;

    if (prop === 'default') {
      orderedRingtones.unshift(meta[prop]);
    } else {
      orderedRingtones.push(meta[prop]);
    }
  });

  yield put(setRingtones(orderedRingtones));
}
