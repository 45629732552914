import _ from 'lodash';
import crypto from 'crypto-browserify';

import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

// import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import { selectContacts, setContacts } from '../../../state/contacts/contactsSlice';
import processResultSet from '../processResultSet/processResultSet';
import processSearchResultSet from '../processSearchResultSet/processSearchResultSet';
import formatContact from '../../../utils/contact/formatContact';

// supply entire contact object, or supply contactId and
// a params object (params: {}) containing new values
export default function* updateContact({ payload: toUpdate }) {
  let contacts = yield select(selectContacts);
  let contact = { ...toUpdate };
  if (toUpdate.contactId && toUpdate.params) {
    contact = _.find(contacts, (c) => c.id === toUpdate.contactId);
    Object.keys(toUpdate.params).forEach((property) => {
      contact = {
        ...contact,
        [`${property}`]: toUpdate.params[property],
      };
    });
  }

  if (contact.email) {
    // make a copy of the contact email
    contact.email = contact.email.slice();
  } else {
    contact.email = [''];
  }
  // clean phone numbers and turn email array into string
  contact.cell_phone = nsUtils.cleanPhoneNumber(contact.cell_phone);
  contact.home_phone = nsUtils.cleanPhoneNumber(contact.home_phone);
  contact.work_phone = nsUtils.cleanPhoneNumber(contact.work_phone);
  if (contact.email) {
    contact.email = _.chain(contact.email).compact().join(';').value();
  }

  // prep post params
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'contact',
    action: contact.contact_id ? 'update' : 'create',
    contact_id: contact.id,
    domain: decodedToken.domain,
    cell_phone: contact.cell_phone || '',
    email: contact.email,
    first_name: contact.first_name,
    home_phone: contact.home_phone || '',
    last_name: contact.last_name,
    tags: contact.tags,
    user: decodedToken.user,
    work_phone: contact.work_phone || '',
  };

  // post to api
  nsApi.post(params);

  if (params.action === 'create') {
    contact.contact_id = contact.id;
  }

  // format contact
  contact = formatContact(contact);

  if (contact.email) {
    contact.gravatar = crypto.createHash('md5').update(contact.email[0]).digest('hex');
  } else {
    contact.gravatar = false;
  }

  // const contactsArr = getState().contacts.get('contacts').toArray();
  contacts = yield select(selectContacts);
  const newContacts = [...contacts];

  // Find item index using _.findIndex (thanks @AJ Richardson for comment)
  const index = _.findIndex(newContacts, { id: contact.id });
  if (index >= 0) {
    newContacts.splice(index, 1, contact);
  } else {
    newContacts.push(contact);
  }

  yield put(setContacts(newContacts));

  yield call(processResultSet);
  yield call(processSearchResultSet);
}
