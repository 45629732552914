import { eventChannel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';
import i18n from 'i18next';
import { setNetworkConnected } from '../../state/network/networkSlice';
import { setSnackbar } from '../../state/snackbar/snackbarSlice';
import { SUCCESS } from '../../constants';

export default function* networkConnection() {
  const channel = eventChannel((emitter) => {
    let lastState = null;

    const interval = setInterval(() => {
      if (lastState !== navigator.onLine) {
        lastState = navigator.onLine;
        emitter(lastState);
      }
    }, 1000);

    // unsubscribe method
    return () => {
      clearInterval(interval);
    };
  });

  while (true) {
    const res = yield take(channel);
    yield put(setNetworkConnected(res));
    if (res === false) {
      yield put(setSnackbar({
        type: SUCCESS,
        open: true,
        message: i18n.t('NETWORK_LOST_CONNECTION_TOAST'),
        duration: 5000,
      }));
    }
  }
}
