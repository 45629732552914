/* eslint-disable camelcase */
import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import { selectQueues, setQueues } from '../../state/agent/agentSlice';

export default function* updateQueueSessionCount({ payload }) {
  let queues = yield select(selectQueues);
  queues = _.cloneDeep(queues);
  const queue = _.find(queues, { queue_name: payload.user });
  if (queue !== undefined) {
    if (payload.gau_queue_length !== undefined) {
      queue.queuedcall_count = payload.gau_queue_length;
    }
    if (payload.gau_agent_available !== undefined) {
      queue.agent_available_count = Number(payload.gau_agent_available);
    }
    const upQueues = queues.map((q) => (q.queue_name === payload.user ? queue : q));
    yield put(setQueues(upQueues));
  }
}
