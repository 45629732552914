/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const normalizeList = nsUtils.normalizeList('id', []);

export const agentSlice = createSlice({
  name: 'answeringRules',
  initialState: {
    loading: false,
    answeringRules: [],
    answeringRulesIds: normalizeList.ids,
    answeringRulesEntities: normalizeList.entities,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAnsweringRules: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('id', action.payload);
      state.answeringRules = action.payload;
      state.answeringRulesIds = ids;
      state.answeringRulesEntities = entities;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAnsweringRules, setLoading } = agentSlice.actions;

// Selectors
export const selectLoading = (state) => state.answeringRules.loading;
export const selectAnsweringRules = (state) => state.answeringRules.answeringRules;
export const selectAnsweringRule = (state, id) => state.answeringRules.answeringRulesEntities[id] || null;

export const selectEnabled = (state) => {
  const { answeringRules } = state.answeringRules;
  let answeringRule = null;
  for (let i = 0; i < answeringRules.length; i += 1) {
    if (answeringRules[i].active) {
      answeringRule = answeringRules[i];
      break;
    }
  }
  return answeringRule;
};

export default agentSlice.reducer;
