import formatPhoneNumber from '../formatPhoneNumber';

export default function getPartipcantNames(
  contactsArr,
) {
  let partNames = '';

  if (!contactsArr) {
    return null;
  }

  for (let i = 0; i < contactsArr.length; i += 1) {
    const contact = contactsArr[i];
    if (contact === undefined) {
      continue; // eslint-disable-line no-continue
    }
    if (contact.customContact && contactsArr.length === 1) {
      return formatPhoneNumber(contact.label);
    }

    if (contact.first_name === undefined
      && contact.last_name === undefined
      && contact.name !== undefined
    ) {
      if (contact.customContact) {
        partNames += formatPhoneNumber(contact.name);
      } else {
        partNames += contact.name;
      }
    } else if (contact.customContact && contact.label !== undefined) {
      partNames += formatPhoneNumber(contact.label);
    } else if (contact.customContact && contact.cell_phone !== undefined) {
      partNames += formatPhoneNumber(contact.cell_phone);
    }

    if (contact.first_name !== undefined && !contact.customContact) {
      partNames += contact.first_name;
    }
    // only show the first names
    // if (contact.last_name !== undefined && contact.last_name !== '') {
    //   partNames += (` ${contact.last_name}`);
    // }
    if (i !== (contactsArr.length - 1)) {
      partNames += ', ';
    }
  }

  return partNames;
}
