/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const normalizeList = nsUtils.normalizeList('id', []);

// let activeCall = null;
// let sessions = [];
// let sessionEntities = normalizeList.entities;

export const callSessionsSlice = createSlice({
  name: 'callSessions',
  initialState: {
    activeCount: 0,
    previousActiveCount: 0,
    activeCallId: null,
    callEnded: null,
    sessions: [],
    sessionIds: normalizeList.ids,
    sessionEntities: normalizeList.entities,
    isConference: false,
    isAutodialling: false,
  },
  reducers: {
    setActiveCount: (state, action) => {
      state.previousActiveCount = state.activeCount;
      state.activeCount = _.toNumber(action.payload);
    },
    setActiveCallId: (state, action) => {
      state.activeCallId = action.payload;
    },
    setCallEnded: (state, action) => {
      state.callEnded = action.payload;
    },
    setCallSessions: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('id', action.payload);
      state.sessions = action.payload;
      state.sessionIds = ids;
      state.sessionEntities = entities;
    },
    setIsConference: (state, action) => {
      state.isConference = action.payload;
    },
    setIsAutodialling: (state, action) => {
      state.isAutodialling = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveCount,
  setActiveCallId,
  setCallEnded,
  setCallSessions,
  setIsConference,
  setIsAutodialling,
} = callSessionsSlice.actions;

// Selectors
export const selectPreviousActiveCount = (state) => state.callSessions.previousActiveCount;
export const selectActiveCount = (state) => state.callSessions.activeCount;
export const selectActiveCallId = (state) => state.callSessions.activeCallId;
export const selectActiveCall = (state) => state.callSessions.sessionEntities[selectActiveCallId(state)];
export const selectCallEnded = (state) => state.callSessions.callEnded;
export const selectCallSessions = (state) => state.callSessions.sessions;
export const selectCallSessionIds = (state) => state.callSessions.sessionsIds;
export const selectCallSessionEntities = (state) => state.callSessions.sessionsEntities;
export const selectIsConference = (state) => state.callSessions.isConference;
export const selectIsAutoDialling = (state) => state.callSessions.isAutodialling;

// unused?
export const selectActiveStatusCount = (state) => {
  const sessions = selectCallSessions(state);
  return sessions.filter((s) => s.status === 'accepted').length;
};

export default callSessionsSlice.reducer;
