/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const queuedList = nsUtils.normalizeList('queue_name', []);
const queuesList = nsUtils.normalizeList('queue_name', []);
const respondersList = nsUtils.normalizeList('queue_name', []);

export const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    loading: true,
    showAgentCenter: false,
    agentStatus: 'Offline',
    agentType: '',
    callStats: [],
    dayStats: [],
    dispositionsIn: [],
    dispositionsOut: [],
    tmpDisposition: {},
    hourStats: [],
    kpiStats: {},
    portalStatus: '',
    queued: [],
    queuedIds: queuedList.ids,
    queuedEntities: queuedList.entities,
    queues: [],
    queuesIds: queuesList.ids,
    queuesEntities: queuesList.entities,
    responders: [],
    respondersIds: respondersList.ids,
    respondersEntities: respondersList.entities,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowAgentCenter: (state, action) => {
      state.showAgentCenter = action.payload;
    },
    setAgentStatus: (state, action) => {
      state.agentStatus = action.payload;
    },
    setAgentType: (state, action) => {
      state.agentType = action.payload;
    },
    setCallStats: (state, action) => {
      state.callStats = action.payload;
    },
    setDayStats: (state, action) => {
      state.dayStats = action.payload;
    },
    setDispositionsIn: (state, action) => {
      state.dispositionsIn = action.payload;
    },
    setDispositionsOut: (state, action) => {
      state.dispositionsOut = action.payload;
    },
    setTempDisposition: (state, action) => {
      state.tmpDisposition = action.payload;
    },
    setHourStats: (state, action) => {
      state.hourStats = action.payload;
    },
    setKpiStats: (state, action) => {
      state.kpiStats = action.payload;
    },
    setPortalStatus: (state, action) => {
      state.portalStatus = action.payload;
    },
    setQueued: (state, action) => {
      let updatedQueued;

      if (state.queuedEntities[action.payload.queueId]) {
        updatedQueued = state.queued.map((queue) => (queue.queue_name === action.payload.queueId
          ? { queue_name: action.payload.queueId, queued: action.payload.queued }
          : queue));
      } else {
        updatedQueued = [
          ...state.queued,
          { queue_name: action.payload.queueId, queued: action.payload.queued },
        ];
      }

      const { entities, ids } = nsUtils.normalizeList('queue_name', updatedQueued);
      state.queued = updatedQueued;
      state.queuedIds = ids;
      state.queuedEntities = entities;
    },
    setQueues: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('queue_name', action.payload);
      state.queues = action.payload;
      state.queuesIds = ids;
      state.queuesEntities = entities;
    },
    updateQueueAgent: (state, action) => {
      if (state.queuesEntities[action.payload.huntgroup_name]) {
        const update = state.queues.map((queue) => (queue.queue_name === action.payload.huntgroup_name
          ? { ...queue, agent: { ...queue.agent, ...action.payload } }
          : queue));

        const { entities, ids } = nsUtils.normalizeList('queue_name', update);
        state.queues = update;
        state.queuesIds = ids;
        state.queuesEntities = entities;
      }
    },
    setResponders: (state, action) => {
      let updatedResponders;

      if (state.respondersEntities[action.payload.queueId]) {
        updatedResponders = state.responders.map((responder) => (responder.queue_name === action.payload.queueId
          ? { queue_name: action.payload.queueId, responders: action.payload.responders }
          : responder));
      } else {
        updatedResponders = [
          ...state.responders,
          { queue_name: action.payload.queueId, responders: action.payload.responders },
        ];
      }

      const { entities, ids } = nsUtils.normalizeList('queue_name', updatedResponders);
      state.responders = updatedResponders;
      state.respondersIds = ids;
      state.respondersEntities = entities;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setShowAgentCenter,
  setAgentStatus,
  setAgentType,
  setCallStats,
  setDayStats,
  setDispositionsIn,
  setDispositionsOut,
  setTempDisposition,
  setHourStats,
  setKpiStats,
  setPortalStatus,
  setQueued,
  setQueues,
  setResponders,
  updateQueueAgent,
} = agentSlice.actions;

// Selectors
export const selectLoading = (state) => state.agent.loading;
export const selectShowAgentCenter = (state) => state.agent.showAgentCenter;
export const selectAgentStatus = (state) => state.agent.agentStatus;
export const selectAgentType = (state) => state.agent.agentType;
export const selectCallStats = (state) => state.agent.callStats;
export const selectDayStats = (state) => state.agent.dayStats;
export const selectDispositionsIn = (state) => state.agent.dispositionsIn || [];
export const selectDispositionsOut = (state) => state.agent.dispositionsOut || [];
export const selectTmpDisposition = (state) => state.agent.tmpDisposition;
export const selectHourStats = (state) => state.agent.hourStats;
export const selectKpiStats = (state) => state.agent.kpiStats;
export const selectPortalStatus = (state) => state.agent.portalStatus;
export const selectQueued = (state, id) => state.agent.queuedEntities[id] || {};
export const selectQueuedIds = (state) => state.agent.queuedIds;
export const selectQueuedEntities = (state) => state.agent.queuedEntities;
export const selectQueues = (state) => state.agent.queues;
export const selectQueuesIds = (state) => state.agent.queuesIds;
export const selectQueuesEntities = (state) => state.agent.queuesEntities;
export const selectQueueById = (state, id) => state.agent.queuesEntities[id] || null;
export const selectResponders = (state, id) => state.agent.respondersEntities[id] || { responders: {} };
export const selectRespondersIds = (state) => state.agent.respondersIds;
export const selectRespondersEntities = (state) => state.agent.respondersEntities;

export default agentSlice.reducer;
