/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

// Selectors
export const selectUser = (state) => state.user.user;
export const selectUserLanguage = (state) => state.user.user?.language;
export const selectUserTimeZone = (state) => state.user.user?.timeZone;
export const selectSMS = (state) => state.user.user?.sms;

export default userSlice.reducer;
