import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { put, select } from 'redux-saga/effects';
import { UserAgent, SIPExtension } from 'sip.js';
import { BroadcastChannel } from 'broadcast-channel';

import {
  selectAppName,
  selectAudioCodecs,
  selectIceCheckingTimeout,
  selectStunServers,
  selectUaSocketURLs,
  selectVersion,
  selectWebphoneName,
} from '../../../state/configs/configsSlice';
import { selectWebphoneDevice } from '../../../state/webphoneDevice/webphoneDeviceSlice';
import store from '../../../state/store';
import { onInviteAction } from '../onInvite/action';
import { onConnectAction } from '../onConnect/action';
import { onDisconnectAction } from '../onDisconnect/action';
import { attemptReconnectAction } from '../attemptReconnect/action';
import { setUA } from '../../../state/ua/uaSlice';

export default function* initUA() {
  const appName = yield select(selectAppName);
  let sipTrace = localStorage.getItem(`${appName}_sip-trace`);
  let sipLog = localStorage.getItem(`${appName}_sip-log`);

  if (sipTrace === null) {
    sipTrace = false;
    localStorage.setItem(`${appName}_sip-trace`, 'false');
  } else if (sipTrace === 'true') {
    sipTrace = true;
  } else if (sipTrace === 'false') {
    sipTrace = false;
  }
  if (sipLog === null) {
    sipLog = 'error';
    localStorage.setItem(`${appName}_sip-log`, sipLog);
  } else {
    const validLogLevels = ['debug', 'log', 'warn', 'error'];
    if (!validLogLevels.includes(sipLog)) {
      // eslint-disable-next-line no-console
      console.error(
        `misconfigured logLevel: please set localStorage value:${appName}_sip-log to (debug|log|warn|error)`,
      );
      sipLog = 'debug';
    }
  }

  const audioCodecs = yield select(selectAudioCodecs);
  const webphoneDevice = yield select(selectWebphoneDevice);
  const iceCheckingTimeout = yield select(selectIceCheckingTimeout);
  const uaSocketURLs = yield select(selectUaSocketURLs);
  const version = yield select(selectVersion);
  const wpName = yield select(selectWebphoneName);
  const stunServers = yield select(selectStunServers);

  const UserAgentOptions = {
    uri: UserAgent.makeURI(webphoneDevice.aor),
    transportOptions: { server: uaSocketURLs[0].ws_uri || uaSocketURLs[0] },
    codecsVideo: false,
    displayName: webphoneDevice.sub_fullname,
    iceGatheringTimeout: iceCheckingTimeout,
    logLevel: sipLog,
    noAnswerTimeout: 180,
    authorizationUsername: webphoneDevice.aor,
    authorizationPassword: webphoneDevice.authentication_key,
    register: true,
    sipExtension100rel: SIPExtension.Supported,
    sipExtensionReplaces: SIPExtension.Supported,
    traceSip: sipTrace,
    userAgentString: `${wpName} ${version} (${nsBrowser.name} ${nsBrowser.fullVersion})`,
    delegate: {
      onInvite: function onInvite(invitation) {
        console.debug('onInvite', invitation);
        store.dispatch(onInviteAction(invitation));
      },
      onConnect: function onConnect() {
        console.debug('onConnect');
        store.dispatch(onConnectAction());
      },
      onDisconnect: function onDisconnect(error) {
        console.debug('onDisconnect', error);
        store.dispatch(onDisconnectAction(error));
      },
    },
  };

  if (stunServers) {
    UserAgentOptions.stunServers = stunServers.split(/[, ]+/);
  } else {
    UserAgentOptions.stunServers = ['stun.l.google.com:19302'];
  }

  if (audioCodecs) {
    UserAgentOptions.codecsAudio = audioCodecs;
  }

  let ua;
  try {
    ua = new UserAgent(UserAgentOptions);

    yield put(setUA(ua));
    yield ua.start();

    window.onbeforeunload = () => {
      store.dispatch(onDisconnectAction());
    };

    const encodedData = JSON.stringify(UserAgentOptions);

    const uaBroadcast = new BroadcastChannel('uaBroadcast');
    console.debug('in initUA posting message to uabroadcast encodedData: ', encodedData);
    uaBroadcast.postMessage(encodedData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to Connect', e);
    yield put(setUA(ua));
    yield put(attemptReconnectAction());
  }
}
