import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getLoginRoute } from '../../../routePaths';
import { ssoLoginAction } from '../../../sagas/ssoLogin/action';
import appleLogo from '../../../assets/images/apple-logo.png';
import { LOGIN_FAILED_ERROR } from '../../../constants';

function initApple(appleSsoClientId) {
  const appleAuthSettings = {
    clientId: appleSsoClientId,
    scope: 'name email',
    redirectURI: `${window.location.origin}/webphone`,
    usePopup: true,
  };

  // eslint-disable-next-line no-undef
  AppleID.auth.init(appleAuthSettings);
}

function AppleSso({
  appleSsoClientId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const aScript = document.createElement('script');
    aScript.type = 'text/javascript';
    aScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    document.head.appendChild(aScript);

    aScript.onload = () => {
      initApple(appleSsoClientId);
    };

    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      const { id_token: token } = data.detail.authorization;
      dispatch(ssoLoginAction({ token, vendor: 'apple' }));
    });

    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      // eslint-disable-next-line no-console
      console.error('AppleIDSignInOnFailure', error);
      navigate(getLoginRoute(), { err: LOGIN_FAILED_ERROR });
    });
  }, []);

  const ssoLoginSubmit = (e) => {
    e.preventDefault();
    document.getElementById('appleid-signin').click();
  };

  return (
    <div>
      <Button
        id="AppleSsoLogin"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={ssoLoginSubmit}
        sx={{
          mt: 2,
          color: '#3c4043',
          fontWeight: '400',
          backgroundColor: '#fff',
          boxShadow: '0',
          borderRadius: 1,
          border: '1px solid #cccccc',
          textTransform: 'none',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#fff',
          },
          height: '40px',
        }}
      >
        <img
          src={appleLogo}
          alt="apple logo"
          style={{
            height: 18,
            marginRight: 12,
          }}
        />
        {t('SIGN_IN_WITH_APPLE')}
      </Button>
      <Box id="appleid-signin" style={{ display: 'none' }} />
    </div>
  );
}

AppleSso.propTypes = {
  appleSsoClientId: PropTypes.string.isRequired,
};

export default AppleSso;
