/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

const callHistoryList = nsUtils.normalizeList('cdr_id', []);
const callCrdSetList = nsUtils.normalizeList('cdr_id', []);

export const callHistorySlice = createSlice({
  name: 'callHistory',
  initialState: {
    loading: true,
    filteredBy: 'all',
    callCdrSet: [],
    callCdrSetIds: callCrdSetList.ids,
    callCdrSetEntities: callCrdSetList.entities,
    callHistory: [],
    callHistoryIds: callHistoryList.ids,
    callHistoryEntities: callHistoryList.entities,
    callHistoryResultSet: null,
    missedCallCount: 0,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilteredBy: (state, action) => {
      state.filteredBy = action.payload;
    },
    setCdrSet: (state, action) => {
      let updatedCdrs;

      if (state.callCdrSetEntities[action.payload.cdr_id]) {
        updatedCdrs = state.callCdrSet.map((cdr) => (cdr.cdr_id === action.payload.cdr_id
          ? action.payload.cdr
          : cdr));
      } else {
        updatedCdrs = [
          ...state.callCdrSet,
          action.payload.cdr,
        ];
      }

      const { entities, ids } = nsUtils.normalizeList('cdr_id', updatedCdrs);
      state.callCdrSet = updatedCdrs;
      state.callCdrSetIds = ids;
      state.callCdrSetEntities = entities;
    },
    setCallHistory: (state, action) => {
      const { entities, ids } = nsUtils.normalizeList('cdr_id', action.payload);
      state.callHistory = action.payload;
      state.callHistoryIds = ids;
      state.callHistoryEntities = entities;
    },
    setCallHistoryResultSet: (state, action) => {
      state.callHistoryResultSet = action.payload;
    },
    setMissedCallCount: (state, action) => {
      state.missedCallCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading, setFilteredBy, setCdrSet, setCallHistory, setCallHistoryResultSet, setMissedCallCount,
} = callHistorySlice.actions;

// Selectors
export const selectLoading = (state) => state.callHistory.loading;
export const selectFilteredBy = (state) => state.callHistory.filteredBy;
export const selectCallCdrSet = (state, id) => state.callHistory.callCdrSetEntities[id];
export const selectCallCdrSetIds = (state) => state.callHistory.callCdrSetIds;
export const selectCallCdrSetEntities = (state) => state.callHistory.callCdrSetEntities;
export const selectCallHistory = (state) => state.callHistory.callHistory;
export const selectCallHistoryIds = (state) => state.callHistory.callHistoryIds;
export const selectCallHistoryEntities = (state) => state.callHistory.callHistoryEntities;
export const selectCallHistoryResultSet = (state) => state.callHistory.callHistoryResultSet;
export const selectMissedCallCount = (state) => state.callHistory.missedCallCount;

export default callHistorySlice.reducer;
