import { call, put, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import crypto from 'crypto-browserify';

import { getNavigate } from '../../services/router/router';
import {
  getInitRoute, getLoginRoute, getSelectSubscriberRoute, getSSOEnrollRoute,
} from '../../routePaths';
import { selectApiHash } from '../../state/configs/configsSlice';
import { loggedInAction } from '../loggedIn/action';
import bugsnagClient from '../../services/bugsnag/bugsnag';
import { LOGIN_FAILED_ERROR } from '../../constants';

export default function* ssoLogin({ payload }) {
  const navigate = getNavigate();
  yield call(navigate, getInitRoute());

  const apiHash = yield select(selectApiHash);

  try {
    yield nsApi.ssoLogin({
      idtoken: payload.token,
      vendor: payload.vendor,
      scope: 'webphone',
      apiHash,
    });

    yield put(loggedInAction());
  } catch (e) {
    if (e === 'NO_SUBSCRIBER') {
      yield call(
        navigate,
        getSSOEnrollRoute(),
        { state: { token: payload.token, vendor: payload.vendor } },
      );
    } else if (e.reason === 'SELECT_SUBSCRIBER') {
      let { subscriber_list: subscriberList } = e;

      subscriberList = subscriberList.map((subscriber) => {
        const updatedSubscriber = { ...subscriber };

        if (updatedSubscriber.name) {
          updatedSubscriber.initials = subscriber.name.split(' ').map((n) => n[0]).join('');
        }

        updatedSubscriber.gravatar = crypto.createHash('md5')
          .update(subscriber.email_address.split(';')[0])
          .digest('hex');

        return updatedSubscriber;
      });

      yield call(
        navigate,
        getSelectSubscriberRoute(),
        { state: { token: payload.token, vendor: payload.vendor, subscriberList } },
      );
    } else {
      yield call(navigate, getLoginRoute(), { state: { err: LOGIN_FAILED_ERROR } });
      // eslint-disable-next-line no-console
      console.error(e);
      bugsnagClient.notify(e, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = 'saga: ssoLoginUser';
        event.addMetadata('payload', payload);
      });
    }
  }
}
