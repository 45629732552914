export default function normalizeLocal(local) {
  if (local.includes('_')) {
    const [part1, part2] = local.split('_');
    return `${part1?.toLowerCase()}_${part2?.toLowerCase()}`;
  } if (local.includes('-')) {
    const [part1, part2] = local.split('-');
    return `${part1?.toLowerCase()}_${part2?.toLowerCase()}`;
  }
  console.warn('Locale string incorrect format'); // eslint-disable-line no-console
  return local?.toLowerCase();
}
