import _ from 'lodash';

import {
  call,
  put,
  select,
} from 'redux-saga/effects';

// import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';
import { selectContacts, setContacts } from '../../../state/contacts/contactsSlice';
import processResultSet from '../processResultSet/processResultSet';
import processSearchResultSet from '../processSearchResultSet/processSearchResultSet';

// supply entire contact object, or supply contactId and
// a params object (params: {}) containing new values
export default function* updateContactDetails({ payload: toUpdate }) {
  const contacts = yield select(selectContacts);
  let contact = null;
  if (toUpdate.subscriber_login && toUpdate.params) {
    contact = _.find(contacts, (c) => c.uid === toUpdate.subscriber_login);
    Object.keys(toUpdate.params).forEach((property) => {
      contact = {
        ...contact,
        [`${property}`]: toUpdate.params[property],
      };
    });
  }
  if (!contact) { return; }

  const newContacts = [...contacts];

  const index = _.findIndex(newContacts, { id: contact.id });
  if (index >= 0) {
    newContacts.splice(index, 1, contact);

    yield put(setContacts(newContacts));

    yield call(processResultSet);
    yield call(processSearchResultSet);
  }
}
