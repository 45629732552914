/* eslint-disable no-param-reassign */
import _ from 'lodash';
import naturalSort from 'javascript-natural-sort';

import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

export default function mapUserToRules(answeringRules, user) {
  answeringRules = _.compact(answeringRules); // eslint-disable-line no-param-reassign

  if (!_.size(answeringRules)) {
    return [];
  }
  const properties = ['for', 'fbu', 'fna', 'fnr'];

  properties.forEach((property) => {
    answeringRules = answeringRules.map((ar) => {
      // test if sim control is enabled
      if (ar[`${property}_control`] === 'e'
                && ar[`${property}_parameters`]
                && ar[`${property}_parameters`].indexOf('user_') !== -1
      ) {
        const usernum = ar[`${property}_parameters`].replace('user_', '');

        let str = usernum;
        if (user) {
          if (user.firstName) {
            str += ` (${user.firstName}`;
          }
          if (user.lastName) {
            str += (str) ? ` ${user.lastName}` : user.lastName;
          }
          if (str) {
            str += ')';
          }
        }

        ar[`${property}_user`] = str;
        return ar;
      }
      ar[`${property}_user`] = ar[`${property}_parameters`];
      return ar;
    });
  });

  for (let i = 0; i < answeringRules.length; i += 1) {
    answeringRules[i].id = nsUtils.randomId();

    if (answeringRules[i].for_control === 'e') {
      answeringRules[i].for_number = answeringRules[i].for_user.replace('vmail_', '');
      answeringRules[i].for_number = answeringRules[i].for_number.replace('user_', '');
      answeringRules[i].for_number = answeringRules[i].for_number.replace('phone_', '');
    }
    if (answeringRules[i].foa_control === 'e') {
      answeringRules[i].foa_number = answeringRules[i].foa_parameters.replace('vmail_', '');
      answeringRules[i].foa_number = answeringRules[i].foa_number.replace('user_', '');
      answeringRules[i].foa_number = answeringRules[i].foa_number.replace('phone_', '');
    }
    if (answeringRules[i].fbu_control === 'e') {
      answeringRules[i].fbu_number = answeringRules[i].fbu_user.replace('vmail_', '');
      answeringRules[i].fbu_number = answeringRules[i].fbu_number.replace('user_', '');
      answeringRules[i].fbu_number = answeringRules[i].fbu_number.replace('phone_', '');
    }
    if (answeringRules[i].fna_control === 'e') {
      answeringRules[i].fna_number = answeringRules[i].fna_user.replace('vmail_', '');
      answeringRules[i].fna_number = answeringRules[i].fna_number.replace('user_', '');
      answeringRules[i].fna_number = answeringRules[i].fna_number.replace('phone_', '');
    }
    if (answeringRules[i].fnr_control === 'e') {
      answeringRules[i].fnr_number = answeringRules[i].fnr_user.replace('vmail_', '');
      answeringRules[i].fnr_number = answeringRules[i].fnr_number.replace('user_', '');
      answeringRules[i].fnr_number = answeringRules[i].fnr_number.replace('phone_', '');
    }
    if (answeringRules[i].time_range_data
                      && answeringRules[i].time_range_data.length
                      && answeringRules[i].time_range_data[0].days === '*'
                      && answeringRules[i].time_range_data[0].date_from === 'now'
                      && answeringRules[i].time_range_data[0].date_to === 'never'
    ) {
      answeringRules[i].time_frame_always = true;
    }
    if (answeringRules[i].time_range_data
      && answeringRules[i].time_range_data.length
      && answeringRules[i].time_range_data[0].days !== '*'
    ) {
      answeringRules[i].time_range_data.sort((a, b) => naturalSort(a.days, b.days));
    }
  }

  return answeringRules;
}
