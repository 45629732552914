/* eslint-disable camelcase */
import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import { selectQueued, setQueued } from '../../state/agent/agentSlice';

export default function* removeQueueWaiting({ payload }) {
  const queued = yield select((state) => selectQueued(state, payload.queue_name));
  if (!_.isEmpty(queued.queued)) {
    const filterQ = _.filter(queued.queued, (waiter) => waiter.session_key !== payload.session_key);

    yield put(setQueued({ queued: filterQ, queueId: payload.queue_name }));
  }
}
