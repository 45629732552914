// import moment from 'moment';
import {
  call,
  put,
  // select,
} from 'redux-saga/effects';
// import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import {
  setLoading,
} from '../../../state/chat/chatSlice';
import fetchMessageSession from '../fetchMessageSession/fetchMessageSession';

export default function* toggleMute({ payload }) {
  const debug = false;

  const { session } = payload;

  const oldValue = session.muted;
  const decodedToken = nsToken.getDecoded();

  let value = 'yes';
  if (oldValue === 'no') {
    value = 'yes';
  } else if (oldValue === 'yes') {
    value = 'no';
  }

  if (debug) {
    console.debug('this is toggleMute to post: ', {
      object: 'messagesession',
      action: 'update',
      domain: decodedToken.domain,
      user: decodedToken.user,
      session_id: session.id,
      muted: value,
    });
  }

  const res = yield nsApi.post({
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: session.id,
    muted: value,
  }, {
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: session.id,
    muted: value,
  });

  if (debug) console.debug('this is res: ', res);

  // fetch the new updated message session after the update
  yield call(fetchMessageSession, { sessionId: session.id });

  yield put(setLoading(false));
  if (debug) console.debug('at the end of send message');
}
