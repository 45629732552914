import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import {
  selectCards, selectPinnedCards, setCards, setPinnedCards,
} from '../../../state/cards/cardsSlice';
import { selectAppName, selectUserId } from '../../../state/configs/configsSlice';

/**
 * Updates the video parameter of a conversation card with the specified session ID.
 * @param {Object} params - An object containing the information needed to update the conversation card's video parameter.
 * @param {string} params.sessionId - The ID of the session associated with the conversation card.
 * @param {boolean} params.videoInvite - A boolean value indicating whether the video invite is enabled.
 * @param {string} params.videoId - The ID of the video associated with the conversation card.
 * @param {string} params.videoInviteFrom - The ID of the user who sent the video invite.
 */
export default function* updateMessageCardVideo(params) {
  const debug = false;
  try {
    if (debug) console.debug('this is params updateMessageCardVideo: ', params);
    if (params.payload && params.payload.sessionId) {
      // eslint-disable-next-line no-param-reassign
      params = params.payload;
    }

    if (!params || !params.sessionId) {
      console.error('Invalid video invite object');
      return;
    }

    const pinnedCards = yield select(selectPinnedCards);
    const otherCards = yield select(selectCards);
    const appName = yield select(selectAppName);
    const userId = yield select(selectUserId);

    const allCards = [...pinnedCards, ...otherCards];

    const conversationCard = _.find(
      allCards,
      (card) => (card.type === 'conversation'
        || card.type === 'new-chat')
        && card.meta?.sessionId === params?.sessionId,
    );
    if (debug) console.debug('this is conversationCard updateMessageCardVideo: ', conversationCard);

    if (!conversationCard) {
      return;
    }

    const conversationCardIndex = _.findIndex(
      allCards,
      (card) => (card.type === 'conversation' || card.type === 'new-chat') && card.meta?.sessionId === params?.sessionId,
    );
    if (debug) console.debug('update this conversationcard to have video invite conversationCardIndex: ', conversationCardIndex);

    // update conversation card with video invite
    const updatedVideoInvCard = new nsModels.card.Card({
      id: conversationCard.id,
      meta: {
        contactId: conversationCard.meta.contactId || null,
        sessionId: params.sessionId || null,
        queueId: conversationCard.meta.queueId || null,
        sendMessageParams: conversationCard.meta.sendMessageParams || null,
        videoInvite: params.videoInvite || false,
        videoId: params.videoId || null,
        videoInviteFrom: params.videoInviteFrom || null,
        details: conversationCard.meta.details || {},
        stat: conversationCard.meta.stat || null,
      },
      type: conversationCard.type,
    });
    if (debug) console.debug('update this updatedVideoInvCard: ', updatedVideoInvCard);

    // get card and update from list if pinned
    const pinnedOrder = [];
    for (let i = 0; i < pinnedCards.length; i += 1) {
      if (pinnedCards[i].id !== conversationCard.id) {
        pinnedOrder.push(pinnedCards[i]);
      } else {
        pinnedOrder.push(updatedVideoInvCard);
      }
    }

    // get card and update from list if not pinned
    const otherOrder = [];
    for (let i = 0; i < otherCards.length; i += 1) {
      if (otherCards[i].id !== conversationCard.id) {
        otherOrder.push(otherCards[i]);
      } else {
        otherOrder.push(updatedVideoInvCard);
      }
    }

    localStorage.setItem(`${appName}-${userId}_pinned_cards`, JSON.stringify(pinnedOrder));
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(otherOrder));

    yield put(setPinnedCards(pinnedOrder));
    yield put(setCards(otherOrder));
  } catch (error) {
    console.error('Error updating conversation card with video invite:', error);
  }
}
