import {
  call,
  // fork,
  put,
  select,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import moment from 'moment';
import {
  // setSessionsList,
  setLoading,
  selectMessagesEntities,
  setMessages,
  // setMessagesList,
} from '../../../state/chat/chatSlice';
import { selectUserLanguage } from '../../../state/user/userSlice';

// override will fetchMessages regardless of whether the sessionMessages init is set
export default function* fetchMessages({ payload: sessionId, override }) {
  try {
    const debug = false;
    yield put(setLoading(true));
    const messagesEntitites = yield select(selectMessagesEntities);
    const userLanguage = yield select(selectUserLanguage);

    const sessionMessages = messagesEntitites[sessionId] || { init: false };
    if (debug) console.debug('in fetchMessages sessionMessages: ', sessionMessages);
    if (debug) console.debug('in fetchMessages override: ', override);

    if (sessionMessages.init && !override) {
      // console.debug('init was set: ', override);
      return;
    }

    // update messages object
    if (!override) {
      sessionMessages.id = sessionId;
      sessionMessages.init = true;
      sessionMessages.loading = true;
      sessionMessages.messages = [];
    }

    // might be something different
    if (debug) console.debug('this is sessionMessages new: ', sessionMessages);
    yield put(setMessages(sessionMessages));

    const decodedToken = yield call(nsToken.getDecoded);
    // console.debug('in fetchMessages payload: ', payload);
    if (debug) console.debug('in fetchMessages payload.sessionId: ', sessionId);
    if (debug) console.debug('in fetchMessages decodedToken: ', decodedToken);

    const res = yield call(nsApi.get, {
      object: 'message',
      action: 'read',
      session_id: sessionId,
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
    });

    // const res2 = 'res2 string';

    if (debug) console.debug('this is res in fetchmessages0: ', res);

    const sessionMessages2 = {};

    sessionMessages2.id = sessionId;
    sessionMessages2.init = true;
    sessionMessages2.loading = false;
    if (debug) console.debug('this is res in fetchmessages0.01: ', res);
    sessionMessages2.messages = res;
    if (debug) console.debug('this is res in fetchmessages0.1: ', res);

    // reverse order
    if (sessionMessages2.messages && sessionMessages2.messages.length) {
      sessionMessages2.messages.reverse();
    }

    const convertTo = (arr) => {
      const toRet = [];
      let currTimestamp = '';

      for (let i = 0; i < arr.length; i += 1) {
        if (currTimestamp !== moment.utc(arr[i].timestamp).locale(userLanguage).fromNow()) {
          toRet.push({
            type: 'timestampGroup',
            timestamp: moment.utc(arr[i].timestamp).locale(userLanguage).fromNow(),
            dateTime: moment.utc(arr[i].timestamp),
          });
          currTimestamp = moment.utc(arr[i].timestamp).locale(userLanguage).fromNow();
        }
        // eslint-disable-next-line no-param-reassign
        arr[i].dateTime = moment.utc(arr[i].timestamp);

        // eslint-disable-next-line no-param-reassign
        arr[i].timestamp = moment.utc(arr[i].timestamp).locale(userLanguage).fromNow();
        toRet.push(arr[i]);
      }
      return toRet;
    };
    // const groupMessagesMeta = {};
    const groupMessagesMeta = { ...sessionMessages2 };

    groupMessagesMeta.messages = convertTo(groupMessagesMeta.messages, 'timestamp');

    yield put(setMessages(groupMessagesMeta));

    yield put(setLoading(false));
    if (debug) console.debug('this is res in fetchmessages END');
  } catch (e) {
    // todo bugsnag
    yield put(setLoading(false));
  }
}
