import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog as MUIDialog, DialogContent, Typography, DialogActions, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectDialog, setDialog } from '../../../state/dialog/dialogSlice';

function Dialog() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    open,
    title,
    titleParams,
    text,
    textParams,
    confirmText,
    cancelText,
    onResult,
  } = useSelector(selectDialog);

  const handleConfirm = () => {
    if (onResult) onResult(true);
    dispatch(setDialog({ open: false }));
  };

  const handleCancel = (e, reason) => {
    // ignore click away
    if (!reason) {
      if (onResult) onResult(false);
      dispatch(setDialog({ open: false }));
    }
  };

  return (
    <MUIDialog onClose={handleCancel} open={open}>
      <DialogContent sx={{ width: { xs: 340, sm: 460, md: 600 } }}>
        {title && (
          <Typography
            variant="h5"
            sx={{ m: (theme) => theme.spacing(0, 0, 2, 0) }}
          >
            {t(title, titleParams)}
          </Typography>
        )}
        <Typography
          sx={{
            mt: 2,
          }}
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: t(text, {
              ...(textParams || {}),
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button sx={{ color: 'secondary.main' }} onClick={handleCancel}>
            {t(cancelText)}
          </Button>
        )}
        {confirmText && (
          <Button sx={{ color: 'primary.main' }} onClick={handleConfirm}>
            {t(confirmText)}
          </Button>
        )}
      </DialogActions>
    </MUIDialog>
  );
}

export default Dialog;
