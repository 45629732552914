import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import Push from 'push.js';
import { SessionState, UserAgent } from 'sip.js';

import i18n from 'i18next';
import { AcceptIncomingCallBehavior } from '@gnaudio/jabra-js';
import store from '../state/store';
import { selectConfig, selectUseHeadsetRinger } from '../state/configs/configsSlice';
import { acceptCallAction } from '../sagas/ua/acceptCall/action';
import {
  selectActiveCall,
  selectActiveCallId,
  selectActiveCount, selectCallSessions,
  setActiveCallId,
} from '../state/callSessions/callSessionsSlice';
import { selectCurrentState } from '../state/routeStack/routeStackSlice';
import { stopStreamsAction } from '../sagas/streams/stopStreams/action';
import { selectHidDevice, selectStream } from '../state/userMedia/userMediaSlice';
import {
  getAudio,
  playCallWaiting,
  playRinger,
  stopCallWaiting,
  stopRingback,
  stopRinger,
} from '../utils/audio';
import { addStreamAction } from '../sagas/streams/addStream/action';
import { removeSessionAction } from '../sagas/callSessions/removeSession/action';
import { removeIncomingCallAction } from '../sagas/callSessions/removeIncomingCall/action';
import { showDispositionAction } from '../sagas/showDisposition/action';
import { addCardAction } from '../sagas/cardManagment/addCard/action';
import stripCodecs from '../utils/stripCodecs';
import removeCodecs from '../utils/removeCodecs';
import { selectUser } from '../state/user/userSlice';
import { sessionsUpdatedAction } from '../sagas/callSessions/sessionsUpdated/action';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import { setCallCardState } from '../state/cards/cardsSlice';
import { CARD_CALL_MAIN } from '../constants';
import {
  addAnsweredCall,
  getHidState,
  removeAnsweredCall,
  removeIncomingCall,
} from '../utils/devices';

function jsUcFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function stripVideo(description) {
  let { sdp } = description;
  const descriptionRegExp = /m=video.*$'/gm;
  const groupRegExp = /^a=group:.*$/gm;
  if (descriptionRegExp.test(sdp)) {
    let midLineToRemove;
    sdp = sdp.split(/^m=/gm).filter((section) => {
      if (section.substr(0, 5) === 'video') {
        midLineToRemove = section.match(/^a=mid:.*$/gm);
        if (midLineToRemove) {
          const step = midLineToRemove[0].match(/:.+$/g);
          if (step) {
            midLineToRemove = step[0].substr(1);
          }
        }
        return false;
      }
      return true;
    }).join('m=');
    const groupLine = sdp.match(groupRegExp);
    if (groupLine && groupLine.length === 1) {
      let groupLinePortion = groupLine[0];
      // eslint-disable-next-line no-useless-escape
      const groupRegExpReplace = new RegExp(`\ *${midLineToRemove}[^\ ]*`, 'g');
      groupLinePortion = groupLinePortion.replace(groupRegExpReplace, '');
      sdp = sdp.split(groupRegExp).join(groupLinePortion);
    }
  }
  // eslint-disable-next-line no-param-reassign
  description.sdp = sdp;
  return Promise.resolve(description);
}

// eslint-disable-next-line no-unused-vars
function clearNotifications(id) {
  const options = { tag: id };

  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.getNotifications(options).then((notifications) => {
        notifications.forEach((notification) => {
          notification.close();
        });

        // do something with your notifications
      });
    });
  }
}

export default class CallSession extends nsModels.sip.Session {
  /**
   *
   * @param params
   */
  constructor(params) {
    super({ ...params, type: 'call' });

    this.name = params.name || null;
    this.callId = null;
    this.parkId = null;
    this.callTime = 0;
    this.callerId = null;
    this.from_tag = params.from_tag || null;
    // this.holdTimer = null;
    this.number = params.number || null;
    this.push = null;
    this.auto_ans = false;
    this.stats = {
      runStatsMsDelay: 200,
      runStatsCounter: 0,
      runStatsLastByte: 0,
      runStatsInterval: null,
    };
    this.timer = null;
    this.isConference = params.isConference || false;
    this.threeWayCallDisconnectOthersOnEndFn = null;
    this.conferenceList = [];
    this.isRecording = false;
    this.hasEarlyMedia = false;
    this.dtmfString = '';

    this.formattedNumber = formatPhoneNumber(this.number);
    this.notifcationOptions = {
      body: this.formattedNumber,
      requireInteraction: true,
      icon: selectConfig(store.getState(), 'faviconURL'),
      tag: this.id,
      persistent: true,
      data: {
        options: {
          sessionId: this.id,
        },
      },
    };

    store.dispatch(setCallCardState(CARD_CALL_MAIN));

    let remoteName = this.name || '';
    if (this.direction === 'inbound') {
      // get push notification remoteName
      if (remoteName) {
        remoteName = `${i18n.t('from')} ${remoteName}`;
      }

      const title = `${i18n.t('INCOMING_CALL')} ${remoteName}`;
      const thisSession = this;

      Notification.requestPermission().then(function handlePermissionRequest(result) {
        if (result === 'granted') {
          if (navigator.serviceWorker) {
            // only show if document does not have focus
            if (document.hasFocus()) {
              return;
            }

            thisSession.addNotification(title, [
              { action: 'Answer', title: jsUcFirst(i18n.t('ANSWER')) },
              { action: 'Reject', title: jsUcFirst(i18n.t('REJECT')) },
            ]);
          } else {
            // open push notification (older style notification)
            this.push = Push.create(`Incoming call ${remoteName}`, {
              body: formatPhoneNumber(this.number),
              icon: selectConfig(store.getState(), 'faviconURL'),
              timeout: 45000,
              onClick: () => {
                window.focus();
                this.push.then((pushResult) => {
                  pushResult.close();
                });
              },
            }).catch(() => {
            });
          }
        }
      });

      const headerAlert = this.getHeader('Alert-Info');
      const autoAnswer = ['Auto Answer', 'AutoAnswer', 'Answer'];
      const ringAnswer = ['Ring Answer', 'RingAnswer'];
      if (headerAlert) {
        if (autoAnswer.includes(headerAlert)) {
          if (selectConfig(store.getState(), 'PORTAL_WEB_PHONE_AUTO_ANSWER_MIC_MUTE') === 'yes') {
            this.isMuted = true;
          }
          this.auto_ans = true;
          store.dispatch(acceptCallAction({ call: this }));
        }
        if (ringAnswer.includes(headerAlert)) {
          if (selectConfig(store.getState(), 'PORTAL_WEB_PHONE_RING_ANSWER_MIC_MUTE') === 'yes') {
            this.isMuted = true;
          }
          this.ringdelay = selectConfig(store.getState(), 'PORTAL_WEB_PHONE_RING_ANSWER_DELAY') || 1.5;
          if (this.ringdelay > 999) {
            this.ringdelay /= 1000;
          }

          setTimeout((self) => {
            store.dispatch(acceptCallAction(self));
          }, this.ringdelay * 1000, { call: this });
        }
      }

      if (!this.auto_ans) {
        const activeId = selectActiveCallId(store.getState());
        const callControl = selectHidDevice(store.getState());
        const useHeadsetRinger = selectUseHeadsetRinger(store.getState());
        if (activeId) {
          // This would be call waiting ringtone.
          playCallWaiting();
        } else if (!callControl || !useHeadsetRinger) {
          playRinger();
        }
      }
    }

    if (this.direction === 'outbound') {
      if ((this.number && this.number.includes('adhoc')) || (this.name != null && this.name.includes('adhoc'))) {
        this.callerId = ' ';
      }
    }

    // Setup session state change handler
    this.session.stateChange.addListener(async (newState) => {
      switch (newState) {
        case SessionState.Establishing:
          if (this.direction === 'outbound') {
            this.status = 'outboundProgressing';
          }
          if (this.direction === 'inbound') {
            this.status = 'inboundProgressing';
          }
          store.dispatch(sessionsUpdatedAction());
          if (typeof this.session.sessionDescriptionHandler !== 'undefined') {
            store.dispatch(
              addStreamAction(this.session.sessionDescriptionHandler.localMediaStream),
            );
          }
          break;
        case SessionState.Established: {
          // WP-1621
          if (this.isOnHold) {
            this.status = 'accepted';
            this.setHold(true);
            // todo set callControl to hold?
            break;
          }

          let hidState = getHidState();

          if (hidState.incomingCallSessions.includes(this.id)) {
            removeIncomingCall(this.id);
            addAnsweredCall(this.id);

            const callControl = selectHidDevice(store.getState());
            if (callControl) {
              if (hidState.answeredCallSessions.length > 0) {
                callControl.acceptIncomingCall(AcceptIncomingCallBehavior.HOLD_CURRENT);
              } else {
                callControl.acceptIncomingCall();
              }
            }
          }

          hidState = getHidState();
          if (hidState.onGoingCalls === 1 && hidState.answeredCallSessions.length === 2) {
            const callControl = selectHidDevice(store.getState());
            if (callControl) {
              callControl.startCall();
            }
          }

          hidState = getHidState();

          this.status = 'accepted';

          this.setupLocalMedia();
          this.setupRemoteMedia();

          stopRinger();
          stopRingback();
          stopCallWaiting();

          this.startTimer();
          if (typeof clearNotifications === 'function') clearNotifications(this.id);

          break;
        }
        case SessionState.Terminated: {
          if (this.status === 'inboundProgressing') {
            store.dispatch(removeIncomingCallAction(this.id));
          }

          const hidState = getHidState();

          // handle incoming calls hid state
          if (hidState.incomingCallSessions.includes(this.id)) {
            const callControl = selectHidDevice(store.getState());
            if (callControl) {
              // hid state will be updated by onInvite callback
              callControl.rejectIncomingCall().catch(console.debug);
            } else {
              // when call control is not configured update the hid state anyways
              removeIncomingCall(this.id);
            }
          }

          // handle answered call hid state
          if (hidState.answeredCallSessions.includes(this.id)) {
            removeAnsweredCall(this.id);
            const callControl = selectHidDevice(store.getState());
            if (callControl) {
              callControl.endCall().catch(console.error);
            }
          }

          if (this.status !== 'ended') {
            this.status = 'ended';
            this.stopTimer();
            store.dispatch(removeSessionAction({ sessionId: this.id }));
            const sessions = selectCallSessions(store.getState());

            if (_.every(sessions, { status: 'accepted' })) {
              stopRinger();
              stopRingback();
              stopCallWaiting();
            }
          }

          if (selectConfig(store.getState(), 'portalCallPopupAllowComplete') === 'yes') {
            store.dispatch(showDispositionAction({
              callTime: this.callTime,
              callerId: this.callerId || this.name,
              number: this.number,
              direction: this.direction,
              callId: this.callId,
              orig_callid: this.id,
            }));
          }

          if (typeof clearNotifications === 'function') { clearNotifications(this.id); }

          if (!selectActiveCount(store.getState())
              // ignore if on the settings page, this is when hark is enabled
              // && !_.get(store.getState().stateHistory.get('currentState'), 'name', '').includes('settings')
              && !_.get(selectCurrentState(store.getState()), 'name', '').includes('greetings-new')
              && this.session.sessionDescriptionHandler
              && this.session.sessionDescriptionHandler.localMediaStream
              && this.session.sessionDescriptionHandler.localMediaStream.active
          ) {
            store.dispatch(stopStreamsAction());
          }
          break;
        }
        default:
      }
    });
  }

  addNotification(title, actions) {
    const thisSession = this;
    const options = JSON.parse(JSON.stringify(thisSession.notifcationOptions));
    options.actions = actions;

    navigator.serviceWorker.ready.then((registration) => {
      thisSession.push = registration.showNotification(title, options);
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event?.data?.sessionId
          && event?.data?.action
          && event.data.sessionId == thisSession.id // eslint-disable-line eqeqeq
        ) {
          if (event.data.action === 'answer-action') {
            console.debug('this is in in answer-action CallSession');
          }
          if (event.data.action === 'Answer') {
            store.dispatch(removeIncomingCallAction(thisSession.id));
            if (thisSession.status === 'ended') return;

            thisSession.accept();

            store.dispatch(addCardAction({ type: 'call' }));
          } else if (event.data.action === 'Reject') {
            store.dispatch(removeIncomingCallAction(thisSession.id));
            thisSession.reject();
          } else if (event.data.action === 'Hold') {
            thisSession.hold();
          } else if (event.data.action === 'Un Hold') {
            thisSession.unhold();
          } else if (event.data.action === 'Hang Up') thisSession.bye();
        }
      });
    });
  }

  setupLocalMedia() {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const mediaElement = getAudio('local');
    mediaElement.srcObject = this.session.sessionDescriptionHandler.localMediaStream;
  }

  setupRemoteMedia() {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const mediaElement = getAudio('remote');
    const remoteStream = this.session.sessionDescriptionHandler.remoteMediaStream;

    mediaElement.autoplay = true;
    mediaElement.srcObject = remoteStream;
  }

  enableSenderTracks(enable) {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }
    const pc = this.session.sessionDescriptionHandler.peerConnection;
    if (!pc) {
      throw new Error('Peer connection closed.');
    }
    pc.getSenders().forEach((sender) => {
      if (sender.track) {
        // eslint-disable-next-line no-param-reassign
        sender.track.enabled = enable;
      }
    });
  }

  getHeaders() {
    if (!this.session) {
      return null;
    }
    if (this.direction === 'inbound' && this.session.incomingInviteRequest) {
      return this.session.incomingInviteRequest.message.headers;
    }
    if ((this.direction === 'outbound' && this.session.outgoingInviteRequest)) {
      return this.session.outgoingInviteRequest.message.headers;
    }
    return null;
  }

  getHeader(header) {
    const headersList = this.getHeaders();
    if (headersList && headersList[header]) {
      return headersList[header][0].raw;
    }
    return null;
  }

  /**
   * Session method
   * see: http://sipjs.com/api/0.7.6/session/#acceptoptions
   */
  accept() {
    // close the push notification if it is being displayed
    if (this.push) {
      this.push.then((res) => {
        if (res) res.close();
      });
    }

    // if there is already an active call, put the call on hold
    const activeId = selectActiveCallId(store.getState());
    if (activeId
      && selectActiveCall(store.getState())
      && !selectActiveCall(store.getState()).isOnHold
    ) {
      selectActiveCall(store.getState()).hold(false);

      setTimeout(() => {
        if (this.session && _.isFunction(this.session.mute)) {
          this.session.mute();
          this.session.unmute();
        }
      }, 500); // force unmute on answer of second call.
    }

    // set call active
    store.dispatch(setActiveCallId(this.id));

    // accept call
    setTimeout(() => {
      const stream = selectStream(store.getState());
      if (this.session) {
        this.session.sessionDescriptionHandlerOptions = { iceGatheringTimeout: 500 };
        const allowedCodecs = selectConfig(store.getState(), 'PORTAL_WEBRTC_CODEC_LIST_AUDIO');
        // WP-1261 & WP-1265 remove G726 & G729a
        const removedCodecs = selectConfig(store.getState(), 'PORTAL_WEBRTC_CODEC_REMOVED_AUDIO') || '2 18';

        this.session.sessionDescriptionHandlerModifiers = [
          stripVideo,
          allowedCodecs && allowedCodecs.length > 0 ? stripCodecs(allowedCodecs) : null,
          removedCodecs && removedCodecs.length > 0 ? removeCodecs(removedCodecs) : null,
        ];

        try {
          this.session.accept({
            media: {
              stream,
              render: {
                remote: getAudio('remote'),
                local: getAudio('local'),
              },
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    }, 100);

    stopRinger();
    stopCallWaiting();

    this.startTimer();
    this.status = 'accepted';

    store.dispatch(sessionsUpdatedAction());
  }

  /**
   * Session method
   * Sends a BYE request on a confirmed session
   * see: http://sipjs.com/api/0.7.6/session/#byeoptions
   *
   * Triggers the terminate function
   * in inbound or outbound handlers depending on the direction
   */
  bye() {
    if (this.session
      && this.session.state !== SessionState.Terminated
      && this.session.state !== SessionState.Terminating
    ) {
      try {
        this.status = 'ended';
        this.session.bye();

        if (this.threeWayCallDisconnectOthersOnEndFn) {
          // nuke other users
          this.threeWayCallDisconnectOthersOnEndFn();
        }

        this.stopTimer();
        store.dispatch(removeSessionAction({ sessionId: this.id }));
        const sessions = selectCallSessions(store.getState());

        if (_.every(sessions, { status: 'accepted' })) {
          stopRinger();
          stopRingback();
          stopCallWaiting();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Session method
   * see: http://sipjs.com/api/0.7.6/session/#canceloptions
   *
   * Triggers the terminate function
   * in inbound or outbound handlers depending on the direction
   */
  cancel(signalHid = true) {
    if (this.session && (this.session.state === SessionState.Initial
      || this.session.state === SessionState.Establishing
    )) {
      this.status = 'ended';
      this.session.cancel();

      this.stopTimer();
      store.dispatch(removeSessionAction({ sessionId: this.id }));
      const sessions = selectCallSessions(store.getState());

      if (_.every(sessions, { status: 'accepted' })) {
        stopRinger();
        stopRingback();
        stopCallWaiting();
      }

      const callControl = selectHidDevice(store.getState());
      if (signalHid && callControl) {
        callControl.endCall().catch(console.error);
      }
    }
  }

  /**
   *
   */
  ignore(signalHid = true, statusCode = false) {
    this.status = 'ended';
    store.dispatch(removeSessionAction({ sessionId: this.id }));

    this.stopTimer();
    const sessions = selectCallSessions(store.getState());

    if (_.every(sessions, { status: 'accepted' })) {
      stopRinger();
      stopRingback();
      stopCallWaiting();
    }

    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.rejectIncomingCall().catch(console.error);
    }

    if (statusCode) {
      this.session.reject({ statusCode }); // WP-1370 send 486 Busy Here response
    }
  }

  // eslint-disable-next-line class-methods-use-this
  holdModifier(description) {
    if (!description.sdp || !description.type) {
      throw new Error('Invalid SDP');
    }
    let { sdp } = description;
    const { type } = description;
    if (sdp) {
      if (!/a=(sendrecv|sendonly|recvonly|inactive)/.test(sdp)) {
        sdp = sdp.replace(/(m=[^\r]*\r\n)/g, '$1a=sendonly\r\n');
      } else {
        sdp = sdp.replace(/a=sendrecv\r\n/g, 'a=sendonly\r\n');
        sdp = sdp.replace(/a=recvonly\r\n/g, 'a=inactive\r\n');
      }
    }
    return Promise.resolve({ sdp, type });
  }

  setHold(hold) {
    // WP-1621
    if (this.held === hold || this.status === 'outboundProgressing') {
      return;
    }

    const inviteOptions = {
      requestDelegate: {
        onAccept: (response) => {
          this.held = hold;
          console.debug('Positive response = ', response);
        },
        onProgress: (response) => {
          console.debug('Positive response = ', response);
        },
        onReject: (response) => {
          console.debug('Negative response = ', response);
        },
        onTerminated: (response) => {
          console.debug('Negative response = ', response);
        },
      },
    };

    inviteOptions.sessionDescriptionHandlerModifiers = hold ? [this.holdModifier] : [];

    this.session.invite(inviteOptions).then(() => {
      if (!this.isMuted) { this.enableSenderTracks(!hold); }
    }).catch((error) => {
      console.error('Hold invite error:', error);
    });
  }

  /**
   * http://sipjs.com/api/0.7.6/session/#holdoptions-modifiers
   */
  hold(signalHid = true) {
    this.isOnHold = true;

    try {
      this.setHold(true);
    } catch (e) {
      console.error(e);
    }

    store.dispatch(sessionsUpdatedAction());

    // const appName = store.getState().configs.get('appName');
    /* let timer = localStorage.getItem(`${appName}_holdtimer`);

        if (timer === null) {
            // initialize local storage var
            timer = true;
            localStorage.setItem(`${appName}_holdtimer`, 'true');
        } else if (timer === 'true') {
            timer = true;
        } else if (timer === 'false') {
            timer = false;
        }

        if (timer) {
            this.holdTimer = setInterval(() => {
                if (this.holdTimer) {
                    this.session.unhold();
                    setTimeout(() => {
                        if (this.holdTimer) {
                            this.session.hold();
                        }
                    }, 200);
                }
            }, 15000);
        } */

    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.hold().catch(console.error);
    }
  }

  /**
   * http://sipjs.com/api/0.7.6/session/#unholdoptions-modifiers
   */
  unhold(signalHid = true) {
    this.isOnHold = false;

    // clearInterval(this.holdTimer);
    // this.holdTimer = null;

    // unhold the session
    try {
      this.setHold(false);
      this.enableSenderTracks(true);
      this.isMuted = false;
    } catch (e) {
      console.error(e);
    }

    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.resume().catch(console.error);
    }

    store.dispatch(sessionsUpdatedAction());
    this.setupRemoteMedia();
  }

  /**
   * @param signalHid
   */
  mute(signalHid = true) {
    this.enableSenderTracks(false);
    this.isMuted = true;
    store.dispatch(sessionsUpdatedAction());

    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.mute().catch(console.error);
    }
  }

  /**
   * @param signalHid
   */
  unmute(signalHid = true) {
    this.enableSenderTracks(true);
    this.isMuted = false;

    store.dispatch(sessionsUpdatedAction());
    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.unmute().catch(console.error);
    }
  }

  /**
   * Api call to record audio
   */
  record() {
    const thisSession = this;
    const { uid } = selectUser(store.getState());
    const recordid = this.id.replace(this.from_tag, ''); // WP-781, remove from_tag from id
    return nsApi.post({
      object: 'call',
      action: 'record_on',
      format: 'json',
      callid: recordid,
      uid,
    }).then(() => {
      thisSession.isRecording = true;
    });
  }

  /**
   *
   */
  reject(signalHid = true) {
    const { uid } = selectUser(store.getState());

    this.status = 'ended';
    this.stopTimer();
    store.dispatch(removeSessionAction({ sessionId: this.id }));
    const sessions = selectCallSessions(store.getState());

    if (_.every(sessions, { status: 'accepted' })) {
      stopRinger();
      stopRingback();
      stopCallWaiting();
    }

    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.rejectIncomingCall().catch(console.error);
    }

    return nsApi.post({
      object: 'call',
      action: 'reject',
      callid: this.callId,
      uid,
    });
  }

  /**
   * Starts the call duration timer
   */
  startTimer() {
    if (!this.callTime && !this.timer) {
      this.timer = setInterval(() => {
        this.callTime += 1;
        // this is needed to update the UI
        store.dispatch(sessionsUpdatedAction());
      }, 1000);
    }
  }

  /**
   * Stops the call duration timer
   */
  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  /**
   * Api call to stop recording audio
   */
  stopRecording() {
    const thisSession = this;
    const { uid } = selectUser(store.getState());
    const stopRecordId = this.id.replace(this.from_tag, ''); // WP-781, remove from_tag from id
    return nsApi.post({
      object: 'call',
      action: 'record_off',
      format: 'json',
      callid: stopRecordId,
      uid,
    }).then(() => {
      thisSession.isRecording = false;
    });
  }

  /**
   * If blind transfer send number, for assisted send the session object
   * Session method
   * see: http://sipjs.com/api/0.7.6/session/#refertarget-options
   * @param target
   * @param blind
   * @param signalHid
   */
  transfer(target, blind, signalHid = true) {
    if (blind) {
      try {
        const token = nsToken.getDecoded();
        const targetUri = UserAgent.makeURI(`sip:${target.replace('#', '%23')}@${token.domain}`);
        this.session.refer(targetUri);
      } catch (e) {
        console.error('Fail to refer');
        console.error(e);
      }
    } else {
      try {
        this.session.refer(target);
      } catch (e) {
        console.error('Fail to assisted refer');
        console.error(e);
      }
    }

    const callControl = selectHidDevice(store.getState());
    if (signalHid && callControl) {
      callControl.hold().catch(console.error);
    }
  }

  updateContactToTransfered(number, contact) {
    this.contact = contact;
    this.callerId = contact?.name || null;
    this.name = contact?.name || null;
    this.number = number;
    this.formattedNumber = formatPhoneNumber(number);
  }

  runStats() {
    if (typeof this.session !== 'undefined'
      && typeof this.session.sessionDescriptionHandler !== 'undefined'
      && typeof this.session.sessionDescriptionHandler.peerConnection !== 'undefined'
    ) {
      /**
       * firefox callback
       * @param stats
       */
      const mozCallBack = (stats) => {
        this.stats.runStatsCounter += 1;
        stats.forEach((result) => {
          const item = {};
          item.id = result.id;
          item.type = result.type;
          item.timestamp = result.timestamp;
        });
      };

      const webkitCallBack = (stats) => {
        this.stats.runStatsCounter += 1;
        stats.result().forEach((result) => {
          const item = {};
          result.names().forEach((name) => {
            item[name] = result.stat(name);
          });

          item.id = result.id;
          item.type = result.type;
          item.timestamp = result.timestamp;
          item.audioInputLevel = parseInt(item.audioInputLevel, 10); // type cast to integer
          item.audioOutputLevel = parseInt(item.audioOutputLevel, 10); // type cast to integer

          if (typeof item.id !== 'undefined') {
            if (item.id.indexOf('send') !== -1 && typeof item.audioInputLevel !== 'undefined') {
              this.stats.micVolume = item.audioInputLevel;

              // Gather peak values for dynamic range on meter in UI
              if (item.audioInputLevel > this.stats.micPeak) {
                this.stats.micPeak = item.audioInputLevel;
              }
            } else if (item.id.indexOf('Conn-audio') !== -1 && typeof item.audioOutputLevel !== 'undefined') {
              this.stats.speakVolume = item.audioOutputLevel;

              // calculate the packet loss
              this.stats.packetLoss = (parseInt(item.packetsLost, 10) / parseInt(item.packetsReceived, 10)).toFixed(0);

              // Gather peak values for dynamic range on meter in UI
              if (item.audioOutputLevel > this.stats.speakPeak) {
                this.stats.speakPeak = item.audioOutputLevel;
              }

              if (typeof item.googCodecName !== 'undefined') {
                this.stats.codecLabel = item.googCodecName;
              }

              if (typeof item.bytesReceived !== 'undefined') {
                if (this.stats.runStatsCounter % (1000 / this.stats.runStatsMsDelay) === 0) {
                  const kbps = ((item.bytesReceived - this.stats.runStatsLastByte) * 8) / 1000;
                  if (this.stats.runStatsLastByte !== 0) {
                    this.stats.kbps = parseFloat(kbps).toFixed(0);
                  }
                  this.stats.runStatsLastByte = item.bytesReceived;
                }
              }
            }
          }
        });
      };

      // start collecting stats
      this.stats.runStatsInterval = setInterval(() => {
        if (navigator.mozGetUserMedia) {
          // deprecated method
          if (this.session.sessionDescriptionHandler.peerConnection.getReceivers
            && this.session.sessionDescriptionHandler.peerConnection.getReceivers()[0]
          ) {
            this.session.sessionDescriptionHandler.peerConnection.getStats(
              this.session.sessionDescriptionHandler.peerConnection.getReceivers()[0].track,
              (res) => {
                mozCallBack(res);
              },
              mozCallBack,
            );
          } else if (this.session.sessionDescriptionHandler.peerConnection.getLocalStreams
            && this.session.sessionDescriptionHandler.peerConnection.getLocalStreams().length
          ) {
            this.session.sessionDescriptionHandler.peerConnection.getStats(
              this.session.sessionDescriptionHandler.peerConnection.getLocalStreams()[0].getAudioTracks()[0],
              (res) => {
                mozCallBack(res);
              },
              mozCallBack,
            );
          }
        } else {
          this.session.sessionDescriptionHandler.peerConnection.getStats(webkitCallBack);
        }
      }, this.stats.runStatsMsDelay);
    }
  }
}
