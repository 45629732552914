import _, { truncate } from 'lodash';
import {
  call,
  // put,
  select,
} from 'redux-saga/effects';

import Push from 'push.js';
// import { useTranslation } from 'react-i18next';
// import {
//   setLoading,
// } from '../../../state/chat/chatSlice';
import matchContact from '../../../utils/matchContact';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import { selectConfig } from '../../../state/configs/configsSlice';
import { selectUser } from '../../../state/user/userSlice';
import { selectSessions } from '../../../state/chat/chatSlice';

export default function* showMessageNotification(msg) {
  const debug = localStorage.getItem('debugFlag') === 'true';

  // if conversation is muted then do not show notification
  const sessionsCurr = yield select(selectSessions);
  const sessionInList = _.find(sessionsCurr, { id: msg.session_id });

  if (sessionInList && sessionInList.muted === 'yes') {
    return;
  }

  // if window is in focus, do not show notification
  const windowInFocus = document.hasFocus();

  if (windowInFocus) {
    return;
  }

  // do not show outbound message
  const user = yield select(selectUser);
  if (msg.direction === 'term' || msg.from_uid === user.uid) {
    return;
  }

  if (debug) console.debug('this is showMessageNotification msg: ', msg);

  // get the contact of who this is from
  let contact;
  if (msg.from_num !== null) {
    // for phone number
    contact = yield call(matchContact, msg.from_num);

    if (contact === null) {
      contact = {
        first_name: formatPhoneNumber(msg.from_num),
      };
    }
  } else {
    contact = yield call(matchContact, msg.from_uid);
  }

  if (debug) console.debug('this is contact in showmessagenotification: ', contact);

  // const pushTitle = `${contact.first_name} ${t('SAYS')}...`;
  const pushTitle = `${contact.first_name} says...`;

  let strippedString = msg.text.replace(/(<([^>]+)>)/gi, '');

  // TODO if the message is a media file, say something different

  // if video meeting invite, then say something else
  if (msg.type === 'chat-video-invite') {
    strippedString = 'Video Meeting Invite';
  }

  // const faviconURL = yield select(selectConfig);
  const faviconURL = yield select((state) => selectConfig(state, 'faviconURL'));

  Push.create(pushTitle, {
    body: truncate(strippedString, 50, {}),
    icon: faviconURL,
    timeout: 10000,
    onClick() {
      if (debug) console.debug('clicked notification');
      window.focus();
      this.close();
    },
  }).catch(() => { });

  // TODO should change the document title back to regular app title
}
