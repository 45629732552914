/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    loading: true,
    endpoints: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserEndpoints: (state, action) => {
      state.endpoints = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading, setUserEndpoints,
} = devicesSlice.actions;

// Selectors
export const selectLoading = (state) => state.devices.loading;
export const selectUserEndpoints = (state) => state.devices.endpoints;

export default devicesSlice.reducer;
