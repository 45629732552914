import { call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setDispositionsIn } from '../../state/agent/agentSlice';
import bugsnagClient from '../../services/bugsnag/bugsnag';

export default function* fetchInDispositions() {
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    const res = yield nsApi.get({
      object: 'disposition',
      action: 'read',
      disposition_type: 'inbound',
      domain: decodedToken.domain,
    });

    yield put(setDispositionsIn(res));
  } catch (e) {
    console.error('Fetching Summary by User failed:', e);
    bugsnagClient.notify(e);
  }
}
