import { call, select } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { selectAgentType } from '../../../state/agent/agentSlice';
import {
  STATUS_END_SHIFT, STATUS_OFFLINE, STATUS_ONLINE, STATUS_SINGLE_CALL,
} from '../../../constants';

export default function* updateAgentStatus({ payload: status }) {
  const decodedToken = yield call(nsToken.getDecoded);
  let agentType = yield select(selectAgentType);
  if (!agentType) { agentType = decodedToken.sub; }

  if (status.toLowerCase() === STATUS_SINGLE_CALL) {
    try {
      yield call(nsApi.v2, {
        object: 'agentstatus',
        action: 'update',
      }, `domains/~/callqueues/all/agents/${agentType}/onecall`);
      return;
    } catch (e) {
      console.error(e);
    }
  }

  if ([
    STATUS_END_SHIFT,
    STATUS_ONLINE,
    STATUS_OFFLINE,
    STATUS_SINGLE_CALL,
  ].includes(status.toLowerCase())) {
    status = ''; // eslint-disable-line no-param-reassign
  }

  yield call(nsApi.post, {
    object: 'subscriber',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    id: agentType,
    message: status,
    noAuditLog: 'yes',
  });
}
