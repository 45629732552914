/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const ringtonesSlice = createSlice({
  name: 'ringtones',
  initialState: {
    path: null,
    ringtones: null,
    toneName: null,
    callWaitTone: null,
  },
  reducers: {
    setRingtonePath: (state, action) => {
      state.path = action.payload;
    },
    setRingtones: (state, action) => {
      state.ringtones = action.payload;
    },
    setToneName: (state, action) => {
      state.toneName = action.payload;
    },
    setCallWaitTone: (state, action) => {
      state.callWaitTone = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  setRingtonePath,
  setRingtones,
  setToneName,
  setCallWaitTone,
} = ringtonesSlice.actions;

// Selectors
export const selectRingtonePath = (state) => state.ringtones.path;
export const selectRingtone = (state) => {
  const { ringtones } = state.ringtones;
  for (let i = 0; i < ringtones.length; i += 1) {
    if (ringtones[i].selected) {
      return ringtones[i];
    }
  }
  return null;
};
export const selectRingtones = (state) => state.ringtones.ringtones;
export const selectToneName = (state) => state.ringtones.toneName;
export const selectCallWaitTone = (state) => state.ringtones.callWaitTone;

export default ringtonesSlice.reducer;
