import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
// import nsDevice from '@netsapiens/netsapiens-js/dist/device';

const debug = true;

// delete push token from server/api
function* deleteServerPush(fetchAudioTaskRes) {
  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'push',
    action: 'delete',
    domain: decodedToken?.domain,
    user: decodedToken?.user,
    device: fetchAudioTaskRes?.aor,
    network: 'pwa',
    continue: true,
  };
  if (debug) console.debug('this is deleteServerPush params: ', params);

  yield console.debug('end of deleteserverpush');

  const res = yield nsApi.post(params);

  if (debug) console.debug('this is api post res: ', res);
}

function* updateServerPush(subscription, fetchAudioTaskRes) {
  if (debug) console.debug('in _updateServerPush subscription: ', subscription);

  const decodedToken = nsToken.getDecoded();
  // const device = nsDevice;

  // subscription = JSON.parse(subscription);
  const sub = JSON.parse(subscription);
  if (debug) console.debug('in _updateServerPush sub: ', sub);

  if (!sub || subscription === null) {
    console.debug('updateServerPush sub was null, returning');
    return;
  }

  const deviceToken = {
    endpoint: sub.endpoint,
    auth: sub.keys.auth,
    p256dh: sub.keys.p256dh,
  };

  const params = {
    object: 'push',
    action: 'create',
    domain: decodedToken.domain,
    user: decodedToken.user,
    device: fetchAudioTaskRes.aor,
    device_token: encodeURIComponent(JSON.stringify(deviceToken)),
    network: 'pwa',
  };
  if (debug) console.debug('this is _updateServerPush params: ', params);
  // post push create
  const res = yield nsApi.post(params);

  if (debug) console.debug('this is api post res: ', res);
  if (debug) console.debug('this is api post res response: ', res.response);

  // console.log('this is endpoint: ', subscription['endpoint']);
  // console.log('this is ps256dh: ', subscription['keys']);
  // let copypaste = "web-push send-notification
  // --endpoint="+ subscription['endpoint']
  // +" --key=" + subscription['keys']['p256dh'] + " --auth="+ subscription['keys']['auth'] +"
  // --payload=hello+there" +" --vapid-subject=mailto:cwei@netsapiens.com" +"
  // --vapid-pub
  // key=BMliW2pX2AmOb9J4ck4OOxCTMxvEmBO01QLHeuDsfRvMaxfaIBmOWAgUykaFo2Y0l_v9DDz97xi-Wa7QhPilMeE
  // --vapid-pvtkey=n50s7fjp7ja6vqfsHLvJylC-5YIRu_R425Qg6pAttVk";

  // console.log('this is copypaste: ');
  // console.log(copypaste);

  if (res.response === 'ok') {
    // yield put(setPushSubscription(subscription));
  }
}

// to format the applicationServerKey
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

function* subscribeUser() {
  if ('serviceWorker' in navigator) {
    const newRegistrationWorker = yield navigator.serviceWorker.ready;
    try {
      const sub = yield newRegistrationWorker.pushManager.subscribe({
        userVisibleOnly: true,
        sampleField: true,
        // eslint-disable-next-line max-len
        applicationServerKey: urlBase64ToUint8Array('BMliW2pX2AmOb9J4ck4OOxCTMxvEmBO01QLHeuDsfRvMaxfaIBmOWAgUykaFo2Y0l_v9DDz97xi-Wa7QhPilMeE'), // hardcoded for now in order to match the node setVapidDetails
      });

      if (debug) console.debug('Endpoint URL: ', sub.endpoint);
      if (debug) console.debug('sub: ', sub);

      return sub;
    } catch (e) {
      if (Notification.permission === 'denied') {
        if (debug) console.debug('Permission for notifications was denied');
        // console.warn('Permission for notifications was denied');
      } else {
        if (debug) console.debug('Unable to subscribe to push', e);
        console.error('Unable to subscribe to push', e);
      }
      return false;
    }
  }
  return false;
}

/**
 */
function* getSubscription(registration) {
  if (debug) console.debug('this is getsubscription: ', registration);

  if ('Notification' in window && navigator.serviceWorker) {
    if (debug) console.debug('Display the UI to let the user toggle notifications');
  }

  if (Notification.permission === 'granted') {
    /* do our magic */
    if (debug) console.debug('granted');
  } else if (Notification.permission === 'blocked') {
    /* the user has previously denied push. Can't reprompt. */
    if (debug) console.debug('blocked');
  } else {
    /* show a prompt to the user */
    if (debug) console.debug('show prompt');
    console.error('show prompt');
  }

  const sub = yield registration.pushManager.getSubscription().then((subscription) => {
    if (debug) console.debug('this is subscription: ', subscription);

    return subscription;
  });
  if (debug) console.debug('this is sub in _getSubscription: ', sub);

  return sub;
}

/**
 */
export default function* initPush(audioTaskRes) {
  if (debug) console.debug('this is initPush audioTaskRes: ', audioTaskRes);

  if ('serviceWorker' in navigator) {
    if (debug) console.debug('this is navigator: ', navigator);
    const serviceWorkerRegistration = yield navigator.serviceWorker.register('/webphone/root-service-worker.js');
    if (debug) console.debug('this is serviceWorkerRegistration: ', serviceWorkerRegistration);
    let sub = yield getSubscription(serviceWorkerRegistration);

    if (debug) console.debug('this is sub initPush: ', sub);

    if (sub === null) {
      // Update UI to ask user to register for Push
      // did not have push subscription so need to get the subscription
      if (debug) console.debug('Not subscribed to push service!!!!!!!');

      sub = yield subscribeUser();
    }

    localStorage.setItem('pushEnabled', true);
    yield updateServerPush(JSON.stringify(sub), audioTaskRes);
  }
}

export function* unsubPush(audioTaskRes) {
  if (debug) console.debug('in unsubPush audioTaskRes: ', audioTaskRes);

  if ('serviceWorker' in navigator) {
    const serviceWorkerRegistration = yield navigator.serviceWorker.register('/webphone/root-service-worker.js');
    const sub = yield getSubscription(serviceWorkerRegistration);

    if (sub !== null) {
      yield sub.unsubscribe();
      if (debug) console.debug('successful unsub');
      localStorage.setItem('pushEnabled', false);
    } else if (debug) {
      console.debug('sub did not exist to unsub');
    }
  }

  // always attempt to delete push on API/NMS
  if (audioTaskRes) {
    yield deleteServerPush(audioTaskRes);
  }
}
