import { all, call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import {
  setAgentType, setLoading, setQueues,
} from '../../state/agent/agentSlice';
import fetchResponders from '../fetchResponders/fetchResponders';
import fetchAgentDeviceQueues from '../fetchAgentDeviceQueues/fetchAgentDeviceQueues';
import socketSubscribe from '../socket/socketSubscribe/socketSubscribe';
import processAgentStatus from '../AgentCenter/processAgentStatus/processAgentStatus';

let updatedStatus;

// eslint-disable-next-line no-inner-declarations
export function* handleQueue(queue) {
  yield call(socketSubscribe, {
    payload: {
      type: 'queued',
      filter: queue.queue_name,
    },
  });

  yield call(socketSubscribe, {
    payload: {
      type: 'call',
      subtype: 'queue',
      filter: queue.queue_name,
    },
  });

  yield call(fetchResponders, queue.queue_name);
  if (!updatedStatus
    || (updatedStatus !== 'available' && queue.agent.entry_status === 'available')
  ) {
    updatedStatus = queue.agent.entry_status;
  }
}

export default function* fetchAgentQueues() {
  updatedStatus = null;
  const decodedToken = yield call(nsToken.getDecoded);

  try {
    yield put(setLoading(true));

    const res = yield call(nsApi.get, {
      object: 'callqueue',
      action: 'read',
      agent: decodedToken.sub,
      user: decodedToken.user,
      domain: decodedToken.domain,
      format: 'json',
    });

    if (!res || res.length === 0) {
      yield call(fetchAgentDeviceQueues);
      yield put(setLoading(false));
      return;
    }

    yield put(setAgentType(decodedToken.sub));
    yield put(setQueues(res));
    yield call(socketSubscribe, { payload: { type: 'agent', filter: `sip:${decodedToken.sub}` } });
    yield all(res.map((queue) => call(handleQueue, queue)));
    yield call(processAgentStatus);
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
  }
}
