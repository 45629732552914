import { all, call, put } from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import { setLoading, setNewVoicemail, setSavedVoicemail } from '../../../state/voicemail/voicemailSlice';
import matchContact from '../../../utils/matchContact';
import bugsnagClient from '../../../services/bugsnag/bugsnag';

export default function* fetchVoicemail() {
  const token = yield call(nsToken.getDecoded);

  yield put(setLoading(true));

  try {
    const [newVoicemail, savedVoicemail] = yield all([
      call(nsApi.get, {
        object: 'audio',
        action: 'read',
        user: token.user,
        domain: token.domain,
        format: 'json',
        count: 200,
        limit: 200,
        intArray: 'true',
        type: 'vmail/new',
      }),
      call(nsApi.get, {
        object: 'audio',
        action: 'read',
        user: token.user,
        domain: token.domain,
        format: 'json',
        limit: 200,
        intArray: 'true',
        type: 'vmail/save',
      }),
    ]);

    console.debug(newVoicemail, savedVoicemail);

    const processedNew = [];
    for (let i = 0; i < newVoicemail.length; i += 1) {
      newVoicemail[i].last = newVoicemail.length - 1 === i;
      newVoicemail[i].expanded = false; // this is used in the view for accordion animations
      newVoicemail[i].remotepath = newVoicemail[i].remotepath.replace('local.netsapiens.com', 'corp.netsapiens.com');
      newVoicemail[i].remotepath = newVoicemail[i].remotepath.replace(/&amp;/g, '&');
      newVoicemail[i].remotepath = newVoicemail[i].remotepath.replace(/%40/g, '@');
      newVoicemail[i].index = newVoicemail[i].index?.split('.')[0]; // eslint-disable-line prefer-destructuring
      // res[i].remotepath = res[i].remotepath + '&convert=signed';
      newVoicemail[i].vmail_type = 'vmail/new';

      // get contact by name
      newVoicemail[i].contact = matchContact(newVoicemail[i].FromUser?.toString());
      newVoicemail[i].callDetails = { name: newVoicemail[i].FromName, numLabel: null };

      if (newVoicemail[i].contact) {
        // get name
        newVoicemail[i].callDetails.name = newVoicemail[i].contact.name || newVoicemail[i].FromName;

        // get number label
        if (newVoicemail[i].contact.user
          && newVoicemail[i].contact.user === newVoicemail[i].FromUser
        ) {
          newVoicemail[i].callDetails.numLabel = 'Extension';
        }
        if (newVoicemail[i].contact.work_phone
          && newVoicemail[i].contact.work_phone === newVoicemail[i].FromUser
        ) {
          newVoicemail[i].callDetails.numLabel = 'Work';
        }
        if (newVoicemail[i].contact.cell_phone
          && newVoicemail[i].contact.cell_phone === newVoicemail[i].FromUser
        ) {
          newVoicemail[i].callDetails.numLabel = 'Mobile';
        }
        if (newVoicemail[i].contact.home_phone
          && newVoicemail[i].contact.home_phone === newVoicemail[i].FromUser
        ) {
          newVoicemail[i].callDetails.numLabel = 'Home';
        }
      }

      processedNew.push(newVoicemail[i]);
    }

    const processedSaved = [];
    for (let i = 0; i < savedVoicemail.length; i += 1) {
      savedVoicemail[i].last = savedVoicemail.length - 1 === i;
      savedVoicemail[i].expanded = false; // this is used in the view for accordion animations
      savedVoicemail[i].remotepath = savedVoicemail[i].remotepath.replace('local.netsapiens.com', 'corp.netsapiens.com');
      savedVoicemail[i].remotepath = savedVoicemail[i].remotepath.replace(/&amp;/g, '&');
      savedVoicemail[i].remotepath = savedVoicemail[i].remotepath.replace(/%40/g, '@');
      // savedVoicemail[i].remotepath = savedVoicemail[i].remotepath + '&convert=signed';
      savedVoicemail[i].index = savedVoicemail[i].index.split('.')[0]; // eslint-disable-line prefer-destructuring
      savedVoicemail[i].vmail_type = 'vmail/save';

      // get contact by name
      savedVoicemail[i].contact = matchContact(savedVoicemail[i].FromUser);
      savedVoicemail[i].callDetails = { name: savedVoicemail[i].FromName, numLabel: null };

      if (savedVoicemail[i].contact) {
        // get name
        savedVoicemail[i].callDetails.name = savedVoicemail[i].contact.name
          || savedVoicemail[i].FromName;

        // get number label
        if (savedVoicemail[i].contact.user
          && savedVoicemail[i].contact.user === savedVoicemail[i].FromUser
        ) {
          savedVoicemail[i].callDetails.numLabel = 'Extension';
        }
        if (savedVoicemail[i].contact.work_phone
          && savedVoicemail[i].contact.work_phone === savedVoicemail[i].FromUser
        ) {
          savedVoicemail[i].callDetails.numLabel = 'Work';
        }
        if (savedVoicemail[i].contact.cell_phone
          && savedVoicemail[i].contact.cell_phone === savedVoicemail[i].FromUser
        ) {
          savedVoicemail[i].callDetails.numLabel = 'Mobile';
        }
        if (savedVoicemail[i].contact.home_phone
          && savedVoicemail[i].contact.home_phone === savedVoicemail[i].FromUser
        ) {
          savedVoicemail[i].callDetails.numLabel = 'Home';
        }
      }

      processedSaved.push(savedVoicemail[i]);
    }

    yield put(setNewVoicemail(processedNew));
    yield put(setSavedVoicemail(processedSaved));

    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    console.error(e);
    bugsnagClient.notify(e);
  }
}
